import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { ethers } from "ethers";
import Tab from "../Tab/Tab";
import { getContract } from "config/contracts";

import {
  getBuyFlpToAmount,
  getBuyFlpFromAmount,
  adjustForDecimals,
  FLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  USDF_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";

import { approvePlugin, useMinExecutionFee } from "domain/legacy";

import TokenSelector from "../Exchange/TokenSelector";
import BuyInputSection from "../BuyInputSection/BuyInputSection";
import Tooltip from "../Tooltip/Tooltip";

import Reader from "abis/Reader.json";
import Vault from "abis/Vault.json";
import FlpManager from "abis/FlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import Router from "abis/Router.json";
import LiquidityRouter from "abis/LiquidityRouter.json";
import Token from "abis/Token.json";

import "./SagaFlpSwap.css";
import "../Flp/FlpSwap.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getNativeToken, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getAums } from "domain/aums";
import { usePrevious } from "lib/usePrevious";
import { pushErrorNotification, pushSuccessNotification } from "pages/Exchange/Exchange";
import { useUserReferralCode } from "domain/referrals";
// import TradeHistory from "components/Exchange/TradeHistory";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { getTierLowerLimit, getTierRewardRate, getTierUpperLimit, SAGA_REWARD_RATE_PRECISION } from "domain/saga";

const { AddressZero } = ethers.constants;

const FlpSwapbox = forwardRef((props, ref) => {
  const {
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldDisableValidationForTesting,
    userStat,
    tierIndex,
    totalStat
  } = props;

  const swapLabel = "Buy FLP For Saga Rewards";
  const tabLabel = t`Buy FLP For Saga Rewards`;
  const { address: account, isConnected: active, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const { chainId } = useChainId();
  const currentAccount = account;

  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const [swapValue, setSwapValue] = useState("");
  const [flpValue, setFlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address`,
    AddressZero
  );

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isWaitingForLiquidityRouterApproval, setIsWaitingForLiquidityRouterApproval] = useState(false);
  const [isLiquidityRouterApproving, setIsLiquidityRouterApproving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const stakedFlpTrackerAddress = getContract(chainId, "StakedFlpTracker");
  const feeFlpTrackerAddress = getContract(chainId, "FeeFlpTracker");
  const usdfAddress = getContract(chainId, "USDF");
  const flpManagerAddress = getContract(chainId, "FlpManager");
  const routerAddress = getContract(chainId, "Router");
  const liquidityRouterAddress = getContract(chainId, "LiquidityRouter");
  const tokensForBalanceAndSupplyQuery = [stakedFlpTrackerAddress, usdfAddress];

  const { attachedOnChain, userReferralCode } = useUserReferralCode(signer, chainId, account);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [`FlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Reader, [tokenAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, tokenBalances, undefined);

  const { data: balancesAndSupplies } = useSWR(
    [
      `FlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, Reader, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: aumAddition } = useSWR([`FlpSwap:aumAddition:${active}`, chainId, flpManagerAddress, "aumAddition"], {
    fetcher: contractFetcher(signer, FlpManager),
  });

  const { data: aumDeduction } = useSWR(
    [`FlpSwap:aumDeduction:${active}`, chainId, flpManagerAddress, "aumDeduction"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`FlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(signer, Vault),
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, routerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: liquidityRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, liquidityRouterAddress],
    {
      fetcher: contractFetcher(signer, Router),
    }
  );

  const { data: flpBalance } = useSWR(
    [`FlpSwap:flpBalance:${active}`, chainId, feeFlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, RewardTracker),
    }
  );

  const flpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const usdfSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);

  const {
    liquidityMinExecutionFee,
    liquidityMinExecutionFeeUSD,
    // liquidityMinExecutionFeeErrorMessage
  } = useMinExecutionFee(signer, active, chainId, infoTokens);

  const aums = getAums(chainId, infoTokens, aumAddition, aumDeduction);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0];
  }
  const flpPrice =
    aum && aum.gt(0) && flpSupply.gt(0)
      ? aum.mul(expandDecimals(1, FLP_DECIMALS)).div(flpSupply)
      : expandDecimals(1, USD_DECIMALS);

  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const flpAmount = parseValue(flpValue, FLP_DECIMALS);


  const esFXDXReward = useMemo(() => {
    if (!flpAmount) {
      return 0;
    }

    if (flpAmount && flpAmount.gt(0) && tierIndex >= 0) {
      let remaining = flpAmount;
      let currentTotal = totalStat.totalBuyAmount;
      let rewards = bigNumberify(0);
      for (let i = tierIndex; i <= 5; i++) {
        let tierLowerLimit = getTierLowerLimit(i);
        let tierUpperLimit = getTierUpperLimit(i);
        let tierAvailability = i === tierIndex ? tierUpperLimit.sub(currentTotal.sub(tierLowerLimit)) : tierUpperLimit.sub(tierLowerLimit);
        if (tierAvailability.gt(remaining) || i === 5) {
          if (i === 5) {
            remaining = tierAvailability
          }
          rewards = rewards.add(remaining.mul(getTierRewardRate(i)).div(SAGA_REWARD_RATE_PRECISION));
          return rewards;
        }
        else {
          rewards = rewards.add(tierAvailability.mul(getTierRewardRate(i)).div(SAGA_REWARD_RATE_PRECISION));
          remaining = remaining.sub(tierAvailability);
        }
      }
    }

  }, [flpAmount, tierIndex,totalStat])

  const needApproval =
    swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const prevNeedApproval = usePrevious(needApproval);
  const prevSwapTokenAddress = usePrevious(swapTokenAddress);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const flpUsdMax = flpAmount && flpPrice ? flpAmount.mul(flpPrice).div(expandDecimals(1, FLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdfAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdfAmount, USDF_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setSwapValue(value);
  };

  const onFlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setFlpValue(value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
  };

  useImperativeHandle(ref, () => ({
    onAddLiquidity(account, token, amount, aumInUsdf, flpSupply, usdfAmount, mintAmount, e) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Bought ${formatAmount(mintAmount, FLP_DECIMALS, 4, true)} FLP with ${formatAmount(
        amount,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol}.`;

      pushSuccessNotification(chainId, message, e);
    },

    onRemoveLiquidity(account, token, flpAmount, aumInUsdf, flpSupply, usdfAmount, amountOut, e) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Sold ${formatAmount(flpAmount, FLP_DECIMALS, 4, true)} FLP for ${formatAmount(
        amountOut,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol}.`;

      pushSuccessNotification(chainId, message, e);
    },

    onCancelAddLiquidity(
      account,
      token,
      amountIn,
      minUsdf,
      minFlp,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Could not buy FLP with ${formatAmount(
        amountIn,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);
    },

    onCancelRemoveLiquidity(
      account,
      tokenOut,
      flpAmount,
      minOut,
      receiver,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, tokenOut);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Could not sell ${formatAmount(
        flpAmount,
        FLP_DECIMALS,
        4,
        true
      )} FLP for ${tokenSymbol} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);
    },
  }));

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setFlpValue("");
          setFeeBasisPoints("");
          return;
        }

        const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyFlpToAmount(
          swapAmount,
          swapTokenAddress,
          infoTokens,
          flpPrice,
          usdfSupply,
          totalTokenWeights
        );
        const nextValue = formatAmountFree(nextAmount, FLP_DECIMALS, FLP_DECIMALS);
        setFlpValue(nextValue);
        setFeeBasisPoints(feeBps);

        return;
      }

      if (!flpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyFlpFromAmount(
          flpAmount,
          swapTokenAddress,
          infoTokens,
          flpPrice,
          usdfSupply,
          totalTokenWeights
        );
        const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
        setSwapValue(nextValue);
        setFeeBasisPoints(feeBps);
      }
    };

    updateSwapAmounts();
  }, [
    anchorOnSwapAmount,
    swapAmount,
    flpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    flpPrice,
    usdfSupply,
    totalTokenWeights,
  ]);

  useEffect(() => {
    if (
      swapToken &&
      swapTokenAddress === prevSwapTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{swapToken.symbol} approved!</div>);
    }
  }, [
    swapToken,
    swapTokenAddress,
    prevSwapTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    isWaitingForApproval,
  ]);

  const needLiquidityRouterApproval = swapTokenAddress !== AddressZero && !liquidityRouterApproved;
  const prevNeedLiquidityRouterApproval = usePrevious(needLiquidityRouterApproval);

  useEffect(() => {
    if (!needLiquidityRouterApproval && prevNeedLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      setIsWaitingForLiquidityRouterApproval(false);
      helperToast.success(<div>Liquidity enabled!</div>);
    }
  }, [
    needLiquidityRouterApproval,
    prevNeedLiquidityRouterApproval,
    setIsWaitingForLiquidityRouterApproval,
    isWaitingForLiquidityRouterApproval,
  ]);

  const fillMaxAmount = () => {
    setAnchorOnSwapAmount(true);
    setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
    return;
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`FLP buy disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!flpAmount || flpAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
    if (
      !savedShouldDisableValidationForTesting &&
      swapTokenInfo &&
      swapTokenInfo.balance &&
      swapAmount &&
      swapAmount.gt(swapTokenInfo.balance)
    ) {
      return [t`Insufficient ${swapTokenInfo.symbol} balance`];
    }

    if (swapTokenInfo.maxUsdfAmount && swapTokenInfo.usdfAmount && swapUsdMin) {
      const usdfFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDF_DECIMALS);
      const nextUsdfAmount = swapTokenInfo.usdfAmount.add(usdfFromAmount);
      if (swapTokenInfo.maxUsdfAmount.gt(0) && nextUsdfAmount.gt(swapTokenInfo.maxUsdfAmount)) {
        return [t`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      return false;
    }
    if (isLiquidityRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isSwapTokenCapReached) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isSwapTokenCapReached) {
      return t`Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      return t`Enabling Buy FLP...`;
    }
    if (isLiquidityRouterApproving) {
      return t`Enabling Buy FLP...`;
    }
    if (needLiquidityRouterApproval) {
      return t`Enable Buy FLP`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return t`Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return t`Buying...`;
    }

    return t`Buy FLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: swapToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const approveLiquidityRouter = () => {
    setIsLiquidityRouterApproving(true);
    return approvePlugin(chainId, liquidityRouterAddress, {
      signer,
      pendingTxns,
      setPendingTxns,
      sentMsg: t`Enable liquidity sent.`,
      failMsg: t`Enable liquidity failed.`,
    })
      .then(() => {
        setIsWaitingForLiquidityRouterApproval(true);
      })
      .finally(() => {
        setIsLiquidityRouterApproving(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const buyFlp = () => {
    setIsSubmitting(true);

    const minFlp = flpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);
    const acceptablePrice = swapTokenInfo.minPrice
      .mul(BASIS_POINTS_DIVISOR - savedSlippageAmount)
      .div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(liquidityRouterAddress, LiquidityRouter.abi, signer);

    const method = swapTokenAddress === AddressZero ? "createAddLiquidityETH" : "createAddLiquidity";

    const params =
      swapTokenAddress === AddressZero
        ? [0, minFlp, acceptablePrice, liquidityMinExecutionFee, referralCode]
        : [swapTokenAddress, swapAmount, 0, minFlp, acceptablePrice, liquidityMinExecutionFee, referralCode];
    const value =
      swapTokenAddress === AddressZero ? swapAmount.add(liquidityMinExecutionFee) : liquidityMinExecutionFee;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: t`Buy submitted.`,
      failMsg: t`Buy failed.`,
      successMsg: t`Requested buy FLP with ${formatAmount(swapAmount, swapTokenInfo.decimals, 4, true)} ${swapTokenInfo.symbol
        }!`,
      setPendingTxns,
    })
      .then(async () => { })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      openConnectModal();
      return;
    }

    if (needLiquidityRouterApproval) {
      approveLiquidityRouter();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    buyFlp();
  };

  let payLabel = t`Pay`;
  let receiveLabel = t`Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (swapUsdMin) {
    payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
  }
  if (flpUsdMax) {
    receiveBalance = `$${formatAmount(flpUsdMax, USD_DECIMALS, 2, true)}`;
  }

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  return (
    <div className="FlpSwap">
      <div className="FlpSwap-content">
        <div className="Flp-box-container">
          <Tab options={[t`Buy FLP For Saga Rewards`]} option={tabLabel} className="Exchange-swap-option-tabs" />
          <div className="FlpSwap-box App-box">
            <BuyInputSection
              topLeftLabel={payLabel}
              topRightLabel={t`Bal:`}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
              onClickTopRightLabel={fillMaxAmount}
              onClickMax={fillMaxAmount}
              selectedToken={swapToken}
              balance={payBalance}
            >
              <TokenSelector
                label={t`Pay`}
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="FlpSwap-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>

            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={t`Bal:`}
              tokenBalance={`${formatAmount(flpBalance, FLP_DECIMALS, 4, true)}`}
              inputValue={flpValue}
              onInputValueChange={onFlpValueChange}
              balance={receiveBalance}
              defaultTokenName={"FLP"}
            >
              <div className="selected-token">
                {/* FLP <img src={flp24Icon} alt="flp24Icon" /> */}
                FLP
              </div>
            </BuyInputSection>

            <div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">{feeBasisPoints > 50 ? t`WARNING: High Fees` : t`Fees`}</div>
                <div className="align-right fee-block">
                  <Tooltip
                    handle={isSwapTokenCapReached ? "NA" : feePercentageText}
                    position="right-bottom"
                    renderContent={() => {
                      if (!feeBasisPoints) {
                        return (
                          <div className="text-white">
                            <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                          </div>
                        );
                      }
                      return (
                        <div className="text-white">
                          {feeBasisPoints > 50 && <Trans>To reduce fees, select a different asset to pay with.</Trans>}
                          <Trans>Check the "Save on Fees" section below to get the lowest fee percentages.</Trans>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">{t`Execution Fee`}</div>
                <div className="align-right fee-block">
                  <Tooltip
                    handle={`${formatAmountFree(liquidityMinExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                    position="right-top"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={t`Network fee`}
                            value={`${formatAmountFree(
                              liquidityMinExecutionFee,
                              18,
                              5
                            )} ${nativeTokenSymbol} ($${formatAmount(liquidityMinExecutionFeeUSD, USD_DECIMALS, 2)})`}
                          />
                          <br />
                          <Trans>
                            This is the network cost required to execute the request.{" "}
                            <ExternalLink href="https://fxdx.gitbook.io/fxdx/trading#execution-fee">
                              More Info
                            </ExternalLink>
                          </Trans>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">{t`esFXDX Rewards`}</div>
                <div className="align-right fee-block">
                  <Tooltip
                    handle={`${formatAmount(esFXDXReward, 18, 2, true)} esFXDX`}
                    position="right-top"
                    renderContent={() => {
                      return (
                        <>
                          <Trans>
                            The esFXDX reward is based on the total amount of FLP that the current account contributed for FLP Saga and held over the period of time.
                          </Trans>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              {/* <div className="Exchange-info-row">
                <div className="Exchange-info-label">{t`Minimum Hold Period`}</div>
                <div className="align-right fee-block">
                  <Tooltip
                    handle={"1 Month"}
                    position="right-top"
                    renderContent={() => {
                      return (
                        <div className="text-white">
                          <Trans>The Minimum hold duration to be eligible for additional Saga rewards is 1 Month</Trans>
                        </div>
                      );
                    }}
                  />
                </div>
              </div> */}
              {userStat && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Tooltip
                      handle={t`Total Commitment`}
                      position="left-top"
                      renderContent={() => {
                        return (
                          <div className="text-white">
                            <Trans>The total amount of FLP that the current account commited for FLP Saga.</Trans>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="align-right fee-block">
                    {formatAmount(userStat.totalCommitment, FLP_DECIMALS, 4)} FLP
                  </div>
                </div>
              )}
              {userStat && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Tooltip
                      handle={t`Tier Commitment`}
                      position="left-top"
                      renderContent={() => {
                        return (
                          <div className="text-white">
                            <Trans>
                              The FLP amount that the current account commited for the current active tier of FLP Saga.
                            </Trans>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="align-right fee-block">
                    {formatAmount(userStat.tierCommitments[tierIndex], FLP_DECIMALS, 4)} FLP
                  </div>
                </div>
              )}
            </div>
            {/* {liquidityMinExecutionFeeErrorMessage && (
              <div className="Confirmation-box-warning">{liquidityMinExecutionFeeErrorMessage}</div>
            )} */}
            <div className="FlpSwap-cta Exchange-swap-button-container">
              <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
                {getPrimaryText()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FlpSwapbox;
