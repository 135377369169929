import React from "react";
import { Trans } from "@lingui/macro";
import useSWR from "swr";
import { getContract } from "config/contracts";
import { USD_DECIMALS, USDF_DECIMALS, PLACEHOLDER_ACCOUNT, importImage } from "lib/legacy";
import { getAccountUrl } from "lib/legacy";

import Reader from "abis/Reader.json";

import "./AssetList.css";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatKeyPrice } from "lib/numbers";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import { FiExternalLink } from "react-icons/fi";
import { getExplorerUrl } from "config/chains";

export default function AssetList() {
  const { isConnected: active, address: account, signer } = useWallet();

  const { chainId } = useChainId();
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);
  const readerAddress = getContract(chainId, "Reader");
  const accountUrl = getAccountUrl(chainId, account);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [`FlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Reader, [tokenAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, tokenBalances, undefined);

  return (
    <div className="FlpSwap">
      <div className="FlpSwap-token-list TradeHistory">
        <table className="token-table all-trades Exchange-list Orders App-box large ">
          <thead className="Exchange-list-header">
            <tr>
              <th>
                <Trans>TOKEN</Trans>
              </th>
              <th>
                <Trans>PRICE</Trans>
              </th>
              <th>
                <Trans>WALLET</Trans>
              </th>
              <th>
                <Trans>EXPLORER</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {visibleTokens.map((token, i) => {
              const tokenAddress = token.address;

              const tokenInfo = getTokenInfo(infoTokens, token.address);
              let balanceUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
                balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
              }
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

              let amountLeftToDeposit = bigNumberify(0);
              if (tokenInfo.maxUsdfAmount && tokenInfo.maxUsdfAmount.gt(0)) {
                amountLeftToDeposit = tokenInfo.maxUsdfAmount
                  .sub(tokenInfo.usdfAmount)
                  .mul(expandDecimals(1, USD_DECIMALS))
                  .div(expandDecimals(1, USDF_DECIMALS));
              }
              if (amountLeftToDeposit.lt(0)) {
                amountLeftToDeposit = bigNumberify(0);
              }

              return (
                <tr key={token.symbol}>
                  <td className="Exchange-list-item">
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt={token.symbol} width="24px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">{token.name}</div>
                      </div>
                      <div className="App-card-info-subtitle">{token.symbol}</div>
                    </div>
                  </td>
                  <td className="Exchange-list-item">${formatKeyPrice(tokenInfo, "minPrice", true)}</td>

                  <td className="Exchange-list-item">
                    {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
                    {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
                  </td>

                  <td className="Exchange-list-item">
                    <a
                      className="balance-explorer"
                      href={i === 0 ? `${accountUrl}` : `${getExplorerUrl(chainId)}/token/${tokenAddress}?a=${account}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      view on explorer &nbsp; <FiExternalLink className="external-link" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
