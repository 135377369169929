// ReferralImageDownload.js
import html2canvas from 'html2canvas';
import './referalimgcs.css'; // Import the CSS

const downloadReferralImage = async (element, referralCode) => {
    // Apply all the visual styles for the download
    element.style.display = 'block';
    element.innerHTML = `
      <div class="referral-image-download">
        <h1>Kickstart 2024 with FXDX</h1>
        <ol>
          <li>Use my referral code and get upto 25% rebates</li>
          <li>Trying out trading on FXDX, up to 50x leverage on $BTC, $ETH</li>
        </ol>
        <div class="referral-code">
          <strong>${referralCode}</strong>
        </div>
      </div>
    `;
  
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'referral-image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    // Clean up: remove the styles and content after download
    element.removeAttribute('style');
    element.innerHTML = '';
  };
  
  export default downloadReferralImage;

