import React from "react";
import "./ActionCard.css";
const ActionCard = ({ props }) => {
  return (
    <div className="actioncard-container">
      <img className="actioncard-icon" src={require(`../../img/${props.icons}.svg`)} alt={props.title} />
      <h2 className="actioncard-title">{props.title}</h2>
      <p className="actioncard-desc">{props.description}</p>
      <a className="actioncard-btn-wrapper" href={props.link} target="_blank" rel="noreferrer">
        <button className="actioncard-btn">{props.actionName}</button>
      </a>
    </div>
  );
};

export default ActionCard;
