import { Menu } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { useAccount } from "wagmi";

import "./AssetDropdown.css";
import coinmarketcapicon from "img/ic_coinmarketcap_16.svg";
import coingeckoIcon from "img/ic_coingecko_16.svg";
import baseIcon from "img/ic_base_16.svg";
import ethereumIcon from "img/ic_ethereum_16.svg";
import optimismIcon from "img/ic_optimism_16.svg";
import arbitrumIcon from "img/ic_arbitrum_16.svg";
import avalancheIcon from "img/ic_avalanche_16.svg";
import moonbeamIcon from "img/ic_moonbeam_16.svg";
import metamaskIcon from "img/ic_metamask_16.svg";

import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ICONLINKS, PLATFORM_TOKENS } from "config/tokens";
import { useChainId } from "lib/chains";

function AssetDropdown({ assetSymbol, assetInfo }) {
  const { isConnected, connector } = useAccount();
  const { chainId } = useChainId();
  let { coinmarketcap, coingecko, base, arbitrum, avalanche, ethereum, moonbeam, optimism } =
    ICONLINKS[chainId][assetSymbol] || {};
  const unavailableTokenSymbols =
    {
      8453: ["ETH"],
      5: ["ETH"],
      10: ["ETH"],
      420: ["ETH"],
      42161: ["ETH"],
      43114: ["AVAX"],
    }[chainId] || [];

  return (
    <Menu>
      <Menu.Button as="div" className="dropdown-arrow center-both">
        <FiChevronDown size={20} />
      </Menu.Button>
      <Menu.Items as="div" className="asset-menu-items">
        <Menu.Item>
          <>
            {coinmarketcap && (
              <ExternalLink href={coinmarketcap} className="asset-item">
                <img src={coinmarketcapicon} alt="cmc" />
                <p>
                  <Trans>Open in CoinMarketCap</Trans>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        <Menu.Item>
          <>
            {coingecko && (
              <ExternalLink href={coingecko} className="asset-item">
                <img src={coingeckoIcon} alt="cg" />
                <p>
                  <Trans>Open in Coingecko</Trans>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        <Menu.Item>
          <>
            {base && (
              <ExternalLink href={base} className="asset-item">
                <img src={baseIcon} alt="explr" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
            {ethereum && (
              <ExternalLink href={ethereum} className="asset-item">
                <img src={ethereumIcon} alt="Open in explorer" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
            {optimism && (
              <ExternalLink href={optimism} className="asset-item">
                <img src={optimismIcon} alt="Open in explorer" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
            {arbitrum && (
              <ExternalLink href={arbitrum} className="asset-item">
                <img src={arbitrumIcon} alt="Open in explorer" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
            {avalanche && (
              <ExternalLink href={avalanche} className="asset-item">
                <img src={avalancheIcon} alt="Open in explorer" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
            {moonbeam && (
              <ExternalLink href={moonbeam} className="asset-item">
                <img src={moonbeamIcon} alt="Open in explorer" />
                <p>
                  <Trans>Open in Explorer</Trans>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        {/* <Menu.Item>
          <>
            {isConnected && unavailableTokenSymbols.indexOf(assetSymbol) < 0 && (
              <div
                onClick={() => {
                  let token = assetInfo
                    ? { ...assetInfo, image: assetInfo.imageUrl }
                    : PLATFORM_TOKENS[chainId][assetSymbol];

                  if (connector?.watchAsset && token) {
                    const { address, decimals, imageUrl, symbol } = token;
                    connector.watchAsset?.({
                      address: address,
                      decimals: decimals,
                      image: imageUrl,
                      symbol: symbol,
                    });
                  }
                }}
                className="asset-item"
              >
                <img src={metamaskIcon} alt={t`Add to Metamask`} />
                <p>
                  <Trans>Add to Metamask</Trans>
                </p>
              </div>
            )}
          </>
        </Menu.Item> */}
      </Menu.Items>
    </Menu>
  );
}

export default AssetDropdown;