import { HeaderLink } from "components/Header/HeaderLink";
import React, { useState, useEffect, useRef } from "react";
import "./NavItem.css";
import { useLocation, useNavigate } from "react-router-dom";
import useWallet from "lib/wallets/useWallet";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { BASE } from "config/chains";

const NavItem = ({ props, redirectPopupTimestamp, showRedirectModal }) => {
  const [expanded, setexpanded] = useState(false);
  const [textHighlight, settextHighlight] = useState(false);
  const expandedRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { isConnected: active, address: account, chainId } = useWallet();
  useOutsideAlerter(expandedRef, setexpanded);

  function useOutsideAlerter(ref, setState) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setState(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setState]);
  }

  function stringMatchesToProperty(data, inputString) {
    if (data.hasChildren && data.children && Array.isArray(data.children)) {
      for (const child of data.children) {
        if (child.to === inputString) {
          return true;
        }
      }
    }
    return false;
  }

  function handleClick() {
    if (props.hasChildren) {
      setexpanded(!expanded);
    } else {
      navigate(props.to);
    }
  }

  useEffect(() => {
    if (location && props) {
      const textHighlight = stringMatchesToProperty(props, location.pathname);
      settextHighlight(textHighlight);
    }
  }, [location, props]);

  return (
    <div className={`nav-item-wrapper ${textHighlight ? "highligted-text" : ""}`} onClick={handleClick}>
      <p className={`nav-item-title ${props.title === "UnknownEvent" ? "unknown-event-title" : ""}`}>
        {props.title} {props.hasChildren ? expanded ? <IoMdArrowDropright /> : <IoMdArrowDropdown /> : <></>}
      </p>
      {expanded && (
        <div className={`expanded-nav`} ref={expandedRef}>
          {props?.hasChildren &&
            props.children.map((item, index) => {
              if ((item.onBaseOnly && chainId === BASE) || !item.onBaseOnly) {
                return (
                  <HeaderLink
                    to={active && item.needWalletConnected ? `${item.to}/${account}` : `${item.to}`}
                    className={`nav-expanded-lin`}
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                    key={`${index}-item-review`}
                  >
                    <div className={`nav-expanded-cnt ${index !== 0 ? "border-top" : ""}`}>
                      <div className="nav-cnt-left">
                        <p className="nav-expanded-title">{item.title}</p>
                        <p className="nav-expanded-desc">{item.desc}</p>
                      </div>
                    </div>
                  </HeaderLink>
                );
              }

            })}
        </div>
      )}
    </div>
  );
};

export default NavItem;
