import React, { useEffect, useState, useMemo } from "react";
import "./Stakefxdx.css";
import { ethers } from "ethers";
import { t } from "@lingui/macro";
import { getProvider } from "lib/rpc";
import StakeHistory from "./StakeHistory";
import { formatAmount } from "lib/numbers";
import { fxdxData } from "./data/fxdx.data";
import { callContract } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import Footer from "components/Footer/Footer";
import useWallet from "lib/wallets/useWallet";
import { getContract } from "config/contracts";
import { FiExternalLink } from "react-icons/fi";
import Tooltip from "components/Tooltip/Tooltip";
import connectWalletImg from "img/ic_wallet_24.svg";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { bigNumberify, expandDecimals } from "lib/numbers";
import { stakeFxdxVaultData } from "./data/stakeFxdxVault.data";
import { rewardFxdxVaultData } from "./data/rewardFxdxVault.data";
import { rewardRouterV3Data } from "./data/rewardRouterV3.data.js";
import { useTotalStakedFxdx } from "domain/legacy";

const durationInfo = [
  {
    id: 0,
    duration: 3,
    interestRate: 20,
  },
  {
    id: 1,
    duration: 6,
    interestRate: 50,
  },
  {
    id: 2,
    duration: 9,
    interestRate: 80,
  },
  {
    id: 3,
    duration: 12,
    interestRate: 100,
  },
];

const StakeFxdx = () => {
  const [amount, setAmount] = useState(100);
  const [userBalance, setUserBalance] = useState(0);
  // console.log(amount);

  const [stakeDuration, setDuration] = useState(durationInfo[durationInfo.length - 1]);
  // console.log(stakeDuration);

  const [stakes, setStakes] = useState([]);
  const [prevStakes, setPrevStakes] = useState([]);
  const [maxRewardCap, setMaxRewardCap] = useState(0);
  const [activeStakes, setActiveStakes] = useState([]);
  const [needApproval, setNeedApproval] = useState(true);
  const [approving, setApproving] = useState(false);
  const [stakePending, setStakePending] = useState(false);
  const [rewardClaimed, setRewardClaimed] = useState(0);
  const [estimatedReward, setEstimatedReward] = useState(0);

  let { isConnected: active, address: account, signer, chainId, ens } = useWallet();

  const { openConnectModal } = useConnectModal();

  // console.log(active);
  // console.log(account);
  // console.log(signer);
  // console.log(chainId);

  useEffect(() => {
    (async function () {
      const provider = getProvider(null, chainId);
      const fxdxAddress = getContract(chainId, "FXDXV2");
      const rewardFxdxVaultAddress = getContract(chainId, "RewardFxdxVault");

      const token = new ethers.Contract(fxdxAddress, fxdxData.abi, provider);
      const rewardFxdxVault = new ethers.Contract(rewardFxdxVaultAddress, rewardFxdxVaultData.abi, provider);

      const tokenDecimals = await token.decimals();

      const rewardCap = await rewardFxdxVault.MAX_REWARD_CAP();
      setMaxRewardCap(() => {
        return bigNumberify(rewardCap).div(bigNumberify(10).pow(tokenDecimals));
      });
    })();
  }, [chainId]);
  

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        if (account && signer) {
          // console.log(account);

          const fxdxAddress = getContract(chainId, "FXDXV2");
          const rewardRouterV3Address = getContract(chainId, "RewardRouterV3");
          const stakedFxdxVaultAddress = getContract(chainId, "StakedFxdxVault");
          const rewardFxdxVaultAddress = getContract(chainId, "RewardFxdxVault");

          const token = new ethers.Contract(fxdxAddress, fxdxData.abi, signer);
          // console.log(signer._address);

          const allowance = await token.allowance(account, rewardRouterV3Address);

          const tokenDecimals = await token.decimals();
          // console.log(tokenDecimals);

          const fxdxBalance = await token.balanceOf(account);
          setUserBalance(() => {
            return formatAmount(fxdxBalance, tokenDecimals, 2, true);
          });
          
          if (allowance.lt(expandDecimals(amount * 100, tokenDecimals - 2))) {
            setNeedApproval(true);
          } else {
            setNeedApproval(false);
          }

          const stakeFxdxVault = new ethers.Contract(stakedFxdxVaultAddress, stakeFxdxVaultData.abi, signer);
          // console.log(stakeFxdxVault);

          const userStakeIdList = await stakeFxdxVault.getUserStakeInfo(account);
          // console.log(userStakeIdList);

          const rewardFxdxVault = new ethers.Contract(rewardFxdxVaultAddress, rewardFxdxVaultData.abi, signer);

          let userStakes = [];

          for (let stakeId of userStakeIdList) {
            // console.log(stakeId);

            const stake = await stakeFxdxVault.getStakeData(stakeId);
            // console.log(stake);

            const rewardId = await rewardFxdxVault.stakeToRewardMapping(stake._stakeId);
            const { _rewardAmount } = await rewardFxdxVault.getRewardData(rewardId);

            userStakes.push({ ...stake, _rewardAmount });
          }

          // console.log(userStakes);

          if (userStakes !== undefined && userStakes.length > 0) {
            setActiveStakes(() => {
              return userStakes.filter((stakeInfo) => {
                return !stakeInfo._unstaked;
              });
            });
            setPrevStakes(() => {
              return userStakes.filter((stakeInfo) => {
                return stakeInfo._unstaked;
              });
            });
            setStakes(() => {
              return userStakes;
            });
          } else {
            setStakes(() => {
              return [];
            });
            setPrevStakes(() => {
              return [];
            });
            setActiveStakes(() => {
              return [];
            });
          }
        } else {
          setStakes(() => {
            return [];
          });
          setPrevStakes(() => {
            return [];
          });
          setActiveStakes(() => {
            return [];
          });
          setAmount(() => {
            return 100;
          });
          setUserBalance(0);
          setDuration(() => {
            return durationInfo[durationInfo.length - 1];
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [account, signer, amount, chainId]);

  useEffect(() => {
    const updateRewardClaimed = async () => {
      try {
        const fxdxAddress = getContract(chainId, "FXDXV2");
        const rewardFxdxVaultAddress = getContract(chainId, "RewardFxdxVault");
        const token = new ethers.Contract(fxdxAddress, fxdxData.abi, signer);
        // console.log(signer._address);

        const rewardFxdxVault = new ethers.Contract(rewardFxdxVaultAddress, rewardFxdxVaultData.abi, signer);

        let rewardAmountClaimed = 0;
        let estimatedRewardAmount = 0;

        if (stakes !== undefined && stakes.length > 0) {
          for (let stake of stakes) {
            const rewardId = await rewardFxdxVault.stakeToRewardMapping(stake._stakeId);

            if (rewardId !== undefined && rewardId > 0) {
              const { _rewardAmount, _isClaimed } = await rewardFxdxVault.getRewardData(rewardId);

              if (_isClaimed) rewardAmountClaimed = bigNumberify(rewardAmountClaimed).add(_rewardAmount);
              else estimatedRewardAmount = bigNumberify(estimatedRewardAmount).add(_rewardAmount);
            }
          }
        }

        const tokenDecimals = await token.decimals();
        // console.log(tokenDecimals);

        setRewardClaimed(() => {
          return formatAmount(rewardAmountClaimed, tokenDecimals, 2, true);
        });
        setEstimatedReward(() => {
          return formatAmount(estimatedRewardAmount, tokenDecimals, 2, true);
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (account && signer) {
      (async () => {
        await updateRewardClaimed();
      })();
    }
  }, [stakes, signer, account, amount, chainId]);

  const approveFxdx = async () => {
    if (account && signer) {
      setApproving(true);

      const fxdxAddress = getContract(chainId, "FXDXV2");
      const rewardRouterV3Address = getContract(chainId, "RewardRouterV3");
      const token = new ethers.Contract(fxdxAddress, fxdxData.abi, signer);
      // console.log(signer._address);

      callContract(chainId, token, "approve", [rewardRouterV3Address, ethers.constants.MaxUint256], {
        sentMsg: t`Approval Request Sent`,
        failMsg: t`Approval Request Failed`,
        successMsg: t`Approved Successfully!`,
      })
      .then(async (res) => {
        await res.wait(2);
        helperToast.success(<div>Approved Successfully!</div>);
        setApproving(false);
      })
      .catch((error) => {
        console.log(error);
        setApproving(false);
      });
        
    } else {
      try {
        openConnectModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const stakeFxdx = async () => {
    if (signer) {
      const fxdxAddress = getContract(chainId, "FXDXV2");
      const rewardRouterV3Address = getContract(chainId, "RewardRouterV3");
      const token = new ethers.Contract(fxdxAddress, fxdxData.abi, signer);
      // console.log(signer._address);

      let tokenDecimals = await token.decimals();
      // console.log(tokenDecimals);
      // console.log(amount);

      const stakeAmount = expandDecimals(amount * 100, tokenDecimals - 2);
      // console.log(stakeAmount);

      setStakePending(() => {
        return true;
      });

      const rewardRouterV3 = new ethers.Contract(rewardRouterV3Address, rewardRouterV3Data.abi, signer);

      // console.log("stake amount ----->", amount);
      // console.log("stake duration ------>", stakeDuration);

      // let updatedTimestamp = new Date();
      // updatedTimestamp = Math.floor(updatedTimestamp.setMonth(updatedTimestamp.getMonth() + stakeDuration.duration) / 1000);
      let monthDuration = 30 * 24 * 60 * 60;

      callContract(
        chainId,
        rewardRouterV3,
        "stake",
        [
          bigNumberify(stakeAmount),
          monthDuration * stakeDuration.duration,
          stakeDuration.interestRate * 100,
          Math.floor(Date.now() / 1000) + 60,
        ],
        {
          sentMsg: t`Stake Request Sent`,
          failMsg: t`Stake Request Failed`,
          successMsg: t`Staked Successfully!`,
        }
      )
        .then(async (res) => {
          await res.wait(2);
          helperToast.success(<div>Staked Successfully!</div>);
          // console.log(res);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setStakePending(() => {
            return false;
          });
          setAmount(() => {
            return 100;
          });
          setDuration(() => {
            return durationInfo[durationInfo.length - 1];
          });
        });
    } else {
      try {
        openConnectModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClaim = async () => {
    if (signer) {
      const rewardRouterV3Address = getContract(chainId, "RewardRouterV3");
      const rewardRouterV3 = new ethers.Contract(rewardRouterV3Address, rewardRouterV3Data.abi, signer);

      if (activeStakes !== undefined && activeStakes.length > 0) {
        let activeStakeIdList = [];

        for (let stake of activeStakes) {
          if (stake._timestamp.add(stake._duration) <= Math.floor(Date.now() / 1000)) {
            activeStakeIdList.push(stake._stakeId);
          }
        }

        callContract(chainId, rewardRouterV3, "unstake", [activeStakeIdList], {
          sentMsg: t`Claim Request Sent`,
          failMsg: t`Claim Request Failed`,
          successMsg: t`Claimed Successfully!`,
        })
          .then(async (res) => {
            await res.wait(2);
            helperToast.success(<div>Claimed Successfully!</div>);
            // console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        helperToast.error(<div>No Reward to Claim!</div>);
      }
    } else {
      try {
        openConnectModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  //for the 2 decimalnumber
  function updateAmount(event) {
    const amountVal = handleDecimalsOnValue(event.target.value);
    setAmount(() => {
      return amountVal;
    });
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[.]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const stakedReward = stakeDuration !== undefined ? [(amount * stakeDuration.interestRate) / 100] : [0];

  //apy formula
  const fxdxApy = useMemo(() => {
    if (stakeDuration.duration === 12) {
      return stakeDuration.interestRate;
    }
    // console.log(stakeDuration);
    const aprDecimal = ((stakeDuration.interestRate / (stakeDuration.duration * 30)) * 365) / 100;
    // console.log(aprDecimal);
    const totalApy = ((aprDecimal / 365 + 1) ** 365 - 1) * 100;

    return totalApy.toFixed(2);
  }, [stakeDuration]);
  let { stakedFXDX, stakedFXDXUSD } = useTotalStakedFxdx();
  
  return (
    <>
      <div className="stake-header-container">
        <div className="stake-header">
          <div className="stake-heading">Stake FXDX</div>
          <div className="stake-header-info-container">
            <div className="stake-header-info-box">
              <div className="stake-header-info-label">Total Rewards</div>
              <div className="stake-header-info-data">{rewardClaimed.toString()}</div>
            </div>
            <div className="stake-header-info-box">
              <div className="stake-header-info-label">Estimated Rewards</div>
              <div className="stake-header-info-data">{estimatedReward.toString()} FXDX</div>
            </div>
            <button className="claimbtn" onClick={handleClaim}>
              {signer !== undefined && account !== undefined ? <span>Claim All</span> : <span>Connect Wallet</span>}
            </button>
          </div>
        </div>
      </div>

      <div className="stake-card-container">
        <div className="stake-card">
          <div className="stake-info-card">
            <div className="stake-info-card-title">
              FXDX
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.mexc.com/exchange/FXDX_USDT"}
              >
                Buy FXDX &nbsp;&nbsp;
                <FiExternalLink className="external-link" style={{ marginLeft: "-8px", marginRight: "-1px" }} />
              </a>
            </div>

            <div className="stake-info-container">
              <div className="info">
                <div className="infoLabel">Total Allocated</div>
                <div className="infoData"> {formatAmount(maxRewardCap, 0, 0, true)} FXDX </div>
              </div>
              
              <div className="info">
                <div className="infoLabel">Estimated Reward</div>
                <div className="infoData">{stakedReward} FXDX</div>
              </div>

              <div className="info">
                <div className="infoLabel">Estimated Reward APY</div>
                <div className="infoData">
                  <Tooltip
                    handle={`${fxdxApy}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <p>{`Note: APY assumes daily compounding`}</p>
                        </>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="info">
                <div className="infoLabel">Maturity Period</div>
                <div className="infoData">
                  {stakeDuration !== undefined ? `${stakeDuration.duration} month(s)` : "_"}
                </div>
              </div>

              <div className="totalstakeddata">
                <div className="info">
                  <div className="infoLabel">
                    {" "}
                    <span className="stakedglow">Total Staked</span>
                  </div>
                  <div className="infoData">
                    <span className="stakedglow">{stakedFXDX.toLocaleString()} FXDX</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="stake-card-partition"></div>

          <div className="stake-data-card">
            <div>
              <div className="stake-amount-label">
                <div className="inputAmountLabel">Amount</div>

                <div
                  className="user-balance"
                  onClick={() => {
                    setAmount(userBalance);
                  }}
                >
                  <img className="btn-image" height="22px" width="22px" src={connectWalletImg} alt="Connect Wallet" />
                  <div className="max-balance">Balance: {userBalance} FXDX</div>
                  <div className="max-balance">Max</div>
                </div>
              </div>

              <div>
                <input
                  className="inputAmountData"
                  type="text"
                  placeholder="Enter Amount"
                  name="name"
                  value={amount}
                  onChange={(event) => updateAmount(event)}
                />
              </div>
            </div>

            <button className="durationComp">
              {durationInfo.length > 0 &&
                durationInfo.map((item) => {
                  return (
                    <div
                      className={`durationCard ${stakeDuration.id === item.id ? "highlighted" : ""}`}
                      onClick={() =>
                        setDuration(() => {
                          return item;
                        })
                      }
                    >
                      <div className="duration">{item.duration}&nbsp;month</div>
                      <div className="interestRate">~{item.interestRate}%</div>
                    </div>
                  );
                })}
            </button>

            <div className="stakebtnBox">
              <button
                className="stakebtn"
                disabled={approving || stakePending}
                onClick={async () => {
                  if (signer === undefined || account === undefined) {
                    openConnectModal();
                  } else if (needApproval) {
                    await approveFxdx();
                  } else {
                    await stakeFxdx();
                  }
                }}
              >
                {" "}
                {signer !== undefined || account !== undefined
                  ? needApproval
                    ? "Approve FXDX (+1 more step)"
                    : "Stake FXDX"
                  : "Connect Wallet"}
                {}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="stake-header-info-container-mobile">
        <div className="stake-header-info-box-mobile">
          <div className="stake-header-info-label-mobile">Total Rewards</div>
          <div className="stake-header-info-data-mobile">{rewardClaimed.toString()}</div>
        </div>
        <div className="stake-header-info-box-mobile">
          <div className="stake-header-info-label-mobile">Estimated Rewards</div>
          <div className="stake-header-info-data-mobile">{estimatedReward.toString()}</div>
        </div>
        <button className="claimbtn-mobile" onClick={handleClaim}>
          {signer !== undefined && account !== undefined ? <span>Claim</span> : <span>Connect Wallet</span>}
        </button>
      </div>

      <StakeHistory stakes={activeStakes} prevStakes={prevStakes} tokenDecimals={18} />

      <Footer />
    </>
  );
};

export default StakeFxdx;
