import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";

import "./Vest.css";
import { Trans, t } from "@lingui/macro";
import useWallet from "lib/wallets/useWallet";
import { FXDX_DECIMALS, useENS } from "lib/legacy";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getProvider } from "lib/rpc";
import FxdxDistributor from "abis/FxdxDistributor.json";
import VesterRouter from "abis/VesterRouter.json";
import VesterVault from "abis/VesterVault.json";
import esFXDX from "abis/esFXDX.json";
import Fxdx from "abis/FXDXV2.json";
import FaucetToken from "abis/FaucetToken.json";
import { getContract } from "config/contracts";
import { useBalance } from "wagmi";
import { helperToast } from "lib/helperToast";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { VoidSigner, ethers } from "ethers";
import { CHAIN_ID } from "config/chains";
import Modal from "react-modal";
import { RiAccountBoxLine } from "react-icons/ri";
// import { error } from 'console';
Modal.setAppElement("#root");

const VestEsfxdx = (props) => {
  const { setPendingTxns } = props;
  const { isConnected: active, address: account, signer, chainId, ens } = useWallet();
  const provider = getProvider(null, chainId);
  const { ensName } = useENS(account);
  const { openConnectModal } = useConnectModal();
  const [vaultBalance, setVaultBalance] = useState("0.00");
  const [walletBalance, setWalletBalance] = useState("0.00");
  const [vaultCapacity, setVaultCapacity] = useState("0.00");
  const [reserveAmount, setReserveAmount] = useState("0.00");
  const [token, setToken] = useState("0.00");

  const [isClaiming, setIsClaiming] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);
  const [fxdxClaimedAmount, setFxdxClaimedAmounts] = useState("0.00");
  const [fxdxClaimableAmounts, setFxdxClaimableAmounts] = useState("0.00");
  const [esFxdxBal, setEsFxdxBal] = useState("0");

  // 1. Add state for modal visibility and deposit amount
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");

  const [atokenBalance, setAtokenBalance] = useState("0.00"); // State to hold the AToken balance

  const [vestedDurationMinutes, setVestedDurationMinutes] = useState(0);

  const [showMotivationalMessage, setShowMotivationalMessage] = useState(false);

  const [vestingEnd, setVestingEnd] = useState(0);

  const handleClaim = () => {
    console.log("claim", isModalOpen);
    setIsModalOpen(true);
    console.log(isModalOpen);
  };
  
  const inputRef = useRef(null);

  const EsFxdxAddress = "0x6d9A610b8D6b7F021bb7b92431093288d49b413E";
  const VesterRouterAddress = "0xe733102af1773115889B32A9a463D9191Ba80Bc6";
  const VesterVaultAddress = getContract(CHAIN_ID, "VesterVault");
  const VesterVaultContract = new ethers.Contract(VesterVaultAddress, VesterVault.abi, signer);
  const Estoken = new ethers.Contract(EsFxdxAddress, esFXDX, signer);
  const VesterRouterContract = new ethers.Contract(VesterRouterAddress, VesterRouter.abi, signer);

  const esFxdxBalancef = async () => {
    if (Estoken && signer) {
      const esfxdxBalance = await Estoken.balanceOf(account);
      setEsFxdxBal(formatAmount(esfxdxBalance, 18, 2, true));
      return esfxdxBalance;
    }
    else{
        return 0;
    }
  };

  useEffect(() => {
    esFxdxBalancef();
  }, [Estoken, signer]);


  // const EsFxdxAddress = getContract(CHAIN_ID, "ES_FXDX")

  const FxdxAddress = getContract(CHAIN_ID, "FXDXV2");

  // const FxdxDistributorAddress = getContract(CHAIN_ID, "FxdxDistributor")
  const FxdxDistributorAddress = "0xFE89968CFfA96803D70D7445934a63B01D6655db";

  // const AToken = getContract(CHAIN_ID, "AToken");

  const isValidForVest = useMemo(() => {
    // Convert depositAmount to a number and check if it is >= 1
    return Number(depositAmount) >= 1;
  }, [depositAmount]);

//   const handleDepositAmountChange = useCallback((event) => {
//     const newValue = event.target.value;
//     if (/^\d*$/.test(newValue)) { // This regex allows only digits.
//         setDepositAmount(newValue);
//       }
//   }, []);

  const handleDepositAmountChange = (e) =>
  {
     setDepositAmount(e.target.value);
  }

  //vesting esFxdx
  const { data: vestingAmounts } = useSWR(
    active &&
      account && [`VesterVault:vestingAmounts:${active}`, chainId, VesterVaultAddress, "getTotalVested", account],
    {
      fetcher: contractFetcher(signer, VesterVault),
    }
  );

  const { data: esFxdxBalances } = useSWR(
    active && account && [`EsFxdx:balance:${active}`, chainId, EsFxdxAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, esFXDX),
    }
  );

  const { data: fxdxBalancesUser } = useSWR(
    active && account && [`FaucetToken:balance:${active}`, chainId, FxdxAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, FaucetToken),
    }
  );

  // const { data: vestingDurationData } = useSWR(
  //     active ? [`VesterVault:vestingDuration:${active}`, chainId, VesterVaultAddress, "vestingDuration"] : null,
  //     {
  //         fetcher: contractFetcher(signer, VesterVault),
  //     }
  // );

  const vestingDurationData = 31536000;

  const { data: claimedAmounts } = useSWR(
    active &&
      account && [`VesterVault:vestingDuration:${active}`, chainId, VesterVaultAddress, "getClaimedAmounts", account],
    {
      fetcher: contractFetcher(signer, VesterVault),
    }
  );

  const { data: claimableAmounts } = useSWR(
    active &&
      account && [`VesterVault:vestingDuration:${active}`, chainId, VesterVaultAddress, "getClaimableAmount", account],
    {
      fetcher: contractFetcher(signer, VesterVault),
    }
  );

  //fetching the lastVestingTime of the user
  const { data: lastVestingTimes } = useSWR(
    active &&
      account && [`VesterVault:vestingDuration:${active}`, chainId, VesterVaultAddress, "lastVestingTimes", account],
    {
      fetcher: contractFetcher(signer, VesterVault),
    }
  );

  const [winWidth, setwinWidth] = useState(undefined);
  const [windowSize, setwindowSize] = useState(undefined);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  function secondsToTime(seconds) {
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;

    const years = Math.floor(seconds / secondsInYear);
    const months = Math.floor((seconds % secondsInYear) / secondsInMonth);
    const days = Math.floor(((seconds % secondsInYear) % secondsInMonth) / secondsInDay);

    return {
      years: years,
      months: months,
      days: days,
    };
  }

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    windowDimenion.winWidth > 1100 && setwindowSize("large");
    windowDimenion.winWidth < 1100 && setwindowSize("small");

    setwinWidth(windowDimenion.winWidth);
  }, [windowDimenion]);

  const vestedAmount = useMemo(() => {
    if (vestingAmounts) {
      return formatAmount(vestingAmounts, FXDX_DECIMALS, 2, true);
    } else {
      return "0";
    }
  });

  const claimableAmount = useMemo(() => {
    if (claimableAmounts) {
      return formatAmount(claimableAmounts, FXDX_DECIMALS, 10, true);
    } else {
      return "0";
    }
  });

  const fxdxBalanceUser = useMemo(() => {
    if (fxdxBalancesUser) {
      return formatAmount(fxdxBalancesUser, FXDX_DECIMALS, 2, true);
    } else {
      return "0";
    }
  });
  console.log(fxdxBalanceUser, "********************************");

  const fetchAtokenBalance = async () => {
    if (!active || !account) {
      console.log("Returning from here***********************************************");
      return;
    }
    try {
      console.log("Trying to fetch user's fxdx balance");
      const FxdxContract = new ethers.Contract(FxdxAddress, Fxdx.abi, signer);
      const balance = await Fxdx.balanceOf(account);
      const formattedBalance = ethers.utils.formatUnits(balance, FXDX_DECIMALS);
      setAtokenBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching fxdx balance:", error);
    }
  };

  useEffect(() => {
    console.log("fetching user's fxdx balance");
    fetchAtokenBalance(); // Call this function when dependencies change
    console.log(atokenBalance);
  }, [active, account]); // Depend on AToken to re-fetch when it's set

  const [vestedDurationSeconds, setVestedDurationSeconds] = useState("0");

  useEffect(() => {
    console.log(" use Effect called");
    const updateVestingDuration = () => {
      if (vestingDurationData && lastVestingTimes) {
        console.log("inside if of time");
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        const vestingEnd = vestingDurationData + lastVestingTimes.toNumber(); // assuming it's a BigNumber
        const remainingTime = vestingEnd - currentTime; // remaining time in seconds
        console.log(currentTime, vestingEnd, remainingTime);
        //console.log("**************Setting vested duration*************")
        setVestedDurationSeconds(remainingTime);
        console.log("set time dynamically", vestedDurationSeconds);
        //console.log(vestingEnd)
      } else {
        console.log("error in fetching vesting duration");
      }
    };

    updateVestingDuration();
    const intervalId = setInterval(updateVestingDuration, 1000000); // update every second

    return () => clearInterval(intervalId);
  }, [vestingDurationData]);

  const vestedDuration = useMemo(() => {
    return `${vestedDurationSeconds} seconds`;
  }, [vestedDurationSeconds]);

  const claimedAmount = useMemo(() => {
    if (claimedAmounts) {
      return formatAmount(claimedAmounts, FXDX_DECIMALS, 10, true);
    } else {
      return "0.00";
    }
  });

  const RemainingAmount = useMemo(() => {
    if (claimableAmounts && vestingAmounts && claimedAmounts) {
      const remainingAmount = vestingAmounts?.sub(claimableAmounts?.add(claimedAmounts));
      return formatAmount(remainingAmount, FXDX_DECIMALS, 2, true);
    } else {
      return "0.00";
    }
  });

  useEffect(() => {
    const updateVestingDuration = () => {
      if (vestingDurationData) {
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        const vestingEndTime = vestingDurationData; // assuming it's a BigNumber
        const remainingTime = vestingEndTime - currentTime; // remaining time in seconds
        setVestedDurationSeconds(Math.max(0, remainingTime));
        setVestingEnd(vestingEndTime); // Store the vesting end time
      }
    };

    updateVestingDuration();
    const intervalId = setInterval(updateVestingDuration, 100000); // update every second

    return () => clearInterval(intervalId);
  }, [vestingDurationData]);

  const onPrimaryClickEsClaim = () => {
    if (!active) {
      openConnectModal();
      return;
    }
    claimVestedEsFXDX();
  };

  async function claimVestedEsFXDX() {
    setIsClaiming(true);    
    if(claimableAmount === "0.0000000000"){
      helperToast.error(<div>Oops! No rewards yet</div>);
    } 
    else if(claimableAmount) {
    console.log("inside else")
    const contract = new ethers.Contract(VesterRouterAddress, VesterRouter.abi, signer);
    callContract(chainId, contract, "claim", [], {
      sentMsg: t`Claim submitted.`,
      failMsg: t`Claim failed.`,
      successMsg: t`Claimed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .catch((err)=> {
        // helperToast.error(<div>Oops! No rewards yet</div>);
        console.log("eororororororororororororororororo")
      }
    )
      .finally(() => {
        setIsClaiming(false);
      });
    } else {
            helperToast.error(<div>Oops! No rewards yet</div>);
        }
    }
  

  // 3. Update depositEsFXDX function to accept amount
  const depositEsFXDX = async (amount) => {

    setIsDepositing(true);
   
    console.log("cum in deposit");
    console.log("contract is ", VesterRouterContract);
    if(depositAmount || depositAmount !== "" ) {
        const depositAmountBN = ethers.utils.parseUnits(amount, FXDX_DECIMALS);
        callContract(chainId, VesterRouterContract, "vest", [depositAmountBN], {
        sentMsg: "Vest submitted.",
        failMsg: "Vest failed.",
        successMsg: "Vested",
        setPendingTxns,
        })
      // .then(async (res) => console.log("pass"))
      // .catch((err)=>{
      //   console.log(err) ;
      //   helperToast.error(<div>Transaction rejected from Wallet!</div>); 
    // })
    // .then(async (res) => {
    //   helperToast.success(<div>Vested Successfully!</div>);
    //   setIsDepositing(false);
    //   setIsModalOpen(false);
    // })
    .then(async (res) => {})
    .catch((error) => {
      console.log("**********sdflkcsjflnkjrfijkearlmfklaermflkarmflksmfklasrmfklrmf");
      // helperToast.error(<div>Vest transaction rejected!</div>);
      setIsDepositing(false);
      setIsModalOpen(false);
    })
    .finally(() => {
      setIsDepositing(false);
      setIsModalOpen(false);
    });
    }
    else {
        helperToast.error(<div>Empty deposit amount!</div>); 
    }
  };

  // 2. Modal Component
  const DepositModal = () => {
    // Modal styles
    const modalStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        marginTop: "20px",
        borderTop: "1px solid #333", // Darker border to match the reference
        paddingTop: "20px",
        fontSize: "1.8rem",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#222124", // Match the background color of the reference
        color: "white", // Keep text color white
        borderRadius: "10px", // Match rounded corners of the reference
        padding: "20px", // Padding inside the modal
        width: "400px", // Adjust width to match the reference
        border: "none", // No border as per the reference
        boxSizing: "border-box", // Include padding in the width
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)", // Semi-transparent overlay
      },
    };

    const closeButtonStyle = {
      position: "absolute",
      top: "16px",
      right: "20px",
      border: "none",
      backgroundColor: "transparent",
      color: "white",
      cursor: "pointer",
      fontSize: "2.0rem",
    };

    const labelStyle = {
      fontSize: "1.8rem",
      display: "block",
      marginBottom: "15px",
      color: "#AAA", // Slightly lighter text for the labels to match the reference
    };

    const balanceInfoStyle = {
      marginTop: "20px",
      borderTop: "1px solid #333", // Darker border to match the reference
      paddingTop: "20px",
      fontSize: "1.5rem",
    };

    const balanceRowStyle = {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      color: "#AAA", // Slightly lighter text for balance rows to match the reference
    };

    // Modified input group style
    const inputGroupStyle = {
      position: "relative",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#333",
      border: "1px solid #444",
      borderRadius: "5px",
      color: "white",
      marginTop: "22px",
    };

    const inputStyle = {
      width: "100%",
      padding: "10px 10px 10px 11px", // Adjust left padding to make space for deposit label
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      color: "white",
      marginTop: "30px",
    };

    // Styles for the max amount label inside the input
    const maxAmountStyle = {
      position: "absolute",
      right: "10px",
      top: "10px",
      fontSize: "1.3rem",
      color: "#AAA",
    };
    // Styles for the deposit label inside the input
    const depositLabelStyle = {
      position: "absolute",
      left: "10px",
      top: "10px",
      fontSize: "1.3rem",
      color: "#AAA",
    };

    const submitButtonStyle = {
      width: "100%",
      padding: "10px",
      backgroundColor: "#e2e2e3", // Grey background color for the button to match the reference
      border: "none",
      borderRadius: "5px",
      color: "#121212",
      fontSize: "1.8rem",
      cursor: "pointer",
      marginTop: "10px", // Added space above the button
    };

    // Styles for the token symbol label inside the input
    const tokenSymbolStyle = {
      position: "absolute",
      right: "15px",
      color: "#AAA",
      marginTop: "30px",
    };

    // Continue with the rest of your styles...

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Deposit esFxdx"
        ariaHideApp={false}
        style={modalStyles}
      >
        <div>
          <div>
            <button style={closeButtonStyle} onClick={() => setIsModalOpen(false)}>
              ×
            </button>
            <h className="esFxdx-vault-heading">esFXDX Vault</h>
          </div>
          <div style={inputGroupStyle}>
            <label style={depositLabelStyle}>Deposit</label>
            <input
              style={inputStyle}
              id="depositAmount"
              type="number"
              value={depositAmount}
              onChange={handleDepositAmountChange}
              placeholder="0.0"
            />
            <div style={maxAmountStyle}>Max: {esFxdxBal}</div>
            <span style={tokenSymbolStyle}>esFXDX</span>
          </div>
          <div style={balanceInfoStyle}>
            <div style={balanceRowStyle}>
              <span>Wallet</span>
              <span>{esFxdxBal} esFXDX</span>
            </div>
            {/* <div style={balanceRowStyle}>
              <span>Vault Capacity</span>
              <span>{vaultBalance}</span>
            </div> */}
          </div>
          <button
            style={submitButtonStyle}
            onClick={() => {
              depositEsFXDX(depositAmount); // Call deposit function with the entered amount
              // setIsModalOpen(false); // Close modal after deposit
            }}
          >
            {Number(depositAmount) >= 1 ? "Vest" : "Enter an Amount"}
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <div className="vest-container">
      <div className="section-title-content">{/* Page title and description */}</div>
      {/* {renderVestEsFXDX()} */}

      <div className="vest-cont">
        <h1>Vest</h1>
        {account === undefined && (
          <button className="Connect-wallet-button-new" onClick={openConnectModal}>
            Connect walllet
          </button>
        )}
      </div>
      <div className="VestingContainer">
        <div>
          <div className="App-card-row-main">
            <div className="extitle">esFXDX</div>
            <div className="buttonclaimdep">
              <div>
                <button onClick={onPrimaryClickEsClaim} className="buttonclaim">
                  Claim
                </button>
              </div>
              <div>
                <button className="buttonclaim" onClick={() => setIsModalOpen(true)}>
                  Deposit
                </button>
                <DepositModal />
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div>
          <div className="App-card-row">
            <div className="label">Total Token Allocation</div>
            <div>{vestedAmount} esFXDX</div>
          </div>
          <div className="App-card-row">
            <div className="label">Vesting Duration</div>
            <div>1 year</div>
          </div>
          <div className="App-card-row">
            <div className="label">Claimed Amount</div>
            <div>{claimedAmount} FXDX</div>
          </div>
          <div className="App-card-row">
            <div className="label">Claimable Amount</div>
            <div>{claimableAmount} FXDX</div>
          </div>
          <div className="App-card-row">
            <div className="label">Remaining Token Allocation</div>
            <div>{RemainingAmount} esFXDX</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VestEsfxdx;
