import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { ethers } from "ethers";
import Tab from "../Tab/Tab";
import cx from "classnames";
import { getContract } from "config/contracts";
import { CgArrowsExchangeV } from "react-icons/cg";
import FlpTvChart from "../FlpTvChart/FlpTvChart";

import {
  getBuyFlpToAmount,
  getBuyFlpFromAmount,
  getSellFlpFromAmount,
  getSellFlpToAmount,
  adjustForDecimals,
  FLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  FLP_COOLDOWN_DURATION,
  SECONDS_PER_YEAR,
  USDF_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  importImage,
} from "lib/legacy";

import { approvePlugin, useFxdxPrice, useHasOutdatedUi, useMinExecutionFee } from "domain/legacy";

import TokenSelector from "../Exchange/TokenSelector";
import BuyInputSection from "../BuyInputSection/BuyInputSection";
import Tooltip from "../Tooltip/Tooltip";

import Reader from "abis/Reader.json";
import RewardReader from "abis/RewardReader.json";
import Vault from "abis/Vault.json";
import FlpManager from "abis/FlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import Router from "abis/Router.json";
import LiquidityRouter from "abis/LiquidityRouter.json";
import Token from "abis/Token.json";

import "./FlpSwap.css";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import SwapErrorModal from "./SwapErrorModal";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import {
  ARBITRUM,
  getChainName,
  getConstant,
  // GOERLI,
  // GOERLI,
  IS_NETWORK_DISABLED,
  // MAINNET,
  // OPTIMISM_GOERLI,
} from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyPrice,
  parseValue,
} from "lib/numbers";
import { getNativeToken, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getAums } from "domain/aums";
import { usePrevious } from "lib/usePrevious";
import { pushErrorNotification, pushSuccessNotification } from "pages/Exchange/Exchange";
import { useUserReferralCode } from "domain/referrals";
// import TradeHistory from "components/Exchange/TradeHistory";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const { AddressZero } = ethers.constants;

function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["stakedFlpTracker", "feeFlpTracker"];
  const data = {};
  const propsLength = 5;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data;
}

function getTooltipContent(managedUsd, tokenInfo, token) {
  return (
    <>
      <StatsTooltipRow
        label={t`Current Pool Amount`}
        value={[
          `$${formatAmount(managedUsd, USD_DECIMALS, 0, true)}`,
          `(${formatKeyAmount(tokenInfo, "poolAmount", token.decimals, 0, true)} ${token.symbol})`,
        ]}
      />
      <StatsTooltipRow label={t`Max Pool Capacity`} value={formatAmount(tokenInfo.maxUsdfAmount, 18, 0, true)} />
    </>
  );
}

export const FlpSwap = forwardRef((props, ref) => {
  const {
    savedSlippageAmount,
    isBuying,
    pendingTxns,
    setPendingTxns,
    setIsBuying,
    savedShouldDisableValidationForTesting,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const swapLabel = isBuying ? "BuyFlp" : "SellFlp";
  const tabLabel = isBuying ? t`Buy FLP` : t`Sell FLP`;
  const { address: account, isConnected: active, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const { chainId } = useChainId();
  const currentAccount = account;

  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);
  const [swapValue, setSwapValue] = useState("");
  const [flpValue, setFlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address`,
    AddressZero
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isWaitingForLiquidityRouterApproval, setIsWaitingForLiquidityRouterApproval] = useState(false);
  const [isLiquidityRouterApproving, setIsLiquidityRouterApproving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");
  const [modalError, setModalError] = useState(false);

  const readerAddress = getContract(chainId, "Reader");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeRewardTokenAddress = getContract(chainId, "FEE_REWARD_TOKEN");
  const stakedFlpTrackerAddress = getContract(chainId, "StakedFlpTracker");
  const feeFlpTrackerAddress = getContract(chainId, "FeeFlpTracker");
  const usdfAddress = getContract(chainId, "USDF");
  const flpManagerAddress = getContract(chainId, "FlpManager");
  const routerAddress = getContract(chainId, "Router");
  const liquidityRouterAddress = getContract(chainId, "LiquidityRouter");
  const tokensForBalanceAndSupplyQuery = [stakedFlpTrackerAddress, usdfAddress];

  const { data: hasOutdatedUi } = useHasOutdatedUi();

  const { attachedOnChain, userReferralCode } = useUserReferralCode(signer, chainId, account);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [`FlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Reader, [tokenAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, tokenBalances, undefined);

  const { data: balancesAndSupplies } = useSWR(
    [
      `FlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, Reader, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: aumAddition } = useSWR([`FlpSwap:aumAddition:${active}`, chainId, flpManagerAddress, "aumAddition"], {
    fetcher: contractFetcher(signer, FlpManager),
  });

  const { data: aumDeduction } = useSWR(
    [`FlpSwap:aumDeduction:${active}`, chainId, flpManagerAddress, "aumDeduction"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`FlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(signer, Vault),
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, routerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: liquidityRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, liquidityRouterAddress],
    {
      fetcher: contractFetcher(signer, Router),
    }
  );
  // console.log('liquidityRouterApproved: ', liquidityRouterApproved);

  const { data: lastPurchaseTime } = useSWR(
    [`FlpSwap:lastPurchaseTime:${active}`, chainId, flpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: flpBalance } = useSWR(
    [`FlpSwap:flpBalance:${active}`, chainId, feeFlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, RewardTracker),
    }
  );

  const flpVesterAddress = getContract(chainId, "FlpVester");
  const { data: reservedAmount } = useSWR(
    [`FlpSwap:reservedAmount:${active}`, chainId, flpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Vester),
    }
  );

  const { fxdxPrice } = useFxdxPrice(chainId, { arbitrum: chainId === ARBITRUM ? signer : undefined }, active);

  const rewardTrackersForStakingInfo = [stakedFlpTrackerAddress, feeFlpTrackerAddress];
  const { data: stakingInfo } = useSWR(
    [`FlpSwap:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const stakingData = getStakingData(stakingInfo);

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(FLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  const flpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const usdfSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);

  const {
    liquidityMinExecutionFee,
    liquidityMinExecutionFeeUSD,
    // liquidityMinExecutionFeeErrorMessage
  } = useMinExecutionFee(signer, active, chainId, infoTokens);

  const aums = getAums(chainId, infoTokens, aumAddition, aumDeduction);

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }
  const flpPrice =
    aum && aum.gt(0) && flpSupply.gt(0)
      ? aum.mul(expandDecimals(1, FLP_DECIMALS)).div(flpSupply)
      : expandDecimals(1, USD_DECIMALS);
  let flpBalanceUsd;
  if (flpBalance) {
    flpBalanceUsd = flpBalance.mul(flpPrice).div(expandDecimals(1, FLP_DECIMALS));
  }
  const flpSupplyUsd = flpSupply.mul(flpPrice).div(expandDecimals(1, FLP_DECIMALS));

  let reserveAmountUsd;
  if (reservedAmount) {
    reserveAmountUsd = reservedAmount.mul(flpPrice).div(expandDecimals(1, FLP_DECIMALS));
  }

  let maxSellAmount = flpBalance;
  if (flpBalance && reservedAmount) {
    maxSellAmount = flpBalance.sub(reservedAmount);
  }

  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const flpAmount = parseValue(flpValue, FLP_DECIMALS);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const prevNeedApproval = usePrevious(needApproval);
  const prevSwapTokenAddress = usePrevious(swapTokenAddress);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const flpUsdMax = flpAmount && flpPrice ? flpAmount.mul(flpPrice).div(expandDecimals(1, FLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdfAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdfAmount, USDF_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setSwapValue(value);
  };

  const onFlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setFlpValue(value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
  };

  const feeRewardToken = getTokenInfo(infoTokens, feeRewardTokenAddress);

  let totalApr = bigNumberify(0);

  let feeFlpTrackerAnnualRewardsUsd;
  let feeFlpTrackerApr;
  if (
    stakingData &&
    stakingData.feeFlpTracker &&
    stakingData.feeFlpTracker.tokensPerInterval &&
    feeRewardToken &&
    feeRewardToken.minPrice &&
    flpSupplyUsd &&
    flpSupplyUsd.gt(0)
  ) {
    feeFlpTrackerAnnualRewardsUsd = stakingData.feeFlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(feeRewardToken.minPrice)
      .div(expandDecimals(1, feeRewardToken.decimals));
    feeFlpTrackerApr = feeFlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(flpSupplyUsd);
    totalApr = totalApr.add(feeFlpTrackerApr);
  }

  let stakedFlpTrackerAnnualRewardsUsd;
  let stakedFlpTrackerApr;

  if (
    fxdxPrice &&
    stakingData &&
    stakingData.stakedFlpTracker &&
    stakingData.stakedFlpTracker.tokensPerInterval &&
    flpSupplyUsd &&
    flpSupplyUsd.gt(0)
  ) {
    stakedFlpTrackerAnnualRewardsUsd = stakingData.stakedFlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(fxdxPrice)
      .div(expandDecimals(1, 18));
    stakedFlpTrackerApr = stakedFlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(flpSupplyUsd);
    totalApr = totalApr.add(stakedFlpTrackerApr);
  }

  useImperativeHandle(ref, () => ({
    onAddLiquidity(account, token, amount, aumInUsdf, flpSupply, usdfAmount, mintAmount, e) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Bought ${formatAmount(mintAmount, FLP_DECIMALS, 4, true)} FLP with ${formatAmount(
        amount,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol}.`;

      pushSuccessNotification(chainId, message, e);
    },

    onRemoveLiquidity(account, token, flpAmount, aumInUsdf, flpSupply, usdfAmount, amountOut, e) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Sold ${formatAmount(flpAmount, FLP_DECIMALS, 4, true)} FLP for ${formatAmount(
        amountOut,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol}.`;

      pushSuccessNotification(chainId, message, e);
    },

    onCancelAddLiquidity(
      account,
      token,
      amountIn,
      minUsdf,
      minFlp,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, token);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Could not buy FLP with ${formatAmount(
        amountIn,
        tokenItem.decimals,
        4,
        true
      )} ${tokenSymbol} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);
    },

    onCancelRemoveLiquidity(
      account,
      tokenOut,
      flpAmount,
      minOut,
      receiver,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }

      const tokenItem = getToken(chainId, tokenOut);
      const tokenSymbol = tokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : tokenItem.symbol;

      const message = t`Could not sell ${formatAmount(
        flpAmount,
        FLP_DECIMALS,
        4,
        true
      )} FLP for ${tokenSymbol} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);
    },
  }));

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setFlpValue("");
          setFeeBasisPoints("");
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyFlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, FLP_DECIMALS, FLP_DECIMALS);
          setFlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellFlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, FLP_DECIMALS, FLP_DECIMALS);
          setFlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!flpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyFlpFromAmount(
            flpAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellFlpToAmount(
            flpAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights,
            true
          );

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    flpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    flpPrice,
    usdfSupply,
    totalTokenWeights,
  ]);

  useEffect(() => {
    if (
      swapToken &&
      swapTokenAddress === prevSwapTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{swapToken.symbol} approved!</div>);
    }
  }, [
    swapToken,
    swapTokenAddress,
    prevSwapTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    isWaitingForApproval,
  ]);

  const needLiquidityRouterApproval = isBuying && swapTokenAddress !== AddressZero && !liquidityRouterApproved;
  const prevNeedLiquidityRouterApproval = usePrevious(needLiquidityRouterApproval);

  useEffect(() => {
    if (!needLiquidityRouterApproval && prevNeedLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      setIsWaitingForLiquidityRouterApproval(false);
      helperToast.success(<div>Liquidity enabled!</div>);
    }
  }, [
    needLiquidityRouterApproval,
    prevNeedLiquidityRouterApproval,
    setIsWaitingForLiquidityRouterApproval,
    isWaitingForLiquidityRouterApproval,
  ]);

  const totalApy = useMemo(() => {
    if (totalApr.eq(0)) {
      return "-";
    }

    const aprDecimal = parseFloat(formatAmount(totalApr, 2, 2, true)) / 100;
    const totalApy = ((aprDecimal / 365 + 1) ** 365 - 1) * 100;

    return totalApy.toFixed(2);
  }, [totalApr]);

  const switchSwapOption = (hash = "") => {
    navigate(`${location.pathname}#${hash}`);
    props.setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setFlpValue(formatAmountFree(maxSellAmount, FLP_DECIMALS, FLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [t`FLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`FLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (hasOutdatedUi) {
      return [t`Page outdated, please refresh`];
    }

    if (!isBuying && inCooldownWindow) {
      const remainingTime = lastPurchaseTime.toNumber() + FLP_COOLDOWN_DURATION - parseInt(Date.now() / 1000);
      const remainingMins = Math.ceil(remainingTime / 60.0);
      let timeStr = "";
      if (remainingMins > 0) {
        timeStr = `: ${remainingMins}min`;
      }
      return [t`Redemption time not yet reached${timeStr}`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!flpAmount || flpAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [t`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdfAmount && swapTokenInfo.usdfAmount && swapUsdMin) {
        const usdfFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDF_DECIMALS);
        const nextUsdfAmount = swapTokenInfo.usdfAmount.add(usdfFromAmount);
        if (swapTokenInfo.maxUsdfAmount.gt(0) && nextUsdfAmount.gt(swapTokenInfo.maxUsdfAmount)) {
          return [t`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && flpAmount && flpAmount.gt(maxSellAmount)) {
        return [t`Insufficient FLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [t`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      return false;
    }
    if (isLiquidityRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return t`Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needLiquidityRouterApproval && isWaitingForLiquidityRouterApproval) {
      return t`Enabling Buy FLP...`;
    }
    if (isLiquidityRouterApproving) {
      return t`Enabling Buy FLP...`;
    }
    if (needLiquidityRouterApproval) {
      return t`Enable Buy FLP (${needApproval ? "2 more steps" : "1 more step"})`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return t`Approve ${swapToken.symbol} (1 more step)`;
    }

    if (isSubmitting) {
      return isBuying ? t`Buying...` : t`Selling...`;
    }

    return isBuying ? t`Buy FLP` : t`Sell FLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: swapToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const approveLiquidityRouter = () => {
    setIsLiquidityRouterApproving(true);
    return approvePlugin(chainId, liquidityRouterAddress, {
      signer,
      pendingTxns,
      setPendingTxns,
      sentMsg: t`Enable liquidity sent.`,
      failMsg: t`Enable liquidity failed.`,
    })
      .then(() => {
        setIsWaitingForLiquidityRouterApproval(true);
      })
      .finally(() => {
        setIsLiquidityRouterApproving(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const buyFlp = () => {
    setIsSubmitting(true);

    const minFlp = flpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);
    const acceptablePrice = swapTokenInfo.minPrice
      .mul(BASIS_POINTS_DIVISOR - savedSlippageAmount)
      .div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(liquidityRouterAddress, LiquidityRouter.abi, signer);
    const method = swapTokenAddress === AddressZero ? "createAddLiquidityETH" : "createAddLiquidity";

    const params =
      swapTokenAddress === AddressZero
        ? [0, minFlp, acceptablePrice, liquidityMinExecutionFee, referralCode]
        : [swapTokenAddress, swapAmount, 0, minFlp, acceptablePrice, liquidityMinExecutionFee, referralCode];
    const value =
      swapTokenAddress === AddressZero ? swapAmount.add(liquidityMinExecutionFee) : liquidityMinExecutionFee;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: t`Buy submitted.`,
      failMsg: t`Buy failed.`,
      successMsg: t`Requested buy FLP with ${formatAmount(swapAmount, swapTokenInfo.decimals, 4, true)} ${
        swapTokenInfo.symbol
      }!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sellFlp = () => {
    setIsSubmitting(true);

    const minOut = swapAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);
    const acceptablePrice = swapTokenInfo.maxPrice
      .mul(BASIS_POINTS_DIVISOR + savedSlippageAmount)
      .div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(liquidityRouterAddress, LiquidityRouter.abi, signer);
    const method = "createRemoveLiquidity";
    const isETHOut = swapTokenAddress === AddressZero;

    const params = [
      swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress,
      flpAmount,
      minOut,
      account,
      acceptablePrice,
      liquidityMinExecutionFee,
      isETHOut,
    ];

    callContract(chainId, contract, method, params, {
      value: liquidityMinExecutionFee,
      sentMsg: t`Sell submitted!`,
      failMsg: t`Sell failed.`,
      successMsg: t`Requested sell ${formatAmount(flpAmount, 18, 4, true)} FLP for ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      openConnectModal();
      return;
    }

    if (needLiquidityRouterApproval) {
      approveLiquidityRouter();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [, modal] = getError();

    if (modal) {
      setModalError(true);
      return;
    }

    if (isBuying) {
      buyFlp();
    } else {
      sellFlp();
    }
  };

  let payLabel = t`Pay`;
  let receiveLabel = t`Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (flpUsdMax) {
      receiveBalance = `$${formatAmount(flpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (flpUsdMax) {
      payBalance = `$${formatAmount(flpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  const selectToken = (token) => {
    setAnchorOnSwapAmount(false);
    setSwapTokenAddress(token.address);
    helperToast.success(t`${token.symbol} selected in order form`);
  };

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  const feeRewardTokenSymbol = getConstant(chainId, "feeRewardTokenSymbol");

  const onSwapOptionChange = (opt) => {
    if (opt === t`Sell FLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  return (
    <div className="FlpSwap">
      <SwapErrorModal
        isVisible={Boolean(modalError)}
        setIsVisible={setModalError}
        swapToken={swapToken}
        chainId={chainId}
        flpAmount={flpAmount}
        usdfSupply={usdfSupply}
        totalTokenWeights={totalTokenWeights}
        flpPrice={flpPrice}
        infoTokens={infoTokens}
        swapUsdMin={swapUsdMin}
      />
      {/* <div className="Page-title-section">
        <div className="Page-title">{isBuying ? "Buy FLP" : "Sell FLP"}</div>
        {isBuying && <div className="Page-description">
          Purchase <a href="https://fxdx.gitbook.io/fxdx/flp" target="_blank" rel="noopener noreferrer">FLP tokens</a> to earn {nativeTokenSymbol} fees from swaps and leverage trading.<br/>
          Note that there is a minimum holding time of 15 minutes after a purchase.<br/>
          <div>View <Link to="/earn">staking</Link> page.</div>
        </div>}
        {!isBuying && <div className="Page-description">
          Redeem your FLP tokens for any supported asset.
          {inCooldownWindow && <div>
            FLP tokens can only be redeemed 15 minutes after your most recent purchase.<br/>
            Your last purchase was at {formatDateTime(lastPurchaseTime)}, you can redeem FLP tokens after {formatDateTime(redemptionTime)}.<br/>
          </div>}
          <div>View <Link to="/earn">staking</Link> page.</div>
        </div>}
      </div> */}
      <div className="section-title-block">
        <div className="section-title-content">
          <div className="Page-title">
            <Trans>Stake and Earn</Trans>
          </div>
          <div className="Page-description">
            <Trans>
              Purchase{" "}
              <ExternalLink href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/fundamentals/tokens/flp">
                FLP tokens
              </ExternalLink>{" "}
              to earn {feeRewardTokenSymbol} fees from swaps and leverages trading.
            </Trans>
            <br />
            <Trans>
              View <Link to="/earn">Account</Link> page.
            </Trans>
          </div>
        </div>
        <div className="App-card totalSupply">
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Supply</Trans>
            </div>
            <div className="value">
              <Trans>
                {formatAmount(flpSupply, FLP_DECIMALS, 4, true)} FLP ($
                {formatAmount(flpSupplyUsd, USD_DECIMALS, 2, true)})
              </Trans>
            </div>
          </div>
        </div>
      </div>

      <div className="FlpSwap-content">
        <div className="App-card FlpSwap-stats-card">
          <div className="App-card-title">
            <div className="App-card-title-mark">
              {/* <div className="App-card-title-mark-icon">
                <img src={flp40Icon} alt="flp40Icon" />
                {chainId === GOERLI || chainId === MAINNET ? (
                  <img src={ethereum16Icon} alt="ethereum16Icon" className="selected-network-symbol" />
                ) : chainId === OPTIMISM_GOERLI ? (
                  <img src={optimism16Icon} alt="optimism16Icon" className="selected-network-symbol" />
                ) : chainId === ARBITRUM ? (
                  <img src={arbitrum16Icon} alt="arbitrum16Icon" className="selected-network-symbol" />
                ) : chainId === AVALANCHE ? (
                  <img src={avalanche16Icon} alt="avalanche16Icon" className="selected-network-symbol" />
                ) : (
                  <img src={moonbeam16Icon} alt="moonbeam16Icon" className="selected-network-symbol" />
                )}
              </div> */}
              <div className="App-card-title-mark-info">
                <div className="App-card-title-mark-title">FLP</div>
                <div className="App-card-title-mark-subtitle">FLP</div>
              </div>
              <div>
                <AssetDropdown assetSymbol="FLP" />
              </div>
            </div>
          </div>
          <div className="App-card-divider" />
          {/* <div className="App-card-content"> */}
          <div className="App-card-row">
            <div className="label">
              <Trans>Price</Trans>
            </div>
            <div className="value">${formatAmount(flpPrice, USD_DECIMALS, 3, true)}</div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Wallet</Trans>
            </div>
            <div className="value">
              {formatAmount(flpBalance, FLP_DECIMALS, 4, true)} FLP ($
              {formatAmount(flpBalanceUsd, USD_DECIMALS, 2, true)})
            </div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Staked</Trans>
            </div>
            <div className="value">
              {formatAmount(flpBalance, FLP_DECIMALS, 4, true)} FLP ($
              {formatAmount(flpBalanceUsd, USD_DECIMALS, 2, true)})
            </div>
          </div>
          {/* </div> */}
          <div className="App-card-divider" />
          {/* <div className="App-card-content"> */}
          {/* {!isBuying && ( */}
          <div className="App-card-row">
            <div className="label">
              <Trans>Reserved</Trans>
            </div>
            <div className="value">
              <Tooltip
                handle={`${formatAmount(reservedAmount, 18, 4, true)} FLP ($${formatAmount(
                  reserveAmountUsd,
                  USD_DECIMALS,
                  2,
                  true
                )})`}
                position="right-bottom"
                renderContent={() =>
                  t`${formatAmount(reservedAmount, 18, 4, true)} FLP have been reserved for vesting.`
                }
              />
            </div>
          </div>
          {/* )} */}
          <div className="App-card-row">
            <div className="label">
              <Trans>APY</Trans>
            </div>
            <div className="value">
              <Tooltip
                handle={`${totalApy}%`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={t`Fee Rewards (${feeRewardTokenSymbol}) APR`}
                        value={`${formatAmount(feeFlpTrackerApr, 2, 2, false)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={t`Escrowed FXDX APR`}
                        value={`${formatAmount(stakedFlpTrackerApr, 2, 2, false)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={t`APR`}
                        value={`${formatAmount(totalApr, 2, 2, true)}%`}
                        showDollar={false}
                      />
                      <p>{`Note: APY assumes daily compounding`}</p>
                    </>
                  );
                }}
              />
            </div>
          </div>
          {/* <div className="App-card-row">
              <div className="label">
                <Trans>Total Supply</Trans>
              </div>
              <div className="value">
                {formatAmount(flpSupply, FLP_DECIMALS, 4, true)} FLP ($
                {formatAmount(flpSupplyUsd, USD_DECIMALS, 2, true)})
              </div>
            </div> */}
          {/* </div> */}
        </div>

        <div className="plainChart" key={chainId}>
          <FlpTvChart chainId={chainId} />
          {/* {chainId === GOERLI ? <Goerli chainId={chainId} /> : <OptimismGoerli chainId={chainId} />} */}
        </div>

        <div className="Flp-box-container">
          <Tab
            options={[t`Buy FLP`, t`Sell FLP`]}
            option={tabLabel}
            onChange={onSwapOptionChange}
            className="Exchange-swap-option-tabs"
          />
          <div className="FlpSwap-box App-box">
            {isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={t`Bal:`}
                tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                selectedToken={swapToken}
                balance={payBalance}
              >
                <TokenSelector
                  label={t`Pay`}
                  chainId={chainId}
                  tokenAddress={swapTokenAddress}
                  onSelectToken={onSelectSwapToken}
                  tokens={whitelistedTokens}
                  infoTokens={infoTokens}
                  className="FlpSwap-from-token"
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                />
              </BuyInputSection>
            )}

            {!isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={t`Bal:`}
                tokenBalance={`${formatAmount(maxSellAmount, FLP_DECIMALS, 4, true)}`}
                inputValue={flpValue}
                onInputValueChange={onFlpValueChange}
                showMaxButton={flpValue !== formatAmountFree(maxSellAmount, FLP_DECIMALS, FLP_DECIMALS)}
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                balance={payBalance}
                defaultTokenName={"FLP"}
              >
                <div className="selected-token">
                  {/* FLP <img src={flp24Icon} alt="flp24Icon" /> */}
                  FLP
                </div>
              </BuyInputSection>
            )}

            <div className="AppOrder-ball-container">
              <div className="AppOrder-ball">
                {/* <img
                  src={arrowIcon}
                  alt="arrowIcon"
                  onClick={() => {
                    setIsBuying(!isBuying);
                    switchSwapOption(isBuying ? "redeem" : "");
                  }}
                /> */}
                <CgArrowsExchangeV
                  size="25"
                  onClick={() => {
                    setIsBuying(!isBuying);
                    switchSwapOption(isBuying ? "redeem" : "");
                  }}
                />
              </div>
            </div>

            {isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={t`Bal:`}
                tokenBalance={`${formatAmount(flpBalance, FLP_DECIMALS, 4, true)}`}
                inputValue={flpValue}
                onInputValueChange={onFlpValueChange}
                balance={receiveBalance}
                defaultTokenName={"FLP"}
              >
                <div className="selected-token">
                  {/* FLP <img src={flp24Icon} alt="flp24Icon" /> */}
                  FLP
                </div>
              </BuyInputSection>
            )}

            {!isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={t`Bal:`}
                tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                balance={receiveBalance}
                selectedToken={swapToken}
              >
                <TokenSelector
                  label={t`Receive`}
                  chainId={chainId}
                  tokenAddress={swapTokenAddress}
                  onSelectToken={onSelectSwapToken}
                  tokens={whitelistedTokens}
                  infoTokens={infoTokens}
                  className="FlpSwap-from-token"
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                />
              </BuyInputSection>
            )}

            <div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">{feeBasisPoints > 50 ? t`WARNING: High Fees` : t`Fees`}</div>
                <div className="align-right fee-block">
                  {isBuying && (
                    <Tooltip
                      handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText}
                      position="right-bottom"
                      renderContent={() => {
                        if (!feeBasisPoints) {
                          return (
                            <div className="text-white">
                              <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                            </div>
                          );
                        }
                        return (
                          <div className="text-white">
                            {feeBasisPoints > 50 && (
                              <Trans>To reduce fees, select a different asset to pay with.</Trans>
                            )}
                            <Trans>Check the "Save on Fees" section below to get the lowest fee percentages.</Trans>
                          </div>
                        );
                      }}
                    />
                  )}
                  {!isBuying && (
                    <Tooltip
                      handle={feePercentageText}
                      position="right-bottom"
                      renderContent={() => {
                        if (!feeBasisPoints) {
                          return (
                            <div className="text-white">
                              <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                            </div>
                          );
                        }
                        return (
                          <div className="text-white">
                            {feeBasisPoints > 50 && <Trans>To reduce fees, select a different asset to receive.</Trans>}
                            <Trans>Check the "Save on Fees" section below to get the lowest fee percentages.</Trans>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">{t`Execution Fee`}</div>
                <div className="align-right fee-block">
                  <Tooltip
                    handle={`${formatAmountFree(liquidityMinExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                    position="right-top"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={t`Network fee`}
                            value={`${formatAmountFree(
                              liquidityMinExecutionFee,
                              18,
                              5
                            )} ${nativeTokenSymbol} ($${formatAmount(liquidityMinExecutionFeeUSD, USD_DECIMALS, 2)})`}
                          />
                          <br />
                          <Trans>
                            This is the network cost required to execute the request.{" "}
                            <ExternalLink href="https://fxdx.gitbook.io/fxdx/trading#execution-fee">
                              More Info
                            </ExternalLink>
                          </Trans>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {/* {liquidityMinExecutionFeeErrorMessage && (
              <div className="Confirmation-box-warning">{liquidityMinExecutionFeeErrorMessage}</div>
            )} */}
            <div className="FlpSwap-cta Exchange-swap-button-container">
              <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
                {getPrimaryText()}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Tab-title-section">
        <div className="Page-title">
          <Trans>Buy / Sell FLP</Trans>
        </div>
        {isBuying && (
          <div className="Page-description">
            <Trans>
              Fees may vary depending on which asset you use to buy FLP. <br />
              Enter the amount of FLP you want to purchase in the order form, then check here to compare fees.
            </Trans>
          </div>
        )}
        {!isBuying && (
          <div className="Page-description">
            <Trans>
              Fees may vary depending on which asset you sell FLP for. <br />
              Enter the amount of FLP you want to redeem in the order form, then check here to compare fees.
            </Trans>
          </div>
        )}
      </div>
      <div className="FlpSwap-token-list">
        {/* <div className="FlpSwap-token-list-content"> */}
        <table className="token-table">
          <thead>
            <tr>
              <th>
                <Trans>TOKEN</Trans>
              </th>
              <th>
                <Trans>PRICE</Trans>
              </th>
              <th>
                {isBuying ? (
                  <Tooltip
                    handle={t`AVAILABLE`}
                    tooltipIconPosition="right"
                    position="right-bottom text-none"
                    renderContent={() => (
                      <p className="text-white">
                        <Trans>Available amount to deposit into FLP.</Trans>
                      </p>
                    )}
                  />
                ) : (
                  <Tooltip
                    handle={t`AVAILABLE`}
                    tooltipIconPosition="right"
                    position="center-bottom text-none"
                    renderContent={() => {
                      return (
                        <p className="text-white">
                          <Trans>
                            Available amount to withdraw from FLP. Funds not utilized by current open positions.
                          </Trans>
                        </p>
                      );
                    }}
                  />
                )}
              </th>
              <th>
                <Trans>WALLET</Trans>
              </th>
              <th>
                <Tooltip
                  handle={t`FEES`}
                  tooltipIconPosition="right"
                  position="right-bottom text-none"
                  renderContent={() => {
                    return (
                      <div className="text-white">
                        <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                      </div>
                    );
                  }}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {visibleTokens.map((token) => {
              let tokenFeeBps;
              if (isBuying) {
                const { feeBasisPoints: feeBps } = getBuyFlpFromAmount(
                  flpAmount,
                  token.address,
                  infoTokens,
                  flpPrice,
                  usdfSupply,
                  totalTokenWeights
                );
                const { feeBasisPoints: nfeeBps } =
                  !feeBps &&
                  getBuyFlpFromAmount(
                    parseValue(1, FLP_DECIMALS),
                    token.address,
                    infoTokens,
                    flpPrice,
                    usdfSupply,
                    totalTokenWeights
                  );
                tokenFeeBps = feeBps ? feeBps : nfeeBps;
              } else {
                const { feeBasisPoints: feeBps } = getSellFlpToAmount(
                  flpAmount,
                  token.address,
                  infoTokens,
                  flpPrice,
                  usdfSupply,
                  totalTokenWeights
                );
                const { feeBasisPoints: nfeeBps } =
                  !feeBps &&
                  getSellFlpToAmount(
                    parseValue(1, FLP_DECIMALS),
                    token.address,
                    infoTokens,
                    flpPrice,
                    usdfSupply,
                    totalTokenWeights
                  );
                tokenFeeBps = feeBps ? feeBps : nfeeBps;
              }
              const tokenInfo = getTokenInfo(infoTokens, token.address);
              let managedUsd;
              if (tokenInfo && tokenInfo.managedUsd) {
                managedUsd = tokenInfo.managedUsd;
              }
              let availableAmountUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
                availableAmountUsd = tokenInfo.availableAmount
                  .mul(tokenInfo.minPrice)
                  .div(expandDecimals(1, token.decimals));
              }
              let balanceUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
                balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
              }
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");
              let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdfAmount);

              let amountLeftToDeposit = bigNumberify(0);
              if (tokenInfo.maxUsdfAmount && tokenInfo.maxUsdfAmount.gt(0)) {
                amountLeftToDeposit = tokenInfo.maxUsdfAmount
                  .sub(tokenInfo.usdfAmount)
                  .mul(expandDecimals(1, USD_DECIMALS))
                  .div(expandDecimals(1, USDF_DECIMALS));
              }
              if (amountLeftToDeposit.lt(0)) {
                amountLeftToDeposit = bigNumberify(0);
              }
              function renderFees() {
                const swapUrl = `https://app.1inch.io/#/${chainId}/swap/`;
                switch (true) {
                  case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                    return (
                      <Tooltip
                        handle="NA"
                        position="right-bottom"
                        renderContent={() => (
                          <div className="text-white">
                            <Trans>
                              Max pool capacity reached for {tokenInfo.symbol}
                              <br />
                              <br />
                              Please buy FLP using another token
                            </Trans>
                            <br />
                            <p>
                              <ExternalLink href={swapUrl}>
                                <Trans> Swap {tokenInfo.symbol} on 1inch</Trans>
                              </ExternalLink>
                            </p>
                          </div>
                        )}
                      />
                    );
                  case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                    return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                      tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                    }`;
                  default:
                    return "";
                }
              }

              return (
                <tr key={token.symbol}>
                  <td>
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt={token.symbol} width="40px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">{token.name}</div>
                        <div className="App-card-info-subtitle">{token.symbol}</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                      </div>
                    </div>
                  </td>
                  <td>${formatKeyPrice(tokenInfo, "minPrice", true)}</td>
                  <td>
                    {isBuying && (
                      <div>
                        <Tooltip
                          handle={
                            amountLeftToDeposit && amountLeftToDeposit.lt(0)
                              ? "$0.00"
                              : `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
                          }
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                        />
                      </div>
                    )}
                    {!isBuying && (
                      <div>
                        <Tooltip
                          handle={
                            availableAmountUsd && availableAmountUsd.lt(0)
                              ? "$0.00"
                              : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
                          }
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
                    {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
                  </td>
                  <td>{renderFees()}</td>
                  <td>
                    <button className={cx("App-button-option action-btn")} onClick={() => selectToken(token)}>
                      {/* {isBuying ? t`Buy with ${token.symbol}` : t`Sell for ${token.symbol}`} */}
                      {t`Buy with ${token.symbol}`}
                    </button>
                    <button
                      className={cx("App-button-option action-btn")}
                      onClick={() => {
                        setIsBuying(false);
                        switchSwapOption(isBuying ? "redeem" : "");
                        selectToken(token);
                      }}
                    >
                      {t`Sell for ${token.symbol}`}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="token-grid">
          {visibleTokens.map((token) => {
            let tokenFeeBps;
            if (isBuying) {
              const { feeBasisPoints: feeBps } = getBuyFlpFromAmount(
                flpAmount,
                token.address,
                infoTokens,
                flpPrice,
                usdfSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            } else {
              const { feeBasisPoints: feeBps } = getSellFlpToAmount(
                flpAmount,
                token.address,
                infoTokens,
                flpPrice,
                usdfSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            }
            const tokenInfo = getTokenInfo(infoTokens, token.address);
            let managedUsd;
            if (tokenInfo && tokenInfo.managedUsd) {
              managedUsd = tokenInfo.managedUsd;
            }
            let availableAmountUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
              availableAmountUsd = tokenInfo.availableAmount
                .mul(tokenInfo.minPrice)
                .div(expandDecimals(1, token.decimals));
            }
            let balanceUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
              balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
            }

            let amountLeftToDeposit = bigNumberify(0);
            if (tokenInfo.maxUsdfAmount && tokenInfo.maxUsdfAmount.gt(0)) {
              amountLeftToDeposit = tokenInfo.maxUsdfAmount
                .sub(tokenInfo.usdfAmount)
                .mul(expandDecimals(1, USD_DECIMALS))
                .div(expandDecimals(1, USDF_DECIMALS));
            }
            if (amountLeftToDeposit.lt(0)) {
              amountLeftToDeposit = bigNumberify(0);
            }
            let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdfAmount);

            function renderFees() {
              switch (true) {
                case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                  return (
                    <Tooltip
                      handle="NA"
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          Max pool capacity reached for {tokenInfo.symbol}. Please buy FLP using another token
                        </Trans>
                      )}
                    />
                  );
                case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                  return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                    tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                  }`;
                default:
                  return "";
              }
            }
            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");

            return (
              <div className="App-card" key={token.symbol}>
                <div className="mobile-token-card">
                  <img src={tokenImage} alt={token.symbol} width="20px" />
                  <div className="token-symbol-text">{token.symbol}</div>
                  <div>
                    <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                  </div>
                </div>
                <div className="App-card-divider" />
                {/* <div className="App-card-content"> */}
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Price</Trans>
                  </div>
                  <div>${formatKeyPrice(tokenInfo, "minPrice", true)}</div>
                </div>
                {isBuying && (
                  <div className="App-card-row">
                    <Tooltip
                      handle="Available"
                      position="left-bottom"
                      handleClassName="available"
                      renderContent={() => (
                        <p className="text-white">
                          <Trans>Available amount to deposit into FLP.</Trans>
                        </p>
                      )}
                    />
                    <div>
                      <Tooltip
                        handle={amountLeftToDeposit && `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`}
                        position="right-bottom"
                        tooltipIconPosition="right"
                        renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                      />
                    </div>
                  </div>
                )}
                {!isBuying && (
                  <div className="App-card-row">
                    <div className="label">
                      <Tooltip
                        handle={t`Available`}
                        handleClassName="available"
                        position="left-bottom"
                        renderContent={() => {
                          return (
                            <p className="text-white">
                              <Trans>
                                Available amount to withdraw from FLP. Funds not utilized by current open positions.
                              </Trans>
                            </p>
                          );
                        }}
                      />
                    </div>

                    <div>
                      <Tooltip
                        handle={
                          availableAmountUsd && availableAmountUsd.lt(0)
                            ? "$0.00"
                            : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
                        }
                        position="right-bottom"
                        tooltipIconPosition="right"
                        renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                      />
                    </div>
                  </div>
                )}

                <div className="App-card-row">
                  <div className="label">
                    <Trans>Wallet</Trans>
                  </div>
                  <div>
                    {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
                    {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
                  </div>
                </div>
                <div className="App-card-row">
                  {isBuying ? (
                    <div>
                      {tokenFeeBps ? (
                        t`Buying Fees`
                      ) : (
                        <Tooltip
                          handle={t`Buying Fees`}
                          className="label"
                          renderContent={() => (
                            <p className="text-white">
                              <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                            </p>
                          )}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      {tokenFeeBps ? (
                        t`Selling Fees`
                      ) : (
                        <Tooltip
                          handle={t`SellingFees`}
                          className="label"
                          renderContent={() => (
                            <p className="text-white">
                              <Trans>Fees will be shown once you have entered an amount in the order form.</Trans>
                            </p>
                          )}
                        />
                      )}
                    </div>
                  )}
                  <div>{renderFees()}</div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {/* {isBuying && ( */}
                  <button
                    className="App-button-option App-card-option"
                    onClick={() => {
                      // setIsBuying(true);
                      // console.log('token.symbol: before ', token.symbol);
                      // selectToken(token)
                      // console.log('token.symbol: after', token.symbol);
                      onSelectSwapToken(token);
                      setIsBuying(true);
                    }}
                  >
                    <Trans>Buy with {token.symbol}</Trans>
                  </button>
                  {/* )} */}
                  {/* {!isBuying && ( */}
                  <button
                    className="App-button-option App-card-option"
                    onClick={() => {
                      // setIsBuying(false);
                      // console.log('token.symbol: before', token.symbol);
                      // switchSwapOption( "redeem");
                      // selectToken(token)
                      // console.log('token.symbol: after', token.symbol);
                      onSelectSwapToken(token);
                      setIsBuying(false);
                    }}
                  >
                    <Trans>Sell for {token.symbol}</Trans>
                  </button>
                  {/* )} */}
                </div>
                {/* </div> */}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="Tab-title-section">
        <div className="Page-title">
          <Trans>Actions</Trans>
        </div>
      </div>
      <TradeHistory
        forSingleAccount={true}
        account={account}
        infoTokens={infoTokens}
        getTokenInfo={getTokenInfo}
        chainId={chainId}
        nativeTokenAddress={nativeTokenAddress}
        shouldShowPaginationButtons={true}
        type="liquidity_actions"
      /> */}
    </div>
  );
});
