import "./StakeHistory.css";
import { ethers } from "ethers";
import { useState } from "react";
import { t } from "@lingui/macro";
import { formatAmount } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import { shortenAddress } from "lib/legacy";
import { callContract } from "lib/contracts";
import useWallet from "lib/wallets/useWallet";
import { helperToast } from "lib/helperToast";
import { rewardRouterV3Data } from "./data/rewardRouterV3.data.js";
import { getContract } from "config/contracts";

export default function StakeHistory({ stakes, tokenDecimals, prevStakes }) {
  const [filterActive, setFilterActive] = useState(true);
  const [filterClaimed, setFilterClaimed] = useState(false);
  const [unstakeStatus, setUnstakeStatus] = useState(undefined);

  let { isConnected: active, address: account, signer, chainId, ens } = useWallet();

  const unstakeFxdx = (stakeIdList) => {
    try {
      if (signer) {
      // console.log("stakeIdList: ", stakeIdList);

      const status = new Map();
      if (stakeIdList && stakeIdList.length === 1) {
        status.set(stakeIdList[0], true);
      }
      setUnstakeStatus(() => { return status });

      // console.log(status.get(stakeIdList[0]));

      const rewardRouterV3Address = getContract(chainId, "RewardRouterV3");

      const rewardRouterV3 = new ethers.Contract(rewardRouterV3Address, rewardRouterV3Data.abi, signer);

      if (stakeIdList !== undefined && stakeIdList.length > 0) {

        callContract(chainId, rewardRouterV3, "unstake", [stakeIdList], {
          sentMsg: t`Unstake Request Sent`,
          failMsg: t`Unstake Request Failed`,
          successMsg: t`Unstaked Successfully!`,
        })
          .then(async (res) => {
            await res.wait(2);
            helperToast.success(<div>Unstaked Successfully!</div>);
            // console.log(res);
          })
          .catch((error) => {
            console.log(error);
            setUnstakeStatus(undefined);
          })
          .finally(() => {
            status.set(stakeIdList, false);
          });
      }
    }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="stake-history-header-container">
        <div className="stake-history-header">
          <div className="stake-history-header-title">Stake History </div>
          <div className="stake-history-header-title-mobile">Stake History </div>
          <div className="stake-history-filters-mobile">
            <button
              className={`filter-btn ${filterActive ? "filter-highlighted" : ""}`}
              onClick={() => {
                setFilterActive(true);
                setFilterClaimed(false);
              }}
            >
              Active
            </button>
            <button
              className={`filter-btn ${filterClaimed ? "filter-highlighted" : ""}`}
              onClick={() => {
                setFilterActive(false);
                setFilterClaimed(true);
              }}
            >
              Claimed
            </button>
          </div>
          <div className="stake-history-filters">
            <button
              className={`filter-btn ${filterActive ? "filter-highlighted" : ""}`}
              onClick={() => {
                setFilterActive(true);
                setFilterClaimed(false);
              }}
            >
              Active
            </button>
            <button
              className={`filter-btn ${filterClaimed ? "filter-highlighted" : ""}`}
              onClick={() => {
                setFilterActive(false);
                setFilterClaimed(true);
              }}
            >
              Claimed
            </button>
          </div>
          
        </div>
        
      </div>

      <div className="stake-history-container">
        <div className="stake-history-table">
          <table className="stake-table">
            <thead className="stake-table-header">
              <tr>
                <th className="stake-id-comp">STAKE#</th>
                <th className="stake-account-comp">ACCOUNT</th>
                <th className="stake-amount-comp">STAKE AMOUNT</th>
                <th className="stake-date-comp">STAKED AT</th>
                <th className="stake-date-comp">STAKED TILL</th>
                <th className="stake-amount-comp">REWARD AMOUNT</th>
                {filterActive ? (
                  <th className="stake-btn-comp">ACTIONS</th>
                ) : (
                  <th className="stake-btn-comp">STATUS</th>
                )}
              </tr>
            </thead>
            <tbody className="stake-table-data">
              {filterActive &&
                stakes !== undefined &&
                stakes.length > 0 &&
                stakes.map(({ _stakeId, _account, _timestamp, _duration, _amount, _rewardAmount }) => {
                  return (
                    <tr className="table-data" key={`${_stakeId}`}>
                      <td className="stake-id-comp">{_stakeId.toString()}</td>
                      <td className="stake-account-comp">{shortenAddress(_account.toString(), 14)}</td>
                      <td className="stake-amount-comp">{formatAmount(_amount, tokenDecimals, 2)}</td>
                      <td className="stake-date-comp">{formatDateTime(_timestamp.toString())}</td>
                      <td className="stake-date-comp">{formatDateTime(_timestamp.add(_duration).toString())}</td>
                      <td className="stake-amount-comp">{formatAmount(_rewardAmount, tokenDecimals, 2)}</td>
                      <td className="stake-btn-comp">
                        <div
                          className="unstakebutton"
                          onClick={() => { unstakeFxdx([_stakeId.toString()]) }}
                        >
                          {
                            unstakeStatus !== undefined && unstakeStatus.get(_stakeId.toString()) ?
                              <span>Pending...</span> : <span>Unstake</span>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {filterClaimed &&
                prevStakes !== undefined &&
                prevStakes.length > 0 &&
                prevStakes.map(({ _stakeId, _account, _timestamp, _duration, _amount, _rewardAmount }) => {
                  return (
                    <tr className="table-data" key={`${_stakeId}`}>
                      <td className="stake-id-comp">{_stakeId.toString()}</td>
                      <td className="stake-account-comp">{shortenAddress(_account.toString(), 14)}</td>
                      <td className="stake-amount-comp">{formatAmount(_amount, tokenDecimals, 2)}</td>
                      <td className="stake-date-comp">{formatDateTime(_timestamp.toString())}</td>
                      <td className="stake-date-comp">{formatDateTime(_timestamp.add(_duration).toString())}</td>
                      <td className="stake-amount-comp">{formatAmount(_rewardAmount, tokenDecimals, 2)}</td>
                      <td className="stake-btn-comp status">Claimed</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
