import React, { useState, useEffect } from "react";
import "./UnknownEvent.css";
import box from "./box.svg";
import LeaderBoardnew from "./Leaderboard/LeaderBoardnew";
import boxmobile from "./boxmobile.svg";
import { Link } from "react-router-dom";

const UnknownEvent = () => {
  // Set the target date and time for the countdown in ISO 8601 format
  const targetDate = new Date("2024-02-08T00:00:00").getTime(); //live on 8th jan
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (isNaN(distance)) {
        setCountdown("Invalid date");
        return;
      }

      if (distance <= 0) {
        setCountdown("0d : 0h : 0m : 0s");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d : ${hours}h : ${minutes}m : ${seconds}s`);
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div>
      <div className="main-container">
        <div className="image_main_box_mobile">
          <img src={boxmobile} />
          <div className="contentinsidebox">
            <div className="text_heading_box_mobile">
              <h4>Prize Pool</h4>
              <div className="monthly_fxdx_data_mobile">
                <h3>3,000,000 $esFXDX Monthly Distribution</h3>
              </div>
            </div>
            <div className="evnet_data_timer">
              <div className="eventdata">
                <h5>Unknown Event - Season 2</h5>
                <p>
                  Welcome back Traders! We've prepared
                  <br />a trading event with an unprecedented
                  <br />
                  amount of rewards for you. Reclaim
                  <br /> your honor in this new trading arena,
                  <br />
                  Unknown Event!
                </p>
              </div>
              <div className="live_timer_countdown">
                <div className="data_insidetime">
                  <h6>
                    Season 3 <span>Coming soon</span>
                  </h6>
                </div>
                {/* <div className="live_countdown_data">{countdown}</div> */}
              </div>
            </div>

            <div className="doc_and_days">
              <div className="reward_doc_txt">
              <text>
                  Learn more about FXDX Rewards
                  <br />
                  Program in&nbsp;
                   <a href="https://fxdxdex.medium.com/fxdx-uknown-event-04944b804345" target="_blank">Rewards Docs</a>
                </text>
              </div>
              <div className="duration_30days_txt">
                <text>
                Rewards will be airdropped 
                  <br />
                  to your wallet after season ends.
                </text>
              </div>
            </div>

            {/* <div className="trade_now_button">
              <div className="button_in_svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="444" height="32" viewBox="0 0 444 32" fill="none">
                  <path d="M51.5 0H394.5L444 32H0L51.5 0Z" fill="url(#paint0_linear_9612_1571)" />
                  <text
                   className="svg_trade_now"
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  fill="#000"
                  font-family="Play"
                  font-size="16"
                  font-style="normal"
                  font-weight="700"
                  letter-spacing="8px"
                  text-transform="uppercase"
                >
                  Trade Now
                </text>
                  <defs>
                    <linearGradient
                      id="paint0_linear_9612_1571"
                      x1="-10.4471"
                      y1="3.26894e-07"
                      x2="165.138"
                      y2="237.856"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FED404" />
                      <stop offset="1" stop-color="#FF9A02" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div> */}
          </div>
          {/* <div className="contentinsidebox_mobile">
            
         </div> */}
        </div>
        <div className="image_main_box">
          <img src={box} />
          <div className="contentinsidebox">
            <h4>Prize Pool</h4>
            <h3>3,000,000 $esFXDX Monthly Distribution</h3>
            <div className="line_border"></div>
            <div className="evnet_data_timer">
              <div className="eventdata">
                <h5>Unknown Event - Season 2</h5>
                <p>
                  Welcome back Traders! We've prepared a trading event with an unprecedented
                  <br />
                  amount of rewards for you. Reclaim your honor in this new trading arena,
                  <br />
                  Unknown Event!
                </p>
              </div>
              <div className="live_timer_countdown">
                <div className="data_insidetime">
                  <h6>
                    Season 3 <span>Coming Soon</span>
                  </h6>
                </div>
                {/* <div className="live_countdown_data">{countdown}</div> */}
              </div>
            </div>

            <div className="doc_and_days">
              <div className="reward_doc_txt">
              <text>
                  Learn more about FXDX Rewards
                  <br />
                  Program in&nbsp;
                   <a href="https://fxdxdex.medium.com/fxdx-uknown-event-04944b804345" target="_blank">Rewards Docs</a>
                </text>
              </div>
              <div className="duration_30days_txt">
                <text>
                Rewards will be airdropped 
                  <br />
                  to your wallet after season ends.
                </text>
              </div>
            </div>
            
            <div className="trade_now_button">
              
                <div className="button_in_svg">
                <Link to={`/trade`} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="444" height="32" viewBox="0 0 444 32" fill="none">
                    <g filter="url(#filter0_b_10314_1915)">
                      <path d="M51.5 0H394.5L444 32H0L51.5 0Z" fill="url(#paint0_linear_10314_1915)" />
                    </g>
                    <Link to={`/trade`} target="_blank">
                    <text
                      className="svg_trade_now"
                      x="50%"
                      y="50%"
                      dominant-baseline="middle"
                      text-anchor="middle"
                      fill="#000"
                      font-family="Play"
                      font-size="16"
                      font-style="normal"
                      font-weight="700"
                      letter-spacing="8px"
                      text-transform="uppercase"
                    >
                      Trade Now
                    </text>
                    </Link>
                    <defs>
                      <filter
                        id="filter0_b_10314_1915"
                        x="-12.5556"
                        y="-12.5556"
                        width="469.111"
                        height="57.1112"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.27781" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10314_1915" />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_backgroundBlur_10314_1915"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_10314_1915"
                        x1="-35.6"
                        y1="6.26793"
                        x2="498.752"
                        y2="116.828"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#FA75F8" />
                        <stop offset="1" stop-color="#9E6EE6" />
                      </linearGradient>
                    </defs>
                  </svg>
                  </Link>
                </div>
              
            </div>
            
          </div>
        </div>
      </div>
      <LeaderBoardnew />
    </div>
  );
};

export default UnknownEvent;
