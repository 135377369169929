import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, GOERLI, OPTIMISM_GOERLI, MOONBEAM, OPTIMISM, BASE} from "./chains";

const CONTRACTS = {
  [BASE]: {
    // Base Mainnet
    Vault: "0x1ce0EBd2b95221b924765456fdE017B076E79dbe",
    Router: "0xb575cEa2d54C08D9C25AC033b8C93BCE2FB49495",
    VaultReader: "0xe6228dD16f26484dd5eb8CeDC0ed329e40F2Ee9c",
    Reader: "0x0A33e2241AF597c9FB0dF5a44D3cCFf9E29d1013",
    FlpManager: "0x78155dE31d78777433CDB01bD105520a69f84312",
    RewardRouter: "0xa94143B7D38c8968c07f443D53C890d119213404",
    RewardReader: "0xCEbDB9939E5AE48Da9D3BD311bDFeD67747b20DD",
    NATIVE_TOKEN: "0xd6c5469A7cc587E1E89A841FB7c102FF1370C05F",
    FEE_REWARD_TOKEN: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    FLP: "0xaE4bB7E295E9f762B8c5d53E5dd31B4820d3A25c",
    FXDX: "0x55931579172622D7C714591B981bdD08C5Db8551",
    FXDXV2: "0x30b593f8c3ab37615359B4E0E6df2e06d55bB55d",
    ES_FXDX: "0x6d9A610b8D6b7F021bb7b92431093288d49b413E",
    BN_FXDX: "0xf43a20bDeeFF8E4131d79ED731Ed59AD67c3a4c7",
    USDF: "0xfe4DFb5789f6FD2c2bc3C3B8D1a13025B55756B1",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder
    StakedFxdxTracker: "0x1b819745582653a34C15c6f32c3c7Bfaca3E78E7",
    BonusFxdxTracker: "0x2e5e631B51c1c0b7287963729Bc6a68201A483ce",
    FeeFxdxTracker: "0x3EEb1184b59F6f5AF876c172a6F8488eC6Da32C2",
    StakedFlpTracker: "0xD964646861fae6525a42e074E7F6DfdaB4101e9d",
    FeeFlpTracker: "0x46BAA2cfDbF83232C2a85C74D521440F9bC1Fa4C",
    StakedFxdxDistributor: "0x92c2E315c1f5d75Ab5ef24b65f428cCFcd5e32dD",
    StakedFlpDistributor: "0xc2Bc824820B1A90B7D98CAa5343D977329f0Bd1f",

    // FxdxVester: "0xD5443e24ca5779f9DdAbd8f5b1dAe6E930c2C339",
    FxdxVester: "0x76EA2b66dFAB0B02ccC3d5917104bc5e1a258c6f",
    FlpVester: "0xcC705C69AFb552CEDdf999C9205d1633320d6a00",

    OrderBook: "0x8988BDdd4746d2e8C651FdB039F3F0303C686dBf",
    OrderBookReader: "0x96Bf83893a55A443Fa754317BfefA7500eDA67eF",

    PositionRouter: "0xa688605056b6656EAB66Cf7E82EA3608eA9d9E27",
    PositionManager: "0x3AeCf84f080cb28D694d0E64e8F0a20ECD86BB6c",

    SwapRouter: "0xf3D60285B29Cba1d14061eCf71f56777c5e1ab85",
    LiquidityRouter: "0x8a10708c91e74023221E6a8009790A7Ce5Dea03F",

    // UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x4d0247E79a00a31e92F81C9d90b734088fE8F278",
    ReferralReader: "0x263972832dcE5128136e6C5045974b0A5fBFc7D3",
    LiquidityReferralStorage: "0x197071aebAe5365FCB3883D6290ff1B19F3769e6",

    FaucetManager: "",

    TGE: "0x0fd187f2610790f3E56873C7226498E63cd021b7",

    FxdxConverter: "0xaa0Da2174BD5a7040816561a339A1D963Bcc18b8",
    FxdxDistributor: "0xFE6f18db4360BE0901a044e025120881eECE6aAe",

    StakedFxdxRouter: "0x4D975bE6032fE1E2E97b1482Bf9514c033e8f148",
    StakedFxdxVault: "0x7221B51F28C150e4D90aEAb2393B0Ea04B7F710F",
    RewardFxdxRouter: "0x09C4cEcC261b21BdfEf22c1cD9017e4727Ace998",
    RewardFxdxVault: "0xb9451677AAD516ce435b55d8989743881B5A0444",
    RewardRouterV3: "0xE0EbE07CDb7cE3671cE9D3fF899940864c83b38e",
    
    VesterVault: "0x058d3Cf1972ec9F2FF989f28464Fc3d9f8199cCa",
    VesterKeeper: "0x85fa8D80012e5c0aA80e804C7Cda6fCdDaCB00e5",
    VesterRouter: "0xe733102af1773115889B32A9a463D9191Ba80Bc6",

    // VesterVault: "0x058d3Cf1972ec9F2FF989f28464Fc3d9f8199cCa",
    // VesterKeeper: "0x85fa8D80012e5c0aA80e804C7Cda6fCdDaCB00e5",
    // VesterRouter: "0xe733102af1773115889B32A9a463D9191Ba80Bc6",

  },
  [GOERLI]: {
    // Goerli Testnet
    Vault: "0x4D47D1a8C302a81a1563458Ab5c9C4ac03f402c2",
    Router: "0xa6dF3F753076420b3807d980d388329616cFeFD2",
    VaultReader: "0x2ac8763aB3193FaE3155607e46f54193d6B69cDE",
    Reader: "0x07966EEd2B9C28110d2b4810813fbb904342EbCD",
    FlpManager: "0x535627418E938514D2E7188ABCD285CedF1F3749",
    RewardRouter: "0xE1e27A8a5657d67AbB5Bdf0D5A2021558F5CB27F",
    RewardReader: "0x8626aC37D5e45249c1875Cc11E7a6AbBa9753Cc4",
    NATIVE_TOKEN: "0x2B918c4E864056D7F9feB88D05d71F922fDDfFD0",
    FEE_REWARD_TOKEN: "0x1788509e1Bd6cd9b797B10E344A43d7Aea668Fc5",
    FLP: "0x433Ad5632b8c695ea09d2FB6D345cF579B1168fF",
    FXDX: "0x5FAb4cE46Ef52805178263D1c81aeA83685130D6",
    FXDXV2: "",
    ES_FXDX: "0x0C1799A98d9Af3beE56c217acC8CB989CA6a0481",
    BN_FXDX: "0x259889Bb235AF81AdEDe2D6c7206A8DD29445fE0",
    USDF: "0x19B3f3a90fA8e94A45bc4B19EeD5058851EBe30D",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder
    StakedFxdxTracker: "0x138929700459AbD5d23E1E7DA9282981F4Ea4d99",
    BonusFxdxTracker: "0x0dB372B4e83D0cf697fD0E5aabD88ee49174C458",
    FeeFxdxTracker: "0x69Fb6e305e80fcb325A7604A9d34d170A4e8E225",
    StakedFlpTracker: "0x89FEC4EBBE487D129120E16fc88f2fF6d0235b43",
    FeeFlpTracker: "0xCc8303005ed8E45D19149762dB2bb97511ca71fD",

    StakedFxdxDistributor: "0x0641562c81720a7aB6Cd45b81b54Bf24d4c54096",
    StakedFlpDistributor: "0x4d80d95047c2bE547C579b73658dE792c0DD5793",

    FxdxVester: "0x1CAF8580c64Fb4EDcbbaeD1b52bA8A1F68E5b10f",
    FlpVester: "0x8cDF8CABAFcc1813D2Da7B6C8fe2081E378b9fa0",

    OrderBook: "0x7660718A23845D7DE1470474445083F7df0f0003",
    OrderBookReader: "0x93c6679fe43026daF06424e0DD19Ff91cbBd7353",

    PositionRouter: "0xd9d9bF13fee2674687Fd37f030E3EC82F0d608c3",
    PositionManager: "0x6b1409Dc8C3182CdF3413F36C126c93A454A2f2e",

    SwapRouter: "0x508192f30CC01de0D10063D0981Fb63890C5000C",
    LiquidityRouter: "0x032091aCCc5e3e25c87Cda8Ce6Ae68e957417bD5",

    // UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xB2440A67229a941F9c48F53fD51B15c01a8dCb9E",
    ReferralReader: "0x5410a9e90c31C59B4ACd9Fd41f920664c44b439C",

    StakedFxdxRouter:  "",
    StakedFxdxVault: "",
    RewardFxdxRouter: "",
    RewardFxdxVault: "",
    RewardRouterV3: "",
  },
  [OPTIMISM_GOERLI]: {
    // Optimism Goerli Testnet
    Vault: "0xFA82987B691fEF62aA00b1563B09989E45604E28",
    Router: "0x2A6f0961c2dd13Ec5d2eA3F5E5Ea0753569595F0",
    VaultReader: "0x27AB6443Df01Ce9854dDD8630f31291BDd758670",
    Reader: "0xc72525E0Bea625a8A32b925532D7CC3d33B98A97",
    FlpManager: "0x738ED4DaeA704070873B62f1e9235a3aA87bB4Ad",
    RewardRouter: "0x004Aa0260A39126AFDA92c5c1B30d79591d9E6f1",
    RewardReader: "0xBbbBCF23A8c8Db3C488B642d0f0a515FE0eb7141",
    NATIVE_TOKEN: "0x5088964b5174E7DEe70a5343066f6a6Ac5ca71Fc",
    FEE_REWARD_TOKEN: "0xD1D57Fd32AE51eB778730d4C740E8C041891F525",
    FLP: "0x5615A47584C91879DE2Dd9B4491AA55Ab7041c4C",
    FXDX: "0xAcD5af1f4c2Ec46cc7b5E9e0d48b2cD32CdA7824",
    FXDXV2: "0xAcD5af1f4c2Ec46cc7b5E9e0d48b2cD32CdA7824",
    ES_FXDX: "0xAb068bfaBe430039728497BA37e8092849664250",
    BN_FXDX: "0x7C62eFE2586266912eaDa79586FB4F272348Ac6e",
    USDF: "0x34FEC3c093c381114Cd8307DBBe10494aED2ecc9",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder
    StakedFxdxTracker: "0xF60F7Fba90bF8B1bE92e3f7a0283ea61502b53b5",
    BonusFxdxTracker: "0x2D3103FB25D6431Bb0EE0C1C027fc2ac6AE45A76",
    FeeFxdxTracker: "0xeac58B419465688D5307077c6d682Fef5E754396",
    StakedFlpTracker: "0x5416A8dCF68d36cbe005Fc6F22E667c0D86eC585",
    FeeFlpTracker: "0x9644a161182CbcA864B7bcA4fEA4a29A8d921660",

    StakedFxdxDistributor: "0xFE89968CFfA96803D70D7445934a63B01D6655db",
    StakedFlpDistributor: "0xa9D99F5bc059cB38a90A6FC401e3098cF3CfD713",

    FxdxVester: "0xD5443e24ca5779f9DdAbd8f5b1dAe6E930c2C339",
    FlpVester: "0xE66462b7901663B0b290893e9AdbcbB718F9592a",

    OrderBook: "0x298c3F8e53ec8305325c45d1BB62A73a228C0b02",
    OrderBookReader: "0x777e502cCa58d1E16538A306398128Fd9B3c1280",

    PositionRouter: "0xd0B76C37a492E221855D6F103aE8E8Db98D2821B",
    PositionManager: "0xeb5A8cBd1c14c72Ffbc3FAA9a269c2737FB7164B",

    SwapRouter: "0xBda3EEC734F9b5AEf9Cc093C8DE4434A5Abf6665",
    LiquidityRouter: "0x020E770672Ab33a332D0EC7946c893A87B675070",

    // UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xCf11BCE7Bd96c42fe7E0c33F004d786642664A29",
    ReferralReader: "0x8E2a008f0c44C91ea45fE147315e2B54cd8FED80",
    LiquidityReferralStorage: "0xC338AA6C69c12B918C333128ab3321Bb964E638c",

    FaucetManager: "0xbd7A8b168e01103f8EDA4F240b484ba13bB309e7",

    StakedFxdxRouter: "0x9d396023f7f34D842852789A38369f11C6243eD5",
    StakedFxdxVault: "0xe26B3Fe13C6Cf4f354d5EEfC521cD1E5cEa06BCD",
    RewardFxdxRouter: "0xDA002b15c771B2B32a88378481289c945dB0F85C",
    RewardFxdxVault: "0x0c4e4b8dd09F2027C71BC48EFe663Ed883c8aFDB",
    RewardRouterV3: "0x0Bb66d467C39935d76cc7d2b6Af9EAd957f9cD5c",
    
    VesterVault: "0x958a6847aBEa6e52C99582566B107249D9e9d438",
    VesterKeeper: "",
    VesterRouter:"0x0990FC7a15884e74e1Ff4349e3f979FEf12c3FD5",
    DummyEsFxdx: "0xAb068bfaBe430039728497BA37e8092849664250",
    AToken: "0xAcD5af1f4c2Ec46cc7b5E9e0d48b2cD32CdA7824",
  },
  [OPTIMISM]: {
    // Optimism Mainnet
    Vault: "0x10235996C4DAbCE8430a71Cbc06571bd475A1d0C",
    Router: "0xE060f04E3707E21359471e854C929Dd60c2D362C",
    VaultReader: "0x22815ABAdaE86B84A4A5EE31fEa0BA5d4965AB74",
    Reader: "0x3B561E6270D5AF59624F6Ae7aBC6f950A2a16dc5",
    FlpManager: "0x945aC6C5e0ffF9522af1dDeEB04e8c1A32FaEbC2",
    RewardRouter: "0xd1A2E4BE651a33Ec2fCaC18d3418d9512c6AE443",
    RewardReader: "0xA20E707B94FC0F7DA3131f4377860cebD203Ef61",
    NATIVE_TOKEN: "0xD158B0F013230659098e58b66b602dFF8f7ff120",
    FEE_REWARD_TOKEN: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    FLP: "0xFc8d50529a5fA9e3Bcbbc2F3B2DE87B41146E512",
    FXDX: "0xB283fC842e695Ee27242aEAE7D591dbE1dde51C6",
    FXDXV2: "",
    ES_FXDX: "0x5e8C88F001698e68Fc648E87F3BFDCef9FE82Eb2",
    BN_FXDX: "0xBaDa39437EC773ddeb0f6e65CA4ef000a3bB0985",
    USDF: "0x702A9B84D2210B80d560a5D2e1833aB6A217b2F0",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder
    StakedFxdxTracker: "0x03B34a55eC24AA568Bd799D7566dFF5C1CF5a583",
    BonusFxdxTracker: "0x9E29d8274b1c37a28f6c247606C5D66602A2eB6C",
    FeeFxdxTracker: "0x14c43CC5C08f57C673B7fB6371FDE1131901b5C5",
    StakedFlpTracker: "0xAC28452710b2F5902ed2eC64B7909A47a85FE8e3",
    FeeFlpTracker: "0x98670281abb53B0c6c4e1F433CE1ea7Cd56f3C33",

    StakedFxdxDistributor: "0xaF4c0dE1b42eb4778fBd3d55C12b80600FC82E7B",
    StakedFlpDistributor: "0x4022c3FD7ACD9314b261B7b4EcB84510D9934329",

    FxdxVester: "0x5AB0Bb45661E3875Bc88fFB71aACa0C9fBFB74e6",
    FlpVester: "0x5098bdF91725BF811f1A9567e2A50c5A317D4137",

    OrderBook: "0x57E83bd936B659abE02ecbb145F28E22Ab786bAf",
    OrderBookReader: "0x89fd82d69FFbF3D4940b3aF90f82DAF242EbC1cb",

    PositionRouter: "0x29aEa7e7e81B3eF6ABbb43AC5e92354685812DaE",
    PositionManager: "0x10ff3F7eEFd237551026Dbeda6B8a88a35BF6DB0",

    SwapRouter: "0x62D2e45B3133C25E9b04C6a289674068d45Db33a",
    LiquidityRouter: "0x9c330a0066D85AB706b6ABc565f9E5462F176714",

    // UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x598B03D381EA2e3c517442105497d136b4085b25",
    ReferralReader: "0x9A46A582A484dF9b471BecFa0073be218494e732",
    LiquidityReferralStorage: "0x4Cc0fDd5Ea3b7E78B58483eA4929a367aAF7A667",

    FaucetManager: "",

    StakedFxdxRouter:  "",
    StakedFxdxVault: "",
    RewardFxdxRouter: "",
    RewardFxdxVault: "",
    RewardRouterV3: "",
  },

  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    FlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    FLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    FXDX: "0x0000000000000000000000000000000000000000",
    FXDXV2: "",
    ES_FXDX: "0x0000000000000000000000000000000000000000",
    BN_FXDX: "0x0000000000000000000000000000000000000000",
    USDF: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_FXDX_IOU: "0x0000000000000000000000000000000000000000",
    StakedFxdxTracker: "0x0000000000000000000000000000000000000000",
    BonusFxdxTracker: "0x0000000000000000000000000000000000000000",
    FeeFxdxTracker: "0x0000000000000000000000000000000000000000",
    StakedFlpTracker: "0x0000000000000000000000000000000000000000",
    FeeFlpTracker: "0x0000000000000000000000000000000000000000",

    StakedFxdxDistributor: "0x0000000000000000000000000000000000000000",
    StakedFlpDistributor: "0x0000000000000000000000000000000000000000",

    FxdxVester: "0x0000000000000000000000000000000000000000",
    FlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",

    StakedFxdxRouter:  "",
    StakedFxdxVault: "",
    RewardFxdxRouter: "",
    RewardFxdxVault: "",
    RewardRouterV3: "",
  },
  // [ARBITRUM_SEPOLIA]: {
  //   dummyEsFxdx: "0x49FF58AfA482B6AF926086f0f9d71BB3B01047E9",
  //   fxdx: "0x160703AbBa9F8D2Ae03Cc14C2Ec1e646b896D768",
  //   stakedFxdxTracker: "0x5190d286616d88047566bCF5457BFE2f84bB2D37",
  //   vesterVault: "",
  //   vesterKeeper: "",
  //   vesterRouter: "",
  // },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    FlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    FLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    FXDX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    FXDXV2: "",
    ES_FXDX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_FXDX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDF: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedFxdxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusFxdxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeFxdxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedFlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeFlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedFxdxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedFlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    FxdxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    FlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapFxdxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",

    // StakedFxdxRouter:  "",
    // StakedFxdxVault: "",
    // RewardFxdxRouter: "",
    // RewardFxdxVault: "",
    // RewardRouterV3: "",
  },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    FlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    FLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    FXDX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    FXDXV2: "",
    ES_FXDX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_FXDX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDF: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedFxdxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusFxdxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeFxdxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedFlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeFlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedFxdxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedFlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    FxdxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    FlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
    PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

    TraderJoeFxdxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",

    StakedFxdxRouter:  "",
    StakedFxdxVault: "",
    RewardFxdxRouter: "",
    RewardFxdxVault: "",
    RewardRouterV3: "",

  },
  [MOONBEAM]: {
    // MoonBeam
    Vault: "0xD3dDd179093C612d6f6abd48F65090DD008bA321",
    Router: "0xC824cAeA4Bcd3C054FeA78A58ba6D0A52f661792",
    VaultReader: "0x9BC6B9dB96327335480CC4D6F08A7E00a2384477",
    Reader: "0xB48fbc1F71B8E6C459736e59B80e27aFA3a0Ce3F",
    FlpManager: "0x6EEC778670d1b1c82b85271c3bFb4281A3DcCa8d",
    RewardRouter: "0x597bF61B3bC1AE3542c04b7070d24cE0735957c6",
    RewardReader: "0x268DE15eB064C01A0F837330510c755A5081E56c",
    NATIVE_TOKEN: "0x226bD09205A6Aa9374f77e746feB18971A2501a8",
    FLP: "0x779676333543249A741C1afB7f88e6aFB861427d",
    FXDX: "0x9a74dcaf28a9b7B9BB2Ec59F458EC77565A6A8b2",
    FXDXV2: "",
    ES_FXDX: "0xf596B3c00695d61b4F79477aD522f0cD46F0db25",
    BN_FXDX: "0x576Aa7EE949600939DBB36781cAF1110CB67a7c7",
    USDF: "0xB36A70FE255f56739fE65C26Ae8Fd0edd0DF2E83",
    ES_FXDX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedFxdxTracker: "0xe4a4b754Ead7245F19B82ba346285208fBC6146d",
    BonusFxdxTracker: "0x035564819A51B12De273E0C49517186Cba7f6C87",
    FeeFxdxTracker: "0xDaA78A48C99A23b1c4156F68A6678E97bfF0256F",
    StakedFlpTracker: "0xddb999B022816b774f5dE06B9f0Faf3b79cE2653",
    FeeFlpTracker: "0xA17eea14C82b42aa955d40a1438A31D693652fC7",

    StakedFxdxDistributor: "0xec0fcDE013059191EE2C164d3b1e4782C6aB4F60",
    StakedFlpDistributor: "0x9243e2f252952c4263dE06CDEaA8684DF014D16D",

    FxdxVester: "0xc3Ac168845c8DAbc8ad42B72F42Ebf48eAADf0F5",
    FlpVester: "0x9E87f4F8E6fad3334682Be65960c7ef112F93Aa0",

    OrderBook: "0x804e614b059Cc7fD8326d1B5C2ACc2A884BD3c0E",
    OrderBookReader: "0xbbf78fF7af9beB06D7B5D582dAfE83397D45d891",

    PositionRouter: "0xE939DfE46Eb7f18093BfbAe0dF8d34455893e0Ee",
    PositionManager: "0x6bD37eB049CbCc833AaB5172Ec2F0ce053FCa41c",

    ReferralStorage: "0x97B85d651fc31c877c23A954f851c99372178ad7",
    ReferralReader: "0xBA6eD2156C3e4791b43F98276BDA66B901A0659B",

    StakedFxdxRouter:  "",
    StakedFxdxVault: "",
    RewardFxdxRouter: "",
    RewardFxdxVault: "",
    RewardRouterV3: "",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
