import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import "./LaunchCard.css";
import "./LaunchCard2.css";

import { Timer } from "components/Timer/Timer";
import ProgressLine from "components/ProgressLine/ProgressLine";
import { getTimeLeft } from "pages/Launch/Launch";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useWallet from "lib/wallets/useWallet";
import Select from "react-select";
import { approveTokens, getTokenInfo, useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import TGE from "../../../src/abis/TGE.json";
import Token from "../../../src/abis/Token.json";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { getTokenBySymbol } from "config/tokens";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { BASIS_POINTS_DIVISOR, DEFAULT_SLIPPAGE_AMOUNT, FXDX_DECIMALS, shortenAddress } from "lib/legacy";
import { t } from "@lingui/macro";
import { usePrevious } from "lib/usePrevious";
import { helperToast } from "lib/helperToast";
import { IS_NETWORK_DISABLED, getChainName, getExplorerUrl, isSupportedChain } from "config/chains";
import { getContract } from "config/contracts";

const USDBC_HARD_CAP = expandDecimals(1, 11);

const LaunchCard2 = ({ props, pendingTxns, setPendingTxns }) => {
  const startTimestamp = 1696168800000; // October 1, 2023 2:00:00 PM - GMT
  const endTimestamp = 1696701600000; // October 7, 2023 6:00:00 PM - GMT
  const claimTimestamp = 1696874400000; // October 9, 2023 6:00:00 PM - GMT

  const { openConnectModal } = useConnectModal();
  const { isConnected: active, address: userAddress, signer, chainId, isConnected } = useWallet();

  const tgeAddress = getContract(chainId, "TGE");
  const usdbc = getTokenBySymbol(chainId, "USDbC");

  const [daysleft, setDays] = useState(0);
  const [hoursleft, setHours] = useState(0);
  const [minutesleft, setMinutes] = useState(0);
  const [secondsleft, setSeconds] = useState(0);
  const [expandedView, setexpandedView] = useState(props.clickable);
  const [inputvalue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState();
  const [inputCurrency, setInputCurrency] = useState("USDbC");
  const [checkbox, setcheckBox] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);

  const tgeStarted = Date.now() > startTimestamp;
  const tgeEnded = Date.now() > endTimestamp;
  const claimStarted = Date.now() > claimTimestamp;

  const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);

  const { data: usdbcDeposited } = useSWR([active, chainId, tgeAddress, "usdbcDeposited"], {
    fetcher: contractFetcher(signer, TGE),
  });

  const { data: usdbcContributed } = useSWR(
    isConnected && userAddress && [active, chainId, tgeAddress, "deposits", userAddress],
    {
      fetcher: contractFetcher(signer, TGE),
    }
  );

  const { data: tokenAllowance } = useSWR(
    isConnected && userAddress && [active, chainId, usdbc.address, "allowance", userAddress, tgeAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: claimableAmount } = useSWR(
    isConnected && userAddress && [active, chainId, tgeAddress, "claimableAmount", userAddress],
    {
      fetcher: contractFetcher(signer, TGE),
    }
  );

  const { data: refundAmount } = useSWR(
    isConnected && userAddress && [active, chainId, tgeAddress, "refundAmount", userAddress],
    {
      fetcher: contractFetcher(signer, TGE),
    }
  );

  const fromAmount = useMemo(() => {
    if (!inputvalue) {
      return bigNumberify(0);
    }
    return ethers.utils.parseUnits(inputvalue, inputCurrency === "ETH" ? 18 : usdbc.decimals);
  }, [inputCurrency, inputvalue, usdbc]);

  const needApproval =
    userAddress && tokenAllowance && inputvalue && inputCurrency === "USDbC" && tokenAllowance.lt(fromAmount);
  const prevNeedApproval = usePrevious(needApproval);
  const prevInputCurrency = usePrevious(inputCurrency);

  useEffect(() => {
    if (inputCurrency === prevInputCurrency && !needApproval && prevNeedApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{inputCurrency} approved!</div>);
    }
  }, [inputCurrency, isWaitingForApproval, needApproval, prevInputCurrency, prevNeedApproval]);

  const ethPrice = useMemo(() => {
    if (!infoTokens[ethers.constants.AddressZero].minPrice) {
      return 1;
    }
    return ethers.utils.formatUnits(infoTokens[ethers.constants.AddressZero].minPrice, 30);
  }, [infoTokens]);

  const getRunningTimer = (timestamp) => {
    let { days, hours, minutes, seconds } = getTimeLeft(timestamp);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  const calculateFXDXtokens = useCallback(
    (inputValue, isETH = false) => {
      const Price = 10; //cents
      let usdInput = inputValue;
      if (isETH) {
        usdInput = inputValue * ethPrice;
      }
      const fxdxToken = (100 / Price) * usdInput;
      if (fxdxToken === 0 || fxdxToken === undefined) {
        setOutputValue("");
        return;
      }
      setOutputValue(fxdxToken);
    },
    [ethPrice]
  );

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Deposits disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!inputvalue || !(parseFloat(inputvalue) || 0)) {
      return [t`Enter an amount`];
    }

    const fromTokenInfo = getTokenInfo(
      infoTokens,
      inputCurrency === "ETH" ? ethers.constants.AddressZero : usdbc.address
    );
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return [t`Incorrect network`];
    }
    if (fromTokenInfo && fromTokenInfo.balance && fromAmount && fromAmount.gt(fromTokenInfo.balance)) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`];
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }

    if (!tgeStarted) {
      return false;
    }

    if (!checkbox) {
      return false;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }

    if (!tgeStarted) {
      return t`TGE not started yet`;
    }

    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (!checkbox) {
      return t`Confirm checkbox`;
    }

    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving USDbC...`;
    }
    if (needApproval) {
      return t`Approve USDbC`;
    }

    if (isSubmitting) {
      return t`Depositing ...`;
    }

    return t`Deposit`;
  };

  const isSecondaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }

    if (!tgeEnded || !claimStarted) {
      return false;
    }

    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }

    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getSecondaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }

    if (!tgeEnded) {
      return t`TGE not ended yet`;
    }

    if (!claimStarted) {
      return t`Claim not started yet`;
    }

    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (IS_NETWORK_DISABLED[chainId]) {
      return t`Claim disabled, pending ${getChainName(chainId)} upgrade`;
    }

    if (isSubmitting) {
      return t`Claiming ...`;
    }

    return t`Claim`;
  };

  const saleTitle = useMemo(() => {
    if (tgeStarted) {
      if (tgeEnded) {
        return t`FXDX Token Generation event has now ended`;
      }
      return t`FXDX Token Generation event is now live`;
    }
    return t`FXDX Token Generation event is yet to start`;
  }, [tgeEnded, tgeStarted]);

  function approveUSDbC(amount) {
    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: usdbc.address,
      spender: tgeAddress,
      amount: amount,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
  }

  function claimFXDX() {
    if (!active) {
      openConnectModal?.();
      return;
    }
    if (isConnected && signer && tgeEnded) {
      setIsSubmitting(true);
      const contract = new ethers.Contract(tgeAddress, TGE.abi, signer);
      callContract(chainId, contract, "claimFXDX", {
        sentMsg: t`Claim FXDX Submitted`,
        successMsg: t`Claimed ${formatAmount(claimableAmount, FXDX_DECIMALS, 4, true)} FXDX and ${formatAmount(
          refundAmount,
          usdbc.decimals,
          4,
          true
        )} USDbC`,
        failMsg: t`Claim FXDX failed.`,
        setPendingTxns,
      })
        .then(async (res) => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }

  function handleBuy() {
    if (!active) {
      openConnectModal?.();
      return;
    }

    if (needApproval) {
      approveUSDbC(fromAmount);
      return;
    }

    if (tgeStarted && isConnected) {
      if (inputCurrency === "ETH") {
        depositETH(inputvalue);
      } else if (inputCurrency === "USDbC") {
        depositUSDbC(inputvalue);
      }
    }
  }

  const depositETH = async (amt) => {
    if (fromAmount && fromAmount.gt(0) && ethPrice && ethPrice !== 1) {
      let minoutUSD = ethers.utils.parseUnits((amt * ethPrice).toFixed(usdbc.decimals), usdbc.decimals);
      minoutUSD = minoutUSD.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

      setIsSubmitting(true);

      const contract = new ethers.Contract(tgeAddress, TGE.abi, signer);
      callContract(chainId, contract, "depositETH", [userAddress, minoutUSD], {
        value: fromAmount,
        sentMsg: t`Deposit ETH submitted.`,
        successMsg: t`deposited ${formatAmount(fromAmount, 18, 4, true)} ETH for ${outputValue} FXDX`,
        failMsg: t`Deposit ETH failed.`,
        setPendingTxns,
      })
        .then(async (res) => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const depositUSDbC = async (amt) => {
    if (!fromAmount || fromAmount.eq(0)) {
      return;
    }

    setIsSubmitting(true);

    const contract = new ethers.Contract(tgeAddress, TGE.abi, signer);
    callContract(chainId, contract, "depositUsdbc", [userAddress, fromAmount], {
      sentMsg: t`Deposit USDbC submitted.`,
      successMsg: t`deposited ${formatAmount(fromAmount, usdbc.decimals, 4, true)} USDbC for ${outputValue} FXDX`,
      failMsg: t`Deposit USDbC failed.`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    const nextTimestamp = tgeEnded ? claimTimestamp : endTimestamp;
    getRunningTimer(nextTimestamp);
    const interval = setInterval(() => getRunningTimer(nextTimestamp), 1000);
    return () => clearInterval(interval);
  }, [tgeEnded]);

  useEffect(() => {
    if (inputCurrency === "USDbC") {
      calculateFXDXtokens(inputvalue);
    }
    if (inputCurrency === "ETH") {
      calculateFXDXtokens(inputvalue, true);
    }
  }, [calculateFXDXtokens, inputCurrency, inputvalue]);

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: "#fff",
      backgroundColor: "#363636",
      margin: "0",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#363636",
      padding: "0",
      border: "none",
      boxShadow: "none",
      margin: "0",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff", backgroundColor: "#363636", margin: "0" }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      backgroundColor: "#363636",
      padding: "0",
    }),
    menubar: (defaultStyles) => ({ ...defaultStyles, color: "#fff", backgroundColor: "#363636", padding: "0" }),
    indicatorSeparator: (defaultStyles) => ({ ...defaultStyles, display: "none" }),
  };

  const options = [
    { value: "ETH", label: "ETH" },
    { value: "USDbC", label: "USDbC" },
  ];

  const depositPercentage = useMemo(() => {
    if (!usdbcDeposited || usdbcDeposited.eq(0)) {
      return "0%";
    }

    if (usdbcDeposited.gte(USDBC_HARD_CAP)) {
      return "100%";
    }

    const percentage = usdbcDeposited.mul(BASIS_POINTS_DIVISOR).div(USDBC_HARD_CAP);

    return `${formatAmount(percentage, 2, 2, false)}%`;
  }, [usdbcDeposited]);

  const userContribution = useMemo(() => {
    if (!usdbcContributed || usdbcContributed.eq(0)) {
      return "0.00";
    }
    return `${parseFloat(formatAmount(usdbcContributed, usdbc.decimals)).toFixed(2)}`;
  }, [usdbcContributed, usdbc]);

  return (
    <div
      className={`launch-card-container ${expandedView ? "expanded-border" : ""} ${props.clickable ? "" : "blur-it"}`}
    >
      <div
        className={`title ${expandedView ? "expanded-bg " : ""} ${props.clickable ? "clickable" : ""}`}
        onClick={() => {
          props.clickable && setexpandedView(!expandedView);
        }}
      >
        Phase {props.Stage}: {props.title}
        {expandedView ? (
          <FiChevronUp size={25} className={`expand-icon ${expandedView ? "expanded-bg" : ""}`} />
        ) : (
          <FiChevronDown size={25} className="expand-icon" />
        )}
      </div>
      <div className="launch-card-overview">
        <div className="overview-left">
          <p className="overview-left-desc1">{props.desc1}</p>
          <p className="overview-left-desc2">{props.desc2}</p>
          {/* <a href="/" className="learn-more">
            Learn More
          </a> */}
        </div>
        <div className="overview-right">
          {props.completed && <BsCheckCircleFill size={26} />}
          <div className="overview-right-content">
            Phase {props.Stage}: {props.title}
          </div>

          <div className="overview-right-subheading">
            {props.completed ? (
              "Completed"
            ) : props.started ? (
              <Timer endTime={props.endAt} text="Ends" />
            ) : (
              <Timer endTime={props.startAt} text="Starts" />
            )}
          </div>
        </div>
      </div>
      {expandedView && (
        <div className="expanded-view-container">
          <h2 className="expanded-title">{props.expandedTitle}</h2>
          <p className="expanded-desc">{props.expandedDesc}</p>
          <p className="expanded-desc">{props.expandedDescPointsHead}</p>
          <ul className="desc-points-cnt">
            {props.expandedDescPoints &&
              props.expandedDescPoints.map((ele, index) => {
                return <li key={`${index}-li`}>{ele}</li>;
              })}
          </ul>
          <div className="expanded-stat-cnt">
            <div className="expanded-stat-item-cnt">
              <p className="stat-item-title">FXDX TGE Price</p>
              <p className="stat-item-value font-bold">$0.10</p>
            </div>
            <div className="expanded-stat-item-cnt">
              <p className="stat-item-title">Inital Circulating Supply</p>
              <p className="stat-item-value font-bold">27.25M FXDX</p>
            </div>
            <div className="expanded-stat-item-cnt">
              <p className="stat-item-title">Inital Market Cap</p>
              <p className="stat-item-value font-bold">$2.72M </p>
            </div>
          </div>
          <div className="expaned-main-cnt">
            <div className="progress-stats">
              <p className="stats-title">{saleTitle}</p>
              <p>
                Users can contribute ETH/USDbC and take part in FXDX Token Generation Event. Tokens will be available
                for claiming two days after the end of the event.{" "}
              </p>
              <div className="progress-cnt">
                <ProgressLine
                  className="progress-bar"
                  visualParts={[
                    {
                      percentage: depositPercentage,
                      color: "#FFFFFF",
                    },
                  ]}
                />
                <div className="progress-value flex">
                  <p>$0</p>
                  <p>$100,000</p>
                </div>
              </div>

              <div className="expanded-stat-cnt margin-min">
                <div className="expanded-stat-item-cnt">
                  <p className="stat-item-title">You've contributed </p>
                  <p className="stat-item-value font-bold">${userContribution} </p>
                </div>
                <div className="expanded-stat-item-cnt">
                  <p className="stat-item-title">Contract Address</p>
                  <p className="stat-item-value">
                    <a
                      href={chainId && `${getExplorerUrl(chainId)}address/${tgeAddress}`}
                      target="_blank"
                      className="text-small"
                      rel="noreferrer"
                    >
                      {shortenAddress(tgeAddress, 11)}
                    </a>
                  </p>
                </div>
                <div className="expanded-stat-item-cnt">
                  <p className="stat-item-title"> {tgeEnded ? "Claim Starts in" : "TGE Ends in"}</p>
                  <p className="stat-item-value font-bold">
                    <div className="time-left-cnt">
                      <p>{daysleft}d:</p>
                      <p>{hoursleft}h:</p>
                      <p>{minutesleft}m:</p>
                      <p>{secondsleft}s</p>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            {tgeEnded ? (
              <div className="buy-fxdx">
                <h4 className="buy-fxdx-title">Claim FXDX</h4>
                <div>
                  <p className="fxdx-subtitle">You've contributed</p>
                  <div className="fxdx-input-cnt disabled">
                    <input type="number" placeholder="0.0" value={userContribution} disabled />
                    <p className="fxdx-token-label">USD</p>
                  </div>
                </div>
                <div>
                  <p className="fxdx-subtitle">You Will Receive</p>
                  <div className="fxdx-input-cnt disabled">
                    <input
                      type="number"
                      placeholder="0.0"
                      value={parseFloat(formatAmount(claimableAmount, FXDX_DECIMALS)).toFixed(2)}
                      disabled
                    />
                    <p className="fxdx-token-label">FXDX</p>
                  </div>

                  <div className="fxdx-input-cnt disabled">
                    <input
                      type="number"
                      placeholder="0.0"
                      value={parseFloat(formatAmount(refundAmount, usdbc.decimals)).toFixed(2)}
                      disabled
                    />
                    <p className="fxdx-token-label">USDBC</p>
                  </div>
                  <button
                    className={`fxdx-wallet-btn`}
                    onClick={() => {
                      claimFXDX();
                    }}
                    disabled={!isSecondaryEnabled()}
                  >
                    <p>{getSecondaryText()}</p>
                  </button>
                </div>
              </div>
            ) : (
              <div className="buy-fxdx">
                <h4 className="buy-fxdx-title">Buy FXDX</h4>
                <div>
                  <p lassName="fxdx-subtitle">Pay</p>
                  <div className="fxdx-input-cnt">
                    <input
                      type="number"
                      placeholder="0.0"
                      min={1}
                      value={inputvalue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Select
                      className="fxdx-token-label label-buy flex"
                      options={options}
                      defaultValue={options[1]}
                      styles={customStyles}
                      onChange={(choice) => {
                        setInputCurrency(choice.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="fxdx-subtitle">Receive</p>
                  <div className="fxdx-input-cnt">
                    <input type="number" placeholder="0.0" value={parseFloat(outputValue).toFixed(2)} />
                    <p className="fxdx-token-label">FXDX</p>
                  </div>
                </div>
                <span className="checkbox-cnt">
                  <input
                    type="checkbox"
                    value={checkbox}
                    onChange={(e) => {
                      setcheckBox(e.target.checked);
                    }}
                  />
                  <span>
                    Confirm that you're not from&nbsp;
                    <a
                      href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/base-debut-plan#phase-2-usdfxdx-token-generation-event"
                      target="_blank"
                      rel="noreferrer"
                    >
                      restricted jurisdiction
                    </a>
                  </span>
                </span>
                <button
                  className={`fxdx-wallet-btn`}
                  onClick={() => {
                    handleBuy();
                  }}
                  disabled={!isPrimaryEnabled()}
                >
                  <p>{getPrimaryText()}</p>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LaunchCard2;
