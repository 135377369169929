import { ARBITRUM, AVALANCHE, GOERLI, OPTIMISM_GOERLI, MOONBEAM, OPTIMISM, BASE } from "./chains";

export const SUBGRAPH_URLS = {
  [BASE]: {
    stats: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-base-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-base-referrals",
    saga: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-base-saga",
  },

  [GOERLI]: {
    stats: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-goerli-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-goerli-referrals",
  },

  [OPTIMISM_GOERLI]: {
    stats: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-goerli-stats2",
    referrals: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-goerli-referral2",
    saga: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-goerli-saga2",
  },

  [OPTIMISM]: {
    stats: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-referrals",
    saga: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-saga",
  },

  [ARBITRUM]: {
    stats: "https://api.thegraph.com/subgraphs/name/fxdx-io/fxdx-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/fxdxdex/fxdx-arbitrum-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/fxdx-vault",
  },

  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/fxdx-io/fxdx-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/fxdxdex/fxdx-avalanche-referrals",
  },

  [MOONBEAM]: {
    stats: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-moonbeam-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-moonbeam-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
