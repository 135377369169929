import { useEffect, useState } from "react";
import "./LeaderBoard.css";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import Traderone from "./Traderone.svg";
import { getLeaderBoardData, getUserActions } from "./newreferaldata";
import { shortenAddress } from "lib/legacy";
import TraderSmall from "./tradersmall.svg";
import { BigNumber } from "ethers";



const LeaderBoardnew = ({ period, chainId }) => {
  const [leaderboardData, setLeaderBoardData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch a different number of items based on screen size
        // const itemCount = isMobileView ? 7 : 20;
        const action = ["IncreasePosition-Long", "DecreasePosition-Long", "IncreasePosition-Short", "DecreasePosition-Short"]
        // getUserActions(8453, 1704803400, ["IncreasePosition-Long", "DecreasePosition-Long", "IncreasePosition-Short", "DecreasePosition-Short"]);

        const data = await getLeaderBoardData(8453, "daily", 0, 1000, action);
        setLeaderBoardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="leaderboard_container">
        <div className="leaderboard_box">
          {loading ? (
            <div className="loading-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            <div>
              <div className="leaderboard_box_data_fixed">
                <div className="leaderboard_title">
                  <h2>Leaderboard</h2>
                  <span className="invite_leaderboard">
                    <Link className="link-main" target="_blank" to={`/Referral`}>
                      <h4>Invite friends to rank up</h4>
                      <div className="invite_leaderboard_mobile">Invite friends</div>
                    </Link>
                  </span>
                </div>

                <div className="leaderboard_data">
                  <div className="leaderboard_Rank">Rank</div>
                  <div className="leaderboard-traders">Traders</div>
                  <div className="leaderboard-tradingvolume">Trading Volume</div>
                  <div className="leaderboard-rtl">Referral Trading Volume</div>
                  <div className="leaderboard-points">Points</div>
                </div>
              </div>

              <div className="leaderboard_trader_data">
                {leaderboardData &&
                  leaderboardData.map((leaderboardDataItem, index) => (

                    <>
                      <div key={leaderboardDataItem.account} className="mobile_data_all">
                        <div>
                          <div className="mobile_rank_address">
                            <div className="mobile_rank_margin">#{index + 1}</div>
                            <div className="mobile_address_margin"> <img src={TraderSmall} />
                              <Link className="link-main" target="_blank" to={`/trades/${leaderboardDataItem.account}`}>
                                {shortenAddress(leaderboardDataItem.account, 11)}
                              </Link>
                            </div>
                          </div>
                          <div className="mobile_border_line"></div>

                          <div className="data_mobile_check_border">
                            <div className="static_data_leaderboard-mobile">
                              <div className="leaderboard-tradingvolume_mobile_heading">Trading Volume</div>
                              <div className="leaderboard-rtl_mobile_heading">Referral Trading Volume</div>
                              <span className="leaderboard-points_mobile_heading">Points</span>
                            </div>
                            <div className="traderlive_data_mobile">
                              <div className="leaderboard-tradingvolume_mobile_data">
                                ${
                                  leaderboardDataItem.volume !== undefined
                                    ? parseFloat(leaderboardDataItem.volume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100
                                    : 0
                                }
                              </div>
                              <div className="leaderboard-rtl_mobile_data">
                                ${
                                  leaderboardDataItem.totalVolume !== undefined
                                    ? parseFloat(leaderboardDataItem.totalVolume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100
                                    : 0
                                }
                              </div>
                              <div className="leaderboard-points_mobile_data">{(leaderboardDataItem.allPoints)}</div>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div key={leaderboardDataItem.account} className="data_inside_box" onClick={() => { }}>
                        <div className="data_in_box_aligh">
                          <div className="rank_num_data">{index + 1}</div>
                          <div className="img_constant_referal">

                            <img src={Traderone} alt="Trader" />
                            <Link className="link-main" target="_blank" to={`/trades/${leaderboardDataItem.account}`}>
                              <span className="address_data_short">{shortenAddress(leaderboardDataItem.account, 11)}</span>
                            </Link>
                          </div>
                          <div className="leaderboard_trading_volume">
                            ${
                              leaderboardDataItem.volume !== undefined
                                ? parseFloat(leaderboardDataItem.volume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100
                                : 0
                            }
                          </div>
                          <div className="referal_leader_aligh">
                            <div className="referal_leaderboard_volume">
                              ${
                                leaderboardDataItem.totalVolume !== undefined
                                  ? parseFloat(leaderboardDataItem.totalVolume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100
                                  : 0
                              }
                            </div>
                          </div>
                          <div className="points_data">{(leaderboardDataItem.allPoints)}</div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="previoscamp_heading">
        <h3>Previous Campaigns </h3>
        <div className="previous_camp_box">
          <div className="previos_camp_box1">
            <span>
              <h3> Trading Competition</h3>
              <p>Trading Competition was held from <br />20 Oct 2023 - 20 Nov 2023 with a $1,000 prize pool in $esFXDX.</p>
              <Link className="link-main" target="_blank" to={`/leaderboard`}>
                <button>
                  <span className="gradient-text">View</span>
                </button>
              </Link>
            </span>
          </div>
          <div className="previos_camp_box1">
            <span>
              <h3> FLP Saga</h3>
              <p>FXDX's month-long FLP Saga rewarded liquidity providers with esFXDX tokens, boosting DEX liquidity.</p>
              <Link className="link-main" target="_blank" to={`/launch`}>
                <button>
                  <span className="gradient-text">View</span>
                </button>
              </Link>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LeaderBoardnew;
