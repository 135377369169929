import React, { useEffect } from "react";

import { useTokenActions } from "domain/legacy";
import "./TradeHistory.css";
import "./TradeHistoryBook.css";
import { bigNumberify, expandDecimals, formatAmount, formatPrice } from "lib/numbers";
import { formatTime24 } from "lib/dates";
import { t } from "@lingui/macro";

export default function TradeHistoryBook({ chainId, nativeTokenAddress, chartToken, tradeType }) {
  const tokenAddress = chartToken.isNative ? nativeTokenAddress : chartToken.address;

  const { actions, updateActions } = useTokenActions(chainId, tokenAddress, tradeType);

  useEffect(() => {
    const interval = setInterval(() => {
      updateActions(undefined, true);
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, [updateActions]);

  const actionBookContainer = (amount, price, time, color) => {
    return (
      <div className="eachAction">
        <p>{amount}</p>
        <p className={color}>{price}</p>
        <p>{time}</p>
      </div>
    );
  };

  return (
    <div className="TradeHistory Book">
      <div className="OrderBook-header">
        <p>{t`Trades`}</p>
      </div>
      <div className="eachAction-header">
        <div className="eachAction">
          <p>{t`Amount`}</p>
          <p>{t`Price`}</p>
          <p>{t`Time`}</p>
        </div>
      </div>
      <div className="allMessages">
        {chartToken &&
          actions &&
          actions.map((action, index) => {
            const priceStr = formatPrice(action.price, true);
            const timeStr = formatTime24(action.timestamp);
            let color = "green";
            let amountStr = "";

            if (tradeType === "swap") {
              amountStr = formatAmount(action.amount, chartToken.decimals, 4);
              color = action.isIn ? "green" : "red";
            } else {
              const amount = bigNumberify(action.sizeDelta)
                .mul(expandDecimals(1, chartToken.decimals))
                .div(action.price);
              amountStr = formatAmount(amount, chartToken.decimals, 4);
              if (action.type.startsWith("liquidate")) {
                color = "blue";
              } else if (["increaseLong", "decreaseShort"].includes(action.type)) {
                color = "green";
              } else {
                color = "red";
              }
            }

            const amountFloat = parseFloat(amountStr);

            if (amountFloat >= 100) {
              let tokenDecimals = 3;
              if (amountFloat >= 100000) {
                tokenDecimals = 0;
              } else if (amountFloat >= 10000) {
                tokenDecimals = 1;
              } else if (amountFloat >= 1000) {
                tokenDecimals = 2;
              }

              amountStr = formatAmount(Math.round(amountFloat * 10000), 4, tokenDecimals, true);
            }

            return (
              <div className="TradeHistory-row App-box App-box-border" key={index}>
                {actionBookContainer(amountStr, priceStr, timeStr, color)}
              </div>
            );
          })}
      </div>
    </div>
  );
}
