import { useConnectModal } from "@rainbow-me/rainbowkit";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";
import connectWalletImg from "img/ic_wallet_24.svg";
import { useState, useEffect } from "react";
import "./Header.css";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import { Trans } from "@lingui/macro";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome";
import { BASE, getChainName, IS_TESTNET, OPTIMISM, OPTIMISM_GOERLI } from "config/chains";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import axios from "axios";


type Props = {
  openSettings: () => void;
  small?: boolean;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderUser({
  openSettings,
  small,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const [price, setPrice] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get("https://token.fxdx.exchange/tokendata")
        .then((res) => setPrice(res.data.fxdxPrice))
        .catch((err) => {
          console.log(err.message);
        });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { chainId } = useChainId();
  const showConnectionOptions = !isHomeSite();

  const networkOptions = IS_TESTNET
    ? [
        // {
        //   label: getChainName(GOERLI),
        //   value: GOERLI,
        //   icon: "ic_ethereum_24.svg",
        //   color: "#264f79",
        // },
        {
          label: getChainName(OPTIMISM_GOERLI),
          value: OPTIMISM_GOERLI,
          icon: "ic_optimism_24.svg",
          color: "#E841424D",
        },
      ]
    : [
        {
          label: getChainName(BASE),
          value: BASE,
          icon: "ic_base_24.svg",
          color: "#264fa9",
        },
        {
          label: getChainName(OPTIMISM),
          value: OPTIMISM,
          icon: "ic_optimism_24.svg",
          color: "#E841424D",
        },
        // {
        //   label: getChainName(MOONBEAM),
        //   value: MOONBEAM,
        //   icon: "ic_moonbeam_24.svg",
        //   color: "#264f79",
        // },
        // {
        //   label: getChainName(ARBITRUM),
        //   value: ARBITRUM,
        //   icon: "ic_arbitrum_24.svg",
        //   color: "#264f79",
        // },
        // {
        //   label: getChainName(AVALANCHE),
        //   value: AVALANCHE,
        //   icon: "ic_avalanche_24.svg",
        //   color: "#E841424D",
        // },
      ];

  const { openConnectModal } = useConnectModal();
  const { isConnected, address } = useWallet();
  const selectorLabel = getChainName(chainId);

  if (!isConnected || !address) {
    return (
      <div className="App-header-user">
        <div className="priceFeed">

          <a target="_blank" rel="noreferrer" href="/getfxdx" style={{ color: "white" }}>
            <div className="priceComponent">
              <div className="fxdxLogo">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="23" height="23" rx="11.5" fill="white" />
                  <path
                    d="M12.9718 14.5424L11.5075 12.5037L10.032 14.5424H8.8493L10.9218 11.6927L8.90561 8.93315H10.0883L11.5075 10.9043L12.9154 8.93315H14.0981L12.0932 11.7153L14.1544 14.5424H12.9718Z"
                    fill="black"
                  />
                  <path d="M6 5.99854H8.46935V6.95382H7.02522V16.0467H8.46935V17.002H6V5.99854Z" fill="black" />
                  <path
                    d="M14.5341 17.002V16.0467H15.9672V6.95382H14.5341V5.99854H17.0035V17.002H14.5341Z"
                    fill="black"
                  />
                </svg>
              </div>
              {price !== "" ? (
                <>
                  <div className="priceData">{price}</div>
                  <div className="buyBtn">
                    <div className="buy">Buy</div>
                  </div>
                </>
              ) : (
                <div className="load shimmer"></div>
              )}
            </div>
          </a>

        </div>
        {showConnectionOptions ? (
          <>
            <ConnectWalletButton onClick={() => openConnectModal?.()} imgSrc={connectWalletImg}>
              {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
            </ConnectWalletButton>
            <NetworkDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              openSettings={openSettings}
            />
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, address);

  return (
    <div className="App-header-user">
      <div className="priceFeed">
        <a target="_blank" rel="noreferrer" href="/getfxdx" style={{ color: "white" }}>
          <div className="priceComponent">
            <div className="fxdxLogo">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="23" height="23" rx="11.5" fill="white" />
                <path
                  d="M12.9718 14.5424L11.5075 12.5037L10.032 14.5424H8.8493L10.9218 11.6927L8.90561 8.93315H10.0883L11.5075 10.9043L12.9154 8.93315H14.0981L12.0932 11.7153L14.1544 14.5424H12.9718Z"
                  fill="black"
                />
                <path d="M6 5.99854H8.46935V6.95382H7.02522V16.0467H8.46935V17.002H6V5.99854Z" fill="black" />
                <path
                  d="M14.5341 17.002V16.0467H15.9672V6.95382H14.5341V5.99854H17.0035V17.002H14.5341Z"
                  fill="black"
                />
              </svg>
            </div>
            {price !== "" ? (
              <>
                <div className="priceData">{price}</div>
                <div className="buyBtn">
                  <div className="buy">Buy</div>
                </div>
              </>
            ) : (
              <div className="load shimmer"></div>
            )}
          </div>
        </a>
      </div>
      {showConnectionOptions ? (
        <>
          <div className="App-header-user-address">
            <AddressDropdown
              account={address}
              accountUrl={accountUrl}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          <NetworkDropdown
            small={small}
            networkOptions={networkOptions}
            selectorLabel={selectorLabel}
            openSettings={openSettings}
          />
        </>
      ) : (
        <LanguagePopupHome />
      )}
    </div>
  );
}
