import React from "react";
import cx from "classnames";
import "./Footer.css";
// import logoImg from "img/ic_fxdx_footer.svg";
import twitterIcon from "img/ic_twitter.svg";
import discordIcon from "img/ic_discord.svg";
import telegramIcon from "img/ic_telegram.svg";
import githubIcon from "img/ic_github.svg";
import mediumIcon from "img/ic_medium.svg";
import { isHomeSite } from "lib/legacy";
import logoImg from "img/NEW_LOGO_LIGHT.svg";
import { IS_TESTNET } from "config/chains";
import { getAppBaseUrl } from "lib/legacy";
import EmailSubscribe from "components/EmailSubscribe/EmailSubscribe";

const socialLinks = [
  { link: "https://twitter.com/fxdxdex", name: "Twitter", icon: twitterIcon },
  { link: "https://discord.gg/duDxNgKGaX", name: "Discord", icon: discordIcon },
  { link: "https://github.com/fxdxdex", name: "Github", icon: githubIcon },
  // { link: "https://t.me/fxdxdex", name: "Telegram", icon: telegramIcon },
  { link: "https://fxdxdex.medium.com", name: "Medium", icon: mediumIcon },
];

export default function Footer() {
  const isHome = isHomeSite();

  return (
    // <div className="Footer">
    <div className={cx("Footer-wrapper", { home: isHome })}>
      <div className="footer-logo-container">
        <div className="Footer-logo">
          <img src={logoImg} alt="MetaMask" />
        </div>
        <div className="Footer-social-link-block">
          {socialLinks.map((platform) => {
            return (
              <a
                key={platform.name}
                className="App-social-link"
                href={platform.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={platform.icon} alt={platform.name} />
              </a>
            );
          })}
        </div>
        <EmailSubscribe />
      </div>
      <div className="footer-links-container">
        <div className="footer-nav">
          <h3>Menu</h3>
          <ul>
            <li>
              <a href="https://fxdxdex.medium.com/" target="_blank" rel="noreferrer">
                Blog
              </a>
            </li>
            <li>
              <a href="https://fxdx.canny.io/public-mainnet-feedback" target="_blank" rel="noreferrer">
                Feedback
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-nav">
          <h3>Docs</h3>
          <ul>
            <li>
              <a
                href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/legal/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-nav">
          <h3>Ecosystem</h3>
          <ul>
            <li>
              {IS_TESTNET ? (
                <a href="https://app.fxdx.exchange/trade" target="_blank" rel="noreferrer">
                  Mainnet
                </a>
              ) : (
                <a href="https://testnet.fxdx.exchange/trade" target="_blank" rel="noreferrer">
                  Testnet
                </a>
              )}
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/trades`} target="_blank" rel="noreferrer">
                TradeBook
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/orders_overview`} target="_blank" rel="noreferrer">
                Orders Overview
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-nav">
          <h3>Pages</h3>
          <ul>
            <li>
              <a href={`${getAppBaseUrl()}/overview`} target="_blank" rel="noreferrer">
                Overview
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/swap`} target="_blank" rel="noreferrer">
                Swap
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/trade`} target="_blank" rel="noreferrer">
                Trade
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/earn`} target="_blank" rel="noreferrer">
                Earn
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/buy`} target="_blank" rel="noreferrer">
                Buy
              </a>
            </li>
            <li>
              <a href={`${getAppBaseUrl()}/referrals`} target="_blank" rel="noreferrer">
                Referrals
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
