import React, { useEffect, useMemo, useState } from 'react'
import "./Vest.css"
import { Trans, t } from "@lingui/macro";
import useWallet from 'lib/wallets/useWallet';
import { FXDX_DECIMALS, useENS } from 'lib/legacy';
import { bigNumberify, formatAmount } from 'lib/numbers';
import FxdxDistributor from "abis/FxdxDistributor.json";
import { getContract } from 'config/contracts';
import useSWR from 'swr';
import { callContract, contractFetcher } from 'lib/contracts';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';

const Vest = (props) => {
    const { setPendingTxns } = props;
    const { isConnected: active, address: account, signer, chainId, ens } = useWallet();
    const { ensName } = useENS(account);
    const { openConnectModal } = useConnectModal();

    const [isClaiming, setIsClaiming] = useState(false);

    const FxdxDistributorAddress = getContract(chainId, "FxdxDistributor");

    const { data: vestingAmounts } = useSWR(
        active && account && [
            `FxdxDistributor:vestingAmounts:${active}`,
            chainId,
            FxdxDistributorAddress,
            "getVestedAmount",
            account,
        ],
        {
            fetcher: contractFetcher(signer, FxdxDistributor),
        }
    );

    const { data: vestingDurations } = useSWR(
        active && account && [
            `FxdxDistributor:vestingDurations:${active}`,
            chainId,
            FxdxDistributorAddress,
            "vestingDurations",
            account,
        ],
        {
            fetcher: contractFetcher(signer, FxdxDistributor),
        }
    );

    const { data: claimedAmounts } = useSWR(
        active && account && [
            `FxdxDistributor:vestingDurations:${active}`,
            chainId,
            FxdxDistributorAddress,
            "claimedAmounts",
            account,
        ],
        {
            fetcher: contractFetcher(signer, FxdxDistributor),
        }
    );

    const { data: claimableAmounts } = useSWR(
        active && account && [
            `FxdxDistributor:vestingDurations:${active}`,
            chainId,
            FxdxDistributorAddress,
            "claimable",
            account,
        ],
        {
            fetcher: contractFetcher(signer, FxdxDistributor),
        }
    );

    const [winWidth, setwinWidth] = useState(undefined);
    const [windowSize, setwindowSize] = useState(undefined);
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    function secondsToTime(seconds) {
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInMonth = 2592000;
        const secondsInYear = 31536000;

        const years = Math.floor(seconds / secondsInYear);
        const months = Math.floor((seconds % secondsInYear) / secondsInMonth);
        const days = Math.floor(((seconds % secondsInYear) % secondsInMonth) / secondsInDay);

        return {
            years: years,
            months: months,
            days: days
        };
    }



    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimenion]);

    useEffect(() => {
        windowDimenion.winWidth > 1100 && setwindowSize("large");
        windowDimenion.winWidth < 1100 && setwindowSize("small");

        setwinWidth(windowDimenion.winWidth);
    }, [windowDimenion]);

    const vestedAmount = useMemo(() => {
        if (vestingAmounts) {
            return formatAmount(vestingAmounts, FXDX_DECIMALS, 2, true)
        }
        else {
            return "0"
        }
    });

    const vestedDuration = useMemo(() => {
        if (vestingDurations?.gt(0)) {
            const { years, months, days } = secondsToTime(parseInt(formatAmount(vestingDurations, 0, 0)));
            return (`${years > 0 ? `${years} years` : ''} ${months > 0 ? `${months} months` : ''} ${days > 0 ? `${days} days` : ''}`)
        }

        return "0 days"
    });

    const claimedAmount = useMemo(() => {
        if (claimedAmounts) {
            return formatAmount(claimedAmounts, FXDX_DECIMALS, 2, true)
        }
        else {
            return "0.00"
        }
    });

    const claimableAmount = useMemo(() => {
        if (claimableAmounts) {
            return formatAmount(claimableAmounts, FXDX_DECIMALS, 2, true)
        }
        else {
            return "0.00"
        }
    });

    const RemainingAmount = useMemo(() => {
        if (claimableAmounts && vestingAmounts && claimedAmounts) {
            const remainingAmount = vestingAmounts?.sub(claimableAmounts?.add(claimedAmounts))
            return formatAmount(remainingAmount, FXDX_DECIMALS, 2, true)
        }
        else {
            return "0.00"
        }
    })

    const isPrimaryEnabled = () => {

        if (isClaiming) {
            return false;
        }

        if (claimableAmounts?.eq(0)) {
            return false;
        }
        return true;
    };

    const getPrimaryText = () => {
        if (!active) {
            return t`Connect Wallet`;
        }
        if (isClaiming) {
            return t`Claiming...`;
        }
        return t`Claim`;
    };

    const onPrimaryClick = () => {
        if (!active) {
            openConnectModal()
            return;
        }
        claimVestedFXDX()
    }

    function claimVestedFXDX() {
        setIsClaiming(true)
        const contract = new ethers.Contract(FxdxDistributorAddress, FxdxDistributor.abi, signer);
        callContract(chainId, contract, "claim", [], {
            sentMsg: t`Claim submitted.`,
            failMsg: t`Claim failed.`,
            successMsg: t`Claimed!`,
            setPendingTxns,
        })
            .then(async (res) => { })
            .finally(() => { setIsClaiming(false) });
    };


    const totalRows = () => {
        return (
            <>
                <div className="App-card-row">
                    <div className="label">Total Token Allocation</div>
                    <div>
                        {vestedAmount} FXDX
                    </div>
                </div>
                <div className="App-card-row">
                    <div className="label">Vesting Duration</div>
                    <div>
                        {vestedDuration}
                    </div>
                </div>
                <div className="App-card-row">
                    <div className="label">Claimed Amount</div>
                    <div>
                        {claimedAmount} FXDX
                    </div>
                </div>
                <div className="App-card-row">
                    <div className="label">Claimable</div>
                    <div>
                        {claimableAmount} FXDX
                    </div>
                </div>
                <div className="App-card-row">
                    <div className="label">Remaining Token Allocation</div>
                    <div>
                        {RemainingAmount} FXDX
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className='default-container vest-container'>
            <div className='section-title-content '>
                <div className='Page-title'>
                    <Trans>Vest</Trans> <div className="Page-description">
                        {account && <span className="user-address">{ensName || account}</span>}
                    </div>
                </div>
                <div className='vest-card-btn'>
                    <button disabled={!isPrimaryEnabled()} onClick={onPrimaryClick}>{getPrimaryText()}</button>
                </div>
            </div>

            <div className="App-card primary vest-card">
                {/* <div className="App-card-divider"></div> */}
                <div className="App-card-content">
                    <div className="App-card-title">
                        <Trans>FXDX </Trans>
                    </div>
                    {winWidth > 1100 && totalRows()}
                    <div className="rows">{winWidth < 1100 && totalRows()}</div>
                    <div className='vest-card-btn-phone'>
                        <button disabled={!isPrimaryEnabled()} onClick={onPrimaryClick}>{getPrimaryText()}</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Vest