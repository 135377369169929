export const ASSETS = [
  {
    name: "Bitcoin",
    symbol: "BTC",
    Issue_Time: "2008-10-31",
    Total_Supply: "21,000,000",
    Circulation: "18,247,575",
    White_Paper: "https://bitcoin.org/bitcoin.pdf",
    Website: "https://bitcoin.org/",
    description:
      "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
  },
  {
    name: "Ethereum",
    symbol: "ETH",
    Issue_Time: "2014-07-24",
    Total_Supply: "109,542,949",
    Circulation: "109,542,949",
    White_Paper: "https://github.com/ethereum/wiki/wiki/White-Paper",
    Website: "https://www.ethereum.org/",
    description:
      "Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called 'ether', which can be transferred between accounts and used to compensate participant nodes for computations performed. 'Gas', an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    Issue_Time: "2018-09-24",
    Total_Supply: "2,856,939,253",
    Circulation: "2,856,939,253",
    White_Paper: "https://www.centre.io/pdfs/centre-whitepaper.pdf",
    Website: "https://www.centre.io/",
    description:
      "Token Introduction: USD Coin (USDC) is a stablecoin fully backed by the US dollar and developed by the Centre consortium. Centre issues and redeems USDC without any extra fee and is licensed as a money-transmitter in the US and as an e-money institution in Europe. USDC can always be redeemed 1:1 to US dollars. Funds (for collateral) are held in segregated bank accounts, that are monitored and audited by third-parties. USD Coin has become one of the largest stablecoins (with a supply approaching a billion), its support has been extended to multiple decentralized applications (e.g., DeFi) and a wide variety of exchanges (e.g., Coinbase, Binance, Kraken).",
  },
  {
    name: "Tether",
    symbol: "USDT",
    Issue_Time: "2014-11-20",
    Total_Supply: "2,830,000,000",
    Circulation: "2,507,000,000",
    White_Paper: "https://tether.to/wp-content/uploads/2016/06/TetherWhitePaper.pdf",
    Website: "https://tether.to/",
    description:
      "USDT is a crypto-currency asset issued on the Bitcoin blockchain via the Omni Layer Protocol. Each USDT unit is backed by a U.S Dollar held in the reserves of the Tether Limited and can be redeemed through the Tether Platform. USDT can be transferred, stored, spent, just like Bitcoins or any other crypto-currency.USDT and other Tether currencies were created to facilitate the transfer of national currencies, to provide users with a stable alternative to Bitcoin and to provide an alternative for exchange and wallet audits which are currently unreliable. USDT provides an alternative to Proof of Solvency methods by introducing a Proof of Reserves Process. In the Tether Proof of Reserves system, the amount of USDT in circulations can be easily checked on the Bitcoin blockchain via the tools provided at Omnichest.info, while the corresponding total amount of USD held reserves is proved by publishing the bank balance and undergoing periodic audits by professionals.",
  },
];
