import { useMemo } from "react";

const ProgressBar = ({ bgcolor, value, minValue, maxValue }) => {
  const percentage = useMemo(() => {
    if (value.lt(minValue)) {
      return 0;
    }
    if (value.gt(maxValue)) {
      return 100;
    }

    return value.sub(minValue).mul(100).div(maxValue.sub(minValue)).toNumber();
  }, [maxValue, minValue, value]);

  const containerStyles = {
    height: "1rem",
    width: "95%",
    backgroundColor: "#D0D0D0",
    borderRadius: "1.5rem",
    margin: ".5rem",
    border: "none",
  };

  const fillerStyles = {
    height: "1rem",
    width: `${percentage}%`,
    backgroundColor: bgcolor,
    borderRadius: "2rem",
    border: "none",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
