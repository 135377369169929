import React from "react";
import "./CoinInfo.css";
import CoinInfoRow from "./CoinInfoRow";
import { ASSETS } from "./constants";
const CoinInfo = () => {
  return (
    <div className="coin-info-wrapper">
      <p className="main-title">Information about the Assets listed on the platform</p>
      {ASSETS.map((coin, index) => {
        return <CoinInfoRow coin={coin} key={`coin ${index}`} />;
      })}
    </div>
  );
};

export default CoinInfo;
