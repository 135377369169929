import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { t, Trans } from "@lingui/macro";
import "rc-slider/assets/index.css";
import "./SwapBox.css";
import { CgArrowsExchangeV } from "react-icons/cg";

import "react-dragswitch/dist/index.css";

import cx from "classnames";
import useSWR from "swr";
import { ethers } from "ethers";

import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  getExchangeRate,
  getNextFromAmount,
  getNextToAmount,
  isTriggerRatioInverted,
  LIMIT,
  MARKET,
  PRECISION,
  SWAP,
  SWAP_ORDER_OPTIONS,
  USD_DECIMALS,
  USDF_ADDRESS,
  USDF_DECIMALS,
} from "lib/legacy";
import {
  // ARBITRUM,
  // AVALANCHE,
  getChainName,
  getConstant,
  IS_NETWORK_DISABLED,
  isSupportedChain,
} from "config/chains";
import * as Api from "domain/legacy";
import { getContract } from "config/contracts";

import Tab from "../Tab/Tab";
import TokenSelector from "./TokenSelector";
import ExchangeInfoRow from "./ExchangeInfoRow";
import ConfirmationBox from "./ConfirmationBox";
import OrdersToa from "./OrdersToa";

import SwapRouter from "abis/SwapRouter.json";
import Token from "abis/Token.json";
import WETH from "abis/WETH.json";

import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, replaceNativeTokenAddress, shouldRaiseGasError } from "domain/tokens";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken, getTokens } from "config/tokens";
import { ErrorCode, ErrorDisplayType } from "./constants";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useWallet from "lib/wallets/useWallet";

const { AddressZero } = ethers.constants;

export default function SwapBox(props) {
  const {
    infoTokens,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    totalTokenWeights,
    usdfSupply,
    orders,
    orderBookApproved,
    swapRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approveSwapRouter,
    setIsWaitingForPluginApproval,
    isWaitingForSwapRouterApproval,
    setIsWaitingForSwapRouterApproval,
    isPluginApproving,
    isSwapRouterApproving,
    savedShouldDisableValidationForTesting,
    swapMinExecutionFee,
    swapMinExecutionFeeUSD,
    swapMinExecutionFeeErrorMessage,
  } = props;

  const { address: account, isConnected: active, signer } = useWallet();
  const { openConnectModal } = useConnectModal();

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const [multiplier, setmultiplier] = useState(0);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  // const getLeaderboardLink = () => {
  //   if (chainId === ARBITRUM) {
  //     return "https://www.fxdx.house/arbitrum/leaderboard";
  //   }
  //   if (chainId === AVALANCHE) {
  //     return "https://www.fxdx.house/avalanche/leaderboard";
  //   }
  //   return "https://www.fxdx.house";
  // };

  const [ordersToaOpen, setOrdersToaOpen] = useState(false);

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Order-option"], MARKET);
  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };

  const isMarketOrder = orderOption === MARKET;
  const orderOptions = SWAP_ORDER_OPTIONS;
  const orderOptionLabels = { [MARKET]: t`Market`, [LIMIT]: t`Limit` };

  const onTriggerRatioChange = (evt) => {
    let value = evt.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setTriggerRatioValue(value);
    setmultiplier(0);
  };

  const tokens = getTokens(chainId);
  const fromTokens = tokens;

  let toTokens = tokens;

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const routerAddress = getContract(chainId, "Router");
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, tokenAllowanceAddress, "allowance", account, routerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: hasOutdatedUi } = Api.useHasOutdatedUi();

  const fromToken = getToken(chainId, fromTokenAddress);
  const toToken = getToken(chainId, toTokenAddress);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  const toBalance = toTokenInfo ? toTokenInfo.balance : bigNumberify(0);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const isPotentialWrap = (fromToken.isNative && toToken.isWrapped) || (fromToken.isWrapped && toToken.isNative);
  const isWrapOrUnwrap = isPotentialWrap;

  const needSwapRouterApproval = !isWrapOrUnwrap && isMarketOrder && !swapRouterApproved;
  const prevNeedSwapRouterApproval = usePrevious(needSwapRouterApproval);

  useEffect(() => {
    if (!needSwapRouterApproval && prevNeedSwapRouterApproval && isWaitingForSwapRouterApproval) {
      setIsWaitingForSwapRouterApproval(false);
      helperToast.success(<div>Swap enabled!</div>);
    }
  }, [
    needSwapRouterApproval,
    prevNeedSwapRouterApproval,
    setIsWaitingForSwapRouterApproval,
    isWaitingForSwapRouterApproval,
  ]);

  const needApproval =
    fromTokenAddress !== AddressZero &&
    tokenAllowance &&
    fromAmount &&
    fromAmount.gt(tokenAllowance) &&
    !isWrapOrUnwrap;
  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption);

  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const maxToTokenOut = useMemo(() => {
    const value = toTokenInfo.availableAmount?.gt(toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount))
      ? toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount)
      : toTokenInfo.availableAmount;

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [toTokenInfo]);

  const maxToTokenOutUSD = useMemo(() => {
    return getUsd(maxToTokenOut, toTokenAddress, false, infoTokens);
  }, [maxToTokenOut, toTokenAddress, infoTokens]);

  const maxFromTokenInUSD = useMemo(() => {
    const value = fromTokenInfo.maxUsdfAmount
      ?.sub(fromTokenInfo.usdfAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDF_DECIMALS));

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [fromTokenInfo]);

  const maxFromTokenIn = useMemo(() => {
    if (!fromTokenInfo.maxPrice) {
      return bigNumberify(0);
    }
    return maxFromTokenInUSD?.mul(expandDecimals(1, fromTokenInfo.decimals)).div(fromTokenInfo.maxPrice).toString();
  }, [maxFromTokenInUSD, fromTokenInfo]);

  let maxSwapAmountUsd = bigNumberify(0);

  if (maxToTokenOutUSD && maxFromTokenInUSD) {
    maxSwapAmountUsd = maxToTokenOutUSD.lt(maxFromTokenInUSD) ? maxToTokenOutUSD : maxFromTokenInUSD;
  }

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (ratio.eq(0)) {
      return bigNumberify(0);
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{fromToken.symbol} approved!</div>);
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromToken.symbol,
    isWaitingForApproval,
    fromToken,
  ]);

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress)) {
      setToTokenAddress(swapOption, toTokens[0].address);
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }
        if (toToken) {
          const { amount: nextToAmount } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            toTokenAddress,
            infoTokens,
            undefined,
            !isMarketOrder && triggerRatio,
            usdfSupply,
            totalTokenWeights,
            false
          );

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);
          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }

      if (fromToken) {
        const { amount: nextFromAmount } = getNextFromAmount(
          chainId,
          toAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          !isMarketOrder && triggerRatio,
          usdfSupply,
          totalTokenWeights,
          false
        );
        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);
        setFromValue(nextFromValue);
      }
    };

    updateSwapAmounts();
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerRatio,
    usdfSupply,
    totalTokenWeights,
    chainId,
  ]);

  const getSwapError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Swaps disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (hasOutdatedUi) {
      return [t`Page outdated, please refresh`];
    }

    if (fromTokenAddress === toTokenAddress) {
      return [t`Select different tokens`];
    }

    if (!isMarketOrder) {
      if ((toToken.isStable || toToken.isUsdf) && (fromToken.isStable || fromToken.isUsdf)) {
        return [t`Select different tokens`];
      }

      if (fromToken.isNative && toToken.isWrapped) {
        return [t`Select different tokens`];
      }

      if (toToken.isNative && fromToken.isWrapped) {
        return [t`Select different tokens`];
      }
    }

    if (!fromAmount || fromAmount.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return [t`Incorrect network`];
    }
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`];
    }

    const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

    if (!isMarketOrder) {
      if (!triggerRatioValue || triggerRatio.eq(0)) {
        return [t`Enter a price`];
      }

      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo);
      if (currentRate && currentRate.lt(triggerRatio)) {
        return triggerRatioInverted ? [t`Price below Mark Price`] : [t`Price above Mark Price`];
      }
    }

    if (
      !isWrapOrUnwrap &&
      toToken &&
      toTokenAddress !== USDF_ADDRESS &&
      toTokenInfo &&
      toTokenInfo.availableAmount &&
      toAmount.gt(toTokenInfo.availableAmount)
    ) {
      return [t`Insufficient liquidity`];
    }
    if (
      !isWrapOrUnwrap &&
      toAmount &&
      toTokenInfo.bufferAmount &&
      toTokenInfo.poolAmount &&
      toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(toAmount))
    ) {
      return [t`Insufficient liquidity`];
    }

    if (
      fromUsdMin &&
      fromTokenInfo.maxUsdfAmount &&
      fromTokenInfo.maxUsdfAmount.gt(0) &&
      fromTokenInfo.usdfAmount &&
      fromTokenInfo.maxPrice
    ) {
      const usdfFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDF_DECIMALS);
      const nextUsdfAmount = fromTokenInfo.usdfAmount.add(usdfFromAmount);

      if (nextUsdfAmount.gt(fromTokenInfo.maxUsdfAmount)) {
        return [t`${fromTokenInfo.symbol} pool exceeded`];
      }
    }

    return [false];
  };

  const getError = () => {
    return getSwapError();
  };

  const renderOrdersToa = () => {
    if (!ordersToaOpen) {
      return null;
    }

    return (
      <OrdersToa
        setIsVisible={setOrdersToaOpen}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    );
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needSwapRouterApproval && isWaitingForSwapRouterApproval) {
      return false;
    }
    if (isSwapRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (needSwapRouterApproval && isWaitingForSwapRouterApproval) {
      return t`Enabling Swap...`;
    }
    if (isSwapRouterApproving) {
      return t`Enabling Swap...`;
    }
    if (needSwapRouterApproval) {
      return t`Enable Swap`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${fromToken.symbol}...`;
    }
    if (needApproval) {
      return t`Approve ${fromToken.symbol}`;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling Orders...`;
    }
    if (isPluginApproving) {
      return t`Enabling Orders...`;
    }
    if (needOrderBookApproval) {
      return t`Enable Orders`;
    }

    if (!isMarketOrder) return t`Create ${orderOption.charAt(0) + orderOption.substring(1).toLowerCase()} Order`;

    if (toUsdMax && toUsdMax.lt(fromUsdMin.mul(95).div(100))) {
      return t`High Slippage, Swap Anyway`;
    }
    return t`Swap`;
  };

  const onSelectFromToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
    setIsWaitingForApproval(false);
  };

  const onSelectToToken = (token) => {
    setToTokenAddress(swapOption, token.address);
  };

  const onFromValueChange = (e) => {
    setmultiplier(0);
    setAnchorOnFromAmount(true);
    let value = e.target.value;
    value = value.replace(/[-eE]+/g, "");
    setFromValue(value);
  };

  const onToValueChange = (e) => {
    setmultiplier(0);
    setAnchorOnFromAmount(false);
    let value = e.target.value;
    value = value.replace(/[-eE]+/g, "");
    setToValue(value);
  };

  const switchTokens = () => {
    if (fromAmount && toAmount) {
      if (anchorOnFromAmount) {
        setToValue(formatAmountFree(fromAmount, fromToken.decimals, 8));
      } else {
        setFromValue(formatAmountFree(toAmount, toToken.decimals, 8));
      }
      setAnchorOnFromAmount(!anchorOnFromAmount);
    }
    setIsWaitingForApproval(false);

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    updatedTokenSelection[swapOption] = {
      from: toTokenAddress,
      to: fromTokenAddress,
    };
    setTokenSelection(updatedTokenSelection);
  };

  const wrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, signer);
    callContract(chainId, contract, "deposit", {
      value: fromAmount,
      sentMsg: t`Swap submitted.`,
      successMsg: t`Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: t`Swap failed.`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const unwrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, signer);
    callContract(chainId, contract, "withdraw", [fromAmount], {
      sentMsg: t`Swap submitted!`,
      failMsg: t`Swap failed.`,
      successMsg: t`Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const swap = async () => {
    if (fromToken.isNative && toToken.isWrapped) {
      wrap();
      return;
    }

    if (fromTokenAddress.isWrapped && toToken.isNative) {
      unwrap();
      return;
    }

    setIsSubmitting(true);
    let path = [fromTokenAddress, toTokenAddress];
    if (anchorOnFromAmount) {
      const { path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdfSupply,
        totalTokenWeights,
        false
      );
      if (multiPath) {
        path = multiPath;
      }
    } else {
      const { path: multiPath } = getNextFromAmount(
        chainId,
        toAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdfSupply,
        totalTokenWeights,
        false
      );
      if (multiPath) {
        path = multiPath;
      }
    }

    let method;
    let contract;
    let value;
    let params;
    let minOut;
    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(true);
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    if (!isMarketOrder) {
      minOut = toAmount;
      Api.createSwapOrder(chainId, signer, path, fromAmount, minOut, triggerRatio, nativeTokenAddress, {
        sentMsg: t`Swap Order submitted!`,
        successMsg: t`Swap Order created!`,
        failMsg: t`Swap Order creation failed.`,
        pendingTxns,
        setPendingTxns,
      })
        .then(() => {
          setIsConfirming(false);
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsPendingConfirmation(false);
        });
      return;
    }

    path = replaceNativeTokenAddress(path, nativeTokenAddress);
    method = "createSwap";
    value = swapMinExecutionFee;
    let isETHOut = false;
    if (toTokenAddress === AddressZero) {
      isETHOut = true;
    }

    minOut = toAmount.mul(BASIS_POINTS_DIVISOR - allowedSlippage).div(BASIS_POINTS_DIVISOR);
    const acceptableRatio = fromTokenInfo.minPrice
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(toTokenInfo.maxPrice)
      .mul(BASIS_POINTS_DIVISOR - allowedSlippage)
      .div(BASIS_POINTS_DIVISOR);

    params = [path, fromAmount, minOut, account, acceptableRatio, swapMinExecutionFee, isETHOut];

    if (fromTokenAddress === AddressZero) {
      method = "createSwapETHToTokens";
      value = fromAmount.add(swapMinExecutionFee);
      params = [path, minOut, account, acceptableRatio, swapMinExecutionFee];
    }

    const swapRouterAddress = getContract(chainId, "SwapRouter");
    contract = new ethers.Contract(swapRouterAddress, SwapRouter.abi, signer);

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: t`Swap submitted!`,
      successMsg: t`Requested swap of ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${toToken.symbol}!`,
      failMsg: t`Swap failed.`,
      setPendingTxns,
    })
      .then(async () => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onConfirmationClick = () => {
    if (!active) {
      openConnectModal();
      return;
    }

    if (needOrderBookApproval) {
      approveOrderBook();
      return;
    }

    setIsPendingConfirmation(true);

    swap();
  };

  function approveFromToken() {
    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
  }

  const onClickPrimary = () => {
    if (!active) {
      openConnectModal();
      return;
    }

    if (needSwapRouterApproval) {
      approveSwapRouter({
        sentMsg: t`Enable swap sent.`,
        failMsg: t`Enable swap failed.`,
      });
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
      wrap();
      return;
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
      unwrap();
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const showTriggerRatioSection = !isMarketOrder;

  let fees;
  let feesUsd;
  let feeBps;

  if (fromAmount) {
    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      toTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdfSupply,
      totalTokenWeights,
      false
    );
    if (feeBasisPoints !== undefined) {
      fees = fromAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
      const feeTokenPrice =
        fromTokenInfo.address === USDF_ADDRESS ? expandDecimals(1, USD_DECIMALS) : fromTokenInfo.maxPrice;
      feesUsd = fees.mul(feeTokenPrice).div(expandDecimals(1, fromTokenInfo.decimals));
    }
    feeBps = feeBasisPoints;
  }

  if (!fromToken || !toToken) {
    return null;
  }

  function setFromValueToMaximumAvailable(multiplier) {
    if (!fromToken || !fromBalance) {
      return;
    }
    multiplier && setmultiplier(multiplier);

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    const fromAvailableAmount =
      !maxAvailableAmount || multiplier === 1 ? maxAvailableAmount : maxAvailableAmount.div(2);
    setFromValue(formatAmountFree(fromAvailableAmount, fromToken.decimals, fromToken.decimals));
    setAnchorOnFromAmount(true);
  }

  const ERROR_TOOLTIP_MSG = {
    [ErrorCode.PoolExceeded]: t`FLP doesn't accept this amount of ${fromTokenInfo.symbol}.`,
  };

  function renderPrimaryButton() {
    const [errorMessage, errorType, errorCode] = getError();
    const primaryTextMessage = getPrimaryText();
    if (errorType === ErrorDisplayType.Tooltip && errorMessage === primaryTextMessage && ERROR_TOOLTIP_MSG[errorCode]) {
      return (
        <Tooltip
          isHandlerDisabled
          handle={
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {primaryTextMessage}
            </button>
          }
          position="center-bottom"
          className="Tooltip-flex"
          renderContent={() => ERROR_TOOLTIP_MSG[errorCode]}
        />
      );
    }
    return (
      <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
        {primaryTextMessage}
      </button>
    );
  }

  return (
    <div className="Exchange-swap-box">
      <div>
        <Tab options={[SWAP]} optionLabels={[SWAP]} option={swapOption} className="Exchange-swap-option-tabs" />
        {flagOrdersEnabled && (
          <Tab
            options={orderOptions}
            optionLabels={orderOptionLabels}
            className="Exchange-swap-order-type-tabs"
            type="inline"
            option={orderOption}
            onChange={onOrderOptionChange}
          />
        )}
      </div>
      <div className="Exchange-swap-box-inner App-box-highlight">
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Pay</Trans>
              </div>
            </div>
            {fromBalance && (
              <div className=" align-right clickable">
                <div
                  className={cx("Exchange-swap-max", {
                    half: multiplier === 0.5,
                  })}
                  onClick={() => setFromValueToMaximumAvailable(0.5)}
                >
                  <Trans>50%</Trans>
                </div>
                <div
                  className={cx("Exchange-swap-max", {
                    max: multiplier === 1,
                  })}
                  onClick={() => setFromValueToMaximumAvailable(1)}
                >
                  <Trans>100%</Trans>
                </div>
                <div className="balance-tag">Bal: {`${formatAmount(fromBalance, fromToken.decimals, 4, true)}`}</div>
              </div>
            )}
          </div>
          <div className="Exchange-swap-section-bottom">
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={fromValue.toString().slice(0, 10)}
                onChange={onFromValueChange}
              />
              <div className="usd-value">~${formatAmount(fromUsdMin, USD_DECIMALS, 2, true, "0.00")}</div>
            </div>
            <div>
              <TokenSelector
                label={t`Pay`}
                chainId={chainId}
                tokenAddress={fromTokenAddress}
                onSelectToken={onSelectFromToken}
                tokens={fromTokens}
                infoTokens={infoTokens}
                showMintingCap={false}
                showTokenImgInDropdown={true}
                showSymbolImageOutside={true}
              />
            </div>
          </div>
        </div>
        <div className="Exchange-swap-ball-container">
          <div className="Exchange-swap-ball" onClick={switchTokens}>
            <CgArrowsExchangeV size="25" />
          </div>
        </div>
        <div className="Exchange-swap-section receive-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              {toUsdMax && <div className="Exchange-swap-usd">{t`Receive`}</div>}
              {!toUsdMax && t`Receive`}
            </div>
            {toBalance && (
              <div className="muted align-right">
                <Trans>Bal</Trans>: {formatAmount(toBalance, toToken.decimals, 4, true)}
              </div>
            )}
          </div>
          <div className="Exchange-swap-section-bottom">
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={toValue.toString().slice(0, 10)}
                onChange={onToValueChange}
              />
              <div className="usd-value">~${formatAmount(toUsdMax, USD_DECIMALS, 2, true, "0.00")}</div>
            </div>
            <div>
              <TokenSelector
                label={t`Receive`}
                chainId={chainId}
                tokenAddress={toTokenAddress}
                onSelectToken={onSelectToToken}
                tokens={toTokens}
                infoTokens={infoTokens}
                showTokenImgInDropdown={true}
                showSymbolImageOutside={true}
              />
            </div>
          </div>
        </div>
        {showTriggerRatioSection && (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <Trans>Price</Trans>
              </div>
              {fromTokenInfo && toTokenInfo && (
                <div
                  className="muted align-right clickable"
                  onClick={() => {
                    setTriggerRatioValue(
                      formatAmountFree(
                        getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted),
                        USD_DECIMALS,
                        10
                      )
                    );
                  }}
                >
                  {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 4)}
                </div>
              )}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input small"
                  value={triggerRatioValue}
                  onChange={onTriggerRatioChange}
                />
              </div>
              {(() => {
                if (!toTokenInfo) return;
                if (!fromTokenInfo) return;
                const [tokenA, tokenB] = triggerRatioInverted
                  ? [toTokenInfo, fromTokenInfo]
                  : [fromTokenInfo, toTokenInfo];
                return (
                  <div className="PositionEditor-token-symbol">
                    {tokenA.symbol}&nbsp;per&nbsp;{tokenB.symbol}
                  </div>
                );
              })()}
            </div>
          </div>
        )}
        <div className="Exchange-swap-box-info">
          <ExchangeInfoRow label={t`Fees`}>
            <div>
              {!fees && "-"}
              {fees && (
                <div>
                  {formatAmount(feeBps, 2, 2, false)}%&nbsp; ({formatAmount(fees, fromToken.decimals, 4, true)}{" "}
                  {fromToken.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                </div>
              )}
            </div>
          </ExchangeInfoRow>
        </div>
        <div className=" Exchange-swap-button-container swap">{renderPrimaryButton()}</div>
        <div className="App-card ">
          <div className="Exchange-swap-market-box-title">
            <Trans>Swap</Trans>
          </div>
          <div className="Exchange-Info-Card">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>{fromToken.symbol} Price</Trans>
              </div>
              <div className="align-right">
                ${fromTokenInfo && formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>{toToken.symbol} Price</Trans>
              </div>
              <div className="align-right">
                ${toTokenInfo && formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Available Liquidity</Trans>
              </div>
              <div className="align-right al-swap">
                <Tooltip
                  handle={`$${formatAmount(maxSwapAmountUsd, USD_DECIMALS, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <StatsTooltipRow
                          label={t`Max ${fromTokenInfo.symbol} in`}
                          value={[
                            `${formatAmount(maxFromTokenIn, fromTokenInfo.decimals, 0, true)} ${fromTokenInfo.symbol}`,
                            `($${formatAmount(maxFromTokenInUSD, USD_DECIMALS, 0, true)})`,
                          ]}
                        />
                        <StatsTooltipRow
                          label={t`Max ${toTokenInfo.symbol} out`}
                          value={[
                            `${formatAmount(maxToTokenOut, toTokenInfo.decimals, 0, true)} ${toTokenInfo.symbol}`,
                            `($${formatAmount(maxToTokenOutUSD, USD_DECIMALS, 0, true)})`,
                          ]}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <ExchangeInfoRow label={t`Direction`}>
              {fromTokenInfo.symbol} {"to"} {toToken.symbol}
            </ExchangeInfoRow>
          </div>
        </div>
      </div>
      {renderOrdersToa()}
      {isConfirming && (
        <ConfirmationBox
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isSwap={true}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          triggerRatio={triggerRatio}
          fees={fees}
          feesUsd={feesUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          swapMinExecutionFee={swapMinExecutionFee}
          swapMinExecutionFeeUSD={swapMinExecutionFeeUSD}
          swapMinExecutionFeeErrorMessage={swapMinExecutionFeeErrorMessage}
        />
      )}
    </div>
  );
}
