/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import { useENS } from "lib/legacy";
import { CgChevronDownR } from "react-icons/cg";

import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";

import Reader from "abis/Reader.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import FlpManager from "abis/FlpManager.json";

import { ethers } from "ethers";
import {
  FLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
  adjustForDecimals,
  USDF_DECIMALS,
} from "lib/legacy";
import { useFxdxPrice, useTotalFxdxStaked, useTotalFxdxSupply } from "domain/legacy";
import { ARBITRUM, getConstant, IS_TESTNET } from "config/chains";

import useSWR from "swr";

import { getContract } from "config/contracts";

import "./StakeV2.css";
import SEO from "components/Common/SEO";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getServerUrl } from "config/backend";
import { callContract, contractFetcher } from "lib/contracts";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { approveTokens, getTokenAmountFromUsd, getTokenInfo, useInfoTokens } from "domain/tokens";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatPrice,
  parseValue,
} from "lib/numbers";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useWallet from "lib/wallets/useWallet";
import { getTokenBySymbol, getTokens } from "config/tokens";
import { FEE_WITHDRAWAL_RECEIVE_TOKEN_KEY } from "config/localStorage";
import FeeWithdrawalTokenSelector, {
  shouldSwap,
} from "components/FeeWithdrawalTokenSelector/FeeWithdrawalTokenSelector";
import { getAums } from "domain/aums";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    signer,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        signer,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, signer);

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: t`Stake submitted!`,
      failMsg: t`Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    return t`Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Stake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => {
                  let value = e.target.value || "";
                  value = value.replace(/[-eE]+/g, "");
                  setValue(value);
                }}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    signer,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusFxdxInFeeFxdx,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusFxdxInFeeFxdx &&
    bonusFxdxInFeeFxdx.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusFxdxInFeeFxdx);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusFxdxInFeeFxdx) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusFxdxInFeeFxdx);
  }

  const getError = () => {
    if (!amount) {
      return t`Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, signer);
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: t`Unstake submitted!`,
      failMsg: t`Unstake failed.`,
      successMsg: t`Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return t`Unstaking...`;
    }
    return t`Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Unstake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => {
                  let value = e.target.value || "";
                  value = value.replace(/[-eE]+/g, "");
                  setValue(value);
                }}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <a href="https://fxdx.gitbook.io/fxdx/rewards" target="_blank" rel="noopener noreferrer">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </a>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    signer,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return t`Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, signer);

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: t`Deposit submitted!`,
      failMsg: t`Deposit failed!`,
      successMsg: t`Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return t`Depositing...`;
    }
    return t`Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Deposit</Trans>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => {
                    let value = e.target.value || "";
                    value = value.replace(/[-eE]+/g, "");
                    setValue(value);
                  }}
                />
              </div>
              <div className="PositionEditor-token-symbol">esFXDX</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Wallet</Trans>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esFXDX</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Vault Capacity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">
                          <Trans>Vault Capacity for your Account:</Trans>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esFXDX`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esFXDX`}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Reserve Amount</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={t`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Additional reserve required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <Trans>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esFXDX.
                            </Trans>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, signer, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, signer);

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: t`Withdraw submitted.`,
      failMsg: t`Withdraw failed.`,
      successMsg: t`Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <Trans>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            esFXDX tokens that have been converted to FXDX will remain as FXDX tokens.
            <br />
            <br />
            To claim FXDX tokens without withdrawing, use the "Claim" button under the Total Rewards section.
            <br />
            <br />
          </div>
        </Trans>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    nativeTokenAddress,
    active,
    account,
    signer,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    infoTokens,
    feeAmount,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [shouldClaimFxdx, setShouldClaimFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-fxdx"],
    true
  );
  const [shouldStakeFxdx, setShouldStakeFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-fxdx"],
    true
  );
  const [shouldClaimEsFxdx, setShouldClaimEsFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-fxdx"],
    true
  );
  const [shouldStakeEsFxdx, setShouldStakeEsFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-fxdx"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimFees, setShouldClaimFees] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-fees"],
    true
  );

  const feeRewardTokenSymbol = getConstant(chainId, "feeRewardTokenSymbol");
  const toTokens = getTokens(chainId).filter((token) => !token.isNotClaimable);
  const feeRewardToken = getTokenBySymbol(chainId, feeRewardTokenSymbol);

  const [savedReceiveTokenAddress, setSavedReceiveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${FEE_WITHDRAWAL_RECEIVE_TOKEN_KEY}`
  );

  const [swapToToken, setSwapToToken] = useState(() =>
    savedReceiveTokenAddress ? toTokens.find((token) => token.address === savedReceiveTokenAddress) : undefined
  );

  const receiveToken = swapToToken ? swapToToken : feeRewardToken;
  const feeRewardTokenInfo = getTokenInfo(infoTokens, feeRewardToken.address);

  const receiveAmountUsd =
    feeAmount && feeRewardTokenInfo && feeRewardTokenInfo.minPrice
      ? feeAmount.mul(feeRewardTokenInfo.minPrice).div(expandDecimals(1, feeRewardToken.decimals))
      : bigNumberify(0);
  const convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountUsd);

  let isNotEnoughReceiveTokenLiquidity;
  let isCollateralPoolCapacityExceeded;
  // Check swap limits (max in / max out)
  if (shouldSwap(feeRewardToken, receiveToken)) {
    const receiveTokenInfo = getTokenInfo(infoTokens, receiveToken.address);

    isNotEnoughReceiveTokenLiquidity =
      receiveTokenInfo?.availableAmount?.lt(convertedReceiveAmount) ||
      receiveTokenInfo?.bufferAmount?.gt(receiveTokenInfo.poolAmount?.sub(convertedReceiveAmount));

    if (
      feeRewardTokenInfo.maxUsdfAmount &&
      feeRewardTokenInfo.maxUsdfAmount.gt(0) &&
      feeRewardTokenInfo.usdfAmount &&
      feeRewardTokenInfo.maxPrice
    ) {
      const usdfFromAmount = adjustForDecimals(receiveAmountUsd, USD_DECIMALS, USDF_DECIMALS);
      const nextUsdfAmount = feeRewardTokenInfo.usdfAmount.add(usdfFromAmount);

      if (nextUsdfAmount.gt(feeRewardTokenInfo.maxUsdfAmount)) {
        isCollateralPoolCapacityExceeded = true;
      }
    }
  }

  const fxdxAddress = getContract(chainId, "FXDX");
  const stakedFxdxTrackerAddress = getContract(chainId, "StakedFxdxTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, fxdxAddress, "allowance", account, stakedFxdxTrackerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const needApproval = shouldStakeFxdx && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const getError = () => {
    if (shouldClaimFees && isNotEnoughReceiveTokenLiquidity) {
      return t`Insufficient receive token liquidity`;
    }

    if (shouldClaimFees && isCollateralPoolCapacityExceeded) {
      return t`${feeRewardToken.symbol} pool exceeded, can only Receive ${feeRewardToken.symbol}`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }

    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (isApproving) {
      return t`Approving FXDX...`;
    }
    if (needApproval) {
      return t`Approve FXDX`;
    }
    if (isCompounding) {
      return t`Compounding...`;
    }
    return t`Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        signer,
        tokenAddress: fxdxAddress,
        spender: stakedFxdxTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const path = [feeRewardToken.address];

    if (shouldSwap(feeRewardToken, receiveToken)) {
      if (receiveToken.isNative) {
        path.push(nativeTokenAddress);
      } else {
        path.push(receiveToken.address);
      }
    }

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, signer);
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimFxdx || shouldStakeFxdx,
        shouldStakeFxdx,
        shouldClaimEsFxdx || shouldStakeEsFxdx,
        shouldStakeEsFxdx,
        shouldStakeMultiplierPoints,
        shouldClaimFees,
        path,
        0,
        receiveToken.isNative,
      ],
      {
        sentMsg: t`Compound submitted!`,
        failMsg: t`Compound failed.`,
        successMsg: t`Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeFxdx = (value) => {
    if (value) {
      setShouldClaimFxdx(true);
    }
    setShouldStakeFxdx(value);
  };

  const toggleShouldStakeEsFxdx = (value) => {
    if (value) {
      setShouldClaimEsFxdx(true);
    }
    setShouldStakeEsFxdx(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Compound Rewards`}>
        <div className="CompoundModal-menu checkboxes">
          <div>
            <Trans>Stake Multiplier Points</Trans>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            ></Checkbox>
          </div>
          {/* <div>
            <Trans>Claim FXDX Rewards</Trans>
            <Checkbox
              isChecked={shouldClaimFxdx}
              setIsChecked={setShouldClaimFxdx}
              disabled={shouldStakeFxdx}
            ></Checkbox>
          </div> */}
          {/* <div>
            <Trans>Stake FXDX Rewards</Trans>
            <Checkbox isChecked={shouldStakeFxdx} setIsChecked={toggleShouldStakeFxdx}></Checkbox>
          </div> */}
          <div>
            <Trans>Claim esFXDX Rewards</Trans>
            <Checkbox
              isChecked={shouldClaimEsFxdx}
              setIsChecked={setShouldClaimEsFxdx}
              disabled={shouldStakeEsFxdx}
            ></Checkbox>
          </div>
          <div>
            <Trans>Stake esFXDX Rewards</Trans>
            <Checkbox isChecked={shouldStakeEsFxdx} setIsChecked={toggleShouldStakeEsFxdx}></Checkbox>
          </div>
          <div>
            <Trans>Claim Fee Rewards</Trans>
            <Checkbox isChecked={shouldClaimFees} setIsChecked={setShouldClaimFees}></Checkbox>
          </div>
        </div>
        {shouldClaimFees && (
          <>
            <div className="divider" />
            <div className="StakeV2-claim-fees-row">
              <Trans>Claim Fee Rewards as </Trans>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              <FeeWithdrawalTokenSelector
                chainId={chainId}
                infoTokens={infoTokens}
                toTokens={toTokens}
                feeRewardToken={feeRewardToken}
                receiveToken={receiveToken}
                receiveAmountUsd={receiveAmountUsd}
                isNotEnoughReceiveTokenLiquidity={isNotEnoughReceiveTokenLiquidity}
                isCollateralPoolCapacityExceeded={isCollateralPoolCapacityExceeded}
                setSwapToToken={setSwapToToken}
                setSavedReceiveTokenAddress={setSavedReceiveTokenAddress}
                onlyLabel={true}
              />
              <FeeWithdrawalTokenSelector
                chainId={chainId}
                infoTokens={infoTokens}
                toTokens={toTokens}
                feeRewardToken={feeRewardToken}
                receiveToken={receiveToken}
                receiveAmountUsd={receiveAmountUsd}
                isNotEnoughReceiveTokenLiquidity={isNotEnoughReceiveTokenLiquidity}
                isCollateralPoolCapacityExceeded={isCollateralPoolCapacityExceeded}
                setSwapToToken={setSwapToToken}
                setSavedReceiveTokenAddress={setSavedReceiveTokenAddress}
                onlyLabel={false}
              />
            </div>
          </>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    nativeTokenAddress,
    signer,
    chainId,
    setPendingTxns,
    infoTokens,
    feeAmount,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimFxdx, setShouldClaimFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-fxdx"],
    true
  );
  const [shouldClaimEsFxdx, setShouldClaimEsFxdx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-fxdx"],
    true
  );
  const [shouldClaimFees, setShouldClaimFees] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-fees"],
    true
  );

  const feeRewardTokenSymbol = getConstant(chainId, "feeRewardTokenSymbol");
  const toTokens = getTokens(chainId).filter((token) => !token.isNotClaimable);
  const feeRewardToken = getTokenBySymbol(chainId, feeRewardTokenSymbol);

  const [savedReceiveTokenAddress, setSavedReceiveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${FEE_WITHDRAWAL_RECEIVE_TOKEN_KEY}`
  );

  const [swapToToken, setSwapToToken] = useState(() =>
    savedReceiveTokenAddress ? toTokens.find((token) => token.address === savedReceiveTokenAddress) : undefined
  );

  const receiveToken = swapToToken ? swapToToken : feeRewardToken;
  const feeRewardTokenInfo = getTokenInfo(infoTokens, feeRewardToken.address);

  const receiveAmountUsd =
    feeAmount && feeRewardTokenInfo && feeRewardTokenInfo.minPrice
      ? feeAmount.mul(feeRewardTokenInfo.minPrice).div(expandDecimals(1, feeRewardToken.decimals))
      : bigNumberify(0);
  const convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountUsd);

  let isNotEnoughReceiveTokenLiquidity;
  let isCollateralPoolCapacityExceeded;
  // Check swap limits (max in / max out)
  if (shouldSwap(feeRewardToken, receiveToken)) {
    const receiveTokenInfo = getTokenInfo(infoTokens, receiveToken.address);

    isNotEnoughReceiveTokenLiquidity =
      receiveTokenInfo?.availableAmount?.lt(convertedReceiveAmount) ||
      receiveTokenInfo?.bufferAmount?.gt(receiveTokenInfo.poolAmount?.sub(convertedReceiveAmount));

    if (
      feeRewardTokenInfo.maxUsdfAmount &&
      feeRewardTokenInfo.maxUsdfAmount.gt(0) &&
      feeRewardTokenInfo.usdfAmount &&
      feeRewardTokenInfo.maxPrice
    ) {
      const usdfFromAmount = adjustForDecimals(receiveAmountUsd, USD_DECIMALS, USDF_DECIMALS);
      const nextUsdfAmount = feeRewardTokenInfo.usdfAmount.add(usdfFromAmount);

      if (nextUsdfAmount.gt(feeRewardTokenInfo.maxUsdfAmount)) {
        isCollateralPoolCapacityExceeded = true;
      }
    }
  }

  const getError = () => {
    if (shouldClaimFees && isNotEnoughReceiveTokenLiquidity) {
      return t`Insufficient receive token liquidity`;
    }

    if (shouldClaimFees && isCollateralPoolCapacityExceeded) {
      return t`${feeRewardToken.symbol} pool exceeded, can only Receive ${feeRewardToken.symbol}`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }

    return !isClaiming;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (isClaiming) {
      return t`Claiming...`;
    }
    return t`Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const path = [feeRewardToken.address];

    if (shouldSwap(feeRewardToken, receiveToken)) {
      if (receiveToken.isNative) {
        path.push(nativeTokenAddress);
      } else {
        path.push(receiveToken.address);
      }
    }

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, signer);
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimFxdx,
        false, // shouldStakeFxdx
        shouldClaimEsFxdx,
        false, // shouldStakeEsFxdx
        false, // shouldStakeMultiplierPoints
        shouldClaimFees,
        path,
        0,
        receiveToken.isNative,
      ],
      {
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Claim Rewards`}>
        <div className="CompoundModal-menu">
          {/* <div>
            <Trans>Claim FXDX Rewards</Trans>
            <Checkbox isChecked={shouldClaimFxdx} setIsChecked={setShouldClaimFxdx}></Checkbox>
          </div> */}
          <div>
            <Trans>Claim esFXDX Rewards</Trans>
            <Checkbox isChecked={shouldClaimEsFxdx} setIsChecked={setShouldClaimEsFxdx}></Checkbox>
          </div>
          <div>
            <Trans>Claim Fee Rewards</Trans>
            <Checkbox isChecked={shouldClaimFees} setIsChecked={setShouldClaimFees}></Checkbox>
          </div>
        </div>
        {shouldClaimFees && (
          <>
            <div className="divider" />
            <div className="StakeV2-claim-fees-row">
              <Trans>Claim Fee Rewards as </Trans>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              <FeeWithdrawalTokenSelector
                chainId={chainId}
                infoTokens={infoTokens}
                toTokens={toTokens}
                feeRewardToken={feeRewardToken}
                receiveToken={receiveToken}
                receiveAmountUsd={receiveAmountUsd}
                isNotEnoughReceiveTokenLiquidity={isNotEnoughReceiveTokenLiquidity}
                isCollateralPoolCapacityExceeded={isCollateralPoolCapacityExceeded}
                setSwapToToken={setSwapToToken}
                setSavedReceiveTokenAddress={setSavedReceiveTokenAddress}
                onlyLabel={true}
              />
              <FeeWithdrawalTokenSelector
                chainId={chainId}
                infoTokens={infoTokens}
                toTokens={toTokens}
                feeRewardToken={feeRewardToken}
                receiveToken={receiveToken}
                receiveAmountUsd={receiveAmountUsd}
                isNotEnoughReceiveTokenLiquidity={isNotEnoughReceiveTokenLiquidity}
                isCollateralPoolCapacityExceeded={isCollateralPoolCapacityExceeded}
                setSwapToToken={setSwapToToken}
                setSavedReceiveTokenAddress={setSavedReceiveTokenAddress}
                onlyLabel={false}
              />
            </div>
          </>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns }) {
  const { isConnected: active, address: account, signer, chainId } = useWallet();
  const { openConnectModal } = useConnectModal();

  const { ensName } = useENS(account);

  // const hasInsurance = false;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const [isDropDownOn, setIsDropDownOn] = useState({
    total: true,
    fxdx: false,
    esFxdx: false,
    flp: false,
    vest0: false,
    vest1: false,
  });

  const [winWidth, setwinWidth] = useState(undefined);
  const [windowSize, setwindowSize] = useState(undefined);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    windowDimenion.winWidth > 1100 && setwindowSize("large");
    // windowDimenion.winWidth > 1100 && setwinWidth("medium")
    windowDimenion.winWidth < 1100 && setwindowSize("small");

    setwinWidth(windowDimenion.winWidth);
  }, [windowDimenion]);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeRewardTokenAddress = getContract(chainId, "FEE_REWARD_TOKEN");
  const fxdxAddress = getContract(chainId, "FXDX");
  const esFxdxAddress = getContract(chainId, "ES_FXDX");
  const bnFxdxAddress = getContract(chainId, "BN_FXDX");
  const flpAddress = getContract(chainId, "FLP");

  const stakedFxdxTrackerAddress = getContract(chainId, "StakedFxdxTracker");
  const bonusFxdxTrackerAddress = getContract(chainId, "BonusFxdxTracker");
  const feeFxdxTrackerAddress = getContract(chainId, "FeeFxdxTracker");

  const stakedFlpTrackerAddress = getContract(chainId, "StakedFlpTracker");
  const feeFlpTrackerAddress = getContract(chainId, "FeeFlpTracker");

  const flpManagerAddress = getContract(chainId, "FlpManager");

  const stakedFxdxDistributorAddress = getContract(chainId, "StakedFxdxDistributor");
  const stakedFlpDistributorAddress = getContract(chainId, "StakedFlpDistributor");

  const fxdxVesterAddress = getContract(chainId, "FxdxVester");
  const flpVesterAddress = getContract(chainId, "FlpVester");

  const vesterAddresses = [fxdxVesterAddress, flpVesterAddress];

  const excludedEsFxdxAccounts = [stakedFxdxDistributorAddress, stakedFlpDistributorAddress];

  const feeRewardTokenSymbol = getConstant(chainId, "feeRewardTokenSymbol");
  const feeRewardToken = getTokenBySymbol(chainId, feeRewardTokenSymbol);

  const walletTokens = [fxdxAddress, esFxdxAddress, flpAddress, stakedFxdxTrackerAddress];
  const depositTokens = [
    fxdxAddress,
    esFxdxAddress,
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    bnFxdxAddress,
    flpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedFxdxTrackerAddress,
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    feeFlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    stakedFlpTrackerAddress,
    feeFlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedFxdxSupply } = useSWR(
    [`StakeV2:stakedFxdxSupply:${active}`, chainId, fxdxAddress, "balanceOf", stakedFxdxTrackerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: aumAddition } = useSWR([`StakeV2:aumAddition:${active}`, chainId, flpManagerAddress, "aumAddition"], {
    fetcher: contractFetcher(signer, FlpManager),
  });

  const { data: aumDeduction } = useSWR(
    [`StakeV2:aumDeduction:${active}`, chainId, flpManagerAddress, "aumDeduction"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: esFxdxSupply } = useSWR(
    [`StakeV2:esFxdxSupply:${active}`, chainId, readerAddress, "getTokenSupply", esFxdxAddress],
    {
      fetcher: contractFetcher(signer, Reader, [excludedEsFxdxAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Reader, [vesterAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);
  const feeRewardTokenInfo = getTokenInfo(infoTokens, feeRewardTokenAddress);
  const feeRewardTokenPrice = feeRewardTokenInfo.minPrice;

  const {
    fxdxPrice,
    // fxdxPriceFromEthereum,
    fxdxPriceFromBase,
    fxdxPriceFromOptimism,
    // fxdxPriceFromMoonBeam /* fxdxPriceFromArbitrum, fxdxPriceFromAvalanche */,
  } = useFxdxPrice(chainId, { arbitrum: chainId === ARBITRUM ? signer : undefined }, active);

  let { total: totalFxdxSupply } = useTotalFxdxSupply();

  let {
    // ethereum: ethereumFxdxStaked,
    base: baseFxdxStaked,
    optimism: optimismFxdxStaked,
    // moonbeam: moonbeamFxdxStaked,
    // avax: avaxFxdxStaked,
    // arbitrum: arbitrumFxdxStaked,
    total: totalFxdxStaked,
  } = useTotalFxdxStaked();

  const fxdxSupplyUrl = getServerUrl(chainId, "/fxdx_supply");
  const { data: fxdxSupply } = useSWR([fxdxSupplyUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  const isFxdxTransferEnabled = true;

  let esFxdxSupplyUsd;
  if (esFxdxSupply && fxdxPrice) {
    esFxdxSupplyUsd = esFxdxSupply.mul(fxdxPrice).div(expandDecimals(1, 18));
  }

  const aums = getAums(chainId, infoTokens, aumAddition, aumDeduction);

  let aum;
  if (aums && aums.length === 2 && aums[0] && aums[1]) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    feeRewardTokenPrice,
    feeRewardTokenInfo.decimals,
    stakedFxdxSupply,
    fxdxPrice,
    fxdxSupply
  );

  const totalApy = useMemo(() => {
    if (Object.keys(processedData).length === 0) {
      return "-";
    }
    const aprDecimal = (parseFloat(formatKeyAmount(processedData, "flpAprTotal", 2, 2, true)) || 0) / 100;

    if (!aprDecimal) {
      return "-";
    }

    const apy = ((aprDecimal / 365 + 1) ** 365 - 1) * 100;

    return apy.toFixed(2);
  }, [processedData]);

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusFxdxTrackerRewards && processedData.bnFxdxInFeeFxdx) {
    multiplierPointsAmount = processedData.bonusFxdxTrackerRewards.add(processedData.bnFxdxInFeeFxdx);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnFxdxInFeeFxdx && processedData.bonusFxdxInFeeFxdx) {
    totalRewardTokens = processedData.bnFxdxInFeeFxdx.add(processedData.bonusFxdxInFeeFxdx);
  }

  let totalRewardTokensAndFlp;
  if (totalRewardTokens && processedData && processedData.flpBalance) {
    totalRewardTokensAndFlp = totalRewardTokens.add(processedData.flpBalance);
  }

  const bonusFxdxInFeeFxdx = processedData ? processedData.bonusFxdxInFeeFxdx : undefined;

  let stakedFxdxSupplyUsd;
  if (!totalFxdxStaked.isZero() && fxdxPrice) {
    stakedFxdxSupplyUsd = totalFxdxStaked.mul(fxdxPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalFxdxSupply && !totalFxdxSupply.isZero() && fxdxPrice) {
    totalSupplyUsd = totalFxdxSupply.mul(fxdxPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableFxdx = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.fxdxVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusFxdxInFeeFxdx
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.fxdxVesterPairAmount);
    const stakedTokens = processedData.bonusFxdxInFeeFxdx;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableFxdx = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const showStakeFxdxModal = () => {
    if (!isFxdxTransferEnabled) {
      helperToast.error(t`FXDX transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake FXDX`);
    setStakeModalMaxAmount(processedData.fxdxBalance);
    setStakeValue("");
    setStakingTokenSymbol("FXDX");
    setStakingTokenAddress(fxdxAddress);
    setStakingFarmAddress(stakedFxdxTrackerAddress);
    setStakeMethodName("stakeFxdx");
  };

  const showStakeEsFxdxModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake esFXDX`);
    setStakeModalMaxAmount(processedData.esFxdxBalance);
    setStakeValue("");
    setStakingTokenSymbol("esFXDX");
    setStakingTokenAddress(esFxdxAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsFxdx");
  };

  const showFxdxVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.fxdxVester.maxVestableAmount.sub(vestingData.fxdxVester.vestedAmount);
    if (processedData.esFxdxBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esFxdxBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(t`FXDX Vault`);
    setVesterDepositStakeTokenLabel("staked FXDX + esFXDX + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esFxdxBalance);
    setVesterDepositEscrowedBalance(vestingData.fxdxVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.fxdxVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.fxdxVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.fxdxVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.fxdxVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(fxdxVesterAddress);
  };

  const showFlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.flpVester.maxVestableAmount.sub(vestingData.flpVester.vestedAmount);
    if (processedData.esFxdxBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esFxdxBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(t`FLP Vault`);
    setVesterDepositStakeTokenLabel("staked FLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esFxdxBalance);
    setVesterDepositEscrowedBalance(vestingData.flpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.flpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.flpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.flpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.flpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.flpBalance);
    setVesterDepositValue("");
    setVesterDepositAddress(flpVesterAddress);
  };

  const showFxdxVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.fxdxVesterVestedAmount || vestingData.fxdxVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from FXDX Vault`);
    setVesterWithdrawAddress(fxdxVesterAddress);
  };

  const showFlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.flpVesterVestedAmount || vestingData.flpVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from FLP Vault`);
    setVesterWithdrawAddress(flpVesterAddress);
  };

  const showUnstakeFxdxModal = () => {
    if (!isFxdxTransferEnabled) {
      helperToast.error(t`FXDX transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake FXDX`);
    let maxAmount = processedData.fxdxInStakedFxdx;
    if (
      processedData.fxdxInStakedFxdx &&
      vestingData &&
      vestingData.fxdxVesterPairAmount.gt(0) &&
      maxUnstakeableFxdx &&
      maxUnstakeableFxdx.lt(processedData.fxdxInStakedFxdx)
    ) {
      maxAmount = maxUnstakeableFxdx;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.fxdxVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("FXDX");
    setUnstakeMethodName("unstakeFxdx");
  };

  const showUnstakeEsFxdxModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake esFXDX`);
    let maxAmount = processedData.esFxdxInStakedFxdx;
    if (
      processedData.esFxdxInStakedFxdx &&
      vestingData &&
      vestingData.fxdxVesterPairAmount.gt(0) &&
      maxUnstakeableFxdx &&
      maxUnstakeableFxdx.lt(processedData.esFxdxInStakedFxdx)
    ) {
      maxAmount = maxUnstakeableFxdx;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.fxdxVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esFXDX");
    setUnstakeMethodName("unstakeEsFxdx");
  };

  const renderMultiplierPointsLabel = useCallback(() => {
    return t`MP APR`;
  }, []);

  const renderMultiplierPointsValue = useCallback(() => {
    return (
      <Tooltip
        handle={`100.00%`}
        position="right-bottom"
        renderContent={() => {
          return (
            <Trans>
              Boost your rewards with Multiplier Points.&nbsp;
              <a href="https://fxdx.gitbook.io/fxdx/rewards#multiplier-points" rel="noreferrer" target="_blank">
                More info
              </a>
              .
            </Trans>
          );
        }}
      />
    );
  }, []);

  let earnMsg = [];
  if (totalRewardTokensAndFlp && totalRewardTokensAndFlp.gt(0)) {
    let fxdxAmountStr;
    if (processedData.fxdxInStakedFxdx && processedData.fxdxInStakedFxdx.gt(0)) {
      fxdxAmountStr = formatAmount(processedData.fxdxInStakedFxdx, 18, 2, true) + " FXDX";
    }
    let esFxdxAmountStr;
    if (processedData.esFxdxInStakedFxdx && processedData.esFxdxInStakedFxdx.gt(0)) {
      esFxdxAmountStr = formatAmount(processedData.esFxdxInStakedFxdx, 18, 2, true) + " esFXDX";
    }
    let mpAmountStr;
    if (processedData.bonusFxdxInFeeFxdx && processedData.bnFxdxInFeeFxdx.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnFxdxInFeeFxdx, 18, 2, true) + " MP";
    }
    let flpStr;
    if (processedData.flpBalance && processedData.flpBalance.gt(0)) {
      flpStr = formatAmount(processedData.flpBalance, 18, 2, true) + " FLP";
    }
    const amountStr = [fxdxAmountStr, esFxdxAmountStr, mpAmountStr, flpStr].filter((s) => s).join(", ");
    earnMsg = [formatAmount(totalRewardTokensAndFlp, 18, 2, true), amountStr];
    // earnMsg = (
    //   <div>
    //     <Trans>
    //       You are earning fee rewards with {formatAmount(totalRewardTokensAndFlp, 18, 2, true)} tokens.
    //       <br />
    //       Tokens: {amountStr}.
    //     </Trans>
    //   </div>
    // );
  }

  const totalRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">Fee Rewards (USDC)</div>
          <div>
            {formatKeyAmount(processedData, "totalFeeRewards", feeRewardToken.decimals, 4, true)} ($
            {formatKeyAmount(processedData, "totalFeeRewardsUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        {/* <div className="App-card-row">
          <div className="label">FXDX</div>
          <div>
            {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
            {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
          </div>
        </div> */}
        <div className="App-card-row">
          <div className="label">
            <Trans>Escrowed FXDX</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "totalEsFxdxRewards", 18, 4, true)} ($
            {formatKeyAmount(processedData, "totalEsFxdxRewardsUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>MP (Multiplier Points)</Trans>
          </div>
          <div>{formatKeyAmount(processedData, "bonusFxdxTrackerRewards", 18, 4, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked MP</Trans>
          </div>
          <div>{formatKeyAmount(processedData, "bnFxdxInFeeFxdx", 18, 4, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Total</Trans>
          </div>
          <div>${formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, 2, true)}</div>
        </div>
      </>
    );
  };

  const totalDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="active buttons">
          {!active && (
            <button className="App-button-option App-card-option" onClick={() => openConnectModal()}>
              <Trans>Connect Wallet</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => setIsCompoundModalVisible(true)}>
              <Trans>Compound</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => setIsClaimModalVisible(true)}>
              <Trans>Claim</Trans>
            </button>
          )}
        </div>
      </div>
    );
  };

  const fxdxRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">
            <Trans>Price</Trans>
          </div>
          <div>
            {!fxdxPrice && "..."}
            {fxdxPrice && (
              <Tooltip
                position="right-bottom-right"
                className="nowrap"
                handle={"$" + formatPrice(fxdxPrice, true)}
                renderContent={() =>
                  IS_TESTNET ? (
                    <>
                      {/* <StatsTooltipRow label={t`Price on Goerli`} value={formatPrice(fxdxPriceFromEthereum, true)} /> */}
                      <StatsTooltipRow
                        label={t`Price on OptimismGoerli`}
                        value={formatPrice(fxdxPriceFromOptimism, true)}
                      />
                    </>
                  ) : (
                    <>
                      <StatsTooltipRow label={t`Price on Base`} value={formatPrice(fxdxPriceFromBase, true)} />
                      <StatsTooltipRow label={t`Price on Optimism`} value={formatPrice(fxdxPriceFromOptimism, true)} />
                      {/* <StatsTooltipRow
                        label={t`Price on Avalanche`}
                        value={formatAmount(fxdxPriceFromAvalanche, USD_DECIMALS, 2, true)}
                      />
                      <StatsTooltipRow
                        label={t`Price on Arbitrum`}
                        value={formatAmount(fxdxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                      /> */}
                    </>
                  )
                }
              />
            )}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Wallet</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "fxdxBalance", 18, 2, true)} FXDX ($
            {formatKeyAmount(processedData, "fxdxBalanceUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "fxdxInStakedFxdx", 18, 2, true)} FXDX ($
            {formatKeyAmount(processedData, "fxdxInStakedFxdxUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-row">
          <div className="label">
            <Trans>APR</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatKeyAmount(processedData, "fxdxAprTotalWithBoost", 2, 2, true)}%`}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      label="Escrowed FXDX APR"
                      showDollar={false}
                      value={`${formatKeyAmount(processedData, "fxdxAprForEsFxdx", 2, 2, true)}%`}
                    />
                    {(!processedData.fxdxBoostAprForFeeRewards || processedData.fxdxBoostAprForFeeRewards.eq(0)) && (
                      <StatsTooltipRow
                        label={`Fee Rewards APR`}
                        showDollar={false}
                        value={`${formatKeyAmount(processedData, "fxdxAprForFeeRewards", 2, 2, true)}%`}
                      />
                    )}
                    {processedData.fxdxBoostAprForFeeRewards && processedData.fxdxBoostAprForFeeRewards.gt(0) && (
                      <div>
                        <br />
                        <StatsTooltipRow
                          label={`Fee Rewards Base APR`}
                          showDollar={false}
                          value={`${formatKeyAmount(processedData, "fxdxAprForFeeRewards", 2, 2, true)}%`}
                        />
                        <StatsTooltipRow
                          label={`Fee Rewards Boosted APR`}
                          showDollar={false}
                          value={`${formatKeyAmount(processedData, "fxdxBoostAprForFeeRewards", 2, 2, true)}%`}
                        />
                        <div className="Tooltip-divider" />
                        <StatsTooltipRow
                          label={`Fee Rewards Total APR`}
                          showDollar={false}
                          value={`${formatKeyAmount(processedData, "fxdxAprFoFeeRewardsWithBoost", 2, 2, true)}%`}
                        />
                        <br />
                        <Trans>The Boosted APR is from your staked Multiplier Points.</Trans>
                      </div>
                    )}
                    <div>
                      <br />
                      <Trans>
                        APRs are updated weekly on Monday and will depend on the fees collected for the week.
                      </Trans>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Rewards</Trans>
          </div>
          <div>
            <Tooltip
              handle={`$${formatKeyAmount(processedData, "totalFxdxRewardsUsd", USD_DECIMALS, 2, true)}`}
              position="right-bottom"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      label={`Fee Rewards (${feeRewardTokenSymbol})`}
                      value={`${formatKeyAmount(
                        processedData,
                        "feeFxdxTrackerRewards",
                        feeRewardToken.decimals,
                        4
                      )} ($${formatKeyAmount(processedData, "feeFxdxTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label="Escrowed FXDX"
                      value={`${formatKeyAmount(processedData, "stakedFxdxTrackerRewards", 18, 4)} ($${formatKeyAmount(
                        processedData,
                        "stakedFxdxTrackerRewardsUsd",
                        USD_DECIMALS,
                        2,
                        true
                      )})`}
                      showDollar={false}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const fxdxDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="App-card-row">
          <div className="label">
            <Trans>Boost Percentage</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      You are earning {formatAmount(processedData.boostBasisPoints, 2, 2, false)}% more fee rewards
                      using {formatAmount(processedData.bnFxdxInFeeFxdx, 18, 4, 2, true)} Staked Multiplier Points.
                    </Trans>
                    <br />
                    <br />
                    <Trans>Use the "Compound" button to stake your Multiplier Points.</Trans>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">{renderMultiplierPointsLabel()}</div>
          <div>{renderMultiplierPointsValue()}</div>
        </div>
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-row">
          <div className="label">
            <Trans>Total Staked</Trans>
          </div>
          <div>
            {!totalFxdxStaked && "..."}
            {totalFxdxStaked && (
              <Tooltip
                position="right-bottom-right"
                className="nowrap"
                handle={
                  formatAmount(totalFxdxStaked, 18, 0, true) +
                  " FXDX" +
                  ` ($${formatAmount(stakedFxdxSupplyUsd, USD_DECIMALS, 0, true)})`
                }
                renderContent={() => (
                  <StatsTooltip
                    showDollar={false}
                    title={t`Staked`}
                    // ethereumValue={ethereumFxdxStaked}
                    baseValue={baseFxdxStaked}
                    optimismValue={optimismFxdxStaked}
                    // moonbeamValue={moonbeamFxdxStaked}
                    // avaxValue={avaxFxdxStaked}
                    // arbitrumValue={arbitrumFxdxStaked}
                    total={totalFxdxStaked}
                    decimalsForConversion={18}
                    symbol="FXDX"
                  />
                )}
              />
            )}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Total Supply</Trans>
          </div>
          {!totalFxdxSupply && "..."}
          {totalFxdxSupply && (
            <div>
              {formatAmount(totalFxdxSupply, 18, 0, true)} FXDX ($
              {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
            </div>
          )}
        </div>
        <div className="active buttons">
          {/* <Link className="App-button-option App-card-option buy-fxdx" to="/buy_fxdx">
            <Trans>Buy FXDX</Trans>
          </Link> */}
          <div className="buttons-options">
            {active && (
              <button className="App-button-option App-card-option" onClick={() => showStakeFxdxModal()}>
                <Trans>Stake</Trans>
              </button>
            )}
            {active && (
              <button className="App-button-option App-card-option" onClick={() => showUnstakeFxdxModal()}>
                <Trans>Unstake</Trans>
              </button>
            )}
          </div>
          {/* {active && (
            <Link className="App-button-option App-card-option" to="/begin_account_transfer">
              <Trans>Transfer Account</Trans>
            </Link>
          )}*/}
        </div>
      </div>
    );
  };

  const esFxdxRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">
            <Trans>Price</Trans>
          </div>
          <div>${formatPrice(fxdxPrice, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Wallet</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "esFxdxBalance", 18, 2, true)} esFXDX ($
            {formatKeyAmount(processedData, "esFxdxBalanceUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "esFxdxInStakedFxdx", 18, 2, true)} esFXDX ($
            {formatKeyAmount(processedData, "esFxdxInStakedFxdxUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-row">
          <div className="label">
            <Trans>APR</Trans>
          </div>
          <div>
            <div>
              <Tooltip
                handle={`${formatKeyAmount(processedData, "fxdxAprTotalWithBoost", 2, 2, true)}%`}
                position="right-bottom-right"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Fee Rewards (${feeRewardTokenSymbol}) Base APR`}
                        value={`${formatKeyAmount(processedData, "fxdxAprForFeeRewards", 2, 2, true)}%`}
                        showDollar={false}
                      />
                      {processedData.bnFxdxInFeeFxdx && processedData.bnFxdxInFeeFxdx.gt(0) && (
                        <StatsTooltipRow
                          label={`Fee Rewards (${feeRewardTokenSymbol}) Boosted APR`}
                          value={`${formatKeyAmount(processedData, "fxdxBoostAprForFeeRewards", 2, 2, true)}%`}
                          showDollar={false}
                        />
                      )}
                      <StatsTooltipRow
                        label="Escrowed FXDX APR"
                        value={`${formatKeyAmount(processedData, "fxdxAprForEsFxdx", 2, 2, true)}%`}
                        showDollar={false}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">{renderMultiplierPointsLabel()}</div>
          <div>{renderMultiplierPointsValue()}</div>
        </div>
      </>
    );
  };

  const esFxdxDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="token-total-dropdown">
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Staked</Trans>
            </div>
            <div>
              {formatKeyAmount(processedData, "stakedEsFxdxSupply", 18, 0, true)} esFXDX ($
              {formatKeyAmount(processedData, "stakedEsFxdxSupplyUsd", USD_DECIMALS, 0, true)})
            </div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Supply</Trans>
            </div>
            <div>
              {formatAmount(esFxdxSupply, 18, 0, true)} esFXDX ($
              {formatAmount(esFxdxSupplyUsd, USD_DECIMALS, 0, true)})
            </div>
          </div>
        </div>
        <div className="active buttons">
          {!active && (
            <button className="App-button-option App-card-option" onClick={() => openConnectModal()}>
              <Trans> Connect Wallet</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showStakeEsFxdxModal()}>
              <Trans>Stake</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showUnstakeEsFxdxModal()}>
              <Trans>Unstake</Trans>
            </button>
          )}
        </div>
      </div>
    );
  };

  const flpRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">
            <Trans>Price</Trans>
          </div>
          <div>${formatKeyAmount(processedData, "flpPrice", USD_DECIMALS, 3, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Wallet</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "flpBalance", FLP_DECIMALS, 2, true)} FLP ($
            {formatKeyAmount(processedData, "flpBalanceUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked</Trans>
          </div>
          <div>
            {formatKeyAmount(processedData, "flpBalance", FLP_DECIMALS, 2, true)} FLP ($
            {formatKeyAmount(processedData, "flpBalanceUsd", USD_DECIMALS, 2, true)})
          </div>
        </div>
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-row">
          <div className="label">
            <Trans>APY</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${totalApy}%`}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      label={`Fee Rewards (${feeRewardTokenSymbol}) APR`}
                      value={`${formatKeyAmount(processedData, "flpAprForFeeRewards", 2, 2, true)}%`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label="Escrowed FXDX APR"
                      value={`${formatKeyAmount(processedData, "flpAprForEsFxdx", 2, 2, true)}%`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label={t`APR`}
                      value={`${formatKeyAmount(processedData, "flpAprTotal", 2, 2, true)}%`}
                      showDollar={false}
                    />
                    <br />

                    <Trans>APRs are updated weekly on Monday and will depend on the fees collected for the week.</Trans>
                    <p>{`Note: APY assumes daily compounding`}</p>
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Rewards</Trans>
          </div>
          <div>
            <Tooltip
              handle={`$${formatKeyAmount(processedData, "totalFlpRewardsUsd", USD_DECIMALS, 2, true)}`}
              position="right-bottom"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      label={`Fee Rewards (${feeRewardTokenSymbol})`}
                      value={`${formatKeyAmount(
                        processedData,
                        "feeFlpTrackerRewards",
                        feeRewardToken.decimals,
                        4
                      )} ($${formatKeyAmount(processedData, "feeFlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label="Escrowed FXDX"
                      value={`${formatKeyAmount(processedData, "stakedFlpTrackerRewards", 18, 4)} ($${formatKeyAmount(
                        processedData,
                        "stakedFlpTrackerRewardsUsd",
                        USD_DECIMALS,
                        2,
                        true
                      )})`}
                      showDollar={false}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-options"></div>
      </>
    );
  };

  const flpDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="token-total-dropdown">
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Staked</Trans>
            </div>
            <div>
              {formatKeyAmount(processedData, "flpSupply", 18, 2, true)} FLP ($
              {formatKeyAmount(processedData, "flpSupplyUsd", USD_DECIMALS, 2, true)})
            </div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Supply</Trans>
            </div>
            <div>
              {formatKeyAmount(processedData, "flpSupply", 18, 2, true)} FLP ($
              {formatKeyAmount(processedData, "flpSupplyUsd", USD_DECIMALS, 2, true)})
            </div>
          </div>
        </div>
        {/* <div className="App-card-divider"></div> */}

        <div className="active buttons">
          <Link className="App-button-option App-card-option" to="/buy_flp">
            <Trans>Mint FLP</Trans>
          </Link>
          <Link className="App-button-option App-card-option" to="/buy_flp#redeem">
            <Trans>Burn FLP</Trans>
          </Link>
          {/* {hasInsurance && (
            <a
              className="App-button-option App-card-option"
              href="https://app.insurace.io/Insurance/Cart?id=124&referrer=545066382753150189457177837072918687520318754040"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Purchase Insurance</Trans>
            </a>
          )} */}
        </div>
      </div>
    );
  };

  const fxdxVestRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked Tokens</Trans>
          </div>
          <div>
            <Tooltip
              handle={formatAmount(totalRewardTokens, 18, 2, true)}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      showDollar={false}
                      label="FXDX"
                      value={formatAmount(processedData.fxdxInStakedFxdx, 18, 2, true)}
                    />

                    <StatsTooltipRow
                      showDollar={false}
                      label="esFXDX"
                      value={formatAmount(processedData.esFxdxInStakedFxdx, 18, 2, true)}
                    />
                    <StatsTooltipRow
                      showDollar={false}
                      label="Multiplier Points"
                      value={formatAmount(processedData.bnFxdxInFeeFxdx, 18, 2, true)}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Reserved for Vesting</Trans>
          </div>
          <div>
            {formatKeyAmount(vestingData, "fxdxVesterPairAmount", 18, 2, true)} /{" "}
            {formatAmount(totalRewardTokens, 18, 2, true)}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Vesting Status</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatKeyAmount(vestingData, "fxdxVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                vestingData,
                "fxdxVesterVestedAmount",
                18,
                4,
                true
              )}`}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      {formatKeyAmount(vestingData, "fxdxVesterClaimSum", 18, 4, true)} tokens have been converted to
                      FXDX from the {formatKeyAmount(vestingData, "fxdxVesterVestedAmount", 18, 4, true)} esFXDX
                      deposited for vesting.
                    </Trans>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Claimable</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatKeyAmount(vestingData, "fxdxVesterClaimable", 18, 4, true)} FXDX`}
              position="right-bottom"
              renderContent={() => (
                <Trans>
                  {formatKeyAmount(vestingData, "fxdxVesterClaimable", 18, 4, true)} FXDX tokens can be claimed, use the
                  options under the Total Rewards section to claim them.
                </Trans>
              )}
            />
          </div>
        </div>
        {/* <div className="App-card-divider"></div> */}
      </>
    );
  };

  const fxdxVestDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="token-total-dropdown">
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Staked</Trans>
            </div>
            <div>
              {!totalFxdxStaked && "..."}
              {totalFxdxStaked && (
                <Tooltip
                  position="right-bottom-right"
                  className="nowrap"
                  handle={
                    formatAmount(totalFxdxStaked, 18, 0, true) +
                    " FXDX" +
                    ` ($${formatAmount(stakedFxdxSupplyUsd, USD_DECIMALS, 0, true)})`
                  }
                  renderContent={() => (
                    <StatsTooltip
                      showDollar={false}
                      title={t`Staked`}
                      // ethereumValue={ethereumFxdxStaked}
                      baseValue={baseFxdxStaked}
                      optimismValue={optimismFxdxStaked}
                      // moonbeamValue={moonbeamFxdxStaked}
                      // avaxValue={avaxFxdxStaked}
                      // arbitrumValue={arbitrumFxdxStaked}
                      total={totalFxdxStaked}
                      decimalsForConversion={18}
                      symbol="FXDX"
                    />
                  )}
                />
              )}
            </div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Supply</Trans>
            </div>
            {!totalFxdxSupply && "..."}
            {totalFxdxSupply && (
              <div>
                {formatAmount(totalFxdxSupply, 18, 0, true)} FXDX ($
                {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
              </div>
            )}
          </div>
        </div>
        <div className="active buttons">
          {!active && (
            <button className="App-button-option App-card-option" onClick={() => openConnectModal()}>
              <Trans>Connect Wallet</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showFxdxVesterDepositModal()}>
              <Trans>Deposit</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showFxdxVesterWithdrawModal()}>
              <Trans>Withdraw</Trans>
            </button>
          )}
        </div>
      </div>
    );
  };

  const flpVestRows = () => {
    return (
      <>
        <div className="App-card-row">
          <div className="label">
            <Trans>Staked Tokens</Trans>
          </div>
          <div>{formatAmount(processedData.flpBalance, 18, 2, true)} FLP</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Reserved for Vesting</Trans>
          </div>
          <div>
            {formatKeyAmount(vestingData, "flpVesterPairAmount", 18, 2, true)} /{" "}
            {formatAmount(processedData.flpBalance, 18, 2, true)}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Vesting Status</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatKeyAmount(vestingData, "flpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                vestingData,
                "flpVesterVestedAmount",
                18,
                4,
                true
              )}`}
              position="right-bottom-right"
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      {formatKeyAmount(vestingData, "flpVesterClaimSum", 18, 4, true)} tokens have been converted to
                      FXDX from the {formatKeyAmount(vestingData, "flpVesterVestedAmount", 18, 4, true)} esFXDX
                      deposited for vesting.
                    </Trans>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <Trans>Claimable</Trans>
          </div>
          <div>
            <Tooltip
              handle={`${formatKeyAmount(vestingData, "flpVesterClaimable", 18, 4, true)} FXDX`}
              position="right-bottom"
              renderContent={() => (
                <Trans>
                  {formatKeyAmount(vestingData, "flpVesterClaimable", 18, 4, true)} FXDX tokens can be claimed, use the
                  options under the Total Rewards section to claim them.
                </Trans>
              )}
            />
          </div>
        </div>
      </>
    );
  };

  const flpVestDropdown = () => {
    return (
      <div className="dropdown-container">
        <div className="token-total-dropdown">
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Staked</Trans>
            </div>
            <div>
              {formatKeyAmount(processedData, "flpSupply", 18, 2, true)} FLP ($
              {formatKeyAmount(processedData, "flpSupplyUsd", USD_DECIMALS, 2, true)})
            </div>
          </div>
          <div className="App-card-row">
            <div className="label">
              <Trans>Total Supply</Trans>
            </div>
            <div>
              {formatKeyAmount(processedData, "flpSupply", 18, 2, true)} FLP ($
              {formatKeyAmount(processedData, "flpSupplyUsd", USD_DECIMALS, 2, true)})
            </div>
          </div>
        </div>
        <div className="active buttons">
          {!active && (
            <button className="App-button-option App-card-option" onClick={() => openConnectModal()}>
              <Trans>Connect Wallet</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showFlpVesterDepositModal()}>
              <Trans>Deposit</Trans>
            </button>
          )}
          {active && (
            <button className="App-button-option App-card-option" onClick={() => showFlpVesterWithdrawModal()}>
              <Trans>Withdraw</Trans>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`default-container page-layout earn-page ${windowSize}`}>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        signer={signer}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        signer={signer}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusFxdxInFeeFxdx={bonusFxdxInFeeFxdx}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        signer={signer}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        signer={signer}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        chainId={chainId}
        active={active}
        signer={signer}
        account={account}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        nativeTokenAddress={nativeTokenAddress}
        infoTokens={infoTokens}
        feeAmount={processedData && processedData.totalFeeRewards}
        totalVesterRewards={processedData.totalVesterRewards}
        setPendingTxns={setPendingTxns}
      />
      <ClaimModal
        chainId={chainId}
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        nativeTokenAddress={nativeTokenAddress}
        infoTokens={infoTokens}
        feeAmount={processedData && processedData.totalFeeRewards}
        totalVesterRewards={processedData.totalVesterRewards}
        signer={signer}
      />
      <div className="section-title-block account">
        <div className="section-title-content ">
          <div className="Page-title">
            <Trans>Account</Trans>
          </div>
          <div className="Page-description">
            {account && <span className="user-address">{ensName || account}</span>}
          </div>
        </div>
        <div className="transfer-account">
          {earnMsg[0] && (
            <div className="App-card account-reward-stats balance">
              <div className="App-card-row total fees">
                {/* <div className="label totaldiv">
                  <Trans>Earning rewards with &nbsp;<span >{`${earnMsg[0]}`}</span>&nbsp; tokens</Trans>
                </div> */}

                <div className="label ">Balance</div>
                <div className="value">{`${earnMsg[1]}`}</div>
              </div>
            </div>
          )}
          <div className="App-card-options">
            {active && (
              <Link className="App-button-option App-card-option" to="/begin_account_transfer">
                <Trans>Transfer Account</Trans>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="App-card primary StakeV2-total-rewards-card">
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-content">
          <div className="App-card-title">
            <Trans>Total Rewards</Trans>
          </div>
          {winWidth > 1100 && totalRows()}
          <div className="App-card-title">
            <div className="label" onClick={() => setIsDropDownOn((prev) => ({ ...prev, total: !isDropDownOn.total }))}>
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>
        {!isDropDownOn.total && <div className="rows">{winWidth < 1100 && totalRows()}</div>}
        {isDropDownOn.total && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.total && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && totalRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && totalRows()}</div>
            {totalDropdown()}
          </div>
        )}
      </div>

      <div className="section-title-content each">
        <div className="Page-title">
          <Trans>Tokens</Trans>
        </div>
      </div>
      {/* <div className="App-card primary StakeV2-fxdx-card tokens-card">
        <div className="App-card-content">
          <div className="App-card-title">FXDX</div>
          {winWidth > 1100 && fxdxRows()}
          <div className="App-card-title drop-down">
            <div className="label" onClick={() => setIsDropDownOn((prev) => ({ ...prev, fxdx: !isDropDownOn.fxdx }))}>
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>
        {!isDropDownOn.fxdx && <div className="rows">{winWidth < 1100 && fxdxRows()}</div>}
        {isDropDownOn.fxdx && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.fxdx && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && fxdxRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && fxdxRows()}</div>
            {fxdxDropdown()}
          </div>
        )}
      </div> */}
      <div className="App-card primary StakeV2-fxdx-card tokens-card">
        {/* <div className="App-card-divider"></div> */}
        <div className="App-card-content">
          <div className="App-card-title">
            <Trans>esFXDX</Trans>
          </div>

          {winWidth > 1100 && esFxdxRows()}

          <div className="App-card-title drop-down">
            <div
              className="label"
              onClick={() => setIsDropDownOn((prev) => ({ ...prev, esFxdx: !isDropDownOn.esFxdx }))}
            >
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>
        {!isDropDownOn.esFxdx && <div className="rows">{winWidth < 1100 && esFxdxRows()}</div>}

        {isDropDownOn.esFxdx && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.esFxdx && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && esFxdxRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && esFxdxRows()}</div>
            {esFxdxDropdown()}
          </div>
        )}
      </div>
      <div className="section-title-content each">
        <div className="Page-title">
          <Trans>Liquidity</Trans>
        </div>
      </div>
      <div className="App-card primary flp-card StakeV2-fxdx-card tokens-card">
        <div className="App-card-content">
          <div className="App-card-title">FLP</div>
          {winWidth > 1100 && flpRows()}
          <div className="App-card-title drop-down">
            <div className="label" onClick={() => setIsDropDownOn((prev) => ({ ...prev, flp: !isDropDownOn.flp }))}>
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>
        {!isDropDownOn.flp && <div className="rows">{winWidth < 1100 && flpRows()}</div>}
        {isDropDownOn.flp && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.flp && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && flpRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && flpRows()}</div>
            {flpDropdown()}
          </div>
        )}
      </div>
      {/* <div className="section-title-content each">
        <div className="Page-title">
          <Trans>Vest</Trans>
        </div>
      </div>
      <div className="App-card primary StakeV2-fxdx-card tokens-card vest">
        <div className="App-card-content">
          <div className="App-card-title">
            <Trans>FXDX Vault</Trans>
          </div>
          {winWidth > 1100 && fxdxVestRows()}
          <div className="App-card-title drop-down">
            <div className="label" onClick={() => setIsDropDownOn((prev) => ({ ...prev, vest0: !isDropDownOn.vest0 }))}>
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>
        {!isDropDownOn.vest0 && <div className="rows">{winWidth < 1100 && fxdxVestRows()}</div>}
        {isDropDownOn.vest0 && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.vest0 && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && fxdxVestRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && fxdxVestRows()}</div>
            {fxdxVestDropdown()}
          </div>
        )}
      </div>
      <div className="App-card primary StakeV2-fxdx-card tokens-card vest">
        <div className="App-card-content">
          <div className="App-card-title">
            <Trans>FLP Vault</Trans>
          </div>
          {winWidth > 1100 && flpVestRows()}

          <div className="App-card-title drop-down">
            <div className="label" onClick={() => setIsDropDownOn((prev) => ({ ...prev, vest1: !isDropDownOn.vest1 }))}>
              <CgChevronDownR color="E2E2E3" size="20" />
            </div>
          </div>
        </div>

        {!isDropDownOn.vest1 && <div className="rows">{winWidth < 1100 && flpVestRows()}</div>}

        {isDropDownOn.vest1 && winWidth > 1100 && <div className="App-card-divider"></div>}
        {isDropDownOn.vest1 && (
          <div className="dropdown-primary">
            <div className="rows">{winWidth < 1100 && flpVestRows()}</div>
            <div className="dropdown-rows">{winWidth < 1100 && flpVestRows()}</div>
            {flpVestDropdown()}
          </div>
        )}
      </div> */}
      <Footer />
    </div>
  );
}
