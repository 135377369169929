import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";
import cx from "classnames";

import {
  USD_DECIMALS,
  // MAX_LEVERAGE,
  // BASIS_POINTS_DIVISOR,
  // LIQUIDATION_FEE,
  TRADES_PAGE_SIZE,
  deserialize,
  shortenAddress,
  getExchangeRateDisplay,
  FLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  MAX_LEVERAGE,
  LIQUIDATION_FEE,
} from "lib/legacy";
import { useTrades, useLiquidationsData } from "domain/legacy";
import { getContract } from "config/contracts";

import "./TradeHistory.css";
import { getExplorerUrl } from "config/chains";
import { bigNumberify, expandDecimals, formatAmount, formatPrice } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { select, t, Trans } from "@lingui/macro";
import { FiExternalLink } from "react-icons/fi";

const { AddressZero } = ethers.constants;

function getPositionDisplay(increase, indexToken, isLong, sizeDelta) {
  // const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";
  return `
    ${increase ? t`Increase` : t`Decrease`} `;
}

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith("Create")) {
    actionDisplay = t`Create`;
  } else if (action.startsWith("Cancel")) {
    actionDisplay = t`Cancel`;
  } else {
    actionDisplay = t`Update`;
  }

  return t`${actionDisplay} Order`;
}

export function renderLiquidationTooltip(liquidationData, label) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE);
  const text =
    liquidationData.type === "full"
      ? t`This position was liquidated as the max leverage of 100x was exceeded.`
      : t`Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account`;
  return (
    <Tooltip
      className="liquidation-tooltip"
      position="left-bottom"
      handle={label}
      renderContent={() => (
        <>
          {text}
          <br />
          <br />
          <StatsTooltipRow
            label={t`Initial collateral`}
            showDollar
            value={formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`Min required collateral`}
            showDollar
            value={formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`Rollover fee`}
            showDollar
            value={formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`PnL`}
            showDollar={false}
            value={`-$${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}`}
          />
          {liquidationData.type === "full" && (
            <StatsTooltipRow label={t`Liquidation fee`} showDollar value={formatAmount(LIQUIDATION_FEE, 30, 2, true)} />
          )}
        </>
      )}
    />
  );
}

export function getLiquidationData(liquidationsDataMap, key, timestamp) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`];
}

export default function TradeHistory(props) {
  const { account, infoTokens, getTokenInfo, chainId, nativeTokenAddress, shouldShowPaginationButtons, isActionPage } =
    props;
  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);

  const getAfterId = () => {
    return pageIds[pageIndex];
  };

  const { trades, updateTrades } = useTrades(chainId, account, props.forSingleAccount, getAfterId(), props.type);

  const liquidationsData = useLiquidationsData(chainId, account);
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {});
  }, [liquidationsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateTrades]);

  useEffect(() => {
    setPageIndex(0);
  }, []);

  const loadNextPage = () => {
    if (!trades || trades.length === 0) {
      return;
    }

    const lastTrade = trades[trades.length - 1];
    pageIds[pageIndex + 1] = lastTrade.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const loadPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const getMsg = useCallback(
    (trade) => {
      const tradeData = trade.data;
      const params = JSON.parse(tradeData.params);
      const longOrShortText = params?.isLong ? t`Long` : t`Short`;
      const defaultMsg = [];
      const gt = () => {
        return ">";
      };
      const lt = () => {
        return "<";
      };

      if (tradeData.action === "BuyUSDF") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return [
          `Swap ${token.symbol}`,
          `${formatAmount(params.usdfAmount, 18, 4, true)}`,
          "-",
          `${token.symbol} -> USDF`,
        ];
      }

      if (tradeData.action === "SellUSDF") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return [
          `Swap ${formatAmount(params.usdfAmount, 18, 4, true)} USDF`,
          ` ${formatAmount(params.tokenAmount, token.decimals, 4, true)} `,
          `-`,
          `USDF -> ${token.symbol}`,
        ];
      }

      if (tradeData.action === "CreateSwap") {
        const tokenIn = getTokenInfo(infoTokens, params.path[0], true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, params.path[1], true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }

        let acceptableRatioStr = "";

        if (expandDecimals(1, 30).lte(params.acceptableRatio)) {
          const displayDecimals = tokenOut.isStable ? 2 : 4;
          acceptableRatioStr = `> ${formatAmount(params.acceptableRatio, USD_DECIMALS, displayDecimals, true)}`;
        } else {
          const displayDecimals = tokenIn.isStable ? 2 : 4;
          acceptableRatioStr = `< ${formatAmount(
            expandDecimals(1, 60).div(params.acceptableRatio),
            USD_DECIMALS,
            displayDecimals,
            true
          )} ${tokenOut.symbol} / ${tokenIn.symbol}`;
        }

        return [
          `Request swap  `,
          `${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol}`,
          `${acceptableRatioStr} `,
          `${tokenIn.symbol} -> ${tokenOut.symbol}`,
        ];
      }

      if (tradeData.action === "CancelSwap") {
        const tokenIn = getTokenInfo(infoTokens, params.path[0], true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, params.path[1], true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }

        let isGreater = false;
        let acceptableRatioStr = "";

        if (expandDecimals(1, 30).lte(params.acceptableRatio)) {
          const displayDecimals = tokenOut.isStable ? 2 : 4;
          isGreater = true;
          acceptableRatioStr = ` ${formatAmount(params.acceptableRatio, USD_DECIMALS, displayDecimals, true)} ${
            tokenIn.symbol
          } / ${tokenOut.symbol}`;
        } else {
          const displayDecimals = tokenIn.isStable ? 2 : 4;
          acceptableRatioStr = `${formatAmount(
            expandDecimals(1, 60).div(params.acceptableRatio),
            USD_DECIMALS,
            displayDecimals,
            true
          )} ${tokenOut.symbol} / ${tokenIn.symbol}`;
        }

        return [
          `Could not swap  `,
          `${formatAmount(params.amountIn, tokenIn.decimals, 4, true)}`,
          `${isGreater ? "> " : "< "} ${acceptableRatioStr}`,
          `${tokenIn.symbol} -> ${tokenOut.symbol}`,
        ];
      }

      if (tradeData.action === "ExecuteSwap") {
        const tokenIn = getTokenInfo(infoTokens, params.path[0], true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, params.path[1], true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }
        let acceptableRatioStr = "";

        if (expandDecimals(1, 30).lte(params.acceptableRatio)) {
          const displayDecimals = tokenOut.isStable ? 2 : 4;
          acceptableRatioStr = `> ${formatAmount(params.acceptableRatio, USD_DECIMALS, displayDecimals, true)}`;
        } else {
          const displayDecimals = tokenIn.isStable ? 2 : 4;
          acceptableRatioStr = `< ${formatAmount(
            expandDecimals(1, 60).div(params.acceptableRatio),
            USD_DECIMALS,
            displayDecimals,
            true
          )} ${tokenOut.symbol} / ${tokenIn.symbol}`;
        }

        return [
          `Swap  ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol}`,
          ` ${formatAmount(params.amountOut, tokenOut.decimals, 4, true)} ${tokenOut.symbol}`,
          `${acceptableRatioStr}`,
          `${tokenIn.symbol} -> ${tokenOut.symbol}`,
        ];
      }

      if (tradeData.action === "Swap") {
        const tokenIn = getTokenInfo(infoTokens, params.tokenIn, true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }

        let acceptableRatioStr = "";

        if (expandDecimals(1, 30).lte(params.acceptableRatio)) {
          const displayDecimals = tokenOut.isStable ? 2 : 4;
          acceptableRatioStr = `> ${formatAmount(params.acceptableRatio, USD_DECIMALS, displayDecimals, true)}`;
        } else {
          const displayDecimals = tokenIn.isStable ? 2 : 4;
          acceptableRatioStr = `< ${formatAmount(
            expandDecimals(1, 60).div(params.acceptableRatio),
            USD_DECIMALS,
            displayDecimals,
            true
          )} ${tokenOut.symbol} / ${tokenIn.symbol}`;
        }

        return [
          `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol}`,
          ` ${formatAmount(params.amountOut, tokenOut.decimals, 4, true)} ${tokenOut.symbol}`,
          `${acceptableRatioStr}`,
          `${tokenIn.symbol} -> ${tokenOut.symbol}`,
        ];
      }

      if (tradeData.action === "CreateIncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        // if (bigNumberify(params.sizeDelta).eq(0)) {
        //   return [
        //     `Request deposit`,
        //     `${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
        //     `${formatPrice(params.acceptablePrice, true)}`,
        //     `${indexToken.symbol} ${longOrShortText}`
        //   ] ;
        // }

        return [
          `Request increase`,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()} ${formatPrice(params.acceptablePrice, true)}`,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "CreateDecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        // if (bigNumberify(params.sizeDelta).eq(0)) {
        //   return [`Request withdrawal `, ];
        // }

        return [
          `Request decrease`,
          `-${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? gt() : lt()} ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "CancelIncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [`Could not execute deposit `, "-", "-", `${indexToken.symbol} ${longOrShortText}`];
        }

        return [
          `Could not increase`,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()} ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "CancelDecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [`Could not execute withdrawal`, "-", "-", `${indexToken.symbol} ${longOrShortText}`];
        }

        return [
          `Could not decrease`,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()} ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "IncreasePosition-Long" || tradeData.action === "IncreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [
            `Deposited `,
            `${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD `,
            `${formatPrice(params.price, true)}`,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }

        return [
          `Increased `,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${formatPrice(params.price, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "DecreasePosition-Long" || tradeData.action === "DecreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [
            `Withdrew `,
            `${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD`,
            `${formatPrice(params.price, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        const isLiquidation = params.flags?.isLiquidation;
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);

        if (isLiquidation && liquidationData) {
          const liquidationTooltip = renderLiquidationTooltip(liquidationData, t`Partially Liquidated `);
          return [
            liquidationTooltip,
            `-${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)} USD`,
            `$${formatPrice(params.price, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        const actionDisplay = isLiquidation ? t`Partially Liquidated` : t`Decreased`;
        const pnl = bigNumberify(params.pnl);
        const pnlAfterFees = pnl.sub(params.fee);
        return [
          `${actionDisplay} `,
          <Tooltip
            position="left-top"
            handle={`-${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`}
            renderContent={() => (
              <>
                <StatsTooltipRow
                  label={t`PnL`}
                  showDollar={false}
                  value={`${pnl.lt(0) ? "-" : ""}$${formatAmount(pnl.abs(), USD_DECIMALS, 2, true)}`}
                />
                <StatsTooltipRow label={t`Fees`} showDollar value={formatAmount(params.fee, USD_DECIMALS, 2, true)} />
                <StatsTooltipRow
                  label={t`PnL After Fees`}
                  showDollar={false}
                  value={`${pnlAfterFees.lt(0) ? "-" : ""}$${formatAmount(pnlAfterFees.abs(), USD_DECIMALS, 2, true)}`}
                />
              </>
            )}
          />,
          `${formatPrice(params.price, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "LiquidatePosition-Long" || tradeData.action === "LiquidatePosition-Short") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);
        if (liquidationData) {
          const liquidationTooltip = renderLiquidationTooltip(liquidationData, t`Liquidated `);
          return [
            liquidationTooltip,
            `-${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)} USD`,
            `${formatPrice(params.markPrice, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        return [
          `Liquidated `,
          `-${formatAmount(params.size, USD_DECIMALS, 2, true)} USD`,
          ` ${formatPrice(params.markPrice, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (["ExecuteIncreaseOrder", "ExecuteDecreaseOrder"].includes(tradeData.action)) {
        const order = deserialize(params);
        const indexToken = getTokenInfo(infoTokens, order.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const longShortDisplay = order.isLong ? t`Long` : t`Short`;
        const executionPriceDisplay = formatPrice(order.executionPrice, true);
        const orderType = tradeData.action.includes("Increase") ? "Increase" : "Decrease";
        const sizeDeltaDisplay = `${orderType === "Increase" ? "+" : "-"}${formatAmount(
          order.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )}`;
        let deltaDisplay = `${sizeDeltaDisplay} USD`;
        if (orderType === "Decrease") {
          const pnl = bigNumberify(params.pnl);
          const pnlAfterFees = pnl.sub(params.fee);

          deltaDisplay = (
            <Tooltip
              position="left-top"
              handle={deltaDisplay}
              renderContent={() => (
                <>
                  <StatsTooltipRow
                    label={t`PnL`}
                    showDollar={false}
                    value={`${pnl.lt(0) ? "-" : ""}$${formatAmount(pnl.abs(), USD_DECIMALS, 2, true)}`}
                  />
                  <StatsTooltipRow label={t`Fees`} showDollar value={formatAmount(params.fee, USD_DECIMALS, 2, true)} />
                  <StatsTooltipRow
                    label={t`PnL After Fees`}
                    showDollar={false}
                    value={`${pnlAfterFees.lt(0) ? "-" : ""}$${formatAmount(
                      pnlAfterFees.abs(),
                      USD_DECIMALS,
                      2,
                      true
                    )}`}
                  />
                </>
              )}
            />
          );
        }
        return [
          `Execute Order: ${select(orderType, { Increase: "Increase", Decrease: "Decrease" })} `,
          deltaDisplay,
          `${executionPriceDisplay}`,
          `${indexToken.symbol} ${longShortDisplay}`,
        ];
      }

      if (
        [
          "CreateIncreaseOrder",
          "CancelIncreaseOrder",
          "UpdateIncreaseOrder",
          "CreateDecreaseOrder",
          "CancelDecreaseOrder",
          "UpdateDecreaseOrder",
        ].includes(tradeData.action)
      ) {
        const order = deserialize(params);
        const indexToken = getTokenInfo(infoTokens, order.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = tradeData.action.includes("Increase");
        const priceDisplay = `${order.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          2,
          true
        )}`;
        const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";

        return [
          `${getOrderActionTitle(tradeData.action)}:
          ${getPositionDisplay(increase, indexToken, order.isLong, order.sizeDelta)}`,
          `${increase ? "+" : "-"} ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${priceDisplay}`,
          `${symbol} ${order.isLong ? t`Long` : t`Short`}`,
        ];
      }

      if (tradeData.action === "ExecuteSwapOrder") {
        const order = deserialize(params);
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const fromAmountDisplay = formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true);
        const toAmountDisplay = formatAmount(order.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true);
        return [
          `Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol}`,
          ` ${toAmountDisplay} ${toToken.symbol}`,
          "-",
          `${fromToken.symbol} -> ${toToken.symbol}`,
        ];
      }

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(tradeData.action)) {
        const order = deserialize(params);
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(order.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";

        return [
          `${getOrderActionTitle(tradeData.action)}:Swap ${amountInDisplay} ${fromToken?.symbol || ""}`,
          ` ${minOutDisplay} ${toToken?.symbol || ""}`,
          `${getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}`,
          `${fromToken?.symbol || ""} ->  ${toToken?.symbol || ""}`,
        ];
      }

      if (tradeData.action === "CreateAddLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Request buy FLP `,
          `${formatAmount(params.amountIn, token.decimals, 4, true)}`,
          `> ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`,
          `${token.symbol}`,
        ];
      }

      if (tradeData.action === "CancelAddLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Could not buy FLP `,
          `${formatAmount(params.amountIn, USD_DECIMALS, 2, true)}`,
          `> ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
        ];
      }

      if (tradeData.action === "AddLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return [
          <Tooltip
            position="left-top"
            handle={`Buy ${formatAmount(params.mintAmount, FLP_DECIMALS, 4, true)} FLP`}
            renderContent={() => (
              <>
                <Trans>FLP Balance After Buy</Trans>
                <br />
                <br />
                {formatAmount(params.flpBalance, FLP_DECIMALS, 2, true)} FLP
              </>
            )}
          />,
          `${formatAmount(params.amount, token.decimals, 4, true)} ${token.symbol} `,
          "-",
          `${token.symbol} ->  FLP`,
        ];
      }

      if (tradeData.action === "CreateRemoveLiquidity") {
        const token = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Request sell FLP`,
          `${formatAmount(params.flpAmount, FLP_DECIMALS, 4, true)} FLP`,
          `< ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
        ];
      }

      if (tradeData.action === "CancelRemoveLiquidity") {
        const token = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Could not sell FLP`,
          `${formatAmount(params.flpAmount, FLP_DECIMALS, 2, true)} FLP`,
          `< ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
        ];
      }

      if (tradeData.action === "RemoveLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return [
          <Tooltip
            position="left-top"
            handle={`Sell ${formatAmount(params.flpAmount, FLP_DECIMALS, 4, true)} FLP `}
            renderContent={() => (
              <>
                <Trans>FLP Balance After Sell</Trans>
                <br />
                <br />
                {formatAmount(params.flpBalance, FLP_DECIMALS, 2, true)} FLP
              </>
            )}
          />,
          ` ${formatAmount(params.amountOut, token.decimals, 4, true)}`,
          "-",
          `FLP ->  ${token.symbol}`,
        ];
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainId, liquidationsDataMap]
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }

    return trades
      .map((trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade) => trade.msg);
  }, [trades, getMsg]);

  const [msgLength, setMsgLength] = useState(isActionPage ? tradesWithMessages.length : 5);

  return (
    <div className="TradeHistory">
      <table
        className={cx(" token-table all-trades Exchange-list Orders App-box large", {
          "Orders-overview-table": isActionPage,
        })}
      >
        <thead className="Exchange-list-header">
          <tr>
            <th>
              <Trans>ACTION</Trans>
            </th>
            <th>
              <Trans>INSTRUMENT</Trans>
            </th>
            <th>
              <Trans>DELTA</Trans>
            </th>
            <th>
              <Trans>PRICE</Trans>
            </th>
            {isActionPage && (!account || account.length === 0) && (
              <th>
                <Trans>ACCOUNT</Trans>
              </th>
            )}
            <th>
              <Trans>TIMESTAMP</Trans>
            </th>
            <th>
              <Trans>TRANSACTION</Trans>
            </th>
          </tr>
        </thead>
        
        <tbody>
          {tradesWithMessages.length > 0 &&
            tradesWithMessages.map((trade, index) => {
              const tradeData = trade.data;
              const txUrl = getExplorerUrl(chainId) + "tx/" + tradeData.txhash;

              let msg = getMsg(trade);

              if (msg.length === 0) {
                return null;
              }

              if (!isActionPage && index >= msgLength) {
                return null;
              }

              return (
                <tr className="Exchange-list-item" key={`${index}`}>
                  <td>{msg[0]}</td>
                  <td>{msg[3]}</td>
                  <td>{msg[1]}</td>
                  <td>{msg[2]}</td>
                  {isActionPage && (!account || account.length === 0) && (
                    <td>
                      {(!account || account.length === 0) && (
                        <span>
                          {" "}
                          <Link
                            to={`/${props.type === "liquidity_actions" ? "liquidity" : "trades"}/${tradeData.account}`}
                          >
                            {shortenAddress(tradeData.account, 18)}
                          </Link>
                        </span>
                      )}
                    </td>
                  )}
                  <td>{formatDateTime(tradeData.timestamp)}</td>
                  <td>
                    <a href={txUrl} className="balance-explorer" target="_blank" rel="noopener noreferrer">
                      view on explorer &nbsp; <FiExternalLink className="external-link" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {tradesWithMessages.length === 0 && (
        <div className="TradeHistory-row App-box no-open-orders">
          <Trans>No actions yet</Trans>
        </div>
      )}

      {!isActionPage && tradesWithMessages.length > 5 && (
        <div className="show-more-container">
          <button className="App-button-option App-card-option show-more" onClick={() => setMsgLength(msgLength + 5)}>
            <Trans>Show more</Trans>
          </button>
        </div>
      )}

      {shouldShowPaginationButtons && isActionPage && (
        <div className="prev-next-container">
          {pageIndex > 0 && (
            <button className="App-button-option App-card-option" onClick={loadPrevPage}>
              <Trans>Prev</Trans>
            </button>
          )}
          {trades && trades.length >= TRADES_PAGE_SIZE && (
            <button className="App-button-option App-card-option" onClick={loadNextPage}>
              <Trans>Next</Trans>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
