import React, { useEffect, useState } from 'react'
import rank1 from "../../img/rank1.svg";
import rank2 from "../../img/rank2.svg";
import rank3 from "../../img/rank3.svg";
import bigInt from "big-integer";
import { shortenAddress } from 'lib/legacy';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';

const MobileCardLeaderBoard = ({ user }) => {

    const [expanded, setexpanded] = useState(false)
    const formatNumber = (value, decimalPlaces) => {
        if (isNaN(value)) {
            return "N/A";
        }
        const bigValue = bigInt(value);
        const divisor = bigInt(10).pow(30);
        const result = bigValue.divide(divisor).toString();
        return parseFloat(result).toFixed(decimalPlaces);
    };

    useEffect(() => {
        if (user.rank < 4) {
            setexpanded(true)
        }
        else {
            setexpanded(false)
        }
    }, [user])

    return (
        <div className="mob-box">
            <div className="mobile-box">
                <button className={`box-header`} onClick={() => setexpanded(!expanded)} >
                    {expanded ? <IoMdArrowDropright /> : <IoMdArrowDropdown />}
                    <div className="box-head">
                        <div className="box-header">
                            <div className={`rank ${user.rank < 4 ? "top-rank-header" : ""}`}>
                                Rank {user.rank}
                                {user.rank === 1 ? (
                                    <img src={rank1} alt="Rank 1" />
                                ) : user.rank === 2 ? (
                                    <img src={rank2} alt="Rank 2" />
                                ) : user.rank === 3 ? (
                                    <img src={rank3} alt="Rank 3" />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="address">
                                <div className={`pnlcol ${user.netPnl < 0 ? "red-text" : "green-text"}`}>
                                    $ {formatNumber(user.netPnl, 2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
                {
                    expanded && (<div className="mob-data">
                        <div className="box">
                            <div className="box-data">
                                <div className="data">
                                    <div className="data-c">
                                        <div className="m-heads">Trading Volume</div>
                                        <div className="dyn-data">
                                            {" "}
                                            ${parseFloat(formatNumber(user.marginVolume, 2)).toLocaleString(`en-US`)}{" "}
                                        </div>
                                    </div>
                                    <div className="data-c">
                                        <div className="m-heads">W-L Ratio</div>
                                        <div className="dyn-data">
                                            {" "}
                                            {user.wins}-{user.losses}
                                        </div>
                                    </div>
                                    <div className="data-c">
                                        <div className="m-heads">Biggest Win</div>
                                        <div className="dyn-data"> $ {formatNumber(user.biggestWin, 2)} </div>
                                    </div>
                                    <div className="data-c">
                                        <div className="m-heads">Address</div>
                                        <div className="dyn-data pnl-address">{shortenAddress(user.account, 11)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }

            </div>
        </div>
    )
}

export default MobileCardLeaderBoard