
import React, { useRef } from "react";
import TTbuyFxdx from "./HeroGetFxdx/HeroGetfxdx";
import Bentogetfxdx from "./BentoGetFxdx/bentogetfx";
import StakeFxdxNew from "./StakeFxdx/Stakefxdx"
import Footer from "components/Footer/Footer";
import Stakehistorynew from "./Stakehistoryfxdx/fxdxstakehis";
import Stakehistoryclick from "./StakeHistoryclick/stakeclickhist";

const GetFxdx = () => {
  const stakeFxdxRef = useRef(null);

  const scrollToStakeFxdx = () => {
    stakeFxdxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  
   return (
    
     <>
    <div style={{ background: '#121212' }}>
      <TTbuyFxdx onStakeHereClick={scrollToStakeFxdx} />
      <Bentogetfxdx />
      <div ref={stakeFxdxRef}>
        <StakeFxdxNew />
      </div>
       <Stakehistorynew/>
       <Stakehistoryclick/>
       
      <Footer />
    </div>
   </>
      )
}
export default GetFxdx;