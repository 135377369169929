import { gql } from "@apollo/client";
import { getActionsClient, getLeaderBoardActionsClient } from "lib/subgraph";

const blackListAddress = [
  "0x0aa521427cf5a815a3b4b5acdcc531a477436457",
  "0xeca8f40f98abde3195f3120e7e9fb0a85cf3417e",
  "0x57cf749ed1f6de91171b44cc660516fe5362f5b4",
  "0xf166b01e8e29488b3f29de3a202134fc5cae82a1",
];

const userTradesStatsQuery = gql`
  query UserTradesStats($period: String, $firstCount: Int) {
    userTradesStats(where:{and:[{period:$period} { marginVolume_gt:0 }]} orderBy:timestamp,orderDirection:desc) {
      account
      netPnl
      marginVolume
      swapVolume
      marginFees
      period
      timestamp
      wins
      losses
      biggestWin
    }
  }
`;

const titleToSeconds = {
  'daily': 60 * 60 * 24,
  'weekly': 60 * 60 * 24 * 7,
  'biweekly': 60 * 60 * 24 * 14,
  'monthly': 60 * 60 * 24 * 31
}

// const userTradeDetailsQuery = gql`
//   query UserTradeDetails($account: String!) {
//     userTradesStats(where: { account: $account, period: "weekly" }) {
//       netPnl
//     }
//   }
// `;

export const getUserTrades = async (chainId, period) => {
  if (!chainId) {
    return [];
  }

  const firstCount = period === "total" ? 104 : 100
  try {
    const client = getLeaderBoardActionsClient(chainId);
    const data = await client.query({
      query: userTradesStatsQuery,
      variables: {
        period: period,
        firstCount: firstCount
      }
    });

    const allUsersData = data.data.userTradesStats;
    const minTimeStamp = parseInt((Date.now() / 1000) - titleToSeconds[period])
    const filteredUserData = allUsersData.filter((user) => {
      return !blackListAddress.includes(user.account);
    });
    const aggregatedData = filteredUserData.reduce((result, user) => {
      const userAccount = user.account;
      const existingUser = result.find((item) => item.account === userAccount);
      if (existingUser) {
        existingUser.netPnl += parseFloat(user.netPnl);
      } else {
        result.push({
          account: userAccount,
          netPnl: parseFloat(user.netPnl),
          swapVolume: parseFloat(user.swapVolume),
          marginFees: parseFloat(user.marginFees),
          marginVolume: parseFloat(user.marginVolume),
          wins: user.wins,
          losses: user.losses,
          biggestWin: user.biggestWin,
          timestamp: user.timestamp
        });
      }
      return result;
    }, []);

    // const filteredArray = aggregatedData
    // const filteredArray = [];
    // console.log("********************************")
    // console.log(aggregatedData.length)
    // console.log(minTimeStamp)
    // for (let i = 0; i < aggregatedData.length; i++) {
    //   console.log(aggregatedData[i].timestamp)
    //   if (aggregatedData[i].timestamp > minTimeStamp) {
    //     filteredArray.push(aggregatedData[i])
    //   }
    // }
    // console.log(filteredArray.length)
    const aggregatedUserData = aggregatedData.sort((a, b) => b.netPnl - a.netPnl);
    return aggregatedUserData;
  } catch (err) {
    console.log("Error fetching data: ", err);
  }
};

// export const getUserWinRatio = async (aggregatedData, chainId) => {
//   try {
//     const data = aggregatedData.map(async (user) => {
//       const client = getLeaderBoardActionsClient(chainId);
//       const wl = await client.query({
//         query: userTradeDetailsQuery,
//         variables: { account: user.account },
//       });

//       let winCount = 0;
//       let lossCount = 0;
//       let maxWin = 0;

//       wl.data.userTradesStats.forEach((trade) => {
//         const pnl = parseFloat(trade.netPnl);
//         if (pnl > 0) {
//           winCount += 1;
//           maxWin = Math.max(maxWin, pnl);
//         } else if (pnl < 0) {
//           lossCount += 1;
//         }
//       });
//       user.winCount = winCount;
//       user.lossCount = lossCount;
//       user.maxWin = maxWin;
//     });

//     await Promise.all(data);
//     return aggregatedData;
//   } catch (error) {
//     console.error("Error fetching win/loss data: ", error);
//   }
// };
