import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { FlpSwap } from "components/Flp/FlpSwap";
import Footer from "components/Footer/Footer";
import "./BuyFlp.css";

export default function BuyFlp({ flpSwapRef, ...props }) {
  const location = useLocation();
  const [isBuying, setIsBuying] = useState(true);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    const buying = hash === "redeem" ? false : true;
    setIsBuying(buying);
  }, [location.hash]);

  return (
    <div className="default-container page-layout buy-flp-page">
      <FlpSwap ref={flpSwapRef} {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
      <Footer />
    </div>
  );
}
