import { OPTIMISM_GOERLI } from "config/chains";
import { getTokenBySymbol, getWhitelistedTokens } from "config/tokens";
import { bigNumberify, expandDecimals } from "lib/numbers";

export const getAums = (chainId, infoTokens, aumAddition, aumDeduction) => {
  if (!infoTokens) {
    return;
  }
  const aums = [];

  aums.push(getAum(chainId, infoTokens, aumAddition, aumDeduction, true));
  aums.push(getAum(chainId, infoTokens, aumAddition, aumDeduction, false));
  return aums;
};

export const getAum = (chainId, infoTokens, aumAddition, aumDeduction, maximize) => {
  if (!infoTokens) {
    return;
  }
  const whitelistedTokens = getWhitelistedTokens(chainId).filter((token) => !token.isNative);

  if (chainId === OPTIMISM_GOERLI) {
    const feth = getTokenBySymbol(chainId, "FETH");
    whitelistedTokens.push(feth);
  }

  const poolTokens = whitelistedTokens.map((token) => infoTokens[token.address]);

  if (poolTokens.findIndex((token) => !token.minPrice || !token.maxPrice) > -1) {
    return;
  }

  if (!aumAddition || !aumDeduction) {
    return;
  }

  let aum = aumAddition;
  let shortProfits = bigNumberify(0);

  for (let i = 0; i < poolTokens.length; i++) {
    const token = poolTokens[i];

    const price = maximize ? token.maxPrice : token.minPrice;

    const poolAmount = token.poolAmount;
    const decimals = token.decimals;

    if (token.isStable) {
      aum = aum.add(poolAmount.mul(price).div(expandDecimals(1, decimals)));
    } else {
      // add global short profit / loss
      const size = token.globalShortSize;
      if (size.gt(0)) {
        const averagePrice = token.globalShortAveragePrice;
        const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice);
        const delta = size.mul(priceDelta).div(averagePrice);
        if (price.gt(averagePrice)) {
          // add losses from shorts
          aum = aum.add(delta);
        } else {
          shortProfits = shortProfits.add(delta);
        }
      }

      aum = aum.add(token.guaranteedUsd);

      const reservedAmount = token.reservedAmount;
      aum = aum.add(poolAmount.sub(reservedAmount).mul(price).div(expandDecimals(1, decimals)));
    }
  }

  aum = shortProfits.gt(aum) ? 0 : aum.sub(shortProfits);
  return aumDeduction.gt(aum) ? 0 : aum.sub(aumDeduction);
};
