import React from "react";

import cx from "classnames";

import "./Checkbox.css";
import { ToggleSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className } = props;

  return (
    <ToggleSwitch
      className={cx("toggle-leverage", className)}
      checked={isChecked}
      onChange={setIsChecked}
      handleColor="#E2E2E3"
      onColor="#9D9D9D"
      offColor="#363636"
      disabled={disabled}
    />
  );
}
