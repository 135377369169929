export const rewardFxdxVaultData = {
    abi: [
        {
            inputs: [
                { internalType: "uint256", name: "_maxRewardCap", type: "uint256" },
                { internalType: "bool", name: "_isMaxRewardCapActive", type: "bool" },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "uint256", name: "rewardId", type: "uint256" },
                { indexed: true, internalType: "uint256", name: "stakeId", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "rewardAmount", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" },
                { indexed: true, internalType: "address", name: "account", type: "address" },
                { indexed: false, internalType: "bool", name: "isClaimed", type: "bool" },
            ],
            name: "AddReward",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "uint256", name: "rewardId", type: "uint256" },
                { indexed: true, internalType: "uint256", name: "stakeId", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "rewardAmount", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" },
                { indexed: true, internalType: "address", name: "account", type: "address" },
                { indexed: false, internalType: "bool", name: "isClaimed", type: "bool" },
            ],
            name: "SendReward",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "address", name: "vault", type: "address" },
                { indexed: true, internalType: "uint256", name: "rewardReserves", type: "uint256" },
            ],
            name: "TotalReserves",
            type: "event",
        },
        {
            inputs: [],
            name: "MAX_REWARD_CAP",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256", name: "_rewardAmount", type: "uint256" },
                { internalType: "uint256", name: "_duration", type: "uint256" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "uint256", name: "_stakeId", type: "uint256" },
            ],
            name: "addRewardAmount",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256[]", name: "_stakeIds", type: "uint256[]" },
                { internalType: "bool", name: "_isRestake", type: "bool" },
            ],
            name: "distributeReward",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "fxdx",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "getMaxRewardCapStatus",
            outputs: [
                { internalType: "bool", name: "", type: "bool" },
                { internalType: "uint256", name: "", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "rewardId", type: "uint256" }],
            name: "getRewardData",
            outputs: [
                { internalType: "uint256", name: "_rewardId", type: "uint256" },
                { internalType: "uint256", name: "_stakeId", type: "uint256" },
                { internalType: "uint256", name: "_rewardAmount", type: "uint256" },
                { internalType: "uint256", name: "_duration", type: "uint256" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "bool", name: "_isClaimed", type: "bool" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256[]", name: "_stakeIds", type: "uint256[]" },
            ],
            name: "getTotalRewardAmount",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_account", type: "address" }],
            name: "getUserRewardInfo",
            outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "gov",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "", type: "address" }],
            name: "isHandler",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "isMaxRewardCapActive",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "rewardCount",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "rewardData",
            outputs: [
                { internalType: "uint256", name: "rewardId", type: "uint256" },
                { internalType: "uint256", name: "stakeId", type: "uint256" },
                { internalType: "uint256", name: "rewardAmount", type: "uint256" },
                { internalType: "uint256", name: "duration", type: "uint256" },
                { internalType: "uint256", name: "timestamp", type: "uint256" },
                { internalType: "address", name: "account", type: "address" },
                { internalType: "bool", name: "isClaimed", type: "bool" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "rewardRouter",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_fxdx", type: "address" }],
            name: "setFxdx",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_gov", type: "address" }],
            name: "setGov",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "bool", name: "_isActive", type: "bool" },
            ],
            name: "setHandler",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "bool", name: "_isMaxRewardCapActive", type: "bool" },
                { internalType: "uint256", name: "_maxRewardCap", type: "uint256" },
            ],
            name: "setMaxRewardCapStatus",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_rewardRouter", type: "address" }],
            name: "setRewardRouter",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_stakedFxdxVault", type: "address" }],
            name: "setStakedFxdxVault",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "stakeToRewardMapping",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "stakedFxdxVault",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "totalAmountToDistribute",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "", type: "address" },
                { internalType: "uint256", name: "", type: "uint256" },
            ],
            name: "userRewardInfo",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "withdrawFunds", outputs: [], stateMutability: "nonpayable", type: "function" },
    ],
};
