import "./BridgeCard.css";
import baseIcon from "../../img/ic_base_24.svg";
import opIcon from "../../img/ic_op_24.svg";

const BridgeCard = ({ bridge }) => {
  return (
    <a className="bridge-card-container" href={bridge.redirectURL} target="_blank" rel="noreferrer">
      <div className="bridge-card-wrapper">
        <div className="bridge-card-logo">
          <img src={bridge.image} alt="logo" />
          <div className="chain-logo">
            {bridge.supportsBase && <img src={baseIcon} alt="base logo" />}
            {bridge.supportsOP && <img src={opIcon} alt="op logo" />}
          </div>
        </div>
        <p className="bridge-card-title">{bridge.name}</p>
        <p className="bridge-card-description">{bridge.description}</p>
      </div>
    </a>
  );
};

export default BridgeCard;
