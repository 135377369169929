import {
  BASE,
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  GOERLI,
  MAINNET,
  OPTIMISM_GOERLI,
  MOONBEAM,
  IS_TESTNET,
  OPTIMISM,
} from "./chains";

export const FXDX_STATS_API_URL = IS_TESTNET
  ? "https://test2.stats.fxdx.exchange/api"
  : "https://data.fxdx.exchange/api";

const BACKEND_URLS = {
  default: "https://fxdx-server-mainnet.uw.r.appspot.com",

  [BASE]: "https://api.base.fxdx.exchange",
  [GOERLI]: "https://api.goerli.fxdx.exchange",
  // [GOERLI]: "http://localhost:3000"
  [OPTIMISM_GOERLI]: "https://api.optimismgoerli2.fxdx.exchange",
  [OPTIMISM]: "https://api.optimism.fxdx.exchange",
  [MAINNET]: "https://api.ethereum.fxdx.exchange",
  [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  [ARBITRUM]: "https://fxdx-server-mainnet.uw.r.appspot.com",
  [AVALANCHE]: "https://fxdx-avax-server.uc.r.appspot.com",
  [MOONBEAM]: "https://api.moonbeam.fxdx.exchange",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
