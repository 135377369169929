import { ethers } from "ethers";
import { sample } from "lodash";

const { parseEther } = ethers.utils;

export const IS_TESTNET = false;

export const BASE = 8453;
export const GOERLI = 5;
export const MAINNET = 1;
export const OPTIMISM_GOERLI = 420;
export const OPTIMISM = 10;
export const AVALANCHE = 43114;
export const ARBITRUM_TESTNET = 421611;
export const ARBITRUM = 42161;
export const MOONBEAM = 1284;

// TODO take it from web3
export const DEFAULT_CHAIN_ID = IS_TESTNET ? OPTIMISM_GOERLI : BASE;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS: number[] = IS_TESTNET ? [OPTIMISM_GOERLI] : [BASE, OPTIMISM];

export const IS_NETWORK_DISABLED = {
  [ARBITRUM]: false,
  [AVALANCHE]: false,
};

export const CHAIN_NAMES_MAP = {
  [BASE]: "Base",
  [GOERLI]: "Goerli",
  [OPTIMISM_GOERLI]: "OptimismGoerli",
  [OPTIMISM]: "Optimism",
  [MAINNET]: "Ethereum",
  [ARBITRUM_TESTNET]: "ArbRinkeby",
  [ARBITRUM]: "Arbitrum",
  [AVALANCHE]: "Avalanche",
  [MOONBEAM]: "MoonBeam",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [BASE]: "0",
  [GOERLI]: "0",
  [OPTIMISM_GOERLI]: "0",
  [OPTIMISM]: "0",
  [ARBITRUM]: "0",
  [AVALANCHE]: "3000000000", // 3 gwei
};

export const MAX_GAS_PRICE_MAP = {
  [GOERLI]: "200000000000", // 3 gwei
  [AVALANCHE]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [BASE]: 3, // 3 USD
  [GOERLI]: 15, // 15 USD
  [OPTIMISM_GOERLI]: 3, // 3 USD
  [OPTIMISM]: 3, // 3 USD
  [ARBITRUM]: 3, // 3 USD
  [AVALANCHE]: 3, // 3 USD
  [MOONBEAM]: 1, // 1 USD
};

const constants = {
  [BASE]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    feeRewardTokenSymbol: "USDbC",
    defaultIndexTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDbC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000500001"),
  },
  [GOERLI]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    feeRewardTokenSymbol: "USDC",
    defaultCollateralSymbol: "USDC",
    defaultIndexTokenSymbol: "ETH",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },

  [OPTIMISM_GOERLI]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    feeRewardTokenSymbol: "USDC",
    defaultIndexTokenSymbol: "FETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.001"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.001"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00100001"),
  },

  [OPTIMISM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    feeRewardTokenSymbol: "USDC",
    defaultIndexTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000500001"),
  },

  [ARBITRUM_TESTNET]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [ARBITRUM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [AVALANCHE]: {
    nativeTokenSymbol: "AVAX",
    wrappedTokenSymbol: "WAVAX",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },

  [MOONBEAM]: {
    nativeTokenSymbol: "GLMR",
    wrappedTokenSymbol: "WGLMR",
    defaultCollateralSymbol: "USDC(M)",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.1"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.1"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.100001"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["fxdx.exchange", "test.fxdx.exchange"];

export const BASE_RPC_PROVIDERS = [
  "https://base-mainnet.blastapi.io/1471a0f7-a6eb-4a33-9063-8b8e2e9d49c2",
  "https://base-mainnet.blastapi.io/03f6065b-82fd-4122-867d-dd4d1ab3ce38",
  "https://base-mainnet.blastapi.io/a4f293ac-4647-4f6f-a13e-2c87b0e76dd7",
  "https://base-mainnet.blastapi.io/75d77399-efc0-4e95-9b1c-8d3937d38d1f",
  "https://base-mainnet.blastapi.io/abdc28b9-e280-49c1-8955-f92f8414cbd8",
  "https://base-mainnet.blastapi.io/f7f23fa0-0fa6-4fc1-b969-946864615f80",
];
export const GOERLI_RPC_PRIVIDERS = ["https://ethereum-goerli-rpc.allthatnode.com"];
export const OPTIMISM_GOERLI_RPC_PROVIDERS = [
  "https://goerli.optimism.io",
  "https://optimism-goerli.blastapi.io/03f6065b-82fd-4122-867d-dd4d1ab3ce38", // Abhijit
  "https://optimism-goerli.blastapi.io/15c0c286-bc5b-4cd8-9ce8-c49ecb670e20", // Nuthan
  "https://optimism-goerli.blastapi.io/deaa57ec-d3ba-432c-8fdf-60123d6b5ccc", // Daniel
];
export const OPTIMISM_RPC_PROVIDERS = [
  // "https://mainnet.optimism.io",
  "https://opt-mainnet.g.alchemy.com/v2/4wXzgICGjfr6txMkkrS3-62GzHXBuryO",
  "https://opt-mainnet.g.alchemy.com/v2/2MQ00R_Pflsz1DwFVK1cCB_oj0NUVE6q",
  "https://opt-mainnet.g.alchemy.com/v2/DEVuMUnvsFCgEXIUveGC36QflayyHfQv",
  "https://opt-mainnet.g.alchemy.com/v2/CfSTFRi4wk0xvck5lF1h1Aw_n0u4_hU3",
  "https://opt-mainnet.g.alchemy.com/v2/YnCWucc4yfhE11x18L34wpO2muwpovPT",
  "https://rpc.ankr.com/optimism/42e0c29445adf47ec3414c46f7d4b9fcf6cbc7a901137b12ddb5135de9ecde85",
];
export const MAINNET_RPC_PROVIDERS = ["https://mainnet.infura.io/v3/5fe38fecbed34e5098225e7b279a2289"];
export const ARBITRUM_RPC_PROVIDERS = [getDefaultArbitrumRpcUrl()];
export const AVALANCHE_RPC_PROVIDERS = ["https://api.avax.network/ext/bc/C/rpc"]; // BSC MAINNET
export const MOONBEAM_RPC_PROVIDERS = ["https://rpc.api.moonbeam.network"];

export const RPC_PROVIDERS = {
  [BASE]: BASE_RPC_PROVIDERS,
  [GOERLI]: GOERLI_RPC_PRIVIDERS,
  [OPTIMISM_GOERLI]: OPTIMISM_GOERLI_RPC_PROVIDERS,
  [OPTIMISM]: OPTIMISM_RPC_PROVIDERS,
  [MAINNET]: MAINNET_RPC_PROVIDERS,
  [ARBITRUM]: ARBITRUM_RPC_PROVIDERS,
  [AVALANCHE]: AVALANCHE_RPC_PROVIDERS,
  [MOONBEAM]: MOONBEAM_RPC_PROVIDERS,
};

export const FALLBACK_PROVIDERS = {
  [BASE]: [
    "https://yolo-ultra-wildflower.base-mainnet.discover.quiknode.pro/f4dd15910e8e8b660055bbd3d694a7c429e5aacf/",
    "https://base-mainnet.unifra.io/v1/6bebb183521b400cbe039dc37c51872f",
    "https://base.gateway.tenderly.co/4U4nIPAhrcMMgIigIa2G9J",
    "https://lb.drpc.org/ogrpc?network=base&dkey=AiOo5RTW8EHEp146I0w8IssterOKODkR7rzIDs9_4c5n",
    "https://base.blockpi.network/v1/rpc/1bab80f1ab87948d8c10a8575f417e2ab8c28785",
    "https://base-mainnet.blastapi.io/55703bb1-89cd-4a97-b09f-994b669bd7c0",
    "https://base-mainnet.blastapi.io/1471a0f7-a6eb-4a33-9063-8b8e2e9d49c2",
    "https://base-mainnet.blastapi.io/e13a71e9-c9f4-4aa3-a5b8-0cd4a2a3df5c",
    "https://base-mainnet.blastapi.io/50458041-c799-419a-bdf1-7a3e2856f445",
    "https://base-mainnet.blastapi.io/1471a0f7-a6eb-4a33-9063-8b8e2e9d49c2",
    "https://base-mainnet.blastapi.io/e13a71e9-c9f4-4aa3-a5b8-0cd4a2a3df5c",
    "https://base-mainnet.blastapi.io/a214f79e-3fce-4c5a-ac89-491bbf30e763",
    "https://base-mainnet.blastapi.io/145e46b4-59bd-4688-815a-8003ec649881",
    "https://base-mainnet.blastapi.io/1e2bf98e-78ad-4439-94c3-eb4e60bbd025",
    "https://base-mainnet.blastapi.io/37a29ef2-3a56-451d-af9b-462399f317df",
    "https://base-mainnet.blastapi.io/5ca0fbce-f1cc-477f-aa0b-939ff7574deb",
    "https://base-mainnet.g.alchemy.com/v2/3o9rUeLGF2tyAo1_UeyxpX3nSzIf6TI9",
    "https://base-mainnet.g.alchemy.com/v2/Iql8Qk13SgaJXU4qwUVVw2qWfY0GPoKZ",
    "https://base-mainnet.g.alchemy.com/v2/_i_hcZItTKCN0DOLmJpE47zMy7ccZb4s",
    "https://base-mainnet.g.alchemy.com/v2/0r_xwabaVoPK2IXZWAYsjxSBjye5nheN",
    "https://base-mainnet.g.alchemy.com/v2/GJBtdacbzOzdw1NiTS9KtyNVqBDKoBNk",
    "https://base-mainnet.g.alchemy.com/v2/lC14sfkQRiyKMCCJUnyyhA9mIaW82lLK"
  ],
  [GOERLI]: ["https://ethereum-goerli-rpc.allthatnode.com"],
  [OPTIMISM_GOERLI]: [
    "https://opt-goerli.g.alchemy.com/v2/Vn5FFfTvDwpWgTEmQD2DfI3xX51YqhmB", // Abhijit
    "https://opt-goerli.g.alchemy.com/v2/XEAtvfOgRVFqJRUqpBUZ60pxBVHslGwG", // Nuthan
    "https://opt-goerli.g.alchemy.com/v2/w3vuUxcwLbu02Pc6BCe2oGNuvJ9sbWkh", // Daniel
  ],
  [OPTIMISM]: [
    "https://optimism-mainnet.blastapi.io/87c06b08-0460-4f90-9056-b4c26ec23bf9",
    "https://optimism-mainnet.blastapi.io/fff718c9-a9bc-43f9-a4fe-77f5267ea717",
    "https://optimism-mainnet.blastapi.io/b332226b-a7cc-43ed-8493-626a99a6a9df",
    "https://optimism-mainnet.blastapi.io/55703bb1-89cd-4a97-b09f-994b669bd7c0",
    "https://optimism-mainnet.blastapi.io/2441b315-203b-483f-8515-c7c72687fa2e",
    "https://opt-mainnet.g.alchemy.com/v2/YnCWucc4yfhE11x18L34wpO2muwpovPT",
    "https://delicate-wiser-cherry.optimism.discover.quiknode.pro/81f4bfb4d85438ab65ca3427fac9d8b4dfb616b4/",
    "https://soft-cosmopolitan-cloud.optimism.discover.quiknode.pro/722bd3505dadbb33651f483923a222acb6fc25a0/",
    "https://op.getblock.io/b6a6806b-4b57-44a9-9bcd-287f7a17d35f/mainnet/",
    "https://op.getblock.io/9bd5cf56-6729-4c1d-90ef-23d0e0267cd6/mainnet/",
    "https://opt-mainnet.blockvision.org/v1/2PuH0QWK2jiKUlfBw6mv927pHSn",
    "https://optimism-mainnet.chainnodes.org/d4908a3f-72c6-4eeb-9ee9-d5d75571e509",
    "https://optimism-mainnet.chainnodes.org/8ef3cfa9-4be2-437c-830c-ce5579425a07",
    // "https://endpoints.omniatech.io/v1/op/mainnet/aa5587fc6d974fabbd0910e5546ecb5c",
    "https://full-optimism.w3node.com/8db24cb03e1b8782b533c05ee05fbdd46b5a8e1e5f21556f1d8c8395bc532d22/api",
    "https://optimism-mainnet.gateway.pokt.network/v1/lb/83c64ef295ef7f5482f1e15f",
    "https://optimism.blockpi.network/v1/rpc/0db1263956ef0df4c1d9a59f854a057f75e04b25",
    "https://optimism.api.onfinality.io/rpc?apikey=ab1c86f0-3bff-438e-aba2-c79cb345b4b7",
  ],
  [MAINNET]: ["https://mainnet.infura.io/v3/5fe38fecbed34e5098225e7b279a2289"],
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
  [MOONBEAM]: [
    "https://moonbeam.public.blastapi.io",
    "https://moonbeam.blastapi.io/fe360d4f-9d4a-4fb1-9030-37aeb5d195b3",
  ],
};

export const NETWORK_METADATA = {
  [BASE]: {
    chainId: "0x" + BASE.toString(16),
    chainName: "Base",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: BASE_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(BASE)],
  },
  [GOERLI]: {
    chainId: "0x" + GOERLI.toString(16),
    chainName: "Goerli test network",
    nativeCurrency: {
      name: "GoerliETH",
      symbol: "GoerliETH",
      decimals: 18,
    },
    rpcUrls: GOERLI_RPC_PRIVIDERS,
    blockExplorerUrls: [getExplorerUrl(GOERLI)],
  },
  [MAINNET]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: MAINNET_RPC_PROVIDERS,
    blockExplorerUrls: ["https://etherscan.io"],
  },
  [OPTIMISM_GOERLI]: {
    chainId: "0x" + OPTIMISM_GOERLI.toString(16),
    chainName: "Optimism Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: OPTIMISM_GOERLI_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(OPTIMISM_GOERLI)],
  },
  [OPTIMISM]: {
    chainId: "0x" + OPTIMISM.toString(16),
    chainName: "Optimism",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: OPTIMISM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(OPTIMISM)],
  },
  [ARBITRUM_TESTNET]: {
    chainId: "0x" + ARBITRUM_TESTNET.toString(16),
    chainName: "Arbitrum Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://rinkeby-explorer.arbitrum.io/"],
  },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ARBITRUM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: "0x" + AVALANCHE.toString(16),
    chainName: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: AVALANCHE_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
  [MOONBEAM]: {
    chainId: "0x" + MOONBEAM.toString(16),
    chainName: "MoonBeam",
    nativeCurrency: {
      name: "Glimmer",
      symbol: "GLMR",
      decimals: 18,
    },
    rpcUrls: MOONBEAM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(MOONBEAM)],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getExplorerUrl(chainId) {
  if (chainId === BASE) {
    return "	https://basescan.org/";
  } else if (chainId === GOERLI) {
    return "https://goerli.etherscan.io/";
  } else if (chainId === OPTIMISM_GOERLI) {
    return "https://goerli-optimism.etherscan.io/";
  } else if (chainId === OPTIMISM) {
    return "https://optimistic.etherscan.io/";
  } else if (chainId === MAINNET) {
    return "https://etherscan.com/";
  } else if (chainId === ARBITRUM_TESTNET) {
    return "https://testnet.arbiscan.io/";
  } else if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === AVALANCHE) {
    return "https://snowtrace.io/";
  } else if (chainId === MOONBEAM) {
    return "https://moonscan.io/";
  }
  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
