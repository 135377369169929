import React from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";

import "../Actions/Actions.css";

import { getContract } from "config/contracts";

import Tooltip from "components/Tooltip/Tooltip";
import TradeHistory from "components/Exchange/TradeHistory";

import Reader from "abis/Reader.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import FlpManager from "abis/FlpManager.json";

import { Trans, t } from "@lingui/macro";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import useWallet from "lib/wallets/useWallet";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import {
  FLP_DECIMALS,
  FXDX_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
} from "lib/legacy";
import { ARBITRUM } from "config/chains";
import { useFxdxPrice } from "domain/legacy";
import { getAums } from "domain/aums";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

const getAccountLiquiditySummary = (statsData, processedData, fxdxPrice, feeTokenInfo) => {
  if (
    !statsData ||
    Object.keys(statsData).length === 0 ||
    !processedData ||
    Object.keys(processedData).length === 0 ||
    !fxdxPrice ||
    !feeTokenInfo ||
    !feeTokenInfo.maxPrice ||
    !feeTokenInfo.minPrice
  ) {
    return;
  }

  const rewardsStat = statsData.rewardsStat;
  const claimedRewards = {
    fee: bigNumberify(0),
    feeUsd: bigNumberify(0),
    fxdx: bigNumberify(0),
    fxdxUsd: bigNumberify(0),
    esFxdx: bigNumberify(0),
    esFxdxUsd: bigNumberify(0),
    bnFxdx: bigNumberify(0),
    totalUsd: bigNumberify(0),
  };
  if (rewardsStat) {
    const feeTokenPrice = feeTokenInfo.maxPrice.add(feeTokenInfo.minPrice).div(2);
    const feeTokenDecimals = feeTokenInfo.decimals;

    claimedRewards.fee = bigNumberify(rewardsStat.fee);
    claimedRewards.feeUsd = claimedRewards.fee.mul(feeTokenPrice).div(expandDecimals(1, feeTokenDecimals));
    claimedRewards.fxdx = bigNumberify(rewardsStat.fxdx);
    claimedRewards.fxdxUsd = claimedRewards.fxdx.mul(fxdxPrice).div(expandDecimals(1, FXDX_DECIMALS));
    claimedRewards.esFxdx = bigNumberify(rewardsStat.esFxdx);
    claimedRewards.esFxdxUsd = claimedRewards.esFxdx.mul(fxdxPrice).div(expandDecimals(1, FXDX_DECIMALS));
    claimedRewards.bnFxdx = bigNumberify(rewardsStat.bnFxdx);
    claimedRewards.totalUsd = claimedRewards.feeUsd.add(claimedRewards.fxdxUsd).add(claimedRewards.esFxdxUsd);
  }

  const unclaimedRewards = {
    fee: processedData.totalFeeRewards,
    feeUsd: processedData.totalFeeRewardsUsd,
    fxdx: processedData.totalVesterRewards,
    fxdxUsd: processedData.totalVesterRewardsUsd,
    esFxdx: processedData.totalEsFxdxRewards,
    esFxdxUsd: processedData.totalEsFxdxRewardsUsd,
    bnFxdx: processedData.bonusFxdxTrackerRewards,
    totalUsd: processedData.totalRewardsUsd,
  };

  const rewardsAccured = {
    fee: claimedRewards.fee.add(unclaimedRewards.fee),
    feeUsd: claimedRewards.feeUsd.add(unclaimedRewards.feeUsd),
    fxdx: claimedRewards.fxdx.add(unclaimedRewards.fxdx),
    fxdxUsd: claimedRewards.fxdxUsd.add(unclaimedRewards.fxdxUsd),
    esFxdx: claimedRewards.esFxdx.add(unclaimedRewards.esFxdx),
    esFxdxUsd: claimedRewards.esFxdxUsd.add(unclaimedRewards.esFxdxUsd),
    bnFxdx: claimedRewards.bnFxdx.add(unclaimedRewards.bnFxdx),
    totalUsd: claimedRewards.totalUsd.add(unclaimedRewards.totalUsd),
  };

  let pnl = processedData.flpBalanceUsd.sub(rewardsAccured.totalUsd);
  const userFlpStat = statsData.userFlpStat;
  if (userFlpStat) {
    pnl = pnl.sub(userFlpStat.usdSpentForBuy).add(userFlpStat.usdReceivedForSell);
  }

  return {
    flpBalance: processedData.flpBalance,
    flpBalanceUsd: processedData.flpBalanceUsd,
    rewardsAccured,
    claimedRewards,
    unclaimedRewards,
    pnl,
  };
};

export default function LiquidityActions() {
  const { isConnected: active, signer, chainId } = useWallet();
  const { account: routeAccount } = useParams();

  let account = "";
  if (ethers.utils.isAddress(routeAccount)) {
    account = ethers.utils.getAddress(routeAccount);
  }

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const feeRewardTokenAddress = getContract(chainId, "FEE_REWARD_TOKEN");
  const fxdxAddress = getContract(chainId, "FXDX");
  const esFxdxAddress = getContract(chainId, "ES_FXDX");
  const bnFxdxAddress = getContract(chainId, "BN_FXDX");
  const flpAddress = getContract(chainId, "FLP");

  const stakedFxdxTrackerAddress = getContract(chainId, "StakedFxdxTracker");
  const bonusFxdxTrackerAddress = getContract(chainId, "BonusFxdxTracker");
  const feeFxdxTrackerAddress = getContract(chainId, "FeeFxdxTracker");

  const stakedFlpTrackerAddress = getContract(chainId, "StakedFlpTracker");
  const feeFlpTrackerAddress = getContract(chainId, "FeeFlpTracker");

  const flpManagerAddress = getContract(chainId, "FlpManager");

  const fxdxVesterAddress = getContract(chainId, "FxdxVester");
  const flpVesterAddress = getContract(chainId, "FlpVester");

  const vesterAddresses = [fxdxVesterAddress, flpVesterAddress];

  const walletTokens = [fxdxAddress, esFxdxAddress, flpAddress, stakedFxdxTrackerAddress];
  const depositTokens = [
    fxdxAddress,
    esFxdxAddress,
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    bnFxdxAddress,
    flpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedFxdxTrackerAddress,
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    feeFlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedFxdxTrackerAddress,
    bonusFxdxTrackerAddress,
    feeFxdxTrackerAddress,
    stakedFlpTrackerAddress,
    feeFlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `Stakeholder:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Stakeholder:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Stakeholder:stakingInfo:${active}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedFxdxSupply } = useSWR(
    [`Stakeholder:stakedFxdxSupply:${active}`, chainId, fxdxAddress, "balanceOf", stakedFxdxTrackerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: aumAddition } = useSWR(
    [`Stakeholder:aumAddition:${active}`, chainId, flpManagerAddress, "aumAddition"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: aumDeduction } = useSWR(
    [`Stakeholder:aumDeduction:${active}`, chainId, flpManagerAddress, "aumDeduction"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`Stakeholder:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, Reader, [vesterAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);
  const feeRewardTokenInfo = getTokenInfo(infoTokens, feeRewardTokenAddress);
  const feeRewardTokenPrice = feeRewardTokenInfo.minPrice;

  const { fxdxPrice } = useFxdxPrice(chainId, { arbitrum: chainId === ARBITRUM ? signer : undefined }, active);

  const fxdxSupplyUrl = getServerUrl(chainId, "/fxdx_supply");
  const { data: fxdxSupply } = useSWR([fxdxSupplyUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  const aums = getAums(chainId, infoTokens, aumAddition, aumDeduction);

  let aum;
  if (aums && aums.length === 2 && aums[0] && aums[1]) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    feeRewardTokenPrice,
    feeRewardTokenInfo.decimals,
    stakedFxdxSupply,
    fxdxPrice,
    fxdxSupply
  );

  const statsUrl = `${getServerBaseUrl(chainId)}/user_liquidity_stat?account=${account}`;
  const { data: statsData } = useSWR(account ? [statsUrl] : undefined, {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const feeTokenInfo = getTokenInfo(infoTokens, feeRewardTokenAddress);

  const summaryData = getAccountLiquiditySummary(statsData, processedData, fxdxPrice, feeTokenInfo);

  return (
    <div className="Actions liquidity">
      {account.length > 0 && (
        <div className="Actions-section">
          <Trans>Account</Trans>: {account}
        </div>
      )}
      {account.length > 0 && !!summaryData && (
        <div className="Actions-section">
          <div className="Actions-title summary-title">
            <Trans>Summary</Trans>
          </div>
          <div>
            <div className="summary-row">
              <div className="summary-label">
                <Trans>FLP Balance</Trans>
              </div>
              <div className="summary-value">
                {formatAmount(summaryData.flpBalance, FLP_DECIMALS, 2, true)} FLP ($
                {formatAmount(summaryData.flpBalanceUsd, USD_DECIMALS, 2, true)})
              </div>
            </div>
            <div className="summary-row">
              <div className="summary-label">
                <Trans>Rewards Accrued</Trans>
              </div>
              <div className="summary-value">
                <Tooltip
                  position="right-bottom"
                  handle={`$${formatAmount(summaryData.rewardsAccured.totalUsd, USD_DECIMALS, 2, true)}`}
                  renderContent={() => (
                    <>
                      {t`Rewards Accrued: Claimed + Unclaimed Rewards`}
                      <br />
                      <br />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fee (${feeTokenInfo.symbol})`}
                        value={`${formatAmount(
                          summaryData.rewardsAccured.fee,
                          feeTokenInfo.decimals,
                          2,
                          true
                        )} ($${formatAmount(summaryData.rewardsAccured.feeUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fxdx`}
                        value={`${formatAmount(
                          summaryData.rewardsAccured.fxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.rewardsAccured.fxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`esFxdx`}
                        value={`${formatAmount(
                          summaryData.rewardsAccured.esFxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.rewardsAccured.esFxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Multiplier Points`}
                        value={`${formatAmount(summaryData.rewardsAccured.bnFxdx, FXDX_DECIMALS, 2, true)}`}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="summary-row">
              <div className="summary-label">
                <Trans>Claimed Rewards</Trans>
              </div>
              <div className="summary-value">
                <Tooltip
                  position="right-bottom"
                  handle={`$${formatAmount(summaryData.claimedRewards.totalUsd, USD_DECIMALS, 2, true)}`}
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fee (${feeTokenInfo.symbol})`}
                        value={`${formatAmount(
                          summaryData.claimedRewards.fee,
                          feeTokenInfo.decimals,
                          2,
                          true
                        )} ($${formatAmount(summaryData.claimedRewards.feeUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fxdx`}
                        value={`${formatAmount(
                          summaryData.claimedRewards.fxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.claimedRewards.fxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`esFxdx`}
                        value={`${formatAmount(
                          summaryData.claimedRewards.esFxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.claimedRewards.esFxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Multiplier Points`}
                        value={`${formatAmount(summaryData.claimedRewards.bnFxdx, FLP_DECIMALS, 2, true)}`}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="summary-row">
              <div className="summary-label">
                <Trans>Unclaimed Rewards</Trans>
              </div>
              <div className="summary-value">
                <Tooltip
                  position="right-bottom"
                  handle={`$${formatAmount(summaryData.unclaimedRewards.totalUsd, USD_DECIMALS, 2, true)}`}
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fee (${feeTokenInfo.symbol})`}
                        value={`${formatAmount(
                          summaryData.unclaimedRewards.fee,
                          feeTokenInfo.decimals,
                          2,
                          true
                        )} ($${formatAmount(summaryData.unclaimedRewards.feeUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Fxdx`}
                        value={`${formatAmount(
                          summaryData.unclaimedRewards.fxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.unclaimedRewards.fxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`esFxdx`}
                        value={`${formatAmount(
                          summaryData.unclaimedRewards.esFxdx,
                          FXDX_DECIMALS,
                          2,
                          true
                        )} ($${formatAmount(summaryData.unclaimedRewards.esFxdxUsd, USD_DECIMALS, 2, true)})`}
                      />
                      <StatsTooltipRow
                        showDollar={false}
                        label={t`Multiplier Points`}
                        value={`${formatAmount(summaryData.unclaimedRewards.bnFxdx, FXDX_DECIMALS, 2, true)}`}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="summary-row">
              <div className="summary-label">
                <Trans>PnL of LP</Trans>
              </div>
              <div className="summary-value">
                {summaryData.pnl.lt(0) ? "-" : ""}$
                {formatAmount(summaryData.pnl.mul(summaryData.pnl.lt(0) ? -1 : 1), USD_DECIMALS, 2, true)}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="Actions-section">
        <div className="Actions-title">
          <Trans>Liquidity Actions</Trans>
        </div>
        <TradeHistory
          account={account}
          infoTokens={infoTokens}
          getTokenInfo={getTokenInfo}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          shouldShowPaginationButtons={true}
          type="liquidity_actions"
          isActionPage={true}
        />
      </div>
    </div>
  );
}
