import React, { useCallback, useState } from "react";
import { helperToast } from "lib/helperToast";
import "./EmailSubscribe.css";
import { isValidEmail } from "lib/emailValidation";
import { useChainId } from "lib/chains";
import { getServerBaseUrl } from "config/backend";
import { useLocalStorage } from "react-use";
import { useAccount } from "wagmi";

const EmailSubscribe = ({ className }) => {
  const { address: account } = useAccount();
  const { chainId } = useChainId();

  const [email, setEmail] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [savedEmail, setSavedEmail] = useLocalStorage("email", "");

  const subscribeEmail = useCallback(() => {
    const isValid = isValidEmail(email);

    if (isValid) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, account: account || "" }),
      };

      setIsSubmitting(true);
      fetch(`${getServerBaseUrl(chainId)}/emails/subscribe`, requestOptions)
        .then((response) => {
          setIsSubmitting(false);
          if (!response.ok) {
            helperToast.error("Something went wrong. Please try again.");
            return;
          }

          helperToast.success("Email was subscribed successfully");
          setSavedEmail(email);
        })
        .catch((err) => {
          setIsSubmitting(false);
          helperToast.error("Something went wrong. Please try again.");
          console.log(err);
        });
    } else {
      helperToast.error("Invalid Email");
    }
  }, [account, chainId, email, setSavedEmail]);

  if (savedEmail) {
    return null;
  }

  return (
    <div className={["subscription-email-wrapper", className].join(" ")}>
      <input
        type="email"
        placeholder="Subscribe to our mailing list"
        value={email}
        onChange={(e) => {
          setEmail((e.target.value || "").trim());
        }}
        readOnly={isSubmitting}
      />
      <button className="submit-btn" onClick={subscribeEmail} disabled={isSubmitting}>
        Submit
      </button>
    </div>
  );
};

export default EmailSubscribe;
