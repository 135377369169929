import React, { useState, useCallback } from "react";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";

import {
  SWAP,
  INCREASE,
  DECREASE,
  USD_DECIMALS,
  getOrderError,
  getExchangeRateDisplay,
  getExchangeRate,
  getPositionForOrder,
} from "lib/legacy.js";
import { handleCancelOrder } from "domain/legacy";
import { getContract } from "config/contracts";

import Tooltip from "../Tooltip/Tooltip";
import OrderEditor from "./OrderEditor";

import "./OrdersList.css";
import CheckboxNoToggle from "../Checkbox/CheckboxNoToggle";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { formatAmount, formatPrice } from "lib/numbers";
import useWallet from "lib/wallets/useWallet";

export default function OrdersList(props) {
  const {
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    totalTokenWeights,
    usdfSupply,
    orders,
    hideActions,
    chainId,
    savedShouldDisableValidationForTesting,
    cancelOrderIdList,
    setCancelOrderIdList,
    isActionPage,
  } = props;

  const { address: account, signer } = useWallet();
  const [editingOrder, setEditingOrder] = useState(null);

  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, signer, order, { pendingTxns, setPendingTxns });
    },
    [signer, pendingTxns, setPendingTxns, chainId]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  const renderHead = useCallback(() => {
    const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length;

    return (
      <tr className="">
        {orders.length > 0 && !isActionPage && (
          <th>
            <div className="checkbox-inline ">
              <CheckboxNoToggle
                isChecked={isAllOrdersSelected}
                setIsChecked={() => {
                  if (isAllOrdersSelected) {
                    setCancelOrderIdList([]);
                  } else {
                    const allOrderIds = orders.map((o) => `${o.type}-${o.index}`);
                    setCancelOrderIdList(allOrderIds);
                  }
                }}
              />
            </div>
          </th>
        )}
        <th>
          <div>
            <Trans>Order</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Type</Trans>
          </div>
        </th>

        <th>
          <div>
            <Trans>Instrument</Trans>
          </div>
        </th>
        {/* <th>
          <div>
            <Trans>Direction</Trans>
          </div>
        </th> */}
        <th>
          <div>
            <Trans>Size</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Collateral</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Trigger Price</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Mark Price</Trans>
          </div>
        </th>
        {!hideActions && (
          <th colSpan="2">
            <div>
              <Trans>Actions</Trans>
            </div>
          </th>
        )}
      </tr>
    );
  }, [cancelOrderIdList?.length, hideActions, isActionPage, orders, setCancelOrderIdList]);

  const renderEmptyRow = useCallback(() => {
    if (orders && orders.length) {
      return null;
    }

    return (
      <tr>
        <td className="no-open-orders">
          <Trans>No open orders</Trans>
        </td>
      </tr>
    );
  }, [orders]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td>
            <button className="Exchange-list-action" onClick={() => onEditClick(order)}>
              <Trans>Edit</Trans>
            </button>
          </td>
          <td>
            <button className="Exchange-list-action" onClick={() => onCancelClick(order)}>
              <Trans>Cancel</Trans>
            </button>
          </td>
        </>
      );
    },
    [onEditClick, onCancelClick]
  );

  const renderLargeList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        // const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);
        const orderId = `${order.type}-${order.index}`;

        // const indexTokenMarkPrice = useMemo(() => {
        //   if (order.type === SWAP) {
        //     return;
        //   }
        //   const toTokenInfo = getTokenInfo(infoTokens, order.indexToken);
        //   return order.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
        // }, [infoTokens, order]);

        return (
          <tr className="Exchange-list-item" key={orderId}>
            {!isActionPage && (
              <td>
                <div className="checkbox-inline ">
                  <CheckboxNoToggle
                    isChecked={cancelOrderIdList?.includes(orderId)}
                    setIsChecked={() => {
                      setCancelOrderIdList((prevState) => {
                        if (prevState.includes(orderId)) {
                          return prevState.filter((i) => i !== orderId);
                        } else {
                          return prevState.concat(orderId);
                        }
                      });
                    }}
                  />
                </div>
              </td>
            )}
            <td className="Exchange-list-item-type">
              <Trans>Limit</Trans>
            </td>
            <td>
              <Trans>Swap</Trans>{" "}
              {/* {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdf ? 2 : 4,
                true
              )}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdf ? 2 : 4,
                true
              )}{" "}
              {toTokenInfo.symbol} */}
            </td>
            <td>{getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, true)}</td>
            <td>
              <Tooltip
                handle={`${formatAmount(
                  order.amountIn,
                  fromTokenInfo.decimals,
                  fromTokenInfo.isStable || fromTokenInfo.isUsdf ? 2 : 4,
                  true
                )}
                ${fromTokenInfo.symbol}`}
                renderContent={() => t`
                  You will receive atleast ${formatAmount(
                    order.minOut,
                    toTokenInfo.decimals,
                    toTokenInfo.isStable || toTokenInfo.isUsdf ? 2 : 4,
                    true
                  )} ${
                  toTokenInfo.symbol
                } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                `}
              />
            </td>
            <td>-</td>
            <td>
              {/*Swap will be executed at a trigger ratio of ${
                       formatPrice(order.triggerRatio, true)} meaning */}
              {fromTokenInfo.isStable ? (
                `$ ${formatPrice(order.triggerRatio, true)}`
              ) : (
                <Tooltip
                  handle={formatPrice(order.triggerRatio, true)}
                  renderContent={() => t`

                     ${formatAmount(
                       order.amountIn,
                       fromTokenInfo.decimals,
                       fromTokenInfo.isStable || fromTokenInfo.isUsdf ? 2 : 4,
                       true
                     )}
                    ${fromTokenInfo.symbol} at price of
                    ${parseFloat(
                      formatPrice(order.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice) /
                        formatPrice(order.triggerRatio, true)
                    ).toFixed(2)} USD
                    will be swapped to
                    ${formatAmount(
                      order.minOut,
                      toTokenInfo.decimals,
                      toTokenInfo.isStable || toTokenInfo.isUsdf ? 2 : 4,
                      true
                    )}
                    ${toTokenInfo.symbol}
                  `}
                />
              )}
            </td>
            {/* <td>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, false)}</td> */}
            <td>{formatPrice(order.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice)}</td>
            {!hideActions && renderActions(order)}
          </tr>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);

      // Longs Increase: max price
      // Longs Decrease: min price
      // Short Increase: min price
      // Short Decrease: max price
      // const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);

      // const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;
      const toTokenInfo = getTokenInfo(infoTokens, order.indexToken);

      const error = getOrderError(account, order, positionsMap);
      const orderId = `${order.type}-${order.index}`;
      const orderText = error ? (
        <Tooltip
          handle={order.type === INCREASE ? t`Increase` : t`Decrease`}
          position="left"
          handleClassName="Exchange-list-item-error"
          renderContent={() => {
            return <Trans>{error}</Trans>;
          }}
        />
      ) : (
        <>
          {order.type === INCREASE ? t`Increase` : t`Decrease`}
          {/* &nbsp;by  */}
          {/* {error && <div className="Exchange-list-item-error">{error}</div>} */}
        </>
      );

      return (
        <tr className="Exchange-list-item" key={`${order.isLong}-${order.type}-${order.index}`}>
          {!isActionPage && (
            <td className="Exchange-list-item-type">
              <div>
                <CheckboxNoToggle
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId);
                      } else {
                        return prevState.concat(orderId);
                      }
                    });
                  }}
                />
              </div>
            </td>
          )}
          <td className="Exchange-list-item-type">{order.type === INCREASE ? t`Limit` : t`Trigger`}</td>
          <td>
            {/* {order.type === DECREASE ? ( */}
            {orderText}
            {/* ) : (
              <Tooltip
                handle={orderText}
                position="right-bottom"
                renderContent={() => {
                  const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
                  const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);
                  return (
                    <StatsTooltipRow
                      label={t`Collateral`}
                      value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                        order.purchaseTokenAmount,
                        collateralTokenInfo.decimals,
                        4,
                        true
                      )}
                      ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})`}
                    />
                  );
                }}
              />
            )} */}
          </td>
          <td>
            {indexTokenSymbol} {order.isLong ? t`Long` : t`Short`}
          </td>

          <td className="plain-price">
            {order.type !== DECREASE
              ? `$${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}`
              : `$${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}`}
          </td>
          <td className="plain-price">
            {order.type !== DECREASE ? (
              <Trans>
                $
                {formatAmount(
                  getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens),
                  USD_DECIMALS,
                  2,
                  true
                )}
                &nbsp;(
                {formatAmount(
                  order.purchaseTokenAmount,
                  getTokenInfo(infoTokens, order.purchaseToken).decimals,
                  4,
                  true
                )}
                &nbsp;
                {getTokenInfo(infoTokens, order.purchaseToken).baseSymbol ||
                  getTokenInfo(infoTokens, order.purchaseToken).symbol}
                )
              </Trans>
            ) : (
              "-"
            )}
          </td>
          <td className="plain-price">
            {triggerPricePrefix} {formatPrice(order.triggerPrice, true)}
          </td>
          <td>
            {formatPrice(order.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice)}
            {/* <Tooltip
              handle=
              position="right-bottom"
              renderContent={() => {
                return (
                  <Trans>
                    The price that orders can be executed at may differ slightly from the chart price, as market orders
                    update oracle prices, while limit/trigger orders do not.
                  </Trans>
                );
              }}
            /> */}
          </td>
          {!hideActions && renderActions(order)}
        </tr>
      );
    });
  }, [
    orders,
    infoTokens,
    account,
    positionsMap,
    isActionPage,
    cancelOrderIdList,
    hideActions,
    renderActions,
    chainId,
    setCancelOrderIdList,
  ]);

  const renderSmallList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );
        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);

        return (
          <div key={`${order.type}-${order.index}`} className="App-card">
            <div className="App-card-title-small">
              Swap {formatAmount(order.amountIn, fromTokenInfo.decimals, fromTokenInfo.isStable ? 2 : 4, true)}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(order.minOut, toTokenInfo.decimals, toTokenInfo.isStable ? 2 : 4, true)}{" "}
              {toTokenInfo.symbol}
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>
                  <Tooltip
                    position="right-bottom-right"
                    handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, false)}
                    renderContent={() => t`
                    You will receive atleast ${formatAmount(
                      order.minOut,
                      toTokenInfo.decimals,
                      toTokenInfo.isStable || toTokenInfo.isUsdf ? 2 : 4,
                      true
                    )} ${
                      toTokenInfo.symbol
                    } if this order is executed. The exact execution price may vary depending on fees at the time the order is executed.
                  `}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Mark Price</Trans>
                </div>
                <div>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, false)}</div>
              </div>
              {!hideActions && (
                <>
                  <div className="App-card-divider"></div>
                  <div className="App-card-options">
                    <button className="App-button-option App-card-option" onClick={() => onEditClick(order)}>
                      <Trans>Edit</Trans>
                    </button>
                    <button className="App-button-option App-card-option" onClick={() => onCancelClick(order)}>
                      <Trans>Cancel</Trans>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, true, infoTokens);

      const error = getOrderError(account, order, positionsMap);

      return (
        <div key={`${order.isLong}-${order.type}-${order.index}`} className="App-card">
          <div className="App-card-title-small">
            {order.type === INCREASE ? t`Increase` : t`Decrease`} {indexTokenSymbol} {order.isLong ? t`Long` : t`Short`}
            {error && <div className="Exchange-list-item-error">{error}</div>}
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <Trans>Size</Trans>
              </div>
              <div>${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div>
                {triggerPricePrefix} {formatPrice(order.triggerPrice, true)}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Mark Price</Trans>
              </div>
              <div>
                <Tooltip
                  handle={formatPrice(markPrice, true)}
                  position="right-bottom-right"
                  renderContent={() => {
                    return (
                      <Trans>
                        The price that the order can be executed at may differ slightly from the chart price as market
                        orders can change the price while limit / trigger orders cannot.
                      </Trans>
                    );
                  }}
                />
              </div>
            </div>
            {order.type === INCREASE && (
              <div className="App-card-row">
                <div className="label">
                  <Trans>Collateral</Trans>
                </div>
                <div>
                  ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                  {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{" "}
                  {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                </div>
              </div>
            )}
            {!hideActions && (
              <>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  <button className="App-button-option App-card-option" onClick={() => onEditClick(order)}>
                    <Trans>Edit</Trans>
                  </button>
                  <button className="App-button-option App-card-option" onClick={() => onCancelClick(order)}>
                    <Trans>Cancel</Trans>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  }, [orders, onEditClick, onCancelClick, infoTokens, positionsMap, hideActions, chainId, account]);

  return (
    <React.Fragment>
      <table className={cx("Exchange-list Orders App-box large", { "Orders-overview-table": isActionPage })}>
        <thead className="Exchange-list-header">{renderHead()}</thead>
        <tbody>
          {renderEmptyRow()}
          {renderLargeList()}
        </tbody>
      </table>
      <div className="Exchange-list Orders small">
        {(!orders || orders.length === 0) && (
          <div className="Exchange-empty-positions-list-note App-card">
            <Trans>No open orders</Trans>
          </div>
        )}
        {renderSmallList()}
      </div>
      {editingOrder && (
        <OrderEditor
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          totalTokenWeights={totalTokenWeights}
          usdfSupply={usdfSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        />
      )}
    </React.Fragment>
  );
}
