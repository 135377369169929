import React, { useState, useEffect } from "react";
import "./mobilestyle.css";
// import search from "../../img/search.svg";
import { getUserWinRatio, getUserTrades } from "./data";
import MobileCardLeaderBoard from "./MobileCardLeaderBoard";

const LeaderBoardMobile = ({ period, chainid }) => {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(false)
  // const [searchInput, setSearchInput] = useState("");
  const usersPerPage = 10;
  const previousClass = currentPage === 1 ? "previous" : "previous";
  const nextClass = "next";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true)
        let userTradeData;
        // let aggregatedDataWithWinLoss;
        userTradeData = await getUserTrades(chainid, period);
        // aggregatedDataWithWinLoss = await getUserWinRatio(userTradeData, chainid);

        for (let i = 0; i < userTradeData?.length; i++) {
          userTradeData[i].rank = i + 1;
        }
        setUserData(userTradeData);
        setloading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    };

    fetchData();
  }, [chainid, period]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = userData
  // const filteredUsers = userData.filter((user) => user.account.toLowerCase().includes(searchInput.toLowerCase()));

  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="leaderboard-contains">
      {loading ? (
        <div className="loading-cnt">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="leaderboard">
          <div>
            {/* <div className="mobilesearch">
              <div className="searchicon">
                <img className="vector" alt="Vector" src={search} />
              </div>
              <input
                type="text"
                className="text-wrapper"
                placeholder="Search by address "
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div> */}
            <div className="mob-container">
              {currentUsers.map((user, index) => (
                <MobileCardLeaderBoard user={user} key={`mbl-card-${index}`} />
              ))}
            </div>
          </div>
          <div className="pagination">
            <div className="page">
              {" "}
              Page {currentPage} to {Math.ceil(userData.length / usersPerPage)}
            </div>
            <div className="nav-page">
              <div className="previousbutton">
                <button
                  className={previousClass}
                  onClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                  disabled={currentPage === 1}
                >
                  &#8249;
                </button>
                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </button>
              </div>
              <div className="nextbutton">
                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastUser >= userData.length}>
                  Next
                </button>
                <button
                  className={nextClass}
                  onClick={() => {
                    if (indexOfLastUser < userData.length) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  disabled={indexOfLastUser >= userData.length}
                >
                  &#8250;
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default LeaderBoardMobile;
