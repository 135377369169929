import { useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { BiEditAlt } from "react-icons/bi";
import Card from "../Common/Card";
import Modal from "../Modal/Modal";
import Tooltip from "../Tooltip/Tooltip";
import { shortenAddress } from "lib/legacy";
import EmptyMessage from "./EmptyMessage";
import InfoCard from "./InfoCard";
import { getTierIdDisplay, getUSDValue, tierDiscountInfo } from "./referralsHelper";
import { ReferralCodeForm } from "./JoinReferralCode";
import { getExplorerUrl } from "config/chains";
import { formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";

function TradersStats({ referralsData, traderTier, chainId, userReferralCodeString, setPendingTxns, pendingTxns }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const editModalRef = useRef(null);

  const open = () => setIsEditModalOpen(true);
  const close = () => setIsEditModalOpen(false);
  return (
    <div className="rebate-container">
      <div className="App-card-divider section-link"></div>

      <div className="section-link-container">
        <a href="#total-stats">Stats</a>
        {referralsData?.discountDistributions.length > 0 > 0 ? (
          <a href="#rebates">Rebates</a>
        ) : (
          <a href="#no-rebates">Rebates</a>
        )}
        <a href="#how">How it works</a>
      </div>

      <h2 className="Page-title" id="total-stats">
        Total Stats
      </h2>
      <div className="referral-stats">
        <InfoCard
          label={t`Total Trading Volume`}
          tooltipText={t`Volume traded by this account with an active referral code.`}
          data={getUSDValue(referralsData?.referralTotalStats?.volume)}
        />
        <InfoCard
          label={t`Total Rebates`}
          tooltipText={t`Rebates earned by this account as a trader.`}
          data={getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
        />
        <InfoCard
          label={t`Active Referral Code`}
          data={
            <div className="active-referral-code">
              <div className="edit">
                <span>{userReferralCodeString}</span>
                <BiEditAlt onClick={open} />
              </div>
              {traderTier && (
                <div className="tier">
                  <Tooltip
                    handle={t`Tier ${getTierIdDisplay(traderTier)} (${tierDiscountInfo[traderTier]}% discount)`}
                    position="right-bottom"
                    renderContent={() => (
                      <p className="text-white">
                        <Trans>
                          You will receive a {tierDiscountInfo[traderTier]}% discount on your funding fees, this
                          discount will be airdropped to your account every Monday
                        </Trans>
                      </p>
                    )}
                  />
                </div>
              )}
            </div>
          }
        />
        <Modal
          className="Connect-wallet-modal"
          isVisible={isEditModalOpen}
          setIsVisible={close}
          label={t`Edit Referral Code`}
          onAfterOpen={() => editModalRef.current?.focus()}
        >
          <div className="edit-referral-modal">
            <ReferralCodeForm
              userReferralCodeString={userReferralCodeString}
              setPendingTxns={setPendingTxns}
              pendingTxns={pendingTxns}
              type="edit"
              callAfterSuccess={() => setIsEditModalOpen(false)}
            />
          </div>
        </Modal>
      </div>

      {/* <h2 className="Page-title" id="rebates">Rebates Distribution</h2> */}
      {referralsData?.discountDistributions.length > 0 ? (
        <div className="reward-history">
          <Card title={t`Rebates Distribution History`} tooltipText={t`Rebates are airdropped weekly.`}>
            <div className="table-wrapper">
              <table className="referral-table">
                <thead>
                  <tr>
                    <th className="table-head" scope="col">
                      <Trans>Date</Trans>
                    </th>
                    <th className="table-head" scope="col">
                      <Trans>Amount</Trans>
                    </th>
                    <th className="table-head" scope="col">
                      <Trans>Transaction</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {referralsData?.discountDistributions.map((rebate, index) => {
                    let tokenInfo;
                    try {
                      tokenInfo = getToken(chainId, rebate.token);
                    } catch {
                      tokenInfo = getNativeToken(chainId);
                    }
                    const explorerURL = getExplorerUrl(chainId);
                    return (
                      <tr key={index}>
                        <td data-label="Date">{formatDate(rebate.timestamp)}</td>
                        <td data-label="Amount">
                          {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                        </td>
                        <td data-label="Transaction">
                          <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                            {shortenAddress(rebate.transactionHash, 20)}
                          </ExternalLink>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      ) : (
        <div className="reward-history" id="no-rebates">
          <h4 className="Page-title">Rebates Distribution</h4>
          <EmptyMessage
            message={t`No rebates distribution history yet. Rebates are airdropped weekly`}
            // tooltipText={t`Rebates are airdropped weekly.`}
          />
        </div>
      )}

      <h2 className="Page-title" id="how">
        How it works
      </h2>

      <div className="referral-info-container">
        <div className="tiers-container">
          <div className="tier">
            <h4>Tier 1</h4>
            <p>for Traders : 5% discount</p>
            <p>to Referrer : 5% rebates</p>
          </div>
          <div className="tier">
            <h4>Tier 2</h4>
            <p>for Traders : 10% discount</p>
            <p>to Referrer : 10% rebates</p>
          </div>
          <div className="tier">
            <h4>Tier 3</h4>
            <p>for Traders : 10% discount</p>
            <p>
              to Referrer : &nbsp;
              <span>
                <Tooltip
                  handle={<p>15% rebates</p>}
                  position="center-bottom"
                  renderContent={() => "15% rebates in ETH + 5% rebates in esFXDX"}
                />
              </span>
            </p>
          </div>
        </div>

        <div className="tier-info">
          <p>Anyone can create a Tier 1 code. To upgrade your code to Tier 2 or Tier 3:</p>
          <p>
            Tier 2: At least 15 active users using your referral codes per week and a combined weekly volume above $5
            million
          </p>
          <p>
            Tier 3: At least 30 active users using your referral codes per week and a combined weekly volume above $25
            million
          </p>
        </div>

        {/* <div className="contact-info">
        <p>If your account fulfils this criteria, please submit the application form at https://forms.gle/8QF2bxdYhUHNo7c96.</p>
        <p>After filling in the form please send a dm to @GMXPartners so that we can follow up.</p>
      </div> */}
      </div>
    </div>
  );
}

export default TradersStats;
