import React from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { isHomeSite } from "lib/legacy";
// import logoImg from "img/logo.png";
import logoImg from "img/NEW_LOGO_LIGHT.svg";
import { BASE, IS_TESTNET } from "config/chains";
import { useChainId } from "lib/chains";
import NavItem from "components/NavItem/NavItem";
import { props , referalnew } from "./HeaderContent";


type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export default function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();

  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={logoImg} alt="FXDX Logo" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {/*
        <div className="App-header-link-container">
          <HeaderLink
            to="/overview"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Overview</Trans>
          </HeaderLink>
        </div>
      */}

      {/*
      <div className="App-header-link-container">
        <HeaderLink to="/swap" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Spot (swap)</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink to="/trade" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Futures (perp)</Trans>
        </HeaderLink>
      </div>
      */}

      {props.map((item, index) => {
        return (
          <div className="App-header-link-container" key={index}>
            <NavItem
              props={item}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            />
          </div>
        );
      })}
      {/* <div className="App-header-link-container">
        <HeaderLink to="/earn" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Account</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink to="/buy" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Stake</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/referrals"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Referral</Trans>
        </HeaderLink>
      </div> */}
      {chainId === BASE && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/launch"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>
              {" "}
              <p className="spl-text"> Launch </p>
            </Trans>
          </HeaderLink>
        </div>
      )}

      {/* {!IS_TESTNET && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/leaderboard"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Leaderboard</Trans>
          </HeaderLink>
        </div>
      )} */}
      {IS_TESTNET && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/faucet"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Faucet</Trans>
          </HeaderLink>
        </div>
      )}
      {/* { referalnew.map((item, index) => {
        return (
          <div className="App-header-link-container" key={index}>
            <NavItem
              props={item}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            />
          </div>
        );
      })} */}
       {/* {!IS_TESTNET && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/Referral"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Referral</Trans>
          </HeaderLink>
        </div>
      )}

      {!IS_TESTNET && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/UnknownEvent"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>UnknownEvent</Trans>
          </HeaderLink>
        </div>
      )} */}

      {!IS_TESTNET && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/bridge"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Bridge</Trans>
          </HeaderLink>
        </div>
      )}
      {small && (
        <div className="App-header-link-container feedback-btn-drawer">
          <a href="https://fxdx.canny.io/public-mainnet-feedback" rel="noreferrer" target="_blank">
            <Trans>Feedback</Trans>
          </a>
        </div>
      )}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/ecosystem"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Ecosystem</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <ExternalLink href="https://fxdx.gitbook.io/fxdx/">
          <Trans>About</Trans>
        </ExternalLink>
      </div> */}
      {small && !isHomeSite() && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
    </div>
  );
}
