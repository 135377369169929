import Tooltip from "../Tooltip/Tooltip";
import "./Card.css";

function Card({ title, children, className, tooltipText }) {
  return (
    <div className={`card ${className ? className : ""}`}>
      <div className="card-header">
        {tooltipText ? <Tooltip handle={title} position="left-bottom" renderContent={() => tooltipText} /> : { title }}
      </div>
      <div className="card-divider"></div>
      <div className="card-body">{children}</div>
    </div>
  );
}

export default Card;
