import "./fxdxstakehis.css"
import stakedataimg from "./assets/stakehistorynewdata.png";


const Stakehistorynew = () => {

    const navigateToTrade = () => {
        window.location.href = '/stakefxdx';
      };
    return (
        <>
         <div className="stake-history-new-all">
            <div className="stake-history-new-heading">
                 <h1>Stake History</h1>
            </div>
            <div className="stake-history-new-table">
               <div>
                <div>
                    <div className="all-text-data">
                        <div className="table-one-stake">STAKE#</div>
                        <div className="table-one-stake">ACCOUNT</div>
                        <div className="table-one-stake">STAKE AMOUNT</div>
                        <div className="table-one-stake">STAKED AT</div>
                        <div className="table-one-stake">STAKED TILL</div>
                        <div className="table-one-stake">REWARD AMOUNT</div>
                        <div className="table-one-stake">ACTIONS</div>

                          <div className="table-one-stake-mobile-view">
                        <div className="table-one-stake-mobile">STAKE#</div>
                        <div className="table-one-stake-mobile">ACCOUNT</div>
                        <div className="table-one-stake-mobile">STAKE AMOUNT</div>
                        </div>

                   </div>
                   <div className="all-table-data-img">
                     <img src={stakedataimg} alt="stakehistorynewdata" />
                   </div>
                     <div className="stake-history-click-old-aligh">
                   <div className="stake-history-click-old"  onClick={navigateToTrade}>
                   
                    <h4>View History</h4>
                    
                    </div>
                    </div>
                </div>
               </div>
            </div>
         </div>
        </>
     )
}
export default Stakehistorynew;