import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { ethers } from "ethers";

import { getContract } from "config/contracts";

import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";

import Token from "abis/Token.json";
import Vester from "abis/Vester.json";
import RewardTracker from "abis/RewardTracker.json";
import RewardRouter from "abis/RewardRouter.json";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { TfiHandPointRight } from "react-icons/tfi";

import { Trans, t } from "@lingui/macro";

import "./BeginAccountTransfer.css";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens } from "domain/tokens";
import useWallet from "lib/wallets/useWallet";

function ValidationRow({ isValid, children }) {
  return (
    <div className="ValidationRow">
      <div className="ValidationRow-icon-container">
        {isValid && <FaCheckCircle className="ValidationRow-icon" color="#91E49E" />}
        {!isValid && <FaTimesCircle className="ValidationRow-icon" color="#F56868" />}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default function BeginAccountTransfer(props) {
  const { setPendingTxns } = props;
  const { isConnected: active, address: account, signer, chainId } = useWallet();

  const [receiver, setReceiver] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isTransferSubmittedModalVisible, setIsTransferSubmittedModalVisible] = useState(false);
  let parsedReceiver = ethers.constants.AddressZero;
  if (ethers.utils.isAddress(receiver)) {
    parsedReceiver = receiver;
  }

  const fxdxAddress = getContract(chainId, "FXDX");
  const fxdxVesterAddress = getContract(chainId, "FxdxVester");
  const flpVesterAddress = getContract(chainId, "FlpVester");

  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const { data: fxdxVesterBalance } = useSWR([active, chainId, fxdxVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(signer, Token),
  });

  const { data: flpVesterBalance } = useSWR([active, chainId, flpVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(signer, Token),
  });

  const stakedFxdxTrackerAddress = getContract(chainId, "StakedFxdxTracker");
  const { data: cumulativeFxdxRewards } = useSWR(
    [active, chainId, stakedFxdxTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(signer, RewardTracker),
    }
  );

  const stakedFlpTrackerAddress = getContract(chainId, "StakedFlpTracker");
  const { data: cumulativeFlpRewards } = useSWR(
    [active, chainId, stakedFlpTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(signer, RewardTracker),
    }
  );

  const { data: transferredCumulativeFxdxRewards } = useSWR(
    [active, chainId, fxdxVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(signer, Vester),
    }
  );

  const { data: transferredCumulativeFlpRewards } = useSWR(
    [active, chainId, flpVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(signer, Vester),
    }
  );

  const { data: pendingReceiver } = useSWR([active, chainId, rewardRouterAddress, "pendingReceivers", account], {
    fetcher: contractFetcher(signer, RewardRouter),
  });

  const { data: fxdxAllowance } = useSWR(
    [active, chainId, fxdxAddress, "allowance", account, stakedFxdxTrackerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: fxdxStaked } = useSWR(
    [active, chainId, stakedFxdxTrackerAddress, "depositBalances", account, fxdxAddress],
    {
      fetcher: contractFetcher(signer, RewardTracker),
    }
  );

  const needApproval = fxdxAllowance && fxdxStaked && fxdxStaked.gt(fxdxAllowance);

  const hasVestedFxdx = fxdxVesterBalance && fxdxVesterBalance.gt(0);
  const hasVestedFlp = flpVesterBalance && flpVesterBalance.gt(0);
  const hasStakedFxdx =
    (cumulativeFxdxRewards && cumulativeFxdxRewards.gt(0)) ||
    (transferredCumulativeFxdxRewards && transferredCumulativeFxdxRewards.gt(0));
  const hasStakedFlp =
    (cumulativeFlpRewards && cumulativeFlpRewards.gt(0)) ||
    (transferredCumulativeFlpRewards && transferredCumulativeFlpRewards.gt(0));
  const hasPendingReceiver = pendingReceiver && pendingReceiver !== ethers.constants.AddressZero;

  const getError = () => {
    if (!account) {
      return t`Wallet is not connected`;
    }
    if (hasVestedFxdx) {
      return t`Vested FXDX not withdrawn`;
    }
    if (hasVestedFlp) {
      return t`Vested FLP not withdrawn`;
    }
    if (!receiver || receiver.length === 0) {
      return t`Enter Receiver Address`;
    }
    if (!ethers.utils.isAddress(receiver)) {
      return t`Invalid Receiver Address`;
    }
    if (hasStakedFxdx || hasStakedFlp) {
      return t`Invalid Receiver`;
    }
    if ((parsedReceiver || "").toString().toLowerCase() === (account || "").toString().toLowerCase()) {
      return t`Self-transfer not supported`;
    }

    if (
      (parsedReceiver || "").length > 0 &&
      (parsedReceiver || "").toString().toLowerCase() === (pendingReceiver || "").toString().toLowerCase()
    ) {
      return t`Transfer already initiated`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isTransferring) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (needApproval) {
      return t`Approve FXDX`;
    }
    if (isApproving) {
      return t`Approving...`;
    }
    if (isTransferring) {
      return t`Transferring`;
    }

    return t`Begin Transfer`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        signer,
        tokenAddress: fxdxAddress,
        spender: stakedFxdxTrackerAddress,
        chainId,
      });
      return;
    }

    setIsTransferring(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, signer);

    callContract(chainId, contract, "signalTransfer", [parsedReceiver], {
      sentMsg: t`Transfer submitted!`,
      failMsg: t`Transfer failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsTransferSubmittedModalVisible(true);
      })
      .finally(() => {
        setIsTransferring(false);
      });
  };

  const completeTransferLink = `/complete_account_transfer/${account}/${parsedReceiver}`;
  const pendingTransferLink = `/complete_account_transfer/${account}/${pendingReceiver}`;

  return (
    <div className="BeginAccountTransfer Page page-layout">
      <Modal
        isVisible={isTransferSubmittedModalVisible}
        setIsVisible={setIsTransferSubmittedModalVisible}
        label={t`Transfer Submitted`}
      >
        <Trans>Your transfer has been initiated.</Trans>
        <br />
        <br />
        <Link className="App-cta" to={completeTransferLink}>
          <Trans>Continue</Trans>
        </Link>
      </Modal>
      <div className="Page-title-section">
        <div className="Page-title">
          <Trans>Transfer Account</Trans>
        </div>
        <div className="Page-description conditions">
          <Trans>
            <p>
              <TfiHandPointRight /> Please only use this for full account transfers.
            </p>
            {/* <br /> */}
            <p>
              <TfiHandPointRight /> This will transfer all your FXDX, esFXDX, FLP and Multiplier Points to your new
              account.
            </p>
            {/* <br /> */}
            <p>
              <TfiHandPointRight /> Transfers are only supported if the receiving account has not staked FXDX or FLP
              tokens before.
            </p>
            {/* <br /> */}
            <p>
              <TfiHandPointRight /> Transfers are one-way, you will not be able to transfer staked tokens back to the
              sending account.
            </p>
          </Trans>
        </div>
        {hasPendingReceiver && (
          <div className="Page-description pending-transfer">
            <Trans>
              You have a &nbsp;<Link to={pendingTransferLink}>pending transfer </Link> &nbsp; to {pendingReceiver}.
            </Trans>
          </div>
        )}
      </div>
      <div className="Page-content">
        <div className="input-form">
          <div className="input-row">
            <label className="input-label">
              <Trans>Receiver Address</Trans>
            </label>
            <div>
              <input
                type="text"
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
                className="text-input"
              />
            </div>
          </div>
          <div className="input-row">
            <button
              className="App-cta Exchange-swap-button"
              disabled={!isPrimaryEnabled()}
              onClick={() => onClickPrimary()}
            >
              {getPrimaryText()}
            </button>
          </div>
          <div className="BeginAccountTransfer-validations">
            <ValidationRow isValid={!hasVestedFxdx}>
              <Trans>Sender has withdrawn all tokens from FXDX Vesting Vault</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasVestedFlp}>
              <Trans>Sender has withdrawn all tokens from FLP Vesting Vault</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedFxdx}>
              <Trans>Receiver has not staked FXDX tokens before</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedFlp}>
              <Trans>Receiver has not staked FLP tokens before</Trans>
            </ValidationRow>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
