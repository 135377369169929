import { useChainId } from "lib/chains";
import { useAccount, useSigner } from "wagmi";

export default function useWallet() {
  const { address, isConnected, connector } = useAccount();
  const { chainId } = useChainId();
  const { data: signer } = useSigner({ chainId: chainId });

  return {
    address,
    isConnected,
    connector,
    chainId,
    signer: isConnected ? signer : undefined,
  };
}
