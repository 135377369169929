import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import TooltipComponent from "components/Tooltip/Tooltip";
import cx from "classnames";
// import { FiExternalLink } from "react-icons/fi";

import { ethers, BigNumber } from "ethers";

import { BiTimeFive } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";

import {
  USD_DECIMALS,
  FXDX_DECIMALS,
  FLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDF_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
} from "lib/legacy";
import { useTotalStakedFxdx, useFxdxPrice, useTotalFxdxStaked, useTotalFxdxSupply } from "domain/legacy";
import useFeesSummary from "domain/useFeesSummary";

import { getContract } from "config/contracts";

import Vault from "abis/Vault.json";
import Reader from "abis/Reader.json";
import FlpManager from "abis/FlpManager.json";
import Footer from "components/Footer/Footer";

import "./Dashboard.css";

// import fxdx40Icon from "img/ic_fxdx_40.svg";
// import flp40Icon from "img/ic_flp_40.svg";
import base24Icon from "img/ic_base_24.svg";
import arbitrum24Icon from "img/ic_arbitrum_24.svg";
import avalanche24Icon from "img/ic_avalanche_24.svg";
import ethereum24Icon from "img/ic_ethereum_24.svg";
import optimism24Icon from "img/ic_optimism_24.svg";
import moonbeam24Icon from "img/ic_moonbeam_24.svg";

import AssetDropdown from "./AssetDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import useTotalVolume from "domain/useTotalVolume";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import {
  BASE,
  ARBITRUM,
  AVALANCHE,
  getChainName,
  GOERLI,
  OPTIMISM_GOERLI,
  IS_TESTNET,
  // MAINNET,
  MOONBEAM,
  OPTIMISM,
} from "config/chains";
import { getServerUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getWhitelistedTokens } from "config/tokens";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, numberWithCommas } from "lib/numbers";
import { getAums } from "domain/aums";
import useWallet from "lib/wallets/useWallet";
import { clearConfigCache } from "prettier";
const ACTIVE_CHAIN_IDS = IS_TESTNET ? [/*GOERLI,*/ OPTIMISM_GOERLI] : [BASE, OPTIMISM];

const { AddressZero } = ethers.constants;

function getVolumeInfo(hourlyVolumes) {
  if (!hourlyVolumes || hourlyVolumes.length === 0) {
    return {};
  }
  const dailyVolumes = hourlyVolumes.map((hourlyVolume) => {
    const secondsPerHour = 60 * 60;
    const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;
    const info = {};
    let totalVolume = bigNumberify(0);
    for (let i = 0; i < hourlyVolume.length; i++) {
      const item = hourlyVolume[i].data;
      if (parseInt(item.timestamp) < minTime) {
        break;
      }

      if (!info[item.token]) {
        info[item.token] = bigNumberify(0);
      }

      info[item.token] = info[item.token].add(item.volume);
      totalVolume = totalVolume.add(item.volume);
    }
    info.totalVolume = totalVolume;
    return info;
  });
  return dailyVolumes.reduce(
    (acc, cv, index) => {
      acc.totalVolume = acc.totalVolume.add(cv.totalVolume);
      acc[ACTIVE_CHAIN_IDS[index]] = cv;
      return acc;
    },
    { totalVolume: bigNumberify(0) }
  );
}

function getPositionStats(positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null;
  }
  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes);
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes);
      acc[ACTIVE_CHAIN_IDS[i]] = cv;
      return acc;
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
    }
  );
}

function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.minPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.minPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
}

export default function Dashboard() {
  const { isConnected: active, signer, chainId } = useWallet();
  const totalVolume = useTotalVolume();



  const chainName = getChainName(chainId);

  const { data: positionStats } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/position_stats")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  const { data: hourlyVolumes } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/hourly_volume")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  let { total: totalFxdxSupply } = useTotalFxdxSupply(true);

  const currentVolumeInfo = getVolumeInfo(hourlyVolumes);

  const positionStatsInfo = getPositionStats(positionStats);

  function getWhitelistedTokenAddresses(chainId) {
    const whitelistedTokens = getWhitelistedTokens(chainId);
    return whitelistedTokens.map((token) => token.address);
  }

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const flpManagerAddress = getContract(chainId, "FlpManager");

  const fxdxAddress = getContract(chainId, "FXDX");
  const flpAddress = getContract(chainId, "FLP");
  const usdfAddress = getContract(chainId, "USDF");

  const tokensForSupplyQuery = [fxdxAddress, flpAddress, usdfAddress];

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(signer, Reader, [tokensForSupplyQuery]),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`FlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(signer, Vault),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);
  // const { infoTokens: infoTokensEthereum } = useInfoTokens(null, GOERLI, active, undefined, undefined);
  const { infoTokens: infoTokensOptimism } = useInfoTokens(
    null,
    IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM,
    active,
    undefined,
    undefined
  );
  const { infoTokens: infoTokensBase } = useInfoTokens(null, BASE, active, undefined, undefined);

  const networkInfoTokens = {};
  let isNetworkTokensLoaded = false;
  if (IS_TESTNET) {
    // networkInfoTokens[GOERLI] = infoTokensEthereum;
    networkInfoTokens[OPTIMISM_GOERLI] = infoTokensOptimism;
    // isNetworkTokensLoaded =
    //   infoTokensEthereum[AddressZero].contractMinPrice && infoTokensOptimism[AddressZero].contractMinPrice;
    isNetworkTokensLoaded = infoTokensOptimism[AddressZero].contractMinPrice;
  } else {
    networkInfoTokens[OPTIMISM] = infoTokensOptimism;
    networkInfoTokens[BASE] = infoTokensBase;
    isNetworkTokensLoaded =
      infoTokensOptimism[AddressZero].contractMinPrice && infoTokensBase[AddressZero].contractMinPrice;
  }

  const { data: currentFees } = useSWR(isNetworkTokensLoaded ? "Dashboard:currentFees" : null, {
    fetcher: () => {
      return Promise.all(
        ACTIVE_CHAIN_IDS.map((chainId) =>
          contractFetcher(null, Reader, [getWhitelistedTokenAddresses(chainId)])(
            `Dashboard:fees:${chainId}`,
            chainId,
            getContract(chainId, "Reader"),
            "getFees",
            getContract(chainId, "Vault")
          )
        )
      ).then((fees) => {
        return fees.reduce(
          (acc, cv, i) => {
            const feeUSD = getCurrentFeesUsd(
              getWhitelistedTokenAddresses(ACTIVE_CHAIN_IDS[i]),
              cv,
              networkInfoTokens[ACTIVE_CHAIN_IDS[i]]
            );
            acc[ACTIVE_CHAIN_IDS[i]] = feeUSD;
            acc.total = acc.total.add(feeUSD);
            return acc;
          },
          { total: bigNumberify(0) }
        );
      });
    },
  });

  const { data: feesSummaryByChain } = useFeesSummary();

  const shouldIncludeCurrrentFees =
    feesSummaryByChain[chainId].lastUpdatedAt &&
    parseInt(Date.now() / 1000) - feesSummaryByChain[chainId].lastUpdatedAt > 60 * 60;

  const totalFees = ACTIVE_CHAIN_IDS.map((chainId) => {
    if (shouldIncludeCurrrentFees && currentFees && currentFees[chainId]) {
      return currentFees[chainId].div(expandDecimals(1, USD_DECIMALS)).add(feesSummaryByChain[chainId].totalFees || 0);
    }

    return feesSummaryByChain[chainId].totalFees || 0;
  })
    .map((v) => Math.round(v))
    .reduce(
      (acc, cv, i) => {
        acc[ACTIVE_CHAIN_IDS[i]] = cv;
        acc.total = acc.total + cv;
        return acc;
      },
      { total: 0 }
    );

  const {
    fxdxPrice,
    // fxdxPriceFromEthereum,
    fxdxPriceFromBase,
    fxdxPriceFromOptimism,
    // fxdxPriceFromMoonBeam,
    /*, fxdxPriceFromArbitrum, fxdxPriceFromAvalanche */
  } = useFxdxPrice(chainId, { arbitrum: chainId === ARBITRUM ? signer : undefined }, active);

  let {
    total: totalStakedFxdx,
    // ethereum: ethereumStakedFxdx,
    optimism: optimismStakedFxdx,
    base: baseStakedFxdx,
    // moonbeam: moonbeamStakedFxdx,
    // avax: avaxStakedFxdx,
    // arbitrum: arbitrumStakedFxdx
  } = useTotalFxdxStaked();

  let {
    stakedFXDX,
    stakedFXDXUSD
  } = useTotalStakedFxdx();

  let fxdxMarketCap;
  if (fxdxPrice && totalFxdxSupply) {
    fxdxMarketCap = fxdxPrice.mul(totalFxdxSupply).div(expandDecimals(1, FXDX_DECIMALS));
  }

  let stakedFxdxSupplyUsd;
  if (fxdxPrice && totalStakedFxdx) {
    stakedFxdxSupplyUsd = totalStakedFxdx.mul(fxdxPrice).div(expandDecimals(1, FXDX_DECIMALS));
  }

  const { data: aumAddition } = useSWR([`FlpSwap:aumAddition:${active}`, chainId, flpManagerAddress, "aumAddition"], {
    fetcher: contractFetcher(signer, FlpManager),
  });

  const { data: aumDeduction } = useSWR(
    [`FlpSwap:aumDeduction:${active}`, chainId, flpManagerAddress, "aumDeduction"],
    {
      fetcher: contractFetcher(signer, FlpManager),
    }
  );

  const aums = getAums(chainId, infoTokens, aumAddition, aumDeduction);

  let aum;
  if (aums && aums.length === 2 && aums[0] && aums[1]) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let flpPrice;
  let flpSupply;
  let flpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    flpSupply = totalSupplies[3];
    flpPrice =
      aum && aum.gt(0) && flpSupply.gt(0)
        ? aum.mul(expandDecimals(1, FLP_DECIMALS)).div(flpSupply)
        : expandDecimals(1, USD_DECIMALS);
    flpMarketCap = flpPrice.mul(flpSupply).div(expandDecimals(1, FLP_DECIMALS));
  }

  let tvl;
  if (flpMarketCap && stakedFXDXUSD) {
    // console.log("flpMarketCap: ", formatAmount(flpMarketCap, USD_DECIMALS, 0));
    // console.log("stakedFXDXUSD: ", stakedFXDXUSD.toString());
    tvl = flpMarketCap.add(BigNumber.from(parseInt(stakedFXDXUSD)).mul(BigNumber.from(10).pow(USD_DECIMALS)));
    // console.log("tvl: ", formatAmount(tvl, USD_DECIMALS, 0));
    
  }

  // const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18);
  // const flpFloorPriceFund = expandDecimals(660001, 18);
  // const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30);

  // let totalFloorPriceFundUsd;

  // if (eth && eth.contractMinPrice && flpPrice) {
  //   const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
  //   const flpFloorPriceFundUsd = flpFloorPriceFund.mul(flpPrice).div(expandDecimals(1, 18));

  //   totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(flpFloorPriceFundUsd).add(usdcFloorPriceFund);
  // }

  let adjustedUsdfSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdfAmount) {
      adjustedUsdfSupply = adjustedUsdfSupply.add(tokenInfo.usdfAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdfAmount ||
      !adjustedUsdfSupply ||
      adjustedUsdfSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdfAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdfSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={t`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is below its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/buy_flp" target="_blank" rel="noopener noreferrer">
                      Mint FLP
                    </Link>{" "}
                    with {tokenInfo.symbol},&nbsp; and to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    {tokenInfo.symbol} for other tokens.
                  </Trans>
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is above its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    tokens for {tokenInfo.symbol}.
                  </Trans>
                </div>
              )}
              <br />
              <div>
                <ExternalLink href="https://fxdx.gitbook.io/fxdx/flp">
                  <Trans>More Info</Trans>
                </ExternalLink>
              </div>
            </>
          );
        }}
      />
    );
  };

  const [winWidth, setwinWidth] = useState(undefined);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    setwinWidth(windowDimenion.winWidth);
  }, [windowDimenion]);

  return (
    <SEO title={getPageTitle("Overview")}>
      <div className="default-container Dashboard page-layout">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title">
              <div>
                <Trans>Overview</Trans>
                {chainId === BASE && <img src={base24Icon} alt="base24Icon" />}
                {chainId === GOERLI && <img src={ethereum24Icon} alt="ethereum24Icon" />}
                {(chainId === OPTIMISM_GOERLI || chainId === OPTIMISM) && (
                  <img src={optimism24Icon} alt="optimism24Icon" />
                )}
                {chainId === MOONBEAM && <img src={moonbeam24Icon} alt="moonbeam24Icon" />}
              </div>

              {/* <div className="Page-description">
                For detailed stats:{"  "}&nbsp;
                {chainId === GOERLI && (
                  <ExternalLink href="https://test.stats.fxdx.exchange">
                    statistics &nbsp;
                    <FiExternalLink className="external-link" />{" "}
                  </ExternalLink>
                )}
                {chainId === OPTIMISM_GOERLI && (
                  <ExternalLink href="https://test2.stats.fxdx.exchange/optimism-goerli">
                    statistics <FiExternalLink className="external-link" />
                  </ExternalLink>
                )}
                {chainId === ARBITRUM && (
                  <ExternalLink href="https://data.fxdx.exchange/arbitrum">
                    statistics &nbsp; <FiExternalLink className="external-link" />
                  </ExternalLink>
                )}
                {chainId === AVALANCHE && (
                  <ExternalLink href="https://data.fxdx.exchange/avalanche">
                    statistics &nbsp; <FiExternalLink className="external-link" />
                  </ExternalLink>
                )}
                {chainId === MOONBEAM && (
                  <ExternalLink href="https://data.fxdx.exchange">
                    statistics &nbsp; <FiExternalLink className="external-link" />
                  </ExternalLink>
                )}
              </div> */}
            </div>

            <div className="total-containers">
              <div className="total fees">
                <div className="label totaldiv">
                  <Trans>Total fees</Trans>
                </div>
                <div className="totaldiv">
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${numberWithCommas(totalFees?.[chainId])}`}
                    renderContent={() => (
                      <StatsTooltip
                        title={t`Total Fees`}
                        // ethereumValue={totalFees?.[IS_TESTNET ? GOERLI : MAINNET]}
                        optimismValue={totalFees?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM]}
                        baseValue={totalFees?.[BASE]}
                        // moonbeamValue={totalFees?.[MOONBEAM]}
                        // arbitrumValue={totalFees?.[ARBITRUM]}
                        // avaxValue={totalFees?.[AVALANCHE]}
                        total={totalFees?.total}
                        decimalsForConversion={0}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="total volume">
                <div className="label totaldiv">
                  <Trans>
                    {" "}
                    <TooltipComponent
                      handle={`TVL`}
                      position="left-bottom"
                      renderContent={() => <span>{t`Total Value Locked`}</span>}
                    />
                  </Trans>
                </div>
                <div className="totaldiv">
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <div className="span-flex">
                        <span>Total Value Locked:</span>
                        <span>{t` FXDX staked (All chains) + FLP pool (${chainName}).`}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stats-container">
          <div className="big-stats-container">
            <div>
              <h4 className="aum">
                <TooltipComponent
                  handle={`Total Volume`}
                  position="right-bottom"
                  renderContent={() => (
                    <StatsTooltip
                      title={t`Total Volume`}
                      // ethereumValue={totalVolume?.[GOERLI]}
                      optimismValue={totalVolume?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM]}
                      baseValue={totalVolume?.[BASE]}
                      // moonbeamValue={totalVolume?.[MOONBEAM]}
                      // arbitrumValue={totalVolume?.[ARBITRUM]}
                      // avaxValue={totalVolume?.[AVALANCHE]}
                      total={totalVolume?.total}
                    />
                  )}
                />
              </h4>
              <div className="aum-amount">${formatAmount(totalVolume?.[chainId], USD_DECIMALS, 0, true)}</div>
            </div>
            <div className="small-stats-aum">
              <div className="each-aum-container">
                {/* <div className="stat-icon-background">
                  <img src="https://app.gmx.io/static/media/ic_gmx_arbitrum.d7115a71.svg" alt="" />
                </div> */}
                <div className="small-stat">
                  <h4>FXDX </h4>
                  <h3 className="amount">{`$${stakedFXDXUSD}`}</h3>
                </div>
              </div>
              <div className="each-aum-container">
                {/* <div className="stat-icon-background">
                  <img src="https://app.gmx.io/static/media/ic_glp_arbitrum.d04b88c9.svg" alt="" />
                </div> */}
                <div className="small-stat">
                  <h4>FLP </h4>
                  <h3 className="amount">{`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="small-stats-container">
            <div className="small-stat">
              <div className="stats-positions">
                <div className="stat-icon-background">
                  <AiOutlineRise size={winWidth < 480 ? "20" : "25"} />
                </div>
                <div className="position-stat-container">
                  <h4>Long Positions</h4>
                  <div className="amount">
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalLongPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Long Positions`}
                          // ethereumValue={positionStatsInfo?.[GOERLI]?.totalLongPositionSizes}
                          baseValue={positionStatsInfo?.[BASE].totalLongPositionSizes}
                          optimismValue={
                            positionStatsInfo?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM]?.totalLongPositionSizes
                          }
                          // moonbeamValue={positionStatsInfo?.[MOONBEAM]?.totalLongPositionSizes}
                          // arbitrumValue={positionStatsInfo?.[ARBITRUM].totalLongPositionSizes}
                          // avaxValue={positionStatsInfo?.[AVALANCHE].totalLongPositionSizes}
                          total={positionStatsInfo?.totalLongPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="stats-positions">
                <div className="stat-icon-background">
                  <AiOutlineFall size={winWidth < 480 ? "20" : "25"} />
                </div>
                <div className="position-stat-container">
                  <h4>Short Positions</h4>
                  <div className="amount">
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalShortPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Short Positions`}
                          // ethereumValue={positionStatsInfo?.[IS_TESTNET ? GOERLI : MAINNET]?.totalShortPositionSizes}
                          baseValue={positionStatsInfo?.[BASE]?.totalShortPositionSizes}
                          optimismValue={
                            positionStatsInfo?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM]?.totalShortPositionSizes
                          }
                          // moonbeamValue={positionStatsInfo?.[MOONBEAM]?.totalShortPositionSizes}
                          // arbitrumValue={positionStatsInfo?.[ARBITRUM].totalShortPositionSizes}
                          // avaxValue={positionStatsInfo?.[AVALANCHE].totalShortPositionSizes}
                          total={positionStatsInfo?.totalShortPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("volume-fees-stats-container", winWidth < 1100 && "small-stat")}>
              <div className={cx(winWidth < 1100 ? "stats-positions volume" : "small-stat")}>
                <div className="stats-positions">
                  <div className="stat-icon-background">
                    <BiTimeFive size={winWidth < 480 ? "20" : "25"} />
                  </div>
                </div>
                <div className="stats-positions">
                  <div className="secondary-stat">
                    <h4>24h Volume</h4>
                    <div className="amount">
                      <TooltipComponent
                        position="right-bottom"
                        className="nowrap"
                        handle={`$${formatAmount(currentVolumeInfo?.[chainId]?.totalVolume, USD_DECIMALS, 0, true)}`}
                        renderContent={() => (
                          <StatsTooltip
                            title={t`Volume`}
                            // ethereumValue={IS_TESTNET ? currentVolumeInfo?.[GOERLI].totalVolume : bigNumberify(0)}
                            baseValue={IS_TESTNET ? bigNumberify(0) : currentVolumeInfo?.[BASE].totalVolume}
                            optimismValue={currentVolumeInfo?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM].totalVolume}
                            // moonbeamValue={IS_TESTNET ? bigNumberify(0) : currentVolumeInfo?.[MOONBEAM].totalVolume}
                            // arbitrumValue={currentVolumeInfo?.[ARBITRUM].totalVolume}
                            // avaxValue={currentVolumeInfo?.[AVALANCHE].totalVolume}
                            total={currentVolumeInfo?.totalVolume}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx(winWidth < 1100 ? "stats-positions volume" : "small-stat")}>
                <div className="stats-positions">
                  <div className="stat-icon-background">
                    <BiCalendar size={winWidth < 480 ? "18" : "20"} />
                  </div>
                </div>

                <div className="stats-positions">
                  <div className="secondary-stat">
                    <h4>Fees since last update</h4>
                    <div className="amount">
                      <TooltipComponent
                        position="right-bottom"
                        className="nowrap"
                        handle={`$${formatAmount(currentFees?.[chainId], USD_DECIMALS, 2, true)}`}
                        renderContent={() => (
                          <StatsTooltip
                            title={t`Fees`}
                            // ethereumValue={currentFees?.[IS_TESTNET ? GOERLI : MAINNET]}
                            baseValue={currentFees?.[BASE]}
                            optimismValue={currentFees?.[IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM]}
                            // moonbeamValue={currentFees?.[MOONBEAM]}
                            // arbitrumValue={currentFees?.[ARBITRUM]}
                            // avaxValue={currentFees?.[AVALANCHE]}
                            total={currentFees?.total}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Tab-title-section tokens">
          <div className="Page-title">
            <Trans>Tokens</Trans>
            {/* <img src={currentIcons.network} width="24" alt="Network Icon" /> */}
          </div>
          <div className="Page-description">
            <Trans>Platform and FLP index tokens.</Trans>
          </div>
        </div>

        {/* <div className="DashboardV2-token-cards"> */}
        <div className="stats-wrapper stats-wrapper--fxdx">
          {/* <div className="App-card"> */}
          <div className="stats-block">
            <div className="App-card-title">
              <div className="App-card-title-mark">
                {/* <div className="App-card-title-mark-icon">
                  <img src={currentIcons.gmx} width="40" alt="GMX Token Icon" />
                </div> */}
                <div className="App-card-title-mark-info">
                  <div className="App-card-title-mark-title">FXDX</div>
                  <div className="App-card-title-mark-subtitle">FXDX</div>
                </div>
                <div>
                  <AssetDropdown assetSymbol="FXDX" />
                </div>
              </div>
            </div>
            {/* <div className="App-card-content"> */}
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div>
                {!fxdxPrice && "..."}
                {fxdxPrice && (
                  <div>{"$" + formatAmount(fxdxPrice, USD_DECIMALS, 5, true)}</div>

                  // <TooltipComponent
                  //   position="right-bottom"
                  //   className="nowrap"
                  //   handle={"$" + formatAmount(fxdxPrice, USD_DECIMALS, 5, true)}
                  //   renderContent={() => (
                  //     <>
                  //       {/* {IS_TESTNET && (
                  //         <StatsTooltipRow
                  //           label={t`Price on Ethereum`}
                  //           value={formatAmount(fxdxPriceFromEthereum, USD_DECIMALS, 2, true)}
                  //           showDollar={true}
                  //         />
                  //       )} */}
                  //       <StatsTooltipRow
                  //         label={t`Price on Base`}
                  //         value={formatAmount(fxdxPriceFromBase, USD_DECIMALS, 4, true)}
                  //         showDollar={true}
                  //       />
                  //       {/* <StatsTooltipRow
                  //         label={t`Price on Optimism`}
                  //         value={formatAmount(fxdxPriceFromOptimism, USD_DECIMALS, 2, true)}
                  //         showDollar={true}
                  //       /> */}
                  //       {/* <StatsTooltipRow
                  //         label={t`Price on Arbitrum`}
                  //         value={formatAmount(fxdxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                  //         showDollar={true}
                  //       />
                  //       <StatsTooltipRow
                  //         label={t`Price on Avalanche`}
                  //         value={formatAmount(fxdxPriceFromAvalanche, USD_DECIMALS, 2, true)}
                  //         showDollar={true}
                  //       /> */}
                  //     </>
                  //   )}
                  // />
                )}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Supply</Trans>
              </div>
              <div>{formatAmount(totalFxdxSupply, FXDX_DECIMALS, 0, true)} FXDX</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>
              {`$${stakedFXDXUSD}`}
                {/* <TooltipComponent
                  position="right-bottom"
                  className="nowrap"
                  handle={`$${formatAmount(stakedFxdxSupplyUsd, USD_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <StatsTooltip
                      title={t`Staked`}
                      // ethereumValue={ethereumStakedFxdx}
                      baseValue={baseStakedFxdx}
                      optimismValue={optimismStakedFxdx}
                      // moonbeamValue={moonbeamStakedFxdx}
                      // arbitrumValue={arbitrumStakedFxdx}
                      // avaxValue={avaxStakedFxdx}
                      total={totalStakedFxdx}
                      decimalsForConversion={FXDX_DECIMALS}
                      showDollar={false}
                    />
                  )}
                /> */}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Market Cap</Trans>
              </div>
              <div>${formatAmount(fxdxMarketCap, USD_DECIMALS, 0, true)}</div>
            </div>
            {/* </div> */}
          </div>

          <div className="App-card-divider"></div>

          {/* <div className="App-card"> */}
          <div className="stats-block">
            <div className="App-card-title">
              <div className="App-card-title-mark">
                {/* <div className="App-card-title-mark-icon">
                  <img src={currentIcons.FLP} width="40" alt="FLP Icon" />
                </div> */}
                <div className="App-card-title-mark-info">
                  <div className="App-card-title-mark-title">FLP</div>
                  <div className="App-card-title-mark-subtitle">FLP</div>
                </div>
                <div>
                  <AssetDropdown assetSymbol="FLP" />
                </div>
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div>${formatAmount(flpPrice, USD_DECIMALS, 3, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Supply</Trans>
              </div>
              <div>{formatAmount(flpSupply, FLP_DECIMALS, 0, true)} FLP</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div>${formatAmount(flpMarketCap, USD_DECIMALS, 0, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Market Cap</Trans>
              </div>
              <div>${formatAmount(flpMarketCap, USD_DECIMALS, 0, true)}</div>
            </div>
            {/* <div className="App-card-row">
              <div className="label">
                <Trans>Stablecoin Percentage</Trans>
              </div>
              <div>{stablePercentage}%</div>
            </div> */}
          </div>
        </div>

        <div className="Tab-title-section">
          <div className="Page-title">
            <Trans>FLP Index Composition</Trans>
            {chainId === BASE && <img src={base24Icon} alt={t`Base Icon`} />}
            {chainId === AVALANCHE && <img src={avalanche24Icon} alt={t`Avalanche Icon`} />}
            {chainId === ARBITRUM && <img src={arbitrum24Icon} alt={t`Arbitrum Icon`} />}
            {chainId === GOERLI && <img src={ethereum24Icon} alt={t`Ethereum Icon`} />}
            {(chainId === OPTIMISM_GOERLI || chainId === OPTIMISM) && (
              <img src={optimism24Icon} alt={t`Optimism Icon`} />
            )}
            {chainId === MOONBEAM && <img src={moonbeam24Icon} alt={t`MoonBeam Icon`} />}
            {/* <img src={currentIcons.network} width="24" alt="Network Icon" /> */}
          </div>
        </div>

        <div className="flp-assets-cardcontainer">
          {visibleTokens.map((token) => {
            const tokenInfo = infoTokens[token.address];
            let utilization = bigNumberify(0);
            if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
              utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
            }
            let maxUSDFAmount = DEFAULT_MAX_USDF_AMOUNT;
            if (tokenInfo.maxUsdfAmount && tokenInfo.maxUsdfAmount.gt(0)) {
              maxUSDFAmount = tokenInfo.maxUsdfAmount;
            }

            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
            return (
              <div className="App-card" key={token.symbol}>
                <div className="App-card-title">
                  <div className="token-symbol-wrapper">
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt={token.symbol} width="40" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">{token.name}</div>
                        <div className="App-card-info-subtitle">{token.symbol}</div>
                      </div>
                      {/* <div>
                        <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-content">
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Price</Trans>
                    </div>
                    <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Pool</Trans>
                    </div>
                    <div>
                      <TooltipComponent
                        handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                        position="right-bottom"
                        renderContent={() => {
                          return (
                            <>
                              <StatsTooltipRow
                                label={t`Pool Amount`}
                                value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                  token.symbol
                                }`}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={t`Target Min Amount`}
                                value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                  token.symbol
                                }`}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={t`Max ${tokenInfo.symbol} Capacity`}
                                value={formatAmount(maxUSDFAmount, 18, 0, true)}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Weight</Trans>
                    </div>
                    <div>{getWeightText(tokenInfo)}</div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Utilization</Trans>
                    </div>
                    <div>{formatAmount(utilization, 2, 2, false)}%</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    </SEO>
  );
}