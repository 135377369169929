import "./bentogetfx.css"
import box1 from "./assets/box1.svg"
import box2 from "./assets/box2.svg"
import box3 from "./assets/box3.svg"
import box4 from "./assets/box4.svg"
import stakepng from "./assets/stakenew.png"

const Bentogetfxdx = ()  => {
    return (
        <div>
             <div className="all-bento-aligh">
            <div className="bento-title">
                <h1>Your Gateway to Advanced DeFi Ecosystem</h1>
            </div>

              <div className="bento-content">
                <div className="bento-data-box">
                    <img src={box1}  alt="box1"/>
                    <div>
                        <div className="box-heading">
                               <h2>FXDX Utility Token</h2>
                        </div>
                        <div className="box-para">
                             <p>A deflationary cornerstone for all our products,<br/> 
                             backed by a robust Buy Back and Burn mechanism</p>
                        </div>
                    </div>
                      
                </div>
                <div className="bento-data-box">
                <img src={box2}  alt="box2"/>
                    <div>
                        <div className="box-heading">
                               <h2>High-Yield Staking Pools</h2>
                        </div>
                        <div className="box-para">
                             <p>Lock in your assets, add value, and reap passive<br/> rewards in your sleep</p>
                        </div>
                    </div>

                </div>
              </div>

               <div className="bento-content">
                <div className="bento-data-box-2">
                    <img src={box3}  alt="box1"/>
                    <div>
                        <div className="box-heading">
                               <h2>Governance</h2>
                        </div>
                        <div className="box-para">
                             <p>Your opinion matters in our ecosystem.
                             </p>
                        </div>
                    </div>
                      
                </div>
                <div className="bento-data-box-2">
                <img src={box4}  alt="box2"/>
                    <div>
                        <div className="box-heading">
                               <h2>Anticipated Fee Discount</h2>
                        </div>
                        <div className="box-para">
                             <p>Coming Very Soon...</p>
                        </div>
                    </div>

                </div>
              </div>


             </div>
                <div className="stake-image-all">
                <div className="border-bento-stake">
                <img src={stakepng}  alt="stakepng"/>
                <h2>Stake FXDX</h2>
                </div>
                </div>
        </div>
    )

}
export default Bentogetfxdx;