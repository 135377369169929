import "./Herogetfxdx.css";
// import backsvg from "./assets/backgroundget.svg";
import arrow from "./assets/arrow.svg"


const TTbuyFxdx = ({ onStakeHereClick }) => {
 
   

  const RedirectTomexce = () => {
    window.open("https://www.mexc.com/exchange/FXDX_USDT", "_blank");
  };


  return (
    <div className="GetFxdxBuy-panel">
        <div className="text_heading_box-all">
          
          <div className="buttonmexce">
            <div className="buttonmexce-text" onClick={RedirectTomexce} >
            <h6>Buy $FXDX on CEX</h6>
            </div>
             <div className="buttonmexce-img">
            <img src={arrow}  alt="arrow"/>
            </div>
          </div> 
           <div className="main-text">
             <h1>It's Time To buy FXDX</h1>
           </div>

           <div className="text-undermain">
            <h3>Stake your $FXDX to earn upto&nbsp;<span>100% APR</span></h3>
           </div>
           
           <div className="stakehere-but" onClick={onStakeHereClick}>
              
                  <h3>Stake Here</h3>
               
           </div>

           <div className="bento-to-buy">
             <div className="bento-box"  onClick={RedirectTomexce} > <h3>Buy $FXDX on MEXC</h3> <img src={arrow}  alt="arrow"/> </div>
             <div className="bento-box"> <h3>More CEX Listings</h3> <span>Coming Soon</span> </div>
           </div>
           <div className="bento-to-buy-two">
             <div className="bento-box2"> <h3>DEX Listings</h3> <span>Coming Soon</span> </div>
             <div className="bento-box2"> <h3>Bond Market</h3> <span>Coming Soon</span> </div>
           </div>
        </div>
       
    </div>
  );
};

export default TTbuyFxdx;
