import { getServerBaseUrl } from "config/backend";
import useSWR from "swr";

export function useFlpPrices(chainId) {
  const url = `${getServerBaseUrl(chainId)}/flp_prices`;

  const { data: flpPrices, mutate: updateFlpPrices } = useSWR(url && url, {
    dedupingInterval: 5000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  if (flpPrices) {
    flpPrices.sort((a, b) => a.timestamp - b.timestamp);
  }

  return { flpPrices, updateFlpPrices };
}
