export const bridgeInfo = [
  {
    name: "Router Protocol",
    image: require("../../img/router-protocol.png"),
    description:
      "Router Protocol is a cross-chain messaging protocol to send assets and messages between different chains.",
    redirectURL:
      "https://app.thevoyager.io/swap?fromChain=1&toChain=10&fromToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&toToken=0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Hop Exchange",
    image: require("../../img/Hop_Exchange.png"),
    description: "Hop allows you to quickly bridge assets between L1 and various L2 solutions.",
    redirectURL: "https://app.hop.exchange/send?sourceNetwork=ethereum&destNetwork=optimism&token=USDC",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Stargate",
    image: require("../../img/Stargate.png"),
    description:
      "Stargate is a fully composable liquidity transport protocol that lives at the heart of Omnichain DeFi.",
    redirectURL: "https://stargate.finance/transfer",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "XY Finance",
    image: require("../../img/XY_Finance.png"),
    description:
      "XY Finance is a cross-chain dex & bridge aggregator that operates on 19 EVM chains including Base, Optimism, Arbitrum & more.",
    redirectURL: "https://app.xy.finance",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Synapse Protocol",
    image: require("../../img/synapse-protocol.png"),
    description:
      "Synapse Protocol allows you to transfer and swap assets across Ethereum, Layer 2 chains, BSC, Avalanche and more.",
    redirectURL: "https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=10",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Across",
    image: require("../../img/Across.jpg"),
    description: "Instantly send assets between layer 2 rollups, as well as to and from Ethereum.",
    redirectURL: "https://across.to/bridge",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Beamer",
    image: require("../../img/Beamer.png"),
    description:
      "Beamer allows consolidation of assets across rollups, make P2P payments to recipients on other rollups, and access dApps on other rollups.",
    redirectURL: "https://app.beamerbridge.com/#/?activeTabLabel=Transfer",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Biconomy Hyphen",
    image: require("../../img/Bico.png"),
    description:
      "Biconomy, a web3 infra project solving transaction layer problems, built Hyphen, the fastest & cheapest cross-chain liquidity bridge in the market.",
    redirectURL: "https://hyphen.biconomy.io/bridge",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Bungee",
    image: require("../../img/bungee.png"),
    description:
      "Bungee is the Google Maps for the multi-chain world. A bridge aggregator that helps users discover the best bridges to move their assets across chains.",
    redirectURL: "https://www.bungee.exchange/",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Celer cBridge",
    image: require("../../img/celercB.jpeg"),
    description:
      "Celer cBridge is a decentralized and non-custodial asset bridge that supports 130+ different tokens across 30+ blockchains and layer-2 rollups.",
    redirectURL: "https://cbridge.celer.network/1/10/USDC",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "ChainPort",
    image: require("../../img/chainPort.svg").default,
    description: "ChainPort.io allows users to port their tokens between different blockchains.",
    redirectURL: "https://app.chainport.io/",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Connext",
    image: require("../../img/Connext.png"),
    description:
      "Connext is a leading protocol for fast, fully noncustodial transfers and contract calls between EVM-compatible systems.",
    redirectURL: "https://bridge.connext.network/USDC-from-ethereum-to-optimism",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "LI.FI",
    image: require("../../img/lifi.svg").default,
    description: "Provides bridge aggregation with DEX-connectivity.",
    redirectURL:
      "https://jumper.exchange/?fromChain=1&fromToken=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&utm_campaign=perma_top_banner&utm_medium=LIFI_banner&utm_source=LIFI",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "LayerSwap",
    image: require("../../img/LayerSwap.png"),
    description: "Save 10x on fees when moving crypto from Coinbase, Binance, Huobi or FTX US to Optimism",
    redirectURL: "https://www.layerswap.io/app",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Meson",
    image: require("../../img/Meson.png"),
    description:
      "Meson is the faster and safer way to execute low-cost, zero-slippage stablecoin cross-chain swaps between Optimism, leading blockchains and L2s.",
    redirectURL: "https://meson.fi/",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Optimism Bridge",
    image: require("../../img/OP_Logo.png"),
    description: "The Optimism Bridge allows you to transfer assets between L1 and Optimism.",
    redirectURL: "https://app.optimism.io/bridge",
    supportsOP: true,
    supportsBase: false,
  },
  {
    name: "Orbiter",
    image: require("../../img/Orbiter.jpg"),
    description: "A decentralized cross-rollup Layer 2 bridge with a contract only on the destination side.",
    redirectURL: "https://www.orbiter.finance/?source=Ethereum&dest=Optimism",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Symbiosis",
    image: require("../../img/Symbiosis.jpg"),
    description:
      "Symbiosis is a cross-chain liquidity protocol. Our know-how: a single click any-to-any token swaps regardless of the network",
    redirectURL:
      "https://app.symbiosis.finance/swap?chainIn=Ethereum&chainOut=Optimism&tokenIn=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&tokenOut=0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    supportsOP: true,
    supportsBase: true,
  },
  {
    name: "Via Protocol",
    image: require("../../img/via.jpeg"),
    description:
      "Cross‑chain bridge and DEX aggregator on EVM and non-EVM blockchains that finds the fastest and cheapest swap paths.",
    redirectURL: "https://router.via.exchange/ethereum/USDC/optimism/USDC",
    supportsOP: true,
    supportsBase: true,
  }
];
