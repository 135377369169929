export const stakeFxdxVaultData = {
    abi: [
        { inputs: [], stateMutability: "nonpayable", type: "constructor" },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "uint256", name: "stakeId", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "rewardInterestRate", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" },
                { indexed: true, internalType: "address", name: "account", type: "address" },
                { indexed: false, internalType: "bool", name: "unstaked", type: "bool" },
            ],
            name: "Stake",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "address", name: "vault", type: "address" },
                { indexed: true, internalType: "uint256", name: "reserves", type: "uint256" },
            ],
            name: "TotalReserves",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "uint256", name: "stakeId", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "rewardInterestRate", type: "uint256" },
                { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" },
                { indexed: true, internalType: "address", name: "account", type: "address" },
                { indexed: false, internalType: "bool", name: "unstaked", type: "bool" },
            ],
            name: "Unstake",
            type: "event",
        },
        {
            inputs: [],
            name: "fxdx",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "stakeId", type: "uint256" }],
            name: "getStakeData",
            outputs: [
                { internalType: "uint256", name: "_stakeId", type: "uint256" },
                { internalType: "uint256", name: "_amount", type: "uint256" },
                { internalType: "uint256", name: "_duration", type: "uint256" },
                { internalType: "uint256", name: "_rewardInterestRate", type: "uint256" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "bool", name: "_unstaked", type: "bool" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256[]", name: "_stakeIds", type: "uint256[]" },
            ],
            name: "getTotalStakedAmount",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_account", type: "address" }],
            name: "getUserStakeInfo",
            outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "getVaultStatus",
            outputs: [
                { internalType: "bool", name: "", type: "bool" },
                { internalType: "uint256", name: "", type: "uint256" },
                { internalType: "uint256", name: "", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "gov",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "", type: "address" }],
            name: "isHandler",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "isVaultActive",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "rewardFxdxVault",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "rewardRouter",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_fxdx", type: "address" }],
            name: "setFxdx",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_gov", type: "address" }],
            name: "setGov",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "bool", name: "_isActive", type: "bool" },
            ],
            name: "setHandler",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_rewardFxdxVault", type: "address" }],
            name: "setRewardFxdxVault",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_rewardRouter", type: "address" }],
            name: "setRewardRouter",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256", name: "_amount", type: "uint256" },
                { internalType: "uint256", name: "_duration", type: "uint256" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "uint256", name: "_rewardInterestRate", type: "uint256" },
            ],
            name: "stake",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "stakeCount",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "stakeData",
            outputs: [
                { internalType: "uint256", name: "stakeId", type: "uint256" },
                { internalType: "uint256", name: "amount", type: "uint256" },
                { internalType: "uint256", name: "duration", type: "uint256" },
                { internalType: "uint256", name: "rewardInterestRate", type: "uint256" },
                { internalType: "uint256", name: "timestamp", type: "uint256" },
                { internalType: "address", name: "account", type: "address" },
                { internalType: "bool", name: "unstaked", type: "bool" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "totalReserves",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_account", type: "address" },
                { internalType: "uint256[]", name: "_stakeIds", type: "uint256[]" },
                { internalType: "bool", name: "_isRestake", type: "bool" },
            ],
            name: "unstake",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "bool", name: "_isActive", type: "bool" },
                { internalType: "uint256", name: "_start", type: "uint256" },
                { internalType: "uint256", name: "_end", type: "uint256" },
            ],
            name: "updateVaultStatus",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "", type: "address" },
                { internalType: "uint256", name: "", type: "uint256" },
            ],
            name: "userStakeInfo",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "vaultActiveFrom",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "vaultActiveTill",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
    ],
};
