import { ethers } from "ethers";
import { getContract } from "./contracts";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, GOERLI, OPTIMISM_GOERLI, MOONBEAM, OPTIMISM, BASE } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  [BASE]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0xd6c5469A7cc587E1E89A841FB7c102FF1370C05F",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Coinbase Wrapped Staked ETH",
      symbol: "cbETH",
      decimals: 18,
      address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
      isShortable: true,
      isNotClaimable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/27008/thumb/cbeth.png?1661390066",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "USD Base Coin",
      symbol: "USDbC",
      decimals: 6,
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/31164/thumb/baseusdc.jpg?1691075428",
      taxBasisPoints: 5, // 0.05%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 100,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      taxBasisPoints: 5, // 0.05%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 100,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      decimals: 18,
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      taxBasisPoints: 5, // 0.05%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 100,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
  ],
  [GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0x2B918c4E864056D7F9feB88D05d71F922fDDfFD0",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: "0x3FB1f5F1297895f4680Adc1F55CC48e95183C55d",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x1788509e1Bd6cd9b797B10E344A43d7Aea668Fc5",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x3491d4649aeBC9f46370DFF87c9887f557fb5954",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
  ],
  [OPTIMISM_GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0x5088964b5174E7DEe70a5343066f6a6Ac5ca71Fc",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "Fxdx Ether",
      symbol: "FETH",
      decimals: 18,
      address: "0x5550c5F3E75611d97A8D048A4Af1a88a4e3329Ed",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: "0x3491d4649aeBC9f46370DFF87c9887f557fb5954",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "Worldcoin",
      symbol: "WLD",
      decimals: 18,
      address: "0xAe2D3B39A9676e32b2BE2Fa40888984F7A24C579",
      isShortable: true,
      isNotClaimable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/31069/thumb/worldcoin.jpeg?1690182386",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "Optimism",
      symbol: "OP",
      decimals: 18,
      address: "0x8b27BEF72216689803df02DbD36c7074c68e49Cb",
      isShortable: true,
      isNotClaimable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/25244/thumb/Optimism.png?1660904599",
      taxBasisPoints: 0, // 0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 0,
      relativePnlList: [0, 1000, 5000],
      positionFeeBpsList: [0, 0, 0],
      profitFeeBpsList: [500, 500, 1000],
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0xD1D57Fd32AE51eB778730d4C740E8C041891F525",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      taxBasisPoints: 0, // 0.0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 0,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x169E3A9F6b76Ad6b3149F1d30d3Ab4d4D6f3EecC",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
      taxBasisPoints: 0, // 0.0%
      mintBurnFeeBasisPoints: 30, // 0.3%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 0,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
  ],
  [OPTIMISM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0xD158B0F013230659098e58b66b602dFF8f7ff120",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Worldcoin",
      symbol: "WLD",
      decimals: 18,
      address: "0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1",
      isShortable: true,
      isNotClaimable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/31069/thumb/worldcoin.jpeg?1690182386",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "Optimism",
      symbol: "OP",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000042",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/25244/thumb/Optimism.png?1660904599",
      taxBasisPoints: 25, // 0.25%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 25, // 0.25%
      rolloverRateFactor: 100,
      relativePnlList: [0, 25, 50, 100, 1000, 5000],
      positionFeeBpsList: [15, 15, 20, 30, 50, 100],
      profitFeeBpsList: [500, 500, 500, 500, 500, 1000],
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      taxBasisPoints: 5, // 0.05%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 100,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
      taxBasisPoints: 5, // 0.05%
      mintBurnFeeBasisPoints: 25, // 0.25%
      swapFeeBasisPoints: 1, // 0.01%
      rolloverRateFactor: 100,
      relativePnlList: [],
      positionFeeBpsList: [],
      profitFeeBpsList: [],
    },
  ],
  [ARBITRUM_TESTNET]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0x27960f9A322BE96A1535E6c19B3958e80E6a2670",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    // https://github.com/OffchainLabs/arbitrum/blob/950c2f91b2e951cd3764394e0a73eac3797aecf3/packages/arb-ts/src/lib/networks.ts#L65
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xf0DCd4737A20ED33481A49De94C599944a3Ca737",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x818ED84bA1927945b631016e0d402Db50cE8865f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [MOONBEAM]: [
    {
      name: "Glimmer",
      symbol: "GLMR",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/22459/small/glmr.png?1641880985",
    },
    {
      name: "Wrapped Glimmer",
      symbol: "WGLMR",
      decimals: 18,
      address: "0x226bD09205A6Aa9374f77e746feB18971A2501a8",
      isWrapped: true,
      baseSymbol: "GLMR",
      imageUrl: "https://assets.coingecko.com/coins/images/22459/small/glmr.png?1641880985",
    },
    {
      name: "Ethereum (Multichain)",
      symbol: "ETH",
      address: "0xfa9343c3897324496a05fc75abed6bac29f8a40f",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ether (Wormhole)",
      symbol: "WETH",
      address: "0xab3f0245b83feb11d15aaffefd7ad465a59817ed",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped BTC (Multichain)",
      symbol: "WBTC(M)",
      address: "0x922d641a426dcffaef11680e5358f34d97d112e1",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Wrapped BTC (Wormhole)",
      symbol: "WBTC(W)",
      address: "0xe57ebd2d67b462e9926e04a8e33f01cd0d64346d",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (Mutlichain)",
      symbol: "USDC(M)",
      address: "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (Wormhole)",
      symbol: "USDC(W)",
      address: "0x931715FEE2d06333043d11F658C8CE934aC61D0c",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Binance-Peg BUSD Token",
      symbol: "BUSD",
      address: "0xa649325aa7c5093d12d6f98eb4378deae68ce23f",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      decimals: 18,
      address: "0x765277EebeCA2e31912C9946eAe1021199B39C61",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x322e86852e492a7ee17f28a78c663da38fb33bfb",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [BASE]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(BASE, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(BASE, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(BASE, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [GOERLI]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(GOERLI, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(GOERLI, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(GOERLI, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [OPTIMISM_GOERLI]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(OPTIMISM_GOERLI, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(OPTIMISM_GOERLI, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(OPTIMISM_GOERLI, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [OPTIMISM]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(OPTIMISM, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(OPTIMISM, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(OPTIMISM, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [ARBITRUM]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(ARBITRUM, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(ARBITRUM, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(ARBITRUM, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(AVALANCHE, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(AVALANCHE, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(ARBITRUM, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [MOONBEAM]: [
    {
      name: "FXDX",
      symbol: "FXDX",
      address: getContract(MOONBEAM, "FXDX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed FXDX",
      symbol: "esFXDX",
      address: getContract(MOONBEAM, "ES_FXDX"),
      decimals: 18,
    },
    {
      name: "FXDX LP",
      symbol: "FLP",
      address: getContract(MOONBEAM, "FLP"),
      decimals: 18,
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [BASE]: {
    // arbitrum
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(BASE, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(BASE, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [GOERLI]: {
    // arbitrum
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(GOERLI, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(GOERLI, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [OPTIMISM_GOERLI]: {
    // arbitrum
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(OPTIMISM_GOERLI, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(OPTIMISM_GOERLI, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [OPTIMISM]: {
    // arbitrum
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(OPTIMISM, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(OPTIMISM, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [ARBITRUM]: {
    // arbitrum
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(ARBITRUM, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(AVALANCHE, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [MOONBEAM]: {
    // moonbeam
    FXDX: {
      name: "FXDX",
      symbol: "FXDX",
      decimals: 18,
      address: getContract(MOONBEAM, "FXDX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    FLP: {
      name: "FXDX LP",
      symbol: "FLP",
      decimals: 18,
      address: getContract(MOONBEAM, "StakedFlpTracker"), // address of fsFLP token because user only holds fsFLP
      imageUrl: "https://github.com/fxdx-io/fxdx-assets/blob/main/FXDX-Assets/PNG/FLP_LOGO%20ONLY.png?raw=true",
    },
  },
};

export const ICONLINKS = {
  [BASE]: {
    FXDX: {
      coinmarketcap: "https://coinmarketcap.com/currencies/fxdx-exchange/",
      base: "https://basescan.org/address/0x30b593f8c3ab37615359B4E0E6df2e06d55bB55d",
      coingecko: "https://www.coingecko.com/en/coins/fxdx",
    },
    FLP: {
      base: "https://basescan.org/token/0xaE4bB7E295E9f762B8c5d53E5dd31B4820d3A25c",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    cbETH: {
      coingecko: "https://www.coingecko.com/en/coins/coinbase-wrapped-staked-eth",
      base: "https://basescan.org/address/0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
    },
    USDbC: {
      coingecko: "https://www.coingecko.com/en/coins/bridged-usd-coin-base",
      base: "https://basescan.org/address/0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      base: "https://basescan.org/address/0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      base: "https://basescan.org/address/0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    },
  },
  [GOERLI]: {
    FXDX: {
      coinmarketcap: "https://coinmarketcap.com/currencies/fxdx-exchange/",
      ethereum: "https://goerli.etherscan.io/address/0x5FAb4cE46Ef52805178263D1c81aeA83685130D6",
    },
    FLP: {
      ethereum: "https://goerli.etherscan.io/token/0x433Ad5632b8c695ea09d2FB6D345cF579B1168fF",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      ethereum: "https://goerli.etherscan.io/address/0x3FB1f5F1297895f4680Adc1F55CC48e95183C55d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      ethereum: "https://goerli.etherscan.io/address/0x1788509e1Bd6cd9b797B10E344A43d7Aea668Fc5",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      ethereum: "https://goerli.etherscan.io/address/0x3491d4649aeBC9f46370DFF87c9887f557fb5954",
    },
  },
  [OPTIMISM_GOERLI]: {
    FXDX: {
      coinmarketcap: "https://coinmarketcap.com/currencies/fxdx-exchange/",
      optimism: "https://goerli-optimism.etherscan.io/address/0xEfB2C7C6266087BB070cF03b9C270fdD607Ca585",
    },
    FLP: {
      optimism: "https://goerli-optimism.etherscan.io/token/0x5615A47584C91879DE2Dd9B4491AA55Ab7041c4C",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    FETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      optimism: "https://goerli-optimism.etherscan.io/address/0x5550c5F3E75611d97A8D048A4Af1a88a4e3329Ed",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      optimism: "https://goerli-optimism.etherscan.io/address/0x3491d4649aeBC9f46370DFF87c9887f557fb5954",
    },
    WLD: {
      coingecko: "https://www.coingecko.com/en/coins/worldcoin",
      optimism: "https://goerli-optimism.etherscan.io/address/0xAe2D3B39A9676e32b2BE2Fa40888984F7A24C579",
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      optimism: "https://goerli-optimism.etherscan.io/address/0x8b27BEF72216689803df02DbD36c7074c68e49Cb",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      optimism: "https://goerli-optimism.etherscan.io/address/0xD1D57Fd32AE51eB778730d4C740E8C041891F525",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      optimism: "https://goerli-optimism.etherscan.io/address/0x169E3A9F6b76Ad6b3149F1d30d3Ab4d4D6f3EecC",
    },
  },
  [OPTIMISM]: {
    FXDX: {
      coinmarketcap: "https://coinmarketcap.com/currencies/fxdx-exchange/",
      optimism: "https://optimistic.etherscan.io/address/0xB283fC842e695Ee27242aEAE7D591dbE1dde51C6",
    },
    FLP: {
      optimism: "https://optimistic.etherscan.io/token/0xFc8d50529a5fA9e3Bcbbc2F3B2DE87B41146E512",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      optimism: "https://optimistic.etherscan.io/address/0x68f180fcCe6836688e9084f035309E29Bf0A2095",
    },
    WLD: {
      coingecko: "https://www.coingecko.com/en/coins/worldcoin",
      optimism: "https://goerli-optimism.etherscan.io/address/0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1",
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      optimism: "https://goerli-optimism.etherscan.io/address/0x4200000000000000000000000000000000000042",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      optimism: "https://optimistic.etherscan.io/address/0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      optimism: "https://optimistic.etherscan.io/address/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    },
  },
  [ARBITRUM_TESTNET]: {
    FXDX: {
      coinmarketcap: "https://coinmarketcap.com/currencies/fxdx-exchange/",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    FLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [ARBITRUM]: {
    FXDX: {
      coingecko: "https://www.coingecko.com/en/coins/fxdx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    FLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
  },
  [AVALANCHE]: {
    FXDX: {
      coingecko: "https://www.coingecko.com/en/coins/fxdx",
      avalanche: "https://snowtrace.io/address/0x62edc0692bd897d2295872a9ffcac5425011c661",
    },
    FLP: {
      avalanche: "https://snowtrace.io/address/0x9e295B5B976a184B14aD8cd72413aD846C299660",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
  },
  [MOONBEAM]: {
    FXDX: {
      coingecko: "https://www.coingecko.com/en/coins/fxdx",
      moonbeam: "https://moonscan.io/address/0x9a74dcaf28a9b7B9BB2Ec59F458EC77565A6A8b2",
    },
    FLP: {
      moonbeam: "https://moonscan.io/token/0x779676333543249A741C1afB7f88e6aFB861427d",
    },
    GLMR: {
      coingecko: "https://www.coingecko.com/en/coins/moonbeam",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      moonbeam: "https://moonscan.io/address/0xfa9343c3897324496a05fc75abed6bac29f8a40f",
    },
    WETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      moonbeam: "https://moonscan.io/address/0xab3f0245b83feb11d15aaffefd7ad465a59817ed",
    },
    "WBTC(M)": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      moonbeam: "https://moonscan.io/address/0x922D641a426DcFFaeF11680e5358F34d97d112E1",
    },
    "WBTC(W)": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      moonbeam: "https://moonscan.io/address/0xE57eBd2d67B462E9926e04a8e33f01cD0D64346D",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      moonbeam: "https://moonscan.io/address/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73",
    },
    "USDC(M)": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      moonbeam: "https://moonscan.io/address/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
    },
    "USDC(W)": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      moonbeam: "https://moonscan.io/address/0x931715FEE2d06333043d11F658C8CE934aC61D0c",
    },
    BUSD: {
      coingecko: "https://www.coingecko.com/en/coins/binance-usd",
      moonbeam: "https://moonscan.io/address/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      moonbeam: "https://moonscan.io/address/0x765277EebeCA2e31912C9946eAe1021199B39C61",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      moonbeam: "https://moonscan.io/address/0x322E86852e492a7Ee17f28a78c663da38FB33bfb",
    },
  },
};

export const FLP_POOL_COLORS = {
  GLMR: "#E84142",
  ETH: "#6062a6",
  WETH: "#E9167C",
  FETH: "#F7931A",
  BTC: "#F7931A",
  "WBTC(M)": "#F7931A",
  WBTC: "#F7931A",
  "WBTC(W)": "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  "USDC(M)": "#2775CA",
  "USDC(W)": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  BUSD: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [BASE, GOERLI, OPTIMISM_GOERLI, OPTIMISM, ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MOONBEAM];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDF");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  } else if (tokenSymbol === "cbETH") {
    return "CBETH";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [BASE]: ["ETH", "CBETH"],
  [ARBITRUM]: ["ETH", "BTC", "LINK", "UNI"],
  [AVALANCHE]: ["AVAX", "ETH", "BTC"],
  [GOERLI]: ["ETH", "BTC"],
  [OPTIMISM_GOERLI]: ["ETH", "FETH", "BTC", "WLD", "OP"],
  [OPTIMISM]: ["ETH", "BTC", "WLD", "OP"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}
