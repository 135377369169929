import React from "react";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";

import { USD_DECIMALS, USDF_DECIMALS } from "lib/legacy";
import TokenSelectorNoModal from "../Exchange/TokenSelectorNoModal";
import "./FeeWithdrawalTokenSelector.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { getTokenAmountFromUsd } from "domain/tokens";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";

export const shouldSwap = (fromToken, receiveToken) => {
  const isFromTokenWrapped = fromToken.isNative;

  const isSameToken = fromToken.address === receiveToken.address || (isFromTokenWrapped && receiveToken.isWrapped);

  const isUnwrap = isFromTokenWrapped && receiveToken.isNative;

  return !isSameToken && !isUnwrap;
};

export const getSwapLimits = (infoTokens, fromTokenAddress, toTokenAddress) => {
  const fromInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toInfo = getTokenInfo(infoTokens, toTokenAddress);

  let maxInUsd;
  let maxIn;
  let maxOut;
  let maxOutUsd;

  if (!fromInfo?.maxUsdfAmount) {
    maxInUsd = bigNumberify(0);
    maxIn = bigNumberify(0);
  } else {
    maxInUsd = fromInfo.maxUsdfAmount
      .sub(fromInfo.usdfAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDF_DECIMALS));

    maxIn = maxInUsd.mul(expandDecimals(1, fromInfo.decimals)).div(fromInfo.maxPrice).toString();
  }

  if (!toInfo?.poolAmount || !toInfo?.bufferAmount) {
    maxOut = bigNumberify(0);
    maxOutUsd = bigNumberify(0);
  } else {
    maxOut = toInfo.availableAmount.gt(toInfo.poolAmount.sub(toInfo.bufferAmount))
      ? toInfo.poolAmount.sub(toInfo.bufferAmount)
      : toInfo.availableAmount;

    maxOutUsd = getUsd(maxOut, toInfo.address, false, infoTokens);
  }

  return {
    maxIn,
    maxInUsd,
    maxOut,
    maxOutUsd,
  };
};

export default function FeeWithdrawalTokenSelector({
  chainId,
  infoTokens,
  toTokens,
  feeRewardToken,
  receiveToken,
  receiveAmountUsd,
  isNotEnoughReceiveTokenLiquidity,
  isCollateralPoolCapacityExceeded,
  setSwapToToken,
  setSavedReceiveTokenAddress,
  onlyLabel,
}) {
  return (
    <TokenSelectorNoModal
      // Scroll lock lead to side effects
      // if it applied on modal inside another modal
      disableBodyScrollLock={true}
      className={cx("fee-withdrawal-token-selector", {
        warning: isNotEnoughReceiveTokenLiquidity || isCollateralPoolCapacityExceeded,
      })}
      label={t`Receive`}
      showBalances={false}
      chainId={chainId}
      tokenAddress={receiveToken.address}
      onSelectToken={(token) => {
        setSwapToToken(token);
        setSavedReceiveTokenAddress(token.address);
      }}
      tokens={toTokens}
      onlyLabel= {onlyLabel}
      getTokenState={(tokenOptionInfo) => {
        if (!shouldSwap(feeRewardToken, tokenOptionInfo)) {
          return;
        }

        const convertedTokenAmount = getTokenAmountFromUsd(infoTokens, tokenOptionInfo.address, receiveAmountUsd);

        const isNotEnoughLiquidity =
          tokenOptionInfo.availableAmount.lt(convertedTokenAmount) ||
          tokenOptionInfo.bufferAmount.gt(tokenOptionInfo.poolAmount.sub(convertedTokenAmount));

        if (isNotEnoughLiquidity) {
          const { maxIn, maxOut, maxInUsd, maxOutUsd } = getSwapLimits(
            infoTokens,
            feeRewardToken.address,
            tokenOptionInfo.address
          );

          return {
            disabled: true,
            message: (
              <div>
                <Trans>Insufficient Available Liquidity to swap to {tokenOptionInfo.symbol}:</Trans>
                <br />
                <br />
                <StatsTooltipRow
                  label={t`Max ${feeRewardToken.symbol} in`}
                  value={[
                    `${formatAmount(maxIn, feeRewardToken.decimals, 0, true)} ${feeRewardToken.symbol}`,
                    `($${formatAmount(maxInUsd, USD_DECIMALS, 0, true)})`,
                  ]}
                />
                <br />
                <StatsTooltipRow
                  label={t`Max ${tokenOptionInfo.symbol} out`}
                  value={[
                    `${formatAmount(maxOut, tokenOptionInfo.decimals, 2, true)} ${tokenOptionInfo.symbol}`,
                    `($${formatAmount(maxOutUsd, USD_DECIMALS, 2, true)})`,
                  ]}
                />
              </div>
            ),
          };
        }
      }}
      infoTokens={infoTokens}
      showTokenImgInDropdown={true}
      // selectedTokenLabel={
      //   <span className="PositionSelector-selected-receive-token">
      //     {formatAmount(convertedReceiveAmount, receiveToken.decimals, 4, true)}&nbsp;
      //     {receiveToken.symbol} (${formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
      //   </span>
      // }
    />
  );
}
