import { gql } from "@apollo/client";
import { getGraphClient } from "domain/referrals";
import { BigNumber } from "ethers";
import bigInt from "big-integer";
import { getLeaderBoardActionsClient } from "lib/subgraph";

const blackListAddress = [
  "0x0aa521427cf5a815a3b4b5acdcc531a477436457",
  "0xeca8f40f98abde3195f3120e7e9fb0a85cf3417e",
  "0x57cf749ed1f6de91171b44cc660516fe5362f5b4",
  "0xf166b01e8e29488b3f29de3a202134fc5cae82a1",
  "0x1a8175e9740bbD198F51728Be3452D3aB31E917D",
];

const actionsQuery = gql`
query Actions($timestamp: Int, $action: [String], $skip: Int) {
    actions(
      where: { and: [{timestamp_gte: $timestamp}, {action_in: $action} ] }
      skip: $skip
    ) {
      account
      action
      blockNumber
      id
      params
      timestamp
      txhash
      type
    }
  }
  `;

const userTradesStatsQuery = gql`
  query UserTradesStats($period: String, $skip: Int, $first: Int, $timestamp: Int) {
    userTradesStats(
      where: { and: [{ period: $period }, { marginVolume_gt: 0 }, {timestamp_gte: $timestamp}] }
      orderBy: timestamp
      orderDirection: desc
      skip: $skip
      first: $first
    ) {
      account
      marginVolume
    }
  }
`;






const formatNumber = (value, decimalPlaces) => {
  if (isNaN(value)) {
    return "N/A";
  }

  const bigValue = bigInt(value);
  const divisor = bigInt(10).pow(30);
  const result = bigValue.divide(divisor).toString();
  return parseFloat(result).toFixed(decimalPlaces);
};

//to calculate referablbonuspoints
function calculateReferralPoints(totalVolume) {
  // console.log(`Total Volume: ${totalVolume}`);
  let points = 0;

  // VIP Tier calculation
  if (totalVolume > 50000) {
    points += ((totalVolume) / 10000) * 0.25;
    totalVolume = 50000;
  }
  // Tier IV calculation
  else if (totalVolume > 20000) {
    points += (totalVolume) * 0.4;
    totalVolume = 20000;
  }
  // Tier III calculation
  else if (totalVolume > 10000) {
    points += (totalVolume) * 0.3;
    totalVolume = 10000;
  }
  // Tier II calculation
  else if (totalVolume > 5000) {
    points += ((totalVolume) * 0.24);
    totalVolume = 5000;
  }
  // Tier I calculation
  else if (totalVolume > 1000) {
    points += ((totalVolume) * 0.2);

    // console.log("Tier I");
  }
  // console.log(`Calculated Points: ${points}`);
  return points;
}


//to calcuate trading points og
function calculateOgPoints(marginVolume) {
  // console.log('Total Trading Volume: ', marginVolume);
  let OgPoints = 0;

  // VIP Tier
  if (marginVolume > 50000) {
    OgPoints += (marginVolume / 15000) * 0.25;
    marginVolume = 50000;
  }

  // Tier III
  else if (marginVolume > 30000) {
    OgPoints += (marginVolume * 0.5);
    marginVolume = 30000
  }

  // Tier II
  else if (marginVolume > 15000) {
    OgPoints += (marginVolume * 0.35);
    marginVolume = 15000;
  }

  // Tier I
  else if (marginVolume > 1000) {
    OgPoints += (marginVolume * 0.25);
  }

  // console.log(`Calculated Og Points: ${OgPoints}`);

  return OgPoints;
}


//the final data
export const getLeaderBoardData = async (chainId, period, skip, first, action) => {
  if (!chainId) {
    return [];
  }

  try {
    const timestamp = 1704803400;
    // const actionsData = 
    const userActionsData = await getUserActions(chainId, timestamp, action)
    // console.log(userTradesData);

    let data = [];
    let highestPoints = 0;
    let highestPointsTrader = null;

    for (let userAction of userActionsData) {
      const { account, volume } = userAction;
      const volumeFormat = volume !== undefined ? parseFloat(volume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100 : 0;
      const totalVolume = await getReferralData(chainId, account, period, timestamp);
      // console.log(totalVolume)
      const totalVolumeFormat = totalVolume !== undefined ? parseFloat(totalVolume.div(BigNumber.from(10).pow(BigNumber.from(28)))) / 100 : 0;

      const referralPoints = calculateReferralPoints(totalVolumeFormat);
      const ogPoints = calculateOgPoints(volumeFormat);

      // console.log(`volume: ${volumeFormat}, referralPoints: ${referralPoints}, ogPoints: ${ogPoints}`);

      const allPoints = parseInt(volumeFormat) + parseInt(referralPoints) + parseInt(ogPoints);

      if (allPoints > highestPoints) {
        highestPoints = allPoints;
        highestPointsTrader = account;
      }

      data.push({
        ...userAction,
        totalVolume,
        referralPoints,
        ogPoints,
        allPoints,
        isHighest: false,
      });
    }

    if (highestPointsTrader !== null) {
      data = data.map((trade) => ({
        ...trade,
        isHighest: trade.account === highestPointsTrader,
      }));
    }

    // Optional: Sort data by allPoints in descending order
    data.sort((a, b) => b.allPoints - a.allPoints);

    return data;
  } catch (err) {
    console.log("Error fetching data: ", err);
    return [];
  }
};

export const getUserActions = async (chainId, timestamp, action) => {
  if (!chainId) {
    return [];
  }

  try {
    const client = getLeaderBoardActionsClient(chainId);
    let allUsersData = [];
    let skip = 0;

    while (skip <= 5000) {

      const data = await client.query({
        query: actionsQuery,
        variables: {
          timestamp: timestamp,
          action: action,
          skip: skip
        },
      });

      for (let i = 0; i < data.data.actions.length; i++) {
        allUsersData.push(data.data.actions[i]);
      }

      if (data.data.actions.length < 100) {
        break;
      }

      skip += 100;
    }

    const filteredUserData = allUsersData.filter((user) => {
      return !blackListAddress.includes(user.account);
    });

    let userDataInfo = new Map();
    let userAddress = new Set();

    for (let userData of filteredUserData) {

      const { account, params, id } = userData;
      const { sizeDelta } = JSON.parse(params);
      userAddress.add(account);
      // console.log(sizeDelta)
      if (!userDataInfo.has(account)) {
        userDataInfo.set(account, {
          account: account,
          volume: BigNumber.from(sizeDelta),
          id: id
        })
        // console.log(userDataInfo.get(account));
      }
      else {
        const data = userDataInfo.get(account);
        data.volume = BigNumber.from(data.volume).add(BigNumber.from(sizeDelta))
      }
    }

    let finalUserActionsData = [];
  
    userAddress.forEach((address) => {
      const userDataObj = userDataInfo.get(address);
      if (!userDataObj.volume.isZero()) {
        finalUserActionsData.push(userDataObj);;
      }
    })

    return finalUserActionsData;
  } catch (err) {
    console.log("Error fetching data: ", err);
  }
};

//for margin volume
export const getUserTrades = async (chainId, period, skip, first, timestamp) => {
  if (!chainId) {
    return [];
  }

  try {
    const client = getLeaderBoardActionsClient(chainId);
    const data = await client.query({
      query: userTradesStatsQuery,
      variables: {
        period: period,
        skip: skip,
        first: first,
        timestamp: timestamp
      },
    });

    const allUsersData = data.data.userTradesStats;

    const filteredUserData = allUsersData.filter((user) => {
      return !blackListAddress.includes(user.account);
    });

    return filteredUserData;
  } catch (err) {
    console.log("Error fetching data: ", err);
  }
};

// for referalvolume
export const getReferralData = async (chainId, account, period, timestamp) => {
  const referralDataQuery = gql`
    query referralData($account: String, $timestamp: Int, $period: String) {
      referrerTotalStats: referrerStats(
        orderBy: volume
        orderDirection: desc
        where: { period: $period, referrer: $account, timestamp_gte: $timestamp, volume_gte: 0 }
      ) {
        referralCode
        volume
        liquidityVolume
        trades
        mints
        tradedReferralsCount
        mintedReferralsCount
        registeredReferralsCount
        totalRebateUsd
        discountUsd
        liquidityTotalRebateUsd
      }
    }
  `;

  const client = getGraphClient(chainId);
  const data = await client.query({
    query: referralDataQuery,
    variables: {
      account: (account || "").toLowerCase(),
      timestamp: timestamp,
      period: period,
    },
  });

  const referrerTotalStats = data.data.referrerTotalStats;
  let totalVolume = BigNumber.from(0);
  for (let referrerData of referrerTotalStats) {
    totalVolume = totalVolume.add(BigNumber.from(referrerData.volume));
  }


  return totalVolume;

};


