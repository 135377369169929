import { createChart } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

import "./FlpTvChart.css";
import { format } from "date-fns";
import { useFlpPrices } from "domain/useFlpPrices";

function formatData(data) {
  return data?.map((item) => {
    const formattedDate = format(new Date(item.timestamp * 1000), "yyyy-MM-dd");
    return { time: formattedDate, value: item.flpPrice };
  });
}

export const ChartComponent = (props) => {
  const {
    data,
    colors: {
      backgroundColor = "black",
      lineColor = "#fff",
      lineWidth = 2,
      textColor = "white",
      areaTopColor = "#ffffff22",
      areaBottomColor = "#222124",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 425,
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false },
      },
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lineWidth: lineWidth,
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor, lineWidth]);

  return <div ref={chartContainerRef} />;
};

export default function FlpTvChart({ chainId }) {
  const { flpPrices } = useFlpPrices(chainId);

  const data = formatData(flpPrices)?.filter((item) => item.value);

  return data ? <ChartComponent data={data}></ChartComponent> : null;
}
