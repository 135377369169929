import React, { useState, useEffect, useCallback, useMemo } from "react";
import { USD_DECIMALS } from "lib/legacy";
import { useTrades, useLiquidationsData } from "domain/legacy";

import "./TradeHistory.css";
import { getExplorerUrl } from "config/chains";
import { bigNumberify, formatAmount, formatPrice } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import { t, Trans } from "@lingui/macro";
import { FiExternalLink } from "react-icons/fi";
import { getLiquidationData, renderLiquidationTooltip } from "./TradeHistory";

export default function TradeHistory(props) {
  const { account, infoTokens, getTokenInfo, chainId, nativeTokenAddress } = props;
  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [msgLength, setMsgLength] = useState(5);

  const getAfterId = () => {
    return pageIds[pageIndex];
  };

  const { trades, updateTrades } = useTrades(chainId, account, props.forSingleAccount, getAfterId(), props.type);

  const onShowMore = () => {
    if (!trades || trades.length === 0) {
      return;
    }

    const lastTrade = trades[trades.length - 1];
    pageIds[pageIndex + 1] = lastTrade.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const liquidationsData = useLiquidationsData(chainId, account);
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {});
  }, [liquidationsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateTrades]);

  const getMsg = useCallback(
    (trade) => {
      const tradeData = trade.data;
      const params = JSON.parse(tradeData.params);
      const longOrShortText = params?.isLong ? t`Long` : t`Short`;
      const defaultMsg = [];

      if (tradeData.action === "IncreasePosition-Long" || tradeData.action === "IncreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [
            `Deposit `,
            `${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD `,
            `${formatPrice(params.price, true)}`,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }

        return [
          `Increase `,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${formatPrice(params.price, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "DecreasePosition-Long" || tradeData.action === "DecreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [
            `Withdraw  `,
            `${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD`,
            `${formatPrice(params.price, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        const isLiquidation = params.flags?.isLiquidation;
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);

        if (isLiquidation && liquidationData) {
          const liquidationTooltip = renderLiquidationTooltip(liquidationData, t`Partially Liquidated `);
          return [
            liquidationTooltip,
            `-${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)} USD`,
            `$${formatPrice(params.price, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        const actionDisplay = isLiquidation ? t`Partially Liquidated` : t`Decreased`;
        return [
          `${actionDisplay} `,
          `-${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${formatPrice(params.price, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }

      if (tradeData.action === "LiquidatePosition-Long" || tradeData.action === "LiquidatePosition-Short") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);
        if (liquidationData) {
          const liquidationTooltip = renderLiquidationTooltip(liquidationData, t`Liquidated `);
          return [
            liquidationTooltip,
            `-${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)} USD`,
            `${formatPrice(params.markPrice, true)} `,
            `${indexToken.symbol} ${longOrShortText}`,
          ];
        }
        return [
          `Liquidated `,
          `-${formatAmount(params.size, USD_DECIMALS, 2, true)} USD`,
          ` ${formatPrice(params.markPrice, true)} `,
          `${indexToken.symbol} ${longOrShortText}`,
        ];
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, liquidationsDataMap]
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }

    return trades
      .map((trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade) => trade.msg);
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      <table className=" token-table all-trades Exchange-list Orders App-box large">
        <thead className="Exchange-list-header">
          <tr>
            <th>
              <Trans>ACTION</Trans>
            </th>
            <th>
              <Trans>INSTRUMENT</Trans>
            </th>
            <th>
              <Trans>DELTA</Trans>
            </th>
            <th>
              <Trans>PRICE</Trans>
            </th>
            <th>
              <Trans>TIMESTAMP</Trans>
            </th>
            <th>
              <Trans>TRANSACTION</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {tradesWithMessages.length > 0 &&
            tradesWithMessages.map((trade, index) => {
              const tradeData = trade.data;
              const txUrl = getExplorerUrl(chainId) + "tx/" + tradeData.txhash;
              let msg = getMsg(trade);
              if (index >= msgLength || msg.length === 0) {
                return null;
              }

              return (
                <tr className="Exchange-list-item" key={index}>
                  <td>{msg[0]}</td>
                  <td>{msg[3]}</td>
                  <td>{msg[1]}</td>
                  <td>{msg[2]}</td>
                  <td>{formatDateTime(tradeData.timestamp)}</td>
                  <td>
                    <a href={txUrl} className="balance-explorer" target="_blank" rel="noopener noreferrer">
                      view on explorer &nbsp; <FiExternalLink className="external-link" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {tradesWithMessages.length === 0 && (
        <div className="TradeHistory-row App-box">
          <Trans>No actions yet</Trans>
        </div>
      )}
      {tradesWithMessages.length > 5 && (
        <div className="show-more-container">
          <button
            className="App-button-option App-card-option show-more"
            onClick={() => {
              if (trades && trades.length <= msgLength + 5) {
                onShowMore();
              }
              setMsgLength(msgLength + 5);
            }}
          >
            <Trans>Show more</Trans>
          </button>
        </div>
      )}
    </div>
  );
}
