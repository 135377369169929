import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets, darkTheme, getDefaultWallets, RainbowKitProvider, Theme } from "@rainbow-me/rainbowkit";
import { ledgerWallet, trustWallet, argentWallet, zerionWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { optimism, optimismGoerli } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import merge from "lodash/merge";
import { IS_TESTNET } from "config/chains";
import { useChainId } from "lib/chains";

const base = {
  id: 8453,
  network: "base",
  name: "Base",
  nativeCurrency: {
    name: "Base",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"],
    },
    public: {
      http: ["https://mainnet.base.org"],
    },
  },
  blockExplorers: {
    blockscout: {
      name: "Basescout",
      url: "https://base.blockscout.com",
    },
    default: {
      name: "Basescan",
      url: "https://basescan.org",
    },
    etherscan: {
      name: "Basescan",
      url: "https://basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 5022,
    },
  },
};

const WALLET_CONNECT_PROJECT_ID = "0a48f107eeeb89a7ff52802262791de3";

const walletTheme = merge(darkTheme(), {
  colors: {
    modalBackground: "#222124",
    menuItemBackground: "#3636367a",
    accentColor: "#cdc5cf",
  },
  radii: {
    modal: "4px",
    menuButton: "4px",
  },
} as Theme);

const networks: any[] = [];
if (!IS_TESTNET) {
  networks.push(base);
}

networks.push(IS_TESTNET ? optimismGoerli : optimism);

const { chains, provider } = configureChains(networks, [publicProvider()]);

const { wallets } = getDefaultWallets({
  appName: "Fxdx Exchange",
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "More",
    wallets: [
      trustWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      ledgerWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      zerionWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      argentWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function WalletProvider({ children }) {
  const { chainId } = useChainId();

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={walletTheme} chains={chains} modalSize="compact" initialChain={chainId}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
