import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BsStopwatchFill } from "react-icons/bs";
import { Trans, t } from "@lingui/macro";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./FlpSaga.css";
import sagabg from "../../img/hyper-bg.svg";

import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import { formatAmount, formatAmountFree } from "lib/numbers";
import { FLP_DECIMALS, FXDX_DECIMALS } from "lib/legacy";
import { formatDate, formatDateTime } from "lib/dates";

import {
  AFTER_REWARD_PERIOD,
  BEFORE_EVENT,
  IN_EVENT,
  IN_HOLDING_PERIOD,
  IN_REWARD_PREIOD,
  SAGA_REWARD_RATE_PRECISION,
  TIERS,
  calculateTimeLeft,
  convertToSymbols,
  getTierLowerLimit,
  getTimerText,
  useSagaTotalStat,
  useSagaUserStat,
} from "domain/saga";

import FlpSagaCard from "components/FlpSaga/FlpSagaCard";
import SagaFlpSwap from "components/FlpSaga/SagaFlpSwap";
import Loader from "components/Common/Loader";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Footer from "components/Footer/Footer";
import ExternalLink from "components/ExternalLink/ExternalLink";

const FlpSaga = ({ flpSwapRef, ...props }) => {
  const { address: account } = useWallet();
  const { chainId } = useChainId();

  const [section, setSection] = useState("");
  const [timeLeft, setTimeLeft] = useState({});

  const { data: totalStat } = useSagaTotalStat(chainId);
  const { data: userStat } = useSagaUserStat(chainId, account);
  useEffect(() => {
    const timer = setInterval(() => {
      if (totalStat) {
        const now = Date.now() / 1000;
        if (now > totalStat.rewardPeriodEndTimestamp) {
          if (section !== AFTER_REWARD_PERIOD) {
            setSection(AFTER_REWARD_PERIOD);
            setTimeLeft({});
          }
        } else if (now > totalStat.holdEndTime) {
          if (section !== IN_REWARD_PREIOD) {
            setSection(IN_REWARD_PREIOD);
          }
          setTimeLeft(calculateTimeLeft(totalStat.rewardPeriodEndTimestamp));
        } else if (now > totalStat.endTimestamp) {
          if (section !== IN_HOLDING_PERIOD) {
            setSection(IN_HOLDING_PERIOD);
          }
          setTimeLeft(calculateTimeLeft(totalStat.holdEndTime));
        } else if (now > totalStat.startTimestamp) {
          if (section !== IN_EVENT) {
            setSection(IN_EVENT);
          }
          setTimeLeft(calculateTimeLeft(totalStat.endTimestamp));
        } else {
          if (section !== BEFORE_EVENT) {
            setSection(BEFORE_EVENT);
          }
          setTimeLeft(calculateTimeLeft(totalStat.startTimestamp));
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [section, totalStat]);

  const renderTimerLabel = useCallback(
    (position) => {
      if (!totalStat) {
        return null;
      }

      return (
        <Tooltip
          handle={t`${getTimerText(section)}`}
          position={position}
          renderContent={() => (
            <>
              <StatsTooltipRow
                label={t`Event period`}
                value={`1 month (${formatDate(totalStat.startTimestamp)} - ${formatDate(totalStat.endTimestamp)})`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Hold period`}
                value={`1 month (${formatDate(totalStat.endTimestamp)} - ${formatDate(totalStat.holdEndTime)})`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Reward period`}
                value={`12 months (${formatDate(totalStat.endTimestamp)} - ${formatDate(
                  totalStat.rewardPeriodEndTimestamp
                )})`}
                showDollar={false}
              />
            </>
          )}
        />
      );
    },
    [section, totalStat]
  );

  const renderTierCards = useCallback(() => {
    if (!totalStat && !section) {
      return null;
    }

    return (
      <div className="saga-card-container">
        <h2 className="saga-section-title">
          <Trans>Tier Information</Trans>
        </h2>
        <div className="saga-flp-card-container">
          {TIERS.map((tier, index) => (
            <FlpSagaCard
              key={`${index}`}
              tier={tier}
              tierIndex={index}
              section={section}
              totalStat={totalStat}
              userStat={userStat}
            />
          ))}
        </div>
      </div>
    );
  }, [section, totalStat, userStat]);

  const activeTier = useMemo(() => {
    if (!totalStat || !section || section !== IN_EVENT) {
      return null;
    }

    return TIERS[totalStat.currentActiveTier];
  }, [section, totalStat]);

  const tierLowerLimit = useMemo(() => {
    if (!activeTier) {
      return;
    }

    return getTierLowerLimit(totalStat.currentActiveTier);
  }, [activeTier, totalStat]);

  const progressPercentage = useMemo(() => {
    if (!activeTier || !tierLowerLimit) {
      return 0;
    }

    const totalBuyAmount = totalStat.totalBuyAmount;

    if (totalBuyAmount.lt(tierLowerLimit)) {
      return 0;
    }

    if (totalBuyAmount.gt(activeTier.upperLimit)) {
      return 100;
    }
    return totalBuyAmount.sub(tierLowerLimit).mul(100).div(activeTier.upperLimit.sub(tierLowerLimit)).toNumber();
  }, [activeTier, tierLowerLimit, totalStat]);

  const { totalRewards, distributedRewards, eligibleRewards } = useMemo(() => {
    if (!totalStat || !userStat || (section !== IN_REWARD_PREIOD && section !== AFTER_REWARD_PERIOD)) {
      return {};
    }

    const now = Math.floor(Date.now() / 1000);
    let totalRewards = userStat.claimableRewards;
    if (now > userStat.claimableLastUpdatedAt) {
      const rewardDuration = totalStat.rewardPeriodEndTimestamp - totalStat.endTimestamp;
      const timeDiff = now - userStat.claimableLastUpdatedAt;

      for (let i = 0; i < 6; i++) {
        totalRewards = totalRewards.add(
          userStat.tierCommitments[i]
            .mul(TIERS[i].rewardRate)
            .mul(timeDiff)
            .div(SAGA_REWARD_RATE_PRECISION)
            .div(rewardDuration)
        );
      }
    }

    return {
      totalRewards,
      distributedRewards: userStat.distributedRewards,
      eligibleRewards: totalRewards.sub(userStat.distributedRewards),
    };
  }, [section, totalStat, userStat]);

  return (
    <div className="default-container saga-flp-wrapper">
      <div className="saga-flp-header">
        <div className="saga-flp-header-text">
          <h1>
            <Trans>FLP Saga Rewards</Trans>
          </h1>
          <p>
            <Trans>Purchase FLP tokens now to earn esFXDX rewards in addition to regular fee rewards.</Trans>
          </p>
        </div>
        {section !== "" && (
          <div className="saga-flp-header-label">
            <div className="saga-flp-header-label-title large">{renderTimerLabel("right-bottom")}</div>
            <div className="saga-flp-header-label-title small">{renderTimerLabel("left-bottom")}</div>
            <div className="saga-flp-header-label-cnt">
              <BsStopwatchFill className="clock-icon" />
              <div className="vertical-line"></div>
              {section !== AFTER_REWARD_PERIOD ? (
                <p>
                  <span>{timeLeft.days} D</span>
                  <span>:</span>
                  <span>{timeLeft.hours} H</span>
                  <span>:</span>
                  <span>{timeLeft.minutes} M</span>
                  <span>:</span>
                  <span>{timeLeft.seconds} S</span>
                </p>
              ) : (
                <p className="timeup">
                  <span>{0} D</span>
                  <span>:</span>
                  <span>{0} H</span>
                  <span>:</span>
                  <span>{0} M</span>
                  <span>:</span>
                  <span>{0} S</span>
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {section === "" && <Loader />}
      {section !== "" && (
        <div className="saga-flp-cnt">
          {renderTierCards()}
          <div className="saga-flp-cnt-right">
            <h2>
              <Trans>Status</Trans>
            </h2>
            {section === BEFORE_EVENT && (
              <div>
                FLP Saga event has not started yet.
                <br />
                It will start at {formatDateTime(totalStat.startTimestamp)}
              </div>
            )}
            {section === IN_EVENT && (
              <>
                <div className="saga-active-tier-info">
                  <div className="saga-active-tier-info-wrapper" style={{ backgroundImage: `url(${sagabg})` }}>
                    <div className="saga-active-tier-info-cnt">
                      <p>Current Tier - {activeTier.name}</p>
                      <h1>{`$${convertToSymbols(tierLowerLimit) || 0} - $${convertToSymbols(activeTier.upperLimit) || 0
                        }`}</h1>
                      <p>
                        <Trans>esFXDX per FLP</Trans>: {formatAmountFree(activeTier.rewardRate, 2)}
                      </p>
                      {userStat && (
                        <p>
                          <Trans>Commitment</Trans>:{" "}
                          {formatAmount(userStat.tierCommitments[totalStat.currentActiveTier], FLP_DECIMALS, 4, true)}{" "}
                          FLP
                        </p>
                      )}
                    </div>
                    <div className="saga-flp-circle-chart-wrapper">
                      <div className="saga-flp-circle-chart">
                        <CircularProgressbar
                          value={progressPercentage}
                          strokeWidth={12}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#0D0D0D",
                            trailColor: "#D0D0D0",
                          })}
                        />
                      </div>
                      <div className="saga-flp-circle-chart-text">
                        <div className="saga-flp-circle-chart-text-box">
                          <div className="saga-flp-available-bg"></div>
                          <p>Available</p>
                        </div>
                        <div className="saga-flp-circle-chart-text-box">
                          <div className="saga-flp-filled-bg"></div>
                          <p>Filled</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="saga-flp-swapbox">
                  <SagaFlpSwap
                    ref={flpSwapRef}
                    {...props}
                    userStat={userStat}
                    tierIndex={totalStat.currentActiveTier}
                    totalStat={totalStat}
                  />
                </div>
              </>
            )}
            {section === IN_HOLDING_PERIOD && (
              <div>
                FLP Saga event ended and Hold period started.
                <br />
                <br />
                Hold period will end at {formatDateTime(totalStat.holdEndTime)}.<br />
                Qualified users will start to receive rewards after the Hold period.
                <br />
                <br />
                <br />
                {userStat && (
                  <>
                    {userStat.totalCommitment.eq(0) && (
                      <>
                        Your account was not qualified for Saga rewards because you didn't commit FLP during the Event
                        period.
                        <br />
                        <br />
                      </>
                    )}
                    {userStat.totalCommitment.gt(0) && (
                      <>
                        <div className="saga-explanation">
                          <p>Total Commitment: {formatAmount(userStat.totalCommitment, FLP_DECIMALS, 4, true)} FLP</p>
                          <p>FLP balance: {formatAmount(userStat.flpBalance, FLP_DECIMALS, 4, true)} FLP</p>
                        </div>
                        <br />
                        {!userStat.isQualifiedForRewards && (
                          <>
                            Your account was disqualified for Saga rewards because your FLP balance went below the total
                            commitment during the Hold Period.
                            <br />
                            You will not receive any Saga rewards after the Hold period ends.
                            <br />
                            <br />
                          </>
                        )}
                        {userStat.isQualifiedForRewards && (
                          <>
                            Your account is being qualified for Saga rewards.
                            <br />
                            <br />
                            Please keep the FLP balance higher than the total commitment during the Hold period.
                            <br />
                            If not, your account will be disqualified for Saga rewards and you will not receive any Saga
                            rewards after the Hold period ends.
                            <br />
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {(section === IN_REWARD_PREIOD || section === AFTER_REWARD_PERIOD) && (
              <div>
                {section === IN_REWARD_PREIOD && "The event is in Reward period."}
                {section === AFTER_REWARD_PERIOD && "The whole saga event ended."}
                <br />
                <br />
                {!account && <>Please connect wallet in order to see Saga rewards data.</>}
                {account && userStat && (
                  <>
                    {userStat.initialTotalCommitment.eq(0) && (
                      <>
                        Your account was not qualified for Saga rewards because you didn't commit FLP during the Event
                        period.
                        <br />
                        <br />
                      </>
                    )}
                    {userStat.initialTotalCommitment.gt(0) && (
                      <>
                        <div className="saga-explanation">
                          <p>Total Commitment: {formatAmount(userStat.totalCommitment, FLP_DECIMALS, 4, true)} FLP</p>
                          <p>FLP balance: {formatAmount(userStat.flpBalance, FLP_DECIMALS, 4, true)} FLP</p>
                        </div>
                        <br />
                        {!userStat.isQualifiedForRewards && (
                          <>
                            Your account was disqualified for Saga rewards because your FLP balance went below the total
                            commitment during the Hold Period.
                            <br />
                            <br />
                          </>
                        )}
                        {userStat.isQualifiedForRewards && (
                          <>
                            <div className="saga-explanation">
                              <p>Total Rewards: {formatAmount(totalRewards, FXDX_DECIMALS, 4, true)} esFXDX</p>
                              <p>
                                Distributed Rewards: {formatAmount(distributedRewards, FXDX_DECIMALS, 4, true)} esFXDX
                              </p>
                              <p>Eligible Rewards: {formatAmount(eligibleRewards, FXDX_DECIMALS, 4, true)} esFXDX</p>
                            </div>
                            <br />
                            <br />
                            {section === IN_REWARD_PREIOD && (
                              <>
                                Please keep the FLP balance higher than the total commitment in order to get full
                                rewards.
                                <br />
                                <br />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {section !== "" && (
        <div className="saga-how-it-works">
          <h2>
            <Trans>How it works</Trans>
          </h2>
          <p>
            This is just a brief explanation of how FLP Saga works. For more information, please read{" "}
            <ExternalLink href="https://medium.com/@abhijit_71155/44fe4e8c9cf1">this article</ExternalLink>.
          </p>

          <div className="saga-explanation">
            <p>FLP Saga consists of 3 periods: Event period, Hold period and Reward Period.</p>
            <ul>
              <p>
                <li>Event period is 1 month. In Event period, users buy FLP that will be committed for the rewards.</li>
              </p>
              <p>
                <li>
                  When the Event period ends, "FLP buy amount - FLP sell amount" during the Event period will be
                  commited for FLP Saga rewards.
                </li>
              </p>
              <p>
                <li>As soon as Event period ends, Hold period starts. Hold Period is 1 month.</li>
              </p>
              <p>
                <li>
                  In order to get FLP Saga Rewards, users should keep their FLP balance higher than the total FLP Saga
                  commitment during the Hold period. If a user's FLP balance goes below the total commitment, the user
                  will be disqualified for FLP Saga rewards.
                </li>
              </p>
              <p>
                <li>
                  After the Hold period, users qualified for FLP Saga rewards starts to receive FLP Saga Rewards as
                  esFXDX. Reward rate for each tier is different, and you can check it in the corresponding Tier card.
                </li>
              </p>
              <p>
                <li>Qualified users receive esFXDX rewards every month until the Reward period ends.</li>
              </p>
              <p>
                <li>
                  If a user's FLP balance goes below the total commitment, then the total commitment is reduced by
                  "total commitment - FLP balance" and the amount of rewards that the user receives each month is
                  reduced.
                </li>
              </p>
            </ul>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default FlpSaga;
