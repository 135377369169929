import React, { useState } from "react";
import cx from "classnames";
import { Trans, t, select } from "@lingui/macro";
import Tooltip from "../Tooltip/Tooltip";
import PositionSeller from "./PositionSeller";
import PositionEditor from "./PositionEditor";
import OrdersToa from "./OrdersToa";
import "./PositionsList.css";
import { ImSpinner2 } from "react-icons/im";

import {
  getLiquidationPrice,
  getLeverage,
  getOrderError,
  USD_DECIMALS,
  FUNDING_RATE_PRECISION,
  SWAP,
  LONG,
  SHORT,
  INCREASE,
  DECREASE,
} from "lib/legacy";
import PositionShare from "./PositionShare";
// import PositionDropdown from "./PositionDropdown";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { helperToast } from "lib/helperToast";
import { getUsd } from "domain/tokens/utils";
import { bigNumberify, formatAmount, formatPrice } from "lib/numbers";
import { AiOutlineEdit, AiOutlineCloseCircle, AiOutlineShareAlt } from "react-icons/ai";

const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return [];
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order) => {
      if (order.type === SWAP) {
        return false;
      }
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address;
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true;
      }
    })
    .map((order) => {
      order.error = getOrderError(account, order, undefined, position);
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = t`Order size is bigger than position, will only be executable if position increases`;
      }
      return order;
    });
};

export default function PositionsList(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positions,
    positionsDataIsLoading,
    positionsMap,
    infoTokens,
    account,
    pendingTxns,
    setPendingTxns,
    setListSection,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    setIsWaitingForPluginApproval,
    approveOrderBook,
    isPluginApproving,
    isWaitingForPluginApproval,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    showPnlAfterFees,
    setMarket,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    usdfSupply,
    totalTokenWeights,
    isActionPage,
    showShare,
  } = props;

  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);
  const [positionToShare, setPositionToShare] = useState(null);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined);
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const [triggerPrice, setTriggerPrice] = useState(["", "", "", ""]); // limit close, tp, sl separate prices
  const [orderType, setOrderType] = useState(0);

  const editPosition = (position) => {
    setCollateralTokenAddress(position.collateralToken.address);
    setPositionToEditKey(position.key);
    setIsPositionEditorVisible(true);
  };

  const sellPosition = (position, ORDER_TYPE) => {
    setOrderType(ORDER_TYPE);
    setPositionToSellKey(position.key);
    setIsPositionSellerVisible(true);
    setIsHigherSlippageAllowed(false);
  };

  const onPositionClick = (position) => {
    if (!setMarket) {
      return;
    }
    helperToast.success(
      t`${select(position.isLong, { true: "Long", false: "Short" })} ${position.indexToken.symbol} market selected`
    );
    setMarket(position.isLong ? LONG : SHORT, position.indexToken.address);
  };

  return (
    <div className="PositionsList">
      <PositionEditor
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
      />
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionShareModalOpen && (
        <PositionShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          positionToShare={positionToShare}
          chainId={chainId}
          account={account}
        />
      )}
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionSellerVisible && (
        <PositionSeller
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          orders={orders}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          usdfSupply={usdfSupply}
          totalTokenWeights={totalTokenWeights}
          triggerPrice={triggerPrice}
          orderType={orderType}
        />
      )}
      {positions && (
        <div className="Exchange-list small">
          <div>
            {positions.length === 0 && positionsDataIsLoading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <Trans>Loading...</Trans>
              </div>
            )}
            {positions.length === 0 && !positionsDataIsLoading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <Trans>No open positions</Trans>
              </div>
            )}
            {positions.map((position) => {
              const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
              const liquidationPrice = getLiquidationPrice(position);
              const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
              const positionDelta =
                position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
              let borrowFeeUSD;
              if (position.collateralToken && position.collateralToken.rolloverRate) {
                const borrowFeeRate = position.collateralToken.rolloverRate
                  .mul(position.size)
                  .mul(24)
                  .div(FUNDING_RATE_PRECISION);
                borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2);
              }

              return (
                <div key={position.key} className="App-card">
                  <div className="App-card-title">
                    <span className="Exchange-list-title">{position.indexToken.symbol}</span>
                    <button
                      className="Exchange-list-action App-button-option App-card-option share-btn"
                      onClick={() => {
                        setPositionToShare(position);
                        setIsPositionShareModalOpen(true);
                      }}
                      disabled={position.size.eq(0)}
                    >
                      <div className="share-btn-cnt">
                        <AiOutlineShareAlt />
                        <Trans>Share</Trans>
                      </div>
                    </button>
                  </div>
                  <div className="App-card-divider" />
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Leverage</Trans>
                      </div>
                      <div>
                        {formatAmount(position.leverage, 4, 2, true)}x&nbsp;
                        <span
                          className={cx("Exchange-list-side", {
                            positive: position.isLong,
                            negative: !position.isLong,
                          })}
                        >
                          {position.isLong ? t`Long` : t`Short`}
                        </span>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Size</Trans>
                      </div>
                      <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Collateral</Trans>
                      </div>
                      <div className="position-list-collateral">
                        <Tooltip
                          handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                          position="right-bottom-right"
                          handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                          renderContent={() => {
                            return (
                              <>
                                {position.hasLowCollateral && (
                                  <div>
                                    <Trans>
                                      WARNING: This position has a low amount of collateral after deducting borrowing
                                      fees, deposit more collateral to reduce the position's liquidation risk.
                                    </Trans>
                                    <br />
                                    <br />
                                  </div>
                                )}
                                <StatsTooltipRow
                                  label={t`Initial Collateral`}
                                  value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                />
                                <StatsTooltipRow
                                  label={t`Rollover Fee`}
                                  value={formatAmount(position.rolloverFee, USD_DECIMALS, 2, true)}
                                />
                                <StatsTooltipRow label={t`Rollover Fee / Day`} value={borrowFeeUSD} />
                                <span>
                                  <Trans>Use the Edit Collateral icon to deposit or withdraw collateral.</Trans>
                                </span>
                              </>
                            );
                          }}
                        />
                        <span className="edit-icon" onClick={() => editPosition(position)}>
                          <AiOutlineEdit fontSize={16} />
                        </span>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>PnL</Trans>
                      </div>
                      <div>
                        <span
                          className={cx("Exchange-list-info-label", {
                            positive: hasPositionProfit && positionDelta.gt(0),
                            negative: !hasPositionProfit && positionDelta.gt(0),
                            muted: positionDelta.eq(0),
                          })}
                        >
                          {position.deltaStr} ({position.deltaPercentageStr})
                        </span>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Net Value</Trans>
                      </div>
                      <div>
                        <Tooltip
                          handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                          position="right-bottom-right"
                          handleClassName="plain"
                          renderContent={() => {
                            return (
                              <>
                                {t`Net Value: Initial Collateral - Fees + PnL`}
                                <br />
                                <br />
                                <StatsTooltipRow
                                  label={t`Initial Collateral`}
                                  value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                />
                                <StatsTooltipRow
                                  label={t`PnL`}
                                  value={position.deltaBeforeFeesStr}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={t`Rollover Fee`}
                                  value={formatAmount(position.rolloverFee, USD_DECIMALS, 2, true)}
                                />
                                <StatsTooltipRow label={t`Open + Close fee`} value={"0"} />
                                <StatsTooltipRow
                                  label={t`Funding Fee`}
                                  value={formatAmount(position.positionFee, USD_DECIMALS, 2, true)}
                                />
                                <StatsTooltipRow
                                  label={t`PnL After Fees`}
                                  value={`${position.deltaAfterFeesStr} (${position.deltaAfterFeesPercentageStr})`}
                                  showDollar={false}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Orders</Trans>
                      </div>
                      <div>
                        {positionOrders.length === 0 && "None"}
                        {positionOrders.map((order) => {
                          const orderText = () => (
                            <>
                              {order.triggerAboveThreshold ? ">" : "<"} {formatPrice(order.triggerPrice, true)}:
                              {order.type === INCREASE ? " +" : " -"}$
                              {formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
                            </>
                          );
                          if (order.error) {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                <Tooltip
                                  className="order-error"
                                  handle={orderText()}
                                  position="right-bottom"
                                  handleClassName="plain"
                                  renderContent={() => <span className="negative">{order.error}</span>}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                {orderText()}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider" />
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Mark Price</Trans>
                      </div>
                      <div>${formatPrice(position.markPrice, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Entry Price</Trans>
                      </div>
                      <div>${formatPrice(position.averagePrice, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Liq. Price</Trans>
                      </div>
                      <div>${formatPrice(liquidationPrice, true)}</div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  {!isActionPage && (
                    <div className="App-card-options position-close-small">
                      {/* <button
                        className="App-button-option App-card-option"
                        disabled={position.size.eq(0)}
                        onClick={() => sellPosition(position)}
                      >
                        <Trans>Close</Trans>
                      </button>
                      <button
                        className="App-button-option App-card-option"
                        disabled={position.size.eq(0)}
                        onClick={() => editPosition(position)}
                      >
                        <Trans>Edit Collateral</Trans>
                      </button> */}
                      {/* <div className="App-card-divider" /> */}
                      <div className="label">
                        <Trans>Close Orders</Trans>
                      </div>
                      <div className="order-container mark">
                        <div onClick={() => sellPosition(position, 0)} className={"action"}>
                          <Tooltip
                            handle={t`Mark`}
                            noIcon={true}
                            position="left-top action-handle"
                            renderContent={() => {
                              return <>Close at market price</>;
                            }}
                          />
                        </div>
                        <div className="order-container">
                          <p className="mark">{`${formatPrice(position.markPrice, true)}`} </p>
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 0)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineCloseCircle fontSize={18} />
                          </span>
                        </div>
                      </div>

                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 1)} className={"action"}>
                          <Tooltip
                            handle={t`Limit`}
                            noIcon={true}
                            position="left-top action-handle"
                            renderContent={() => {
                              return <>Close at limit price</>;
                            }}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[1]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", value, triggerPrice[2], triggerPrice[3]]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 1)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>

                      <div className="App-card-divider" />

                      <div className="label">
                        <Trans>Trigger Orders</Trans>
                      </div>
                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 2)} className={"action"}>
                          <Tooltip
                            handle={t`TP`}
                            noIcon={true}
                            position="left-top action-handle"
                            renderContent={() => {
                              return <>Take Profit Order</>;
                            }}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[2]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", triggerPrice[1], value, triggerPrice[3]]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 2)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>

                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 3)} className={"action"}>
                          <Tooltip
                            handle={t`SL`}
                            noIcon={true}
                            position="left-top action-handle"
                            renderContent={() => {
                              return <>Stop Loss Order</>;
                            }}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[3]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", triggerPrice[1], triggerPrice[2], value]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 3)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <table className={cx("Exchange-list large App-box", { "Orders-overview-table": isActionPage })}>
        <thead>
          <tr className="Exchange-list-header">
            <th>
              <Trans>Position</Trans>
            </th>
            <th>
              <Trans>Net Value</Trans>
            </th>
            <th>
              <Trans>Size</Trans>
            </th>
            <th>
              <Trans>Collateral</Trans>
            </th>
            {/* <th>
              <Trans>Mark Price</Trans>
            </th> */}
            <th>
              <Trans>Entry Price</Trans>
            </th>
            <th>
              <Trans>Liq. Price</Trans>
            </th>
            <th>
              <Trans>PnL</Trans>
            </th>
            {!isActionPage && (
              <>
                <th>
                  <Trans>Take Profit / Stop Loss</Trans>
                </th>
                <th>
                  <Trans>Close Position</Trans>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {positions.length === 0 && positionsDataIsLoading && (
            <tr className="Exchange-list-item">
              <td colSpan="15">
                <div className="Exchange-empty-positions-list-note">Loading...</div>
              </td>
            </tr>
          )}
          {positions.length === 0 && !positionsDataIsLoading && (
            <tr className="Exchange-list-item">
              <td colSpan="15">
                <div className="Exchange-empty-positions-list-note">
                  <Trans>No open positions</Trans>
                </div>
              </td>
            </tr>
          )}
          {positions.map((position) => {
            const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
            const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
            const hasOrderError = !!positionOrders.find((order) => order.error);
            const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
            const positionDelta =
              position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
            let borrowFeeUSD;
            if (position.collateralToken && position.collateralToken.rolloverRate) {
              const borrowFeeRate = position.collateralToken.rolloverRate
                .mul(position.size)
                .mul(24)
                .div(FUNDING_RATE_PRECISION);
              borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2);
            }

            return (
              <tr key={position.key} className="Exchange-list-item">
                <td
                  className={cx({
                    positive: position.isLong,
                    negative: !position.isLong,
                    title: position,
                    clickable: position,
                  })}
                  onClick={() => !isActionPage && onPositionClick(position)}
                >
                  <div className="Postion-lev-tag">
                    <div className="Exchange-list-title">
                      {position.indexToken.symbol}
                      {position.hasPendingChanges && (
                        <ImSpinner2 className="spin position-loading-icon" color="white" />
                      )}
                    </div>
                    <div className="Exchange-list-info-label">
                      {/* <span className="perpetual-tag"> */}
                      <span className={cx({ positive: position.isLong, negative: !position.isLong })}>
                        {position.isLong ? t`Long` : t`Short`}
                      </span>
                    </div>
                  </div>
                  {position.netValue && position.leverageStr && !position.hasPendingChanges && (
                    <span className="muted Position-leverage">{position.leverageStr}</span>
                  )}
                </td>
                <td>
                  <div>
                    {!position.netValue && t`Opening...`}
                    {position.netValue && (
                      <Tooltip
                        handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                        position="left-bottom"
                        handleClassName="plain"
                        renderContent={() => {
                          return (
                            <>
                              {t`Net Value: Initial Collateral - Fees + PnL`}
                              <br />
                              <br />
                              <StatsTooltipRow
                                label={t`Initial Collateral`}
                                value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                              />
                              <StatsTooltipRow label={t`PnL`} value={position.deltaBeforeFeesStr} showDollar={false} />
                              <StatsTooltipRow
                                label={t`Rollover Fee`}
                                value={formatAmount(position.rolloverFee, USD_DECIMALS, 2, true)}
                              />
                              <StatsTooltipRow label={t`Open + Close fee`} value={"0"} />
                              <StatsTooltipRow
                                label={t`Funding Fee`}
                                value={formatAmount(position.positionFee, USD_DECIMALS, 2, true)}
                              />
                              <StatsTooltipRow
                                label={t`PnL After Fees`}
                                value={`${position.deltaAfterFeesStr} (${position.deltaAfterFeesPercentageStr})`}
                                showDollar={false}
                              />
                            </>
                          );
                        }}
                      />
                    )}
                  </div>
                  {/* {position.deltaStr && (
                    <div
                      className={cx("Exchange-list-info-label", {
                        positive: hasPositionProfit && positionDelta.gt(0),
                        negative: !hasPositionProfit && positionDelta.gt(0),
                        muted: positionDelta.eq(0),
                      })}
                    >
                      {position.deltaStr} ({position.deltaPercentageStr})
                    </div>
                  )} */}
                </td>
                <td>
                  <div className="clickable">${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                  {positionOrders.length > 0 && (
                    <div onClick={() => setListSection && setListSection("Orders")}>
                      <Tooltip
                        handle={t`Orders (${positionOrders.length})`}
                        position="left-bottom"
                        handleClassName={cx(
                          ["Exchange-list-info-label", "Exchange-position-list-orders", "plain", "clickable"],
                          { muted: !hasOrderError, negative: hasOrderError }
                        )}
                        renderContent={() => {
                          return (
                            <>
                              <strong>
                                <Trans>Active Orders</Trans>
                              </strong>
                              {positionOrders.map((order) => {
                                return (
                                  <div
                                    key={`${order.isLong}-${order.type}-${order.index}`}
                                    className="Position-list-order"
                                  >
                                    {order.triggerAboveThreshold ? ">" : "<"} {formatPrice(order.triggerPrice, true)}:
                                    {order.type === INCREASE ? " +" : " -"}$
                                    {formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
                                    {order.error && (
                                      <>
                                        , <span className="negative">{order.error}</span>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          );
                        }}
                      />
                    </div>
                  )}
                </td>
                <td>
                  <div className="position-list-collateral">
                    <Tooltip
                      handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                      position="left-bottom"
                      handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                      renderContent={() => {
                        return (
                          <>
                            {position.hasLowCollateral && (
                              <div>
                                <Trans>
                                  WARNING: This position has a low amount of collateral after deducting borrowing fees,
                                  deposit more collateral to reduce the position's liquidation risk.
                                </Trans>
                                <br />
                                <br />
                              </div>
                            )}

                            <StatsTooltipRow
                              label={t`Initial Collateral`}
                              value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                            />
                            <StatsTooltipRow
                              label={t`Rollover Fee`}
                              showDollar={false}
                              value={`-$${formatAmount(position.rolloverFee, USD_DECIMALS, 2, true)}`}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label={t`Rollover Fee / Day`}
                              value={`-$${borrowFeeUSD}`}
                            />
                            <br />
                            <Trans>Use the Edit Collateral icon to deposit or withdraw collateral.</Trans>
                          </>
                        );
                      }}
                    />
                    <span className="edit-icon" onClick={() => editPosition(position)}>
                      <AiOutlineEdit fontSize={16} />
                    </span>
                  </div>
                </td>
                {/* <td className="clickable" onClick={() => onPositionClick(position)}>
                  <Tooltip
                    handle={`$${formatPrice(position.markPrice, true)}`}
                    position="left-bottom"
                    handleClassName="plain clickable"
                    renderContent={() => {
                      return (
                        <div>
                          <Trans>
                            Click on a row to select the position's market, then use the swap box to increase your
                            position size if needed.
                          </Trans>
                          <br />
                          <br />
                          <Trans>
                            Use the "Close" button to reduce your position size, or to set stop-loss / take-profit
                            orders.
                          </Trans>
                        </div>
                      );
                    }}
                  />
                </td> */}
                <td className="clickable" onClick={() => !isActionPage && onPositionClick(position)}>
                  ${formatPrice(position.averagePrice, true)}
                </td>
                <td className="clickable" onClick={() => !isActionPage && onPositionClick(position)}>
                  ${formatPrice(liquidationPrice, true)}
                </td>
                <td className="pnl" onClick={() => !isActionPage && onPositionClick(position)}>
                  {position.deltaStr && (
                    <div
                      className={cx("Exchange-list-info-label pnl-row", {
                        positive: hasPositionProfit && positionDelta.gt(0),
                        negative: !hasPositionProfit && positionDelta.gt(0),
                        muted: positionDelta.eq(0),
                      })}
                      style={{ width: `50px` }}
                    >
                      <span>{position.deltaStr}</span>
                      <span>{position.deltaPercentageStr}</span>
                    </div>
                  )}
                </td>

                {!isActionPage && (
                  <>
                    <td>
                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 2)} className={"action"}>
                          <Tooltip
                            handle={t`TP`}
                            noIcon={true}
                            position="center-top action-handle"
                            renderContent={() => {
                              return <>Take Profit Order</>;
                            }}
                          />
                        </div>
                        <div className="order-container">
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[2]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", triggerPrice[1], value, triggerPrice[3]]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 2)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>

                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 3)} className={"action"}>
                          <Tooltip
                            handle={t`SL`}
                            noIcon={true}
                            position="center-top action-handle"
                            renderContent={() => {
                              return <>Stop Loss Order</>;
                            }}
                          />
                        </div>
                        <div className="order-container">
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[3]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", triggerPrice[1], triggerPrice[2], value]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 3)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="order-container mark">
                        <div onClick={() => sellPosition(position, 0)} className={"action"}>
                          <Tooltip
                            handle={t`Mark`}
                            noIcon={true}
                            position="center-top action-handle"
                            renderContent={() => {
                              return <>Close at market price</>;
                            }}
                          />
                        </div>
                        <div className="order-container">
                          <p className="mark">{`${formatPrice(position.markPrice, true)}`} </p>
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 0)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineCloseCircle fontSize={18} />
                          </span>
                        </div>
                      </div>

                      <div className="order-container">
                        <div onClick={() => sellPosition(position, 1)} className={"action"}>
                          <Tooltip
                            handle={t`Limit`}
                            noIcon={true}
                            position="center-top action-handle"
                            renderContent={() => {
                              return <>Close at limit price</>;
                            }}
                          />
                        </div>
                        <div className="order-container">
                          <input
                            type="number"
                            className="App-close-order"
                            min="0"
                            value={triggerPrice[1]}
                            onChange={(e) => {
                              let value = e.target.value || "";
                              value = value.replace(/[-eE]+/g, "");
                              setTriggerPrice(["", value, triggerPrice[2], triggerPrice[3]]);
                            }}
                          />
                          <span
                            className="edit-icon"
                            onClick={() => sellPosition(position, 1)}
                            disabled={position.size.eq(0)}
                          >
                            <AiOutlineEdit fontSize={18} />
                          </span>
                        </div>
                      </div>
                    </td>
                  </>
                )}
                {/* <td>
                  <PositionDropdown
                    handleEditCollateral={() => {
                      editPosition(position);
                    }}
                    handleShare={() => {
                      setPositionToShare(position);
                      setIsPositionShareModalOpen(true);
                    }}
                    handleMarketSelect={() => {
                      onPositionClick(position);
                    }}
                  />
                </td> */}
                {showShare && !position.hasPendingChanges && (
                  <td>
                    <button
                      className="share-btn"
                      onClick={() => {
                        setPositionToShare(position);
                        setIsPositionShareModalOpen(true);
                      }}
                    >
                      <div className="share-btn-cnt">
                        <AiOutlineShareAlt />
                        Share
                      </div>
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
