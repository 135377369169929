import React, { useState } from "react";
import "./LaunchCard.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import ActionCard from "components/ActionCard/ActionCard";
import { Timer } from "components/Timer/Timer";
const LaunchCard = ({ props }) => {
  const [expandedView, setexpandedView] = useState(props.clickable);
  return (
    <div
      className={`launch-card-container ${expandedView ? "expanded-border" : ""} ${props.clickable ? "" : "blur-it"}`}
    >
      <div
        className={`title ${expandedView ? "expanded-bg " : ""} ${props.clickable ? "clickable" : ""}`}
        onClick={() => {
          props.clickable && setexpandedView(!expandedView);
        }}
      >
        Phase {props.Stage}: {props.title}
        {expandedView ? (
          <FiChevronUp size={25} className={`expand-icon ${expandedView ? "expanded-bg" : ""}`} />
        ) : (
          <FiChevronDown size={25} className="expand-icon" />
        )}
      </div>
      <div className="launch-card-overview">
        <div className="overview-left">
          <p className="overview-left-desc1">{props.desc1}</p>
          <p className="overview-left-desc2">{props.desc2}</p>
          {/* <a href="/" className="learn-more">
            Learn More
          </a> */}
        </div>
        <div className="overview-right">
          {props.completed && <BsCheckCircleFill size={26} />}
          <div className="overview-right-content">
            Phase {props.Stage}: {props.title}
          </div>

          <div className="overview-right-subheading">
            {props.completed ? (
              "Completed"
            ) : props.started ? (
              <Timer endTime={props.endAt} text="Ends" />
            ) : (
              <Timer endTime={props.startAt} text="Starts" />
            )}
          </div>
        </div>
      </div>
      {expandedView && (
        <div className="expanded-view-container">
          <h2 className="expanded-title">{props.expandedTitle}</h2>
          <p className="expanded-desc">{props.expandedDesc}</p>
          <div className="action-card-container">
            {props.actions.map((ele, index) => (
              <ActionCard props={ele} key={`${ele.icons}-action-card`} />
            ))}{" "}
          </div>
          <div className="info-red">
            <p>
              Note: All the tasks & campaigns must be completed by the end of Phase 1 in order to qualify for $esFXDX
              airdrop.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LaunchCard;
