import React, { useState } from "react";
import LeaderBoardMain from "./LeaderBoardMain";
import "./leaderboard.css";
import useWallet from "lib/wallets/useWallet";
import Select from "react-select";

const Leaderboard = () => {
  const options = [
    { value: "daily", label: "1 Day" },
    { value: "weekly", label: "7 Days" },
    { value: "biweekly", label: "14 Days" },
    { value: "total", label: "All Time" },
  ];
  const { chainId } = useWallet();
  const [period, setPeriod] = useState(options[3].value)

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: "#fff",
      backgroundColor: "#010101",
      margin: "0",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#010101",
      padding: "0",
      border: "none",
      boxShadow: "none",
      margin: "0",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff", backgroundColor: "#010101", margin: "0" }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      backgroundColor: "#010101",
      padding: "0",
    }),
    menubar: (defaultStyles) => ({ ...defaultStyles, color: "#fff", backgroundColor: "#010101", padding: "0", margin: "0" }),
    indicatorSeparator: (defaultStyles) => ({ ...defaultStyles, display: "none" }),
  };


  return (
    <div className="default-container table-container">
      <div className="header-row">
        <div> <h1 className="leaderboard-heading">Leaderboard</h1>
          <p className="leaderboard-subtitle">See where you fit against the best</p>
          {/* <p className="leaderboard-subtitle">For more details on our $1000 esFXDX Trading Competition: <a href="https://bit.ly/3tFSyl9">Click Here</a></p> */}
        </div>
        {/* <div>
          <Select
            className="time-dropdown"
            options={options}
            defaultValue={options[1]}
            styles={customStyles}
            isSearchable={false}
            onChange={(choice) => {
              setPeriod(choice.value)
            }}
          />
        </div> */}
      </div>

      <div>{chainId && <LeaderBoardMain period={period} chainId={chainId} />}</div>
    </div>
  );
};

export default Leaderboard;
