import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { Timer } from "components/Timer/Timer";

import "./LaunchCard.css";
import "./LaunchCard3.css";

const LaunchCard3 = ({ props }) => {
  const [expandedView, setexpandedView] = useState(props.clickable);
  return (
    <div
      className={`launch-card-container ${expandedView ? "expanded-border" : ""} ${props.clickable ? "" : "blur-it"}`}
    >
      <div
        className={`title ${expandedView ? "expanded-bg " : ""} ${props.clickable ? "clickable" : ""}`}
        onClick={() => {
          props.clickable && setexpandedView(!expandedView);
        }}
      >
        Phase {props.Stage}: {props.title}
        {expandedView ? (
          <FiChevronUp size={25} className={`expand-icon ${expandedView ? "expanded-bg" : ""}`} />
        ) : (
          <FiChevronDown size={25} className="expand-icon" />
        )}
      </div>
      <div className="launch-card-overview">
        <div className="overview-left">
          <p className="overview-left-desc1">{props.desc1}</p>
          <p className="overview-left-desc2">{props.desc2}</p>
          {/* <a href="/" className="learn-more">
            Learn More
          </a> */}
        </div>
        <div className="overview-right">
          {props.completed && <BsCheckCircleFill size={26} />}
          <div className="overview-right-content">
            Phase {props.Stage}: {props.title}
          </div>

          <div className="overview-right-subheading">
            {props.completed ? (
              "Completed"
            ) : props.started ? (
              <Timer endTime={props.endAt} text="Ends" />
            ) : (
              <Timer endTime={props.startAt} text="Starts" />
            )}
          </div>
        </div>
      </div>
      {expandedView && (
        <div className="expanded-wrapper">
          <div className="expanded-container">
            <div className="expanded-info-table">
              <div className="info-table-title info-table-row">
                <p>Tier</p>
                <p>FLP Amount Range</p>
                <p>Tier Multiplier</p>
                <p>Total allocation for Tier</p>
              </div>
              <div className="info-table-row border-bottom ">
                <p>1</p>
                <p>0 - 250,000</p>
                <p>20</p>
                <p>5,000,000</p>
              </div>
              <div className="info-table-row border-bottom ">
                <p>2</p>
                <p>250,000 - 750,000</p>
                <p>17.5</p>
                <p>8,750,000</p>
              </div>
              <div className="info-table-row border-bottom ">
                <p>3</p>
                <p>750,000 - 1,500,000</p>
                <p>15</p>
                <p>11,250,000</p>
              </div>
              <div className="info-table-row border-bottom ">
                <p>4</p>
                <p>1,500,000 - 3,000,000</p>
                <p>12.5</p>
                <p>18,750,000</p>
              </div>
              <div className="info-table-row border-bottom ">
                <p>5</p>
                <p>3,000,000 - 5,000,000</p>
                <p>10</p>
                <p>20,000,000</p>
              </div>
              <div className="info-table-row border-bottom">
                <p>6</p>
                <p>5,000,000 - 10,000,000</p>
                <p>5</p>
                <p>25,000,000</p>
              </div>
              <div className="info-table-row total-row ">
                <p className="total-row-value ">Total</p>
                <p></p>
                <p></p>
                <p className="total-row-value">88,750,000</p>
              </div>
            </div>

            <div className="expanded-info-text">
              <h3 className="info-title">FLP Saga Rules</h3>
              <ul>
                <li>
                  Users must note that the liquidity they commit during this phase or previously commited will be locked
                  till the end of the hold period. After the end of hold period, users will be able to withdraw their
                  funds at any time, if they wish to. However, please note that once they opt to withdraw, they will
                  forfeit the monthly rewards after the hold period
                </li>
                <li>
                  In the event that a user chooses to withdraw their liquidity from the pool, the rewards for the
                  current month as well as subsequent months will be distributed among the remaining participants
                </li>
              </ul>
              <div className="imp-dates">
                <h3 className="bold-text">Important Dates:</h3>
                <ul>
                  <li>
                    <span>
                      Event Start Date: <span className="bold-text">12th October, 2023 12:00 PM UTC</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Event End Date: <span className="bold-text">12th November, 2023 12:00 PM UTC</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Hold Period Starts: <span className="bold-text">12th November, 2023 12:00 PM UTC</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Hold Period Ends: <span className="bold-text">12th December, 2023 12:00 PM UTC</span>
                    </span>
                  </li>
                </ul>
              </div>

              <div className="action-cnt">
                <p>
                  Click the below Button to launch <span className="bold-text">FLP SAGA</span>
                </p>
                <Link to="/flp-saga">
                  <button className="action-btn">FLP SAGA🚀</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="expanded-footer">
            <ul>
              <li>
                All liquidity contributions will be subject to an automatic lockup until the end of the Hold Period.
                During this duration, FLP holders will continue to accumulate fee rewards in USDbC and receive airdrop
                rewards as per the usual.
              </li>
              <li>
                Furthermore, any FLP Saga Rewards will be provided in addition to these existing rewards, and the
                distribution of these rewards will commence at the end of the Hold Period. This applies to all current
                FLP holders, as well as those who become FLP holders as soon as the Event commences.
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LaunchCard3;
