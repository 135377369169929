import React, { useState } from "react";
import "./Launch.css";
import LaunchCard from "components/LaunchCard/LaunchCard";
import { data } from "./constants";
import launchRM from "../../img/Launchv2img.svg";
import LaunchCardMain from "components/LaunchCardMain/LaunchCardMain";
import { Timer } from "components/Timer/Timer";
import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import LaunchCard2 from "components/LaunchCard/LaunchCard2";
import LaunchCard3 from "components/LaunchCard/LaunchCard3";

export function getTimeLeft(timestamp) {
  const now = new Date().getTime();
  const difference = timestamp - now;

  if (difference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

const Launch = ({ pendingTxns, setPendingTxns }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [mobileCrdIndex, setMobileCrdIndex] = useState(2);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setMobileCrdIndex((mobileCrdIndex + 1) % data.length);
    }
    if (isRightSwipe) {
      if (mobileCrdIndex === 0) {
        setMobileCrdIndex(data.length - 1);
      } else {
        setMobileCrdIndex((mobileCrdIndex - 1) % data.length);
      }
    }
  };

  console.log("mobilecardIndex", mobileCrdIndex);
  return (
    <SEO title={getPageTitle("Launch")}>
      <div className="launch-container default-container">
        <h2>Launch</h2>
        <p>
          The launch of FXDX will be done in four key phases. Each launch phase will have different objectives. Below,
          we discuss the specific details and how you can participate to earn benefits from each phase:
        </p>
        <div className="launchcard-mbl">
          <div className="swipper" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <LaunchCardMain props={data[mobileCrdIndex]} />
          </div>
          <div className="launchcard-imageindex">
            {data &&
              data.map((ele, index) => (
                <p
                  className={`${mobileCrdIndex === index ? "highlight-text" : ""}`}
                  key={`${index}-indicator`}
                  onClick={() => setMobileCrdIndex(index)}
                >
                  &#8226;
                </p>
              ))}
          </div>
          <div>
            {mobileCrdIndex === 0 ? (
              <LaunchCard props={data[mobileCrdIndex]} />
            ) : mobileCrdIndex === 1 ? (
              <LaunchCard2 props={data[mobileCrdIndex]} />
            ) : (
              <LaunchCard3 props={data[2]} pendingTxns={pendingTxns} setPendingTxns={setPendingTxns} />
            )}

            {/* <LaunchCard3
              props={data[2]}
              key={`index-mbl-${data[0].Stage}`}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
            /> */}
          </div>
        </div>

        <div className="flowchart">
          <img src={launchRM} alt="roadmap" />
          <div className="flowchart-actions">
            {data &&
              data.map((ele, index) => {
                return (
                  <button
                    className={`flowchart-actions-btn ${ele.completed ? "" : "highlight-text"}`}
                    key={`${index}-launch-btn`}
                  >
                    {ele.completed ? (
                      "Completed"
                    ) : ele.started ? (
                      <Timer endTime={ele.endAt} text="Ends" />
                    ) : (
                      <Timer endTime={ele.startAt} text="Starts" />
                    )}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="launchcard-desktop">
          {data &&
            data.map((ele, index) => {
              if (ele.clickable) {
                if (index === 1) {
                  return <LaunchCard2 props={ele} key={`index ${ele.Stage}`} />;
                }
                if (index === 2) {
                  return <LaunchCard3 props={ele} key={`index ${ele.Stage}`} />;
                }
                return <LaunchCard props={ele} key={`index ${ele.Stage}`} />;
              } else {
                return <div key={`index ${ele.Stage}`}></div>;
              }
            })}
        </div>
        <Footer />
      </div>
    </SEO>
  );
};

export default Launch;
