import React, { useEffect, useState } from "react";
import { getTimeLeft } from "pages/Launch/Launch";
import "./Timer.css";

export const Timer = ({ endTime, text }) => {
  const [daysleft, setDays] = useState(0);
  const [hoursleft, setHours] = useState(0);
  const [minutesleft, setMinutes] = useState(0);
  const [secondsleft, setSeconds] = useState(0);

  const getRunningTimer = (timestamp) => {
    let { days, hours, minutes, seconds } = getTimeLeft(timestamp);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    getRunningTimer(endTime);
    const interval = setInterval(() => getRunningTimer(endTime), 1000);

    return () => clearInterval(interval);
  }, [endTime, text]);

  return (
    <div className="main-timer-cnt">
      <p>{`${text}`} in:</p>
      <div className="main-timer">
        <p>{`${daysleft}d :`}</p>
        <p>{` ${hoursleft}h :`}</p>
        <p>{` ${minutesleft}m :`}</p>
        <p>{` ${secondsleft}s`}</p>
      </div>
    </div>
  );
};
