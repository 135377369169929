import React from "react";
import "./CoinInfoRow.css";

const CoinInfoRow = ({ coin }) => {
  return (
    <div className="coin-info-row-wrapper">
      <div className="title">
        <h2>{coin.name}</h2>
        <h3>({coin.symbol})</h3>
      </div>
      <div className="coin-info-row-body-row">
        <p className="label">Issue Date:</p>
        <p className="value">{coin.Issue_Time}</p>
      </div>
      <div className="coin-info-row-body-row">
        <p className="label">total Suppl:y</p>
        <p className="value">{coin.Total_Supply}</p>
      </div>
      <div className="coin-info-row-body-row">
        <p className="label">Circulation:</p>
        <p className="value">{coin.Circulation}</p>
      </div>
      <div className="coin-info-row-body-row">
        <p className="label">Whitepaper:</p>
        <a className="value" href={coin.White_Paper}>
          {coin.White_Paper}
        </a>
      </div>
      <div className="coin-info-row-body-row">
        <p className="label">Website:</p>
        <a className="value" href={coin.Website}>
          {coin.Website}
        </a>
      </div>
      <div className="coin-info-row-body-desc">
        <p>Description:</p>
        <p>{coin.description}</p>
      </div>
    </div>
  );
};

export default CoinInfoRow;
