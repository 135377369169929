export const data = [
  {
    Stage: 1,
    title: "Public Open Beta",
    desc1:
      "Join the Exclusive Open Beta and dive into FXDX's cutting-edge features. Plus, grab a chance to earn $esFXDX token airdrops along the way. To claim your airdrops, begin your trading journey on FXDX or add assets to our liquidity pool.",
    desc2:
      " Engage in diverse campaigns on Zealy.io to collect experience points, and secure your piece of the airdrop pie.",
    startAt: 1693828800000, // September 4, 2023 12:00 PM
    endAt: 1696939200000, // Oct 10, 2023 12:00 PM
    get completed() {
      return Date.now() > this.endAt;
    },
    get started() {
      return Date.now() > this.startAt;
    },
    clickable: true,
    expandedTitle: "Public Open Beta",
    expandedDesc:
      "We are thrilled to announce that our Open Beta participants will have the exclusive opportunity to receive up to 10,000,000 $esFXDX tokens, which accounts for 1% of our total supply. This is an exciting chance for early adopters to become part of our community and reap the benefits of our airdrop rewards program.",
    actions: [
      {
        icons: "wallet",
        title: "Earn $esFXDX Airdrops",
        description: "Trade on our platform to earn airdrop",
        actionName: "Trade",
        link: "https://app.fxdx.exchange/trade",
      },
      {
        icons: "airdrop",
        title: "Earn $esFXDX Airdrops",
        description: "Make a deposit in our liquidity pool (FLP) to earn airdrop.",
        actionName: "Deposit",
        link: "https://app.fxdx.exchange/buy",
      },
      {
        icons: "speaker",
        title: "Finish Zealy Campaigns",
        description: "Participate in the quests of zealy.io to boost your $esFXDX rewards.",
        actionName: "Join FXDX",
        link: "https://zealy.io/c/fxdxonbase/questboard",
      },
      {
        icons: "chat",
        title: "Share Feedback",
        description: "Join our Discord server and let us know how we can enhance your experience!",
        actionName: "Submit Feedback",
        link: "https://discord.gg/AdmFvVjfjh",
      },
    ],
  },
  {
    Stage: 2,
    title: "FXDX TGE",
    desc1:
      "Dive into the Token Generation Event (TGE) for $FXDX token, happening over a span of 7 days through an equitable, transparent, and open price determination method. Our methodology is curated to level the playing field - no upper hand for gas-intensive whales, snipe bots, or insiders.",
    desc2:
      "Contribute ETH/USDbC seamlessly to the pool and receive $FXDX tokens in return. Every participant gets $FXDX at the identical rate, with tokens being liquid and available for claim right after the TGE.",
    get completed() {
      return Date.now() > this.endAt;
    },
    get started() {
      return Date.now() > this.startAt;
    },
    startAt: 1696168800000, // September 30, 2023 10:00 PM
    endAt: 1696701600000, // October 7, 2023 6:00 PM
    clickable: true,
    expandedTitle: "FXDX TGE",
    expandedDesc:
      "Up to 1,000,000 $FXDX tokens will be available for sale to the participants of the Token Generation Event. Contribute ETH/USDBC seamlessly to the pool and receive $FXDX tokens in return. Every participant gets $FXDX at the identical rate, with tokens being liquid and available for claim right after the TGE.",
    expandedDescPointsHead: "",
    expandedDescPoints: [
      "Minimum Amount: None",
      "FXDX Tokens for Sale: 1,000,000",
      "Contribution in: ETH and USDbC",
      "Vesting: None and available immediately after TGE",
    ],
  },
  {
    Stage: 3,
    title: "FLP Saga",
    desc1:
      "Join the initiation of our liquidity pool (FLP vault) and secure a portion of the 88,750,000 $esFXDX tokens (8.8% of total supply) as bonus rewards. These bonus incentives will be spread out monthly over a year, in addition to the standard yields earned by FLP vault depositors.",
    desc2: "The sooner you jump in, the greater your potential rewards.",
    get completed() {
      return Date.now() > this.endAt;
    },
    get started() {
      return Date.now() > this.startAt;
    },
    startAt: 1697112000000, // October 11, 2023 12:00 PM
    endAt: 1699790400000, // November 11, 2023 12:00 PM
    clickable: true,
    expandedTitle: "FLP Saga",
    expandedDesc:
      "Up to 200,000 $esFXDX tokens (2% of the total supply) will be distributed as rewards for participants of the Public Open Beta. Complete the tasks and campaigns on zealy.io to earn a share of airdrop rewards. More details here.",
  },
  {
    Stage: 4,
    title: "Ultimate Kickoff",
    desc1:
      "All bonus rewards are activated in this stage.Public Open Beta participants can expect to claim their incentives in late September.",
    desc2: " ",
    get completed() {
      return Date.now() > this.endAt;
    },
    get started() {
      return Date.now() > this.startAt;
    },
    startAt: 1699704000000, // November 11, 2023 12:00 PM
    endAt: 1699704000000, // November 11, 2023 12:00 PM
    clickable: false,
    expandedTitle: "Open Public Beta",
    expandedDesc: "Thank you for being a part of the FXDX's Launch event.",
  },
];
