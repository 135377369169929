import React, { useState, useEffect, useCallback, useRef /*, useMemo*/ } from "react";
import { /*useSWR,*/ SWRConfig } from "swr";
import { useLocalStorage } from "react-use";
import { ethers } from "ethers";
import { DragSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";
import { cssTransition, ToastContainer /*, toast*/ } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { watchNetwork } from "@wagmi/core";
import { useDisconnect } from "wagmi";

import { Routes, Route, useLocation, useNavigate, Navigate, Link } from "react-router-dom";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

// import { ImSpinner2 } from "react-icons/im";
// import { AiOutlineClose } from "react-icons/ai";

import { getProvider } from "lib/rpc";
import useRouteQuery from "lib/useRouteQuery";
import useScrollToTop from "lib/useScrollToTop";
import {
  DEFAULT_SLIPPAGE_AMOUNT,
  BASIS_POINTS_DIVISOR,
  getAppBaseUrl,
  isHomeSite,
  REFERRAL_CODE_QUERY_PARAM,
  isDevelopment,
} from "lib/legacy";

import { encodeReferralCode, decodeReferralCode } from "domain/referrals";

import Home from "pages/Home/Home";
import Dashboard from "pages/Dashboard/Dashboard";
// import Ecosystem from "pages/Ecosystem/Ecosystem";
import Stake from "pages/Stake/Stake";
import { Swap } from "pages/Swap/Swap";
import { Exchange } from "pages/Exchange/Exchange";
import Actions from "pages/Actions/Actions";
import LiquidityActions from "pages/LiquidityActions/LiquidityActions";
import RewardActions from "pages/RewardActions/RewardActions";
import OrdersOverview from "pages/OrdersOverview/OrdersOverview";
import PositionsOverview from "pages/PositionsOverview/PositionsOverview";
import Referrals from "pages/Referrals/Referrals";
import BuyFlp from "pages/BuyFlp/BuyFlp";
// import BuyFXDX from "pages/BuyFXDX/BuyFXDX";
// import Buy from "pages/Buy/Buy";
// import NftWallet from "pages/NftWallet/NftWallet";
// import ClaimEsFxdx from "pages/ClaimEsFxdx/ClaimEsFxdx";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import CompleteAccountTransfer from "pages/CompleteAccountTransfer/CompleteAccountTransfer";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import ReferralTerms from "pages/ReferralTerms/ReferralTerms";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import Jobs from "pages/Jobs/Jobs";
import Faucet from "pages/Faucet/Faucet";
import Bridge from "pages/Bridge/Bridge";
import StakeFxdx from "pages/StakeFxdx/Stakefxdx";

import Modal from "components/Modal/Modal";
import useEventToast from "components/EventToast/useEventToast";
import EventToastContainer from "components/EventToast/EventToastContainer";
import SEO from "components/Common/SEO";
import { RedirectPopupModal } from "components/ModalViews/RedirectModal";
import { Header } from "components/Header/Header";
import ExternalLink from "components/ExternalLink/ExternalLink";
import FlpTvChart from "components/FlpTvChart/FlpTvChart";
import EmailSubscribe from "components/EmailSubscribe/EmailSubscribe";

import "styles/Shared.css";
import "styles/Font.css";
import "./App.scss";
import "styles/Input.css";

import { getContract } from "config/contracts";
import Vault from "abis/Vault.json";
import PositionRouter from "abis/PositionRouter.json";
import SwapRouter from "abis/SwapRouter.json";
import LiquidityRouter from "abis/LiquidityRouter.json";
import FlpManager from "abis/FlpManager.json";

import { REDIRECT_POPUP_TIMESTAMP_KEY, SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";

import { defaultLocale, dynamicActivate } from "lib/i18n";
import {
  // ARBITRUM,
  // AVALANCHE,
  // getAlchemyWsUrl,
  getExplorerUrl,
  // GOERLI,
  OPTIMISM_GOERLI,
  OPTIMISM,
  // MOONBEAM,
  IS_TESTNET,
  BASE,
} from "config/chains";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import {
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  REFERRAL_CODE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
} from "config/localStorage";
import FlpSaga from "pages/FlpSaga/FlpSaga";
import CoinInfo from "components/CoinInfo/CoinInfo";
// import { AiOutlineClose } from "react-icons/ai";
import useWallet from "lib/wallets/useWallet";
import Launch from "pages/Launch/Launch";

import Leaderboard from "components/Leaderboard/Leaderboard";
import Vest from "pages/Vest/Vest";
import VestEsfxdx from "pages/VestEsFxdx/Vest";
import UnknownEvent from "pages/UnknowEvent/UnknownEvent";
import Referral from "pages/Referral/Referral";
import GetFxdx from "pages/getFxdx/fxdxbuy";

// const BLOCKED_LOCATIONS = [
//   { country_code: "US" },
//   { country_code: "IR" },
//   { country_code: "CU" },
//   { country_code: "KP" },
//   { country_code: "SY" },
//   { country_code: "MM" },
//   { country_code: "UK", key_words: ["Crimea", "Donetsk", "Luhansk", "Lugansk"] },
// ];

if ("ethereum" in window) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

// const goerliWsProvider = new ethers.providers.WebSocketProvider("wss://goerli.infura.io/ws/v3/5fe38fecbed34e5098225e7b279a2289");
// const goerliWsProvider = new ethers.providers.JsonRpcProvider("https://ethereum-goerli-rpc.allthatnode.com");

const optimismGoerliWsProvider = new ethers.providers.WebSocketProvider(
  "wss://optimism-goerli.blastapi.io/03f6065b-82fd-4122-867d-dd4d1ab3ce38"
);

const optimismWsProvider = new ethers.providers.WebSocketProvider(
  "wss://optimism-mainnet.blastapi.io/87c06b08-0460-4f90-9056-b4c26ec23bf9" // Signer2
);

// const arbWsProvider = new ethers.providers.WebSocketProvider(getAlchemyWsUrl());

// const avaxWsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

// const moonbeamWsProvider = new ethers.providers.JsonRpcProvider("https://rpc.api.moonbeam.network");

const baseWsProvider = new ethers.providers.WebSocketProvider(
  "wss://base-mainnet.blastapi.io/03f6065b-82fd-4122-867d-dd4d1ab3ce38"
);

function getWsProvider(active, chainId) {
  if (!active) {
    return;
  }

  if (chainId === BASE) {
    return baseWsProvider;
  }

  // if (chainId === GOERLI) {
  //   return goerliWsProvider;
  // }

  if (chainId === OPTIMISM_GOERLI) {
    return optimismGoerliWsProvider;
  }

  if (chainId === OPTIMISM) {
    return optimismWsProvider;
  }

  // if (chainId === ARBITRUM) {
  //   return arbWsProvider;
  // }

  // if (chainId === AVALANCHE) {
  //   return avaxWsProvider;
  // }

  // if (chainId === MOONBEAM) {
  //   return moonbeamWsProvider;
  // }
}

// function LoadingScreen() {
//   return (
//     <div className="loading-container">
//       <ImSpinner2 className="spin location-loading-icon" color="white" />
//     </div>
//   );
// }

// function NotSupported() {
//   return (
//     <div className="loading-container">
//       <h2>
//         <Trans>This service is unavailable in your region.</Trans>
//       </h2>
//     </div>
//   );
// }

function FullApp() {
  const { isConnected: active, signer, address: account, chainId } = useWallet();
  const { disconnect } = useDisconnect();
  const isHome = isHomeSite();
  const exchangeRef = useRef();
  const flpSwapRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  useEventToast();

  const query = useRouteQuery();

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM);
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search);
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM);
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(REFERRAL_CODE_QUERY_PARAM)) {
          queryParams.delete(REFERRAL_CODE_QUERY_PARAM);
          navigate({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [query, navigate, location]);

  const checkForGasFeeBalance = useCallback(async () => {
    if (account) {
      const provider = getProvider(null, chainId);
      let nativebalance = await provider.getBalance(account);
      nativebalance = ethers.utils.formatEther(nativebalance);
      if (nativebalance <= 0) {
        helperToast.error("Insufficient balance to pay gas");
      }
    }
  }, [account, chainId]);

  useEffect(() => {
    checkForGasFeeBalance();
  }, [checkForGasFeeBalance]);

  // useEffect(() => {
  //   const termsAccepted = localStorage.getItem("Terms");

  //   if (!termsAccepted) {
  //     toast.warning(
  //       <div>
  //         <h1>Disclaimer</h1>
  //         <p>
  //           FXDX is a smart contracts platform in beta that has been&nbsp;
  //           <a
  //             href="https://github.com/TheArcadiaGroup/publications/blob/main/audits/FXDX%20Final.pdf"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             audited
  //           </a>
  //           &nbsp; for security and carries inherent contract, security, and market risks.&nbsp; When you use the FXDX
  //           app, there may be bugs and other risks. By clicking ‘Accept’, you agree to these risks and our&nbsp;
  //           <a
  //             href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/legal/terms-of-use"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             Terms of Service
  //           </a>{" "}
  //           and&nbsp;
  //           <a
  //             href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/legal/privacy-policy"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             Privacy Policy
  //           </a>
  //         </p>
  //         <button className="confirn-btn" onClick={acceptTerms}>
  //           Accept
  //         </button>
  //       </div>,
  //       {
  //         className: "disclaimer-popup",
  //         toastClassName: "disclaimer-wrapper",
  //         position: "bottom-right",
  //         autoClose: false,
  //         hideProgressBar: true,
  //         closeOnClick: false,
  //         theme: "grey",
  //         closeButton: false,
  //       }
  //     );
  //   }
  // }, []);

  // const acceptTerms = () => {
  //   localStorage.setItem("Terms", true);
  //   toast.dismiss();
  // };

  const disconnectAccountAndCloseSettings = () => {
    disconnect();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false);
  const [redirectPopupTimestamp, setRedirectPopupTimestamp, removeRedirectPopupTimestamp] =
    useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY);
  const [selectedToPage, setSelectedToPage] = useState("");

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(true);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    true
  );
  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );

  // const [hideWaring, setHideWarning] = useLocalStorageSerializeKey([chainId, "hide-bedrock-warning"], false);

  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount);
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting);
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(t`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(t`Slippage should be less than 5%`);
      return;
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(t`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const baseUrl = getAppBaseUrl();
  let appRedirectUrl = baseUrl + selectedToPage;
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode);
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`;
    }
  }

  const [pendingTxns, setPendingTxns] = useState([]);

  const showRedirectModal = (to) => {
    setRedirectModalVisible(true);
    setSelectedToPage(to);
  };

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await signer.provider.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <Trans>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </Trans>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <Trans>View</Trans>
                </ExternalLink>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [signer, pendingTxns, chainId]);

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const swapRouterAddress = getContract(chainId, "SwapRouter");
  const liquidityRouterAddress = getContract(chainId, "LiquidityRouter");
  const flpManagerAddress = getContract(chainId, "FlpManager");

  useEffect(() => {
    const wsVaultAbi = Vault.abi;
    const wsProvider = getWsProvider(active, chainId);
    if (!wsProvider) {
      return;
    }

    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);
    const wsSwapRouter = new ethers.Contract(swapRouterAddress, SwapRouter.abi, wsProvider);
    const wsLiquidityRouter = new ethers.Contract(liquidityRouterAddress, LiquidityRouter.abi, wsProvider);
    const wsFlpManager = new ethers.Contract(flpManagerAddress, FlpManager.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    const callFlpSwapRef = (method, ...args) => {
      if (!flpSwapRef || !flpSwapRef.current) {
        return;
      }

      flpSwapRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    const onExecuteSwap = (...args) => callExchangeRef("onExecuteSwap", ...args);
    const onCancelSwap = (...args) => callExchangeRef("onCancelSwap", ...args);

    const onAddLiquidity = (...args) => callFlpSwapRef("onAddLiquidity", ...args);
    const onRemoveLiquidity = (...args) => callFlpSwapRef("onRemoveLiquidity", ...args);
    const onCancelAddLiquidity = (...args) => callFlpSwapRef("onCancelAddLiquidity", ...args);
    const onCancelRemoveLiquidity = (...args) => callFlpSwapRef("onCancelRemoveLiquidity", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    wsSwapRouter.on("ExecuteSwap", onExecuteSwap);
    wsSwapRouter.on("CancelSwap", onCancelSwap);

    wsFlpManager.on("AddLiquidity", onAddLiquidity);
    wsFlpManager.on("RemoveLiquidity", onRemoveLiquidity);

    wsLiquidityRouter.on("CancelAddLiquidity", onCancelAddLiquidity);
    wsLiquidityRouter.on("CancelRemoveLiquidity", onCancelRemoveLiquidity);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);

      wsSwapRouter.off("ExecuteSwap", onExecuteSwap);
      wsSwapRouter.off("CancelSwap", onCancelSwap);

      wsFlpManager.off("AddLiquidity", onAddLiquidity);
      wsFlpManager.off("RemoveLiquidity", onRemoveLiquidity);

      wsLiquidityRouter.off("CancelAddLiquidity", onCancelAddLiquidity);
      wsLiquidityRouter.off("CancelRemoveLiquidity", onCancelRemoveLiquidity);
    };
  }, [
    active,
    chainId,
    vaultAddress,
    positionRouterAddress,
    swapRouterAddress,
    liquidityRouterAddress,
    flpManagerAddress,
  ]);

  return (
    <>
      <div className="App">
        <div className="ellipse-gradint-background"></div>
        <div className="App-content">
          {/* {!IS_TESTNET && !hideWaring && (
            <div className="App-warning">
              <div className="App-warning-content">
                <b>Important</b>: Optimism Bedrock upgrade planned for 16:00 UTC on June 6, 2023. FXDX will be
                unavailable during the upgrade. So users are advised to manage their risks accordingly.
              </div>
              <button
                className="App-warning-close"
                onClick={() => {
                  setHideWarning(true);
                }}
              >
                <AiOutlineClose size={16} />
              </button>
            </div>
          )} */}
          {chainId === BASE && location.pathname !== "/stakefxdx" && (
            <Link to="https://trade.fxdx.exchange" className="App-alert App-alert-link">
              <span className="App-alert-text large">
                FXDX V2 Alpha is now Live<button className="buy-btn">Trade Now</button>
              </span>
              <span className="App-alert-text small">
                FXDX V2 Alpha is now Live<button className="buy-btn">Trade Now</button>
              </span>
            </Link>
          )}

          <Header
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            openSettings={openSettings}
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          />
          {isHome && (
            <Routes>
              <Route
                path="/"
                element={<Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />}
              />
              <Route path="/referral-terms" element={<ReferralTerms />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          )}
          {!isHome && (
            <Routes>
              <Route path="/" element={<Navigate to="/trade" replace />} />
              <Route
                path="/trade"
                element={
                  <Exchange
                    ref={exchangeRef}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                }
              />
              <Route path="/overview" element={<Dashboard />} />
              <Route path="/earn" element={<Stake setPendingTxns={setPendingTxns} />} />
              {chainId === BASE && <Route path="/vest" element={<Vest pendingTxns={pendingTxns} />} />}
              {chainId === BASE && <Route path="/vestEsfxdx" element={<VestEsfxdx pendingTxns={pendingTxns} />} />}
              <Route
                path="/swap"
                element={
                  <Swap
                    ref={exchangeRef}
                    seo
                    savedSlippageAmount={savedSlippageAmount}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                }
              />
              <Route
                path="/buy"
                element={
                  // <Buy
                  //   savedSlippageAmount={savedSlippageAmount}
                  //   setPendingTxns={setPendingTxns}
                  // />
                  <BuyFlp
                    flpSwapRef={flpSwapRef}
                    savedSlippageAmount={savedSlippageAmount}
                    pendingTxns={pendingTxns}
                    setPendingTxns={setPendingTxns}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                }
              />
              <Route path="/StakeFxdx" element={<StakeFxdx />} />

              <Route path="/GetFxdx" element={<GetFxdx />} />

              <Route
                path="/buy_flp"
                element={
                  <BuyFlp
                    flpSwapRef={flpSwapRef}
                    savedSlippageAmount={savedSlippageAmount}
                    pendingTxns={pendingTxns}
                    setPendingTxns={setPendingTxns}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                }
              />
              {chainId === BASE && (
                <Route
                  path="/flp-saga"
                  element={
                    <FlpSaga
                      flpSwapRef={flpSwapRef}
                      savedSlippageAmount={savedSlippageAmount}
                      pendingTxns={pendingTxns}
                      setPendingTxns={setPendingTxns}
                      savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                    />
                  }
                />
              )}
              <Route path="/jobs" element={<Jobs />} />
              {/* <Route path="/buy_fxdx">
                <BuyFXDX />
              </Route>
              <Route path="/ecosystem">
                <Ecosystem />
              </Route> */}
              <Route
                path="/referrals"
                element={<Referrals pendingTxns={pendingTxns} setPendingTxns={setPendingTxns} />}
              />
              <Route
                path="/referrals/:account"
                element={<Referrals pendingTxns={pendingTxns} setPendingTxns={setPendingTxns} />}
              />
              {!IS_TESTNET && <Route path="/leaderboard" element={<Leaderboard />} />}
              {!IS_TESTNET && <Route path="/Referral" element={<Referral />} />}
              {!IS_TESTNET && <Route path="/UnknownEvent" element={<UnknownEvent />} />}

              {chainId === BASE && (
                <Route path="/launch" element={<Launch pendingTxns={pendingTxns} setPendingTxns={setPendingTxns} />} />
              )}
              <Route path="/trades" element={<Actions />} />
              <Route path="/trades/:account" element={<Actions />} />
              <Route path="/liquidity" element={<LiquidityActions />} />
              <Route path="/liquidity/:account" element={<LiquidityActions />} />
              <Route path="/reward_actions" element={<RewardActions />} />
              <Route path="/reward_actions/:account" element={<RewardActions />} />
              <Route path="/orders_overview" element={<OrdersOverview />} />
              <Route path="/positions_overview" element={<PositionsOverview />} />
              <Route path="/flptv" element={<FlpTvChart chainId={chainId} />} />
              <Route
                path="/begin_account_transfer"
                element={<BeginAccountTransfer setPendingTxns={setPendingTxns} />}
              />
              <Route
                path="/complete_account_transfer/:sender/:receiver"
                element={<CompleteAccountTransfer setPendingTxns={setPendingTxns} />}
              />
              {IS_TESTNET && <Route path="/faucet" element={<Faucet setPendingTxns={setPendingTxns} />} />}
              {!IS_TESTNET && <Route path="/bridge/*" element={<Bridge />} />}
              {/* <Route path="/nft_wallet" element={<NftWallet />} /> */}
              {/* <Route path="/claim_es_fxdx">
                <ClaimEsFxdx setPendingTxns={setPendingTxns} />
              </Route> */}
              {/* <Route path="/stakeholders/:account" element={<Stakeholder />} /> */}
              <Route path="/coininfo" element={<CoinInfo />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          )}
        </div>
      </div>
      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
      <EventToastContainer />
      <RedirectPopupModal
        redirectModalVisible={redirectModalVisible}
        setRedirectModalVisible={setRedirectModalVisible}
        appRedirectUrl={appRedirectUrl}
        setRedirectPopupTimestamp={setRedirectPopupTimestamp}
        setShouldHideRedirectModal={setShouldHideRedirectModal}
        shouldHideRedirectModal={shouldHideRedirectModal}
        removeRedirectPopupTimestamp={removeRedirectPopupTimestamp}
      />
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={t`Settings`}
      >
        <div className="App-settings-row">
          <div>
            <Trans>Allowed Slippage</Trans>
          </div>
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              value={slippageAmount}
              onChange={(e) => {
                let value = e.target.value || "";
                value = value.replace(/[-eE]+/g, "");
                setSlippageAmount(value);
              }}
            />
            <div className="App-slippage-tolerance-input-percent">%</div>
          </div>
        </div>
        <div className="Exchange-settings-row">
          <Trans>Display PnL after fees</Trans>
          <DragSwitch
            className="toggle-leverage"
            checked={showPnlAfterFees}
            onChange={setShowPnlAfterFees}
            handleColor="#E2E2E3"
            onColor="#9D9D9D"
            offColor="#363636"
          />
        </div>
        <div className="Exchange-settings-row">
          <Trans>Include PnL in leverage display</Trans>
          <DragSwitch
            className="toggle-leverage"
            checked={isPnlInLeverage}
            onChange={setIsPnlInLeverage}
            handleColor="#E2E2E3"
            onColor="#9D9D9D"
            offColor="#363636"
          />
        </div>
        <div className="Exchange-settings-row chart-positions-settings">
          <span>
            <Trans>Chart positions</Trans>
          </span>
          <DragSwitch
            className="toggle-leverage"
            checked={savedShouldShowPositionLines}
            onChange={setSavedShouldShowPositionLines}
            handleColor="#E2E2E3"
            onColor="#9D9D9D"
            offColor="#363636"
          />
        </div>
        {isDevelopment() && (
          <div className="Exchange-settings-row">
            <Trans>Disable order validations</Trans>
            <DragSwitch
              className="toggle-leverage"
              checked={shouldDisableValidationForTesting}
              onChange={setShouldDisableValidationForTesting}
              handleColor="#E2E2E3"
              onColor="#9D9D9D"
              offColor="#363636"
            />
          </div>
        )}

        <EmailSubscribe className="outlined" />

        <button className="App-cta Exchange-swap-button" onClick={saveAndCloseSettings}>
          <Trans>Save</Trans>
        </button>

        <button
          className="App-cta Exchange-swap-button"
          onClick={() => {
            localStorage.clear();
            window.location.reload(false);
          }}
        >
          <Trans>Refresh the App</Trans>
        </button>
      </Modal>
    </>
  );
}

function App() {
  const { disconnect } = useDisconnect();
  useScrollToTop();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);

  useEffect(() => {
    const unwatch = watchNetwork(({ chain, chains }) => {
      const isValidChain = !!chains.find((c) => c.id === chain.id);
      if (!isValidChain) {
        disconnect();
        return;
      }

      localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chain.id));
    });
    return () => unwatch();
  }, [disconnect]);

  // const { data: locationData } = useSWR(
  //   IS_TESTNET ? undefined : ["https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8"],
  //   {
  //     fetcher: (...args) => fetch(...args).then((res) => res.json()),
  //     refreshInterval: 500,
  //     refreshWhenHidden: true,
  //   }
  // );

  // // console.log('---> locationData:', locationData);

  // const isLocationAvailable = useMemo(() => {
  //   if (!locationData) {
  //     return false;
  //   }

  //   const country_code = locationData.country_code || "";
  //   const city = (locationData.city || "").toLowerCase();
  //   const state = (locationData.state || "").toLowerCase();
  //   const region = (locationData.region || "").toLowerCase();

  //   for (let item of BLOCKED_LOCATIONS) {
  //     if (item.country_code === country_code) {
  //       if (!item.key_words) {
  //         return false;
  //       }

  //       for (let keyword of item.key_words) {
  //         if (
  //           city.includes(keyword.toLowerCase()) ||
  //           state.includes(keyword.toLowerCase()) ||
  //           region.includes(keyword.toLowerCase())
  //         ) {
  //           return false;
  //         }
  //       }
  //     }
  //   }

  //   return true;
  // }, [locationData]);

  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <SEO>
        <I18nProvider i18n={i18n}>
          {/* {IS_TESTNET ? (
            <FullApp />
          ) : !locationData ? (
            <LoadingScreen />
          ) : isLocationAvailable ? (
            <FullApp />
          ) : (
            <NotSupported />
          )} */}
          <FullApp />
        </I18nProvider>
      </SEO>
    </SWRConfig>
  );
}

export default App;
