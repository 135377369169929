import "./stakehistoryclick.css"



const Stakehistoryclick = () => { 
 return (
   <>
    <div className="stake-click-all">
         <div>

         </div>
    </div>
   </>
 )
}
export default Stakehistoryclick;