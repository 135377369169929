import "./Referrals.css";
import React from "react";
import { useLocalStorage } from "react-use";
import { t } from "@lingui/macro";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from "domain/referrals";
import JoinReferralCode from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode from "components/Referrals/AddAffiliateCode";
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from "components/Referrals/referralsHelper";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import useWallet from "lib/wallets/useWallet";

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
const TAB_OPTIONS = [TRADERS, AFFILIATES];

function Referrals({ setPendingTxns, pendingTxns }) {
  const { isConnected, address: walletAccount, signer, chainId } = useWallet();

  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }

  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData, loading } = useReferralsData(chainId, account);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });
  const { userReferralCode, userReferralCodeString } = useUserReferralCode(signer, chainId, account);
  const { codeOwner } = useCodeOwner(signer, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(signer, chainId, codeOwner);

  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, signer, {
      sentMsg: t`Referral code submitted!`,
      failMsg: t`Referral code creation failed.`,
      pendingTxns,
    });
  }

  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;
    if (loading) return <Loader />;
    if (isReferralCodeAvailable) {
      return (
        <AffiliatesStats
          referralsData={referralsData}
          handleCreateReferralCode={handleCreateReferralCode}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          recentlyAddedCodes={recentlyAddedCodes}
          chainId={chainId}
        />
      );
    } else {
      return (
        <div className="headerTitle-gs">
          <AddAffiliateCode
            handleCreateReferralCode={handleCreateReferralCode}
            active={isConnected}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
          />
          <h2 className="title-gs">Getting started</h2>
          <p>
            Step 1: Begin by creating your unique referral code. Choose the desired blockchain network for your
            referral. You would have to generate multiple codes for multiple networks.
          </p>
          <p>
            Step 2: Generate and share your personalized referral code to start reaping the rewards through rebates. For
            instance, your referral code could be something catchy like “Airdrop”.
          </p>
          <p>
            Step 3: Spread the word by sharing the following link with your audience: &nbsp;
            <a href="http://app.fxdx.exchange/trade/?ref=Airdrop">app.fxdx.exchange</a> <br /> Substitute "Airdrop" with
            your actual referral code to guide them directly to the action point!
          </p>
        </div>
      );
    }
  }

  function renderTradersTab() {
    if (loading) return <Loader />;
    if (isHashZero(userReferralCode) || !account || !userReferralCode) {
      return (
        <div className="headerTitle-gs">
          <JoinReferralCode active={isConnected} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
          <h2 className="title-gs">Getting Started</h2>
          <p>
            Step 1: Start by using the invite code shared by a friend to enjoy discounts on trade fees, setting the
            stage for a fruitful trading journey.
          </p>
          <p>
            Step 2: If you don't have a referral code, it's okay! Use our own special "airdrop" code to start having fun
            and earning 5% rebates right from your very first day.
          </p>
          <p>
            Step 3: To amp up your earnings, craft your personalized referral code through the "Affiliate" tab and step
            into a world of even greater rebates.
          </p>
        </div>
      );
    }
    return (
      <TradersStats
        userReferralCodeString={userReferralCodeString}
        chainId={chainId}
        referralsData={referralsData}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
        traderTier={traderTier}
      />
    );
  }
  const TAB_OPTION_LABELS = { [TRADERS]: t`Traders`, [AFFILIATES]: t`Affiliates` };

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="default-container page-layout Referrals">
        <div className="referral-tab-container">
          <Tab
            options={TAB_OPTIONS}
            optionLabels={TAB_OPTION_LABELS}
            option={activeTab}
            setOption={setActiveTab}
            onChange={setActiveTab}
          />
        </div>
        {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()}
      </div>
      <Footer />
    </SEO>
  );
}

export default Referrals;
