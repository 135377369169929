import { useMemo } from "react";
import { Trans, t } from "@lingui/macro";

import "./FlpSagaCard.css";
import sagabg from "../../img/hyper-bg.svg";

import { FLP_DECIMALS } from "lib/legacy";
import { formatAmount, formatAmountFree } from "lib/numbers";
import { BEFORE_EVENT, IN_EVENT, convertToSymbols, getTierLowerLimit } from "domain/saga";
import ProgressBar from "components/ProgressBar/ProgressBar";

const FlpSagaCard = ({ tier, tierIndex, section, totalStat, userStat }) => {
  const totalBuyAmount = totalStat.totalBuyAmount;
  const currentActiveTier = totalStat.currentActiveTier;

  const isActiveTier = section === IN_EVENT && currentActiveTier === tierIndex;

  const tierMinValue = useMemo(() => {
    return getTierLowerLimit(tierIndex);
  }, [tierIndex]);

  return (
    <div
      className={!isActiveTier ? "saga-flp-card-wrapper" : "saga-flp-card-wrapper active"}
      style={{ backgroundImage: isActiveTier ? `url(${sagabg})` : "" }}
    >
      <h1>{tier.name}</h1>
      {totalBuyAmount.gt(tierMinValue) || isActiveTier ? (
        <>
          <ProgressBar
            bgcolor={isActiveTier ? "#0D0D0D" : "#FFFFFF"}
            value={totalBuyAmount}
            minValue={tierMinValue}
            maxValue={tier.upperLimit}
          />
          <div className="saga-flp-rows">
            <p>${convertToSymbols(tierMinValue)}</p>
            <p>${convertToSymbols(tier.upperLimit)}</p>
          </div>
        </>
      ) : (
        <p className="saga-flp-p">
          {section === BEFORE_EVENT || section === IN_EVENT ? t`Not Started Yet` : t`Not Started`}
        </p>
      )}

      <hr />
      <div className="saga-flp-rows">
        <p>
          <Trans>Allocation</Trans>
        </p>
        <p>{`$${convertToSymbols(tierMinValue)} - $${convertToSymbols(tier.upperLimit)}`}</p>
      </div>
      {userStat && section !== BEFORE_EVENT && (
        <div className="saga-flp-rows">
          <p>
            <Trans>Commitment</Trans>
          </p>
          <p>{`$${formatAmount(userStat.tierCommitments[tierIndex], FLP_DECIMALS, 4, true)}`}</p>
        </div>
      )}
      <div className="saga-flp-rows">
        <p>
          <Trans>esFXDX per FLP</Trans>
        </p>
        <p>{formatAmountFree(tier.rewardRate, 2)}</p>
      </div>
    </div>
  );
};

export default FlpSagaCard;
