export const props = [
  {
    title: "Overview",
    hasChildren: false,
    to: "/overview",
  },

  
  {
    title: "Trade",
    hasChildren: true,
    children: [
      {
        title: "Spot",
        desc: "Buy & Sell Crypto Instantly",
        to: "/swap",
        needWalletConnected: false,
        onBaseOnly: false,
      },
      {
        title: "Futures (perp)",
        desc: "Bet on Future Prices",
        to: "/trade",
        needWalletConnected: false,
        onBaseOnly: false,
      },
    ],
  },


  {
    title: "Account",
    hasChildren: true,
    children: [
      {
        title: "Account",
        desc: " Personalize Your Profile, Manage Preferences",
        to: "/earn",
        needWalletConnected: false,
        onBaseOnly: false,
      },
      {
        title: "Referral",
        desc: "Invite Friends, Earn Rewards",
        to: "/referrals",
        needWalletConnected: false,
        onBaseOnly: false,
      },
      {
        title: "Trades",
        desc: "Review Past Transactions, Analyze Trading Performance",
        to: "/trades",
        needWalletConnected: true,
        onBaseOnly: false,
      },
      {
        title: "Liquidity",
        desc: " Deposit and Withdraw Funds, Manage Balances",
        to: "/liquidity",
        needWalletConnected: true,
        onBaseOnly: false,
      },
      {
        title: "Rewards",
        desc: "Monitor Earnings, Track Incentives and Bonuses",
        to: "/reward_actions",
        needWalletConnected: true,
        onBaseOnly: false,
      },
      {
        title: "Vest",
        desc: "Check Vesting Status",
        to: "/vest",
        needWalletConnected: false,
        onBaseOnly: true,
      },
      {
        title: "Vest esFXDX",
        desc: "Vesting Your exFxdx",
        to: "/vestEsfxdx",
        needWalletConnected: false,
        onBaseOnly: true,
      },
      {
        title: "Unknown Event",
        desc: "The Event",
        to: "/UnknownEvent",
      },
    ],
  },

  {
    title: "Stake ",
    hasChildren: true,
    children: [
      {
        title: "FXDX 🔥",
        desc: "Stake Your FXDX Tokens",
        to: "/stakefxdx",
        // needWalletConnected: true,
        // onBaseOnly: false,
      },
      {
        title: "FLP",
        desc: "Stake Your FLP Tokens",
        to: "/buy",
        // needWalletConnected: true,
        // onBaseOnly: true,
      },
    ],
    },
  // {
  //     title: 'Stats',
  //     hasChildren: true,
  //     children: [
  //         {
  //             title: 'LeaderBoard',
  //             desc: 'See Top Traders, Track Performance Rankings',
  //             to: '/leaderboard',
  //             needWalletConnected: false
  //         }
  //     ]
  // }
];

export const referalnew = [{
  
    title: "UnknownEvent",
    hasChildren: true,
    children: [
      {
        title: "Unknown Event",
        desc: "The Event",
        to: "/UnknownEvent",
      },
      {
        title: "Referral",
        desc: "Share Your Referral Code",
        to: "/Referral",
      },
    ],

},

]