import "./LaunchCardMainButton.css";
import { Timer } from "components/Timer/Timer";

const LaunchCardMainButton = ({ props }) => {
  return (
    <div className="launchcard-main-btn-container">
      {props.completed ? (
        "Completed"
      ) : props.started ? (
        <Timer endTime={props.endAt} text="Ends" />
      ) : (
        <Timer endTime={props.startAt} text="Starts" />
      )}
    </div>
  );
};

export default LaunchCardMainButton;
