import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  GOERLI,
  OPTIMISM_GOERLI,
  MOONBEAM,
  OPTIMISM,
  BASE,
} from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const baseGraphClient = createClient(SUBGRAPH_URLS[BASE].stats);
export const baseReferralsGraphClient = createClient(SUBGRAPH_URLS[BASE].referrals);
export const baseSagaGraphClient = createClient(SUBGRAPH_URLS[BASE].saga);

export const goerliGraphClient = createClient(SUBGRAPH_URLS[GOERLI].stats);
export const goerliReferralsGraphClient = createClient(SUBGRAPH_URLS[GOERLI].referrals);

export const optimismGoerliGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM_GOERLI].stats);
export const optimismGoerliReferralsGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM_GOERLI].referrals);
export const optimismGoerliSagaGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM_GOERLI].saga);

export const optimismGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM].stats);
export const optimismReferralsGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM].referrals);
export const optimismSagaGraphClient = createClient(SUBGRAPH_URLS[OPTIMISM].saga);

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].stats);
export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].referrals);
export const nissohGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].nissohVault);

export const avalancheGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].stats);
export const avalancheReferralsGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].referrals);

export const moonbeamGraphClient = createClient(SUBGRAPH_URLS[MOONBEAM].stats);
export const moonbeamReferralsGraphClient = createClient(SUBGRAPH_URLS[MOONBEAM].referrals);

export const optimismActionsGraphClient = createClient(
  "https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-optimism-actions"
);
export const baseActionsGraphClient = createClient(
  "https://api.thegraph.com/subgraphs/name/abhijitkrm/fxdx-base-actions"
);
export const baseActionsLeaderBoardGraphClient = createClient("https://api.thegraph.com/subgraphs/name/noveleader/fxdx-actions-base")
export const optimismActionsLeaderBoardGraphClient = createClient("https://api.thegraph.com/subgraphs/name/noveleader/fxdx-action-optimism")

export const basechainnewleaderboard = createClient ("https://api.thegraph.com/subgraphs/name/danielsmith0630/fxdx-base-referrals")
export const basechainnewleaderboardvol = createClient ("https://api.thegraph.com/subgraphs/name/noveleader/fxdx-actions-base")

export function getFxdxGraphClient(chainId: number) {
  if (chainId === BASE) {
    return baseGraphClient;
  } else if (chainId === GOERLI) {
    return goerliGraphClient;
  } else if (chainId === OPTIMISM_GOERLI) {
    return optimismGoerliGraphClient;
  } else if (chainId === OPTIMISM) {
    return optimismGraphClient;
  } else if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient;
  } else if (chainId === ARBITRUM_TESTNET) {
    return null;
  } else if (chainId === MOONBEAM) {
    return moonbeamGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}

export const getActionsClient = (chainId) => {
  if (chainId === BASE) {
    return baseActionsGraphClient;
  }
  if (chainId === OPTIMISM) {
    return optimismActionsGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
};

export const getLeaderBoardActionsClient = (chainId) => {
  if (chainId === BASE) {
    return baseActionsLeaderBoardGraphClient;
  }
  if (chainId === OPTIMISM) {
    return optimismActionsLeaderBoardGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
};

