import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { select, t, Trans } from "@lingui/macro";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./SwapBox.css";
import { GrAdd, GrSubtract } from "react-icons/gr";

import { DragSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";

import cx from "classnames";
import useSWR from "swr";
import { ethers } from "ethers";

import { BsArrowRight } from "react-icons/bs";

import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  getLeverage,
  getLiquidationPrice,
  getNextToAmount,
  getPositionKey,
  isTriggerRatioInverted,
  LEVERAGE_ORDER_OPTIONS,
  LIMIT,
  LONG,
  MARKET,
  PRECISION,
  SHORT,
  STOP,
  SWAP_OPTIONS,
  USD_DECIMALS,
  USDF_ADDRESS,
  USDF_DECIMALS,
  MAX_ALLOWED_LEVERAGE,
} from "lib/legacy";
import {
  ARBITRUM,
  // AVALANCHE,
  getChainName,
  getConstant,
  IS_NETWORK_DISABLED,
  isSupportedChain,
} from "config/chains";
import * as Api from "domain/legacy";
import { getContract } from "config/contracts";

import Tab from "../Tab/Tab";
import TokenSelector from "./TokenSelector";
import ExchangeInfoRow from "./ExchangeInfoRow";
import ConfirmationBox from "./ConfirmationBox";
import OrdersToa from "./OrdersToa";

import PositionRouter from "abis/PositionRouter.json";
import Token from "abis/Token.json";

import { useUserReferralCode } from "domain/referrals";
import NoLiquidityErrorModal from "./NoLiquidityErrorModal";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import {
  approveTokens,
  getMostAbundantStableToken,
  replaceNativeTokenAddress,
  shouldRaiseGasError,
} from "domain/tokens";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken, getTokens, getWhitelistedTokens } from "config/tokens";
// import UsefulLinks from "./UsefulLinks";
import { ErrorCode, ErrorDisplayType } from "./constants";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useWallet from "lib/wallets/useWallet";

const { AddressZero } = ethers.constants;

// const leverageSliderHandle = (props) => {
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <SliderTooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={`${parseFloat(value).toFixed(2)}x`}
//       visible={dragging}
//       placement="top"
//       key={index}
//     >
//       <Slider.Handle value={value} {...restProps} />
//     </SliderTooltip>
//   );
// };

function getNextAveragePrice({ size, sizeDelta, hasProfit, delta, nextPrice, isLong }) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return;
  }
  const nextSize = size.add(sizeDelta);
  let divisor;
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta);
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta);
  }
  if (!divisor || divisor.eq(0)) {
    return;
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor);
  return nextAveragePrice;
}

export default function SwapBox(props) {
  const {
    pendingPositions,
    setPendingPositions,
    infoTokens,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    shortCollateralAddress,
    setShortCollateralAddress,
    swapOption,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    totalTokenWeights,
    usdfSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableValidationForTesting,
    positionMinExecutionFee,
    positionMinExecutionFeeUSD,
    positionMinExecutionFeeErrorMessage,
  } = props;

  const { address: account, isConnected: active, signer } = useWallet();
  const { openConnectModal } = useConnectModal();

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const { attachedOnChain, userReferralCode } = useUserReferralCode(signer, chainId, account);
  const [multiplier, setmultiplier] = useState(0);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  // const getLeaderboardLink = () => {
  //   if (chainId === ARBITRUM) {
  //     return "https://www.fxdx.house/arbitrum/leaderboard";
  //   }
  //   if (chainId === AVALANCHE) {
  //     return "https://www.fxdx.house/avalanche/leaderboard";
  //   }
  //   return "https://www.fxdx.house";
  // };

  function getTokenLabel() {
    switch (true) {
      case isLong:
        return t`Long`;
      case isShort:
        return t`Short`;
      default:
        return "";
    }
  }
  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-option"],
    "2"
  );

  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-slider-enabled"],
    true
  );

  const hasLeverageOption = true;
  // const hasLeverageOption = isLeverageSliderEnabled && !isNaN(parseFloat(leverageOption));

  const [ordersToaOpen, setOrdersToaOpen] = useState(false);

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Order-option"], MARKET);
  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };

  const isMarketOrder = orderOption === MARKET;
  const orderOptions = LEVERAGE_ORDER_OPTIONS;
  const orderOptionLabels = { [MARKET]: t`Market`, [LIMIT]: t`Limit` };
  // const orderOptionLabels = { [STOP]: t`Trigger`, [MARKET]: t`Market`, [LIMIT]: t`Limit` };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const onTriggerPriceChange = (evt) => {
    let value = evt.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setTriggerPriceValue(value);
  };

  let positionKey;
  if (isLong) {
    positionKey = getPositionKey(account, toTokenAddress, toTokenAddress, true, nativeTokenAddress);
  }
  if (isShort) {
    positionKey = getPositionKey(account, shortCollateralAddress, toTokenAddress, false, nativeTokenAddress);
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined;
  const hasExistingPosition = existingPosition && existingPosition.size && existingPosition.size.gt(0);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokens = getTokens(chainId);
  const fromTokens = tokens;
  const stableTokens = tokens.filter((token) => token.isStable);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  let toTokens = tokens;
  if (isLong) {
    toTokens = indexTokens;
  }
  if (isShort) {
    toTokens = shortableTokens;
  }

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved;
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false);
      helperToast.success(<div>Leverage enabled!</div>);
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ]);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const routerAddress = getContract(chainId, "Router");
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, tokenAllowanceAddress, "allowance", account, routerAddress],
    {
      fetcher: contractFetcher(signer, Token),
    }
  );

  const { data: hasOutdatedUi } = Api.useHasOutdatedUi();

  const fromToken = getToken(chainId, fromTokenAddress);
  const toToken = getToken(chainId, toTokenAddress);
  const shortCollateralToken = getTokenInfo(infoTokens, shortCollateralAddress);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  // const renderAvailableLongLiquidity = () => {
  //   if (!isLong) {
  //     return null;
  //   }

  //   return (
  //     <div className="Exchange-info-row">
  //       <div className="Exchange-info-label">
  //         <Trans>Available Liquidity</Trans>
  //       </div>
  //       <div className="align-right">
  //         <Tooltip
  //           handle={`$${formatAmount(toTokenInfo.maxAvailableLong, USD_DECIMALS, 2, true)}`}
  //           position="right-bottom"
  //           renderContent={() => {
  //             return (
  //               <>
  //                 <StatsTooltipRow
  //                   label={t`Max ${toTokenInfo.symbol} long capacity`}
  //                   value={formatAmount(toTokenInfo.maxLongCapacity, USD_DECIMALS, 0, true)}
  //                 />
  //                 <StatsTooltipRow
  //                   label={t`Current ${toTokenInfo.symbol} long`}
  //                   value={formatAmount(toTokenInfo.guaranteedUsd, USD_DECIMALS, 0, true)}
  //                 />
  //               </>
  //             );
  //           }}
  //         ></Tooltip>
  //       </div>
  //     </div>
  //   );
  // };

  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  // const toBalance = toTokenInfo ? toTokenInfo.balance : bigNumberify(0);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const needApproval =
    fromTokenAddress !== AddressZero && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);
  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);
  const prevToTokenAddress = usePrevious(toTokenAddress);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);

  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
  const collateralTokenAddress = isLong ? indexTokenAddress : shortCollateralAddress;
  const collateralToken = getToken(chainId, collateralTokenAddress);

  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (ratio.eq(0)) {
      return bigNumberify(0);
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{fromToken.symbol} approved!</div>);
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromToken.symbol,
    isWaitingForApproval,
    fromToken,
  ]);

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress)) {
      setToTokenAddress(swapOption, toTokens[0].address);
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  useEffect(() => {
    if (swapOption !== SHORT) {
      return;
    }
    if (toTokenAddress === prevToTokenAddress) {
      return;
    }
    for (let i = 0; i < stableTokens.length; i++) {
      const stableToken = stableTokens[i];
      const key = getPositionKey(account, stableToken.address, toTokenAddress, false, nativeTokenAddress);
      const position = positionsMap[key];
      if (position && position.size && position.size.gt(0)) {
        setShortCollateralAddress(position.collateralToken.address);
        return;
      }
    }
  }, [
    account,
    toTokenAddress,
    prevToTokenAddress,
    swapOption,
    positionsMap,
    stableTokens,
    nativeTokenAddress,
    shortCollateralAddress,
    setShortCollateralAddress,
  ]);

  useEffect(() => {
    const updateLeverageAmounts = () => {
      if (!hasLeverageOption) {
        return;
      }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);
          const toTokenPriceUsd =
            !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

          const { feeBasisPoints } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdfSupply,
            totalTokenWeights,
            false
          );

          let fromUsdMinAfterFee = fromUsdMin;
          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          }

          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);
          const toDenominator = bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR);

          const nextToUsd = toNumerator.div(toDenominator);

          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd);

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);

          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
      if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier);

        let fees = bigNumberify(0);

        const { feeBasisPoints } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdfSupply,
          totalTokenWeights,
          false
        );

        if (feeBasisPoints) {
          const swapFees = baseFromAmountUsd.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          fees = fees.add(swapFees);
        }

        const nextFromUsd = baseFromAmountUsd.add(fees);

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice);

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);

        setFromValue(nextFromValue);
      }
    };

    updateLeverageAmounts();
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    hasLeverageOption,
    usdfSupply,
    totalTokenWeights,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
  ]);

  let entryMarkPrice;
  let exitMarkPrice;
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice;
  }

  let leverage = bigNumberify(0);
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = bigNumberify(0);
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees));
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit;

    if (isMarketOrder) {
      nextDelta = existingPosition.delta;
      nextHasProfit = existingPosition.hasProfit;
    } else {
      const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), existingPosition);
      nextDelta = data.delta;
      nextHasProfit = data.hasProfit;
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    });
  }

  const liquidationPrice = getLiquidationPrice({
    indexToken: getToken(chainId, indexTokenAddress),
    isLong,
    size: hasExistingPosition ? existingPosition.size : bigNumberify(0),
    collateral: hasExistingPosition ? existingPosition.collateral : bigNumberify(0),
    averagePrice: nextAveragePrice,
    entryRolloverRate: hasExistingPosition ? existingPosition.entryRolloverRate : bigNumberify(0),
    cumulativeRolloverRate: hasExistingPosition ? existingPosition.cumulativeRolloverRate : bigNumberify(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined;
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice;

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : bigNumberify(0);
    const sizeDelta = toUsdMax ? toUsdMax : bigNumberify(0);
    leverage = getLeverage({
      indexToken: existingPosition.indexToken,
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryRolloverRate: existingPosition.entryRolloverRate,
      cumulativeRolloverRate: existingPosition.cumulativeRolloverRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    });
  } else if (hasLeverageOption) {
    leverage = bigNumberify(parseInt(leverageOption * BASIS_POINTS_DIVISOR));
  }

  const getLeverageError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Leverage disabled, pending ${getChainName(chainId)} upgrade`];
    }
    if (hasOutdatedUi) {
      return [t`Page outdated, please refresh`];
    }

    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
    if (toTokenInfo && toTokenInfo.isStable) {
      return [t`${select(swapOption, { [LONG]: "Longing", [SHORT]: "Shorting" })} ${toTokenInfo.symbol} not supported`];
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`];
    }

    if (leverage && leverage.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return [t`Enter a price`];
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return [t`Min order: 10 USD`];
    }

    if (leverage && leverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: 1.1x`];
    }

    if (leverage && leverage.gt(MAX_ALLOWED_LEVERAGE)) {
      return [t`Max leverage: ${(MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR).toFixed(1)}x`];
    }

    if (!isMarketOrder && entryMarkPrice && triggerPriceUsd && !savedShouldDisableValidationForTesting) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return [t`Price above Mark Price`];
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return [t`Price below Mark Price`];
      }
    }

    if (isLong) {
      let requiredAmount = toAmount;
      if (fromTokenAddress !== toTokenAddress) {
        const { amount: swapAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdfSupply,
          totalTokenWeights,
          false
        );
        requiredAmount = requiredAmount.add(swapAmount);

        if (toToken && toTokenAddress !== USDF_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return [t`Liquidity data not loaded`];
          }
          if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return [t`Insufficient liquidity`];
          }
        }

        if (
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return [t`Insufficient liquidity`, true, "BUFFER"];
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdfAmount &&
          fromTokenInfo.maxUsdfAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdfAmount
        ) {
          const usdfFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDF_DECIMALS);
          const nextUsdfAmount = fromTokenInfo.usdfAmount.add(usdfFromAmount);
          if (nextUsdfAmount.gt(fromTokenInfo.maxUsdfAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDF"];
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
        if (toTokenInfo.maxAvailableLong && sizeUsd.gt(toTokenInfo.maxAvailableLong)) {
          return [t`Max ${toTokenInfo.symbol} long exceeded`];
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = bigNumberify(0);
      if (fromTokenAddress !== shortCollateralAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          shortCollateralAddress,
          infoTokens,
          undefined,
          undefined,
          usdfSupply,
          totalTokenWeights,
          false
        );
        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
          return [t`Insufficient liquidity, change "Collateral In"`];
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [t`Insufficient liquidity, change "Collateral In"`, true, "BUFFER"];
        }

        if (
          fromTokenInfo.maxUsdfAmount &&
          fromTokenInfo.maxUsdfAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdfAmount
        ) {
          const usdfFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDF_DECIMALS);
          const nextUsdfAmount = fromTokenInfo.usdfAmount.add(usdfFromAmount);
          if (nextUsdfAmount.gt(fromTokenInfo.maxUsdfAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDF"];
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [t`Fetching token info...`];
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice);

      if (!toTokenInfo.maxAvailableShort) {
        return [t`Liquidity data not loaded`];
      }

      if (
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [t`Max ${toTokenInfo.symbol} short exceeded`];
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [t`Insufficient liquidity, change "Collateral In"`];
      }
    }

    return [false];
  };

  const getToLabel = () => {
    return t`Position size`;
  };

  const getError = () => {
    return getLeverageError();
  };

  const renderOrdersToa = () => {
    if (!ordersToaOpen) {
      return null;
    }

    return (
      <OrdersToa
        setIsVisible={setOrdersToaOpen}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    );
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (isStopOrder) {
      return true;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (isStopOrder) {
      return t`Open a position`;
    }
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage...`;
    }
    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`;
    }
    if (needPositionRouterApproval) {
      return t`Enable Leverage`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${fromToken.symbol}...`;
    }
    if (needApproval) {
      return t`Approve ${fromToken.symbol}`;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling Orders...`;
    }
    if (isPluginApproving) {
      return t`Enabling Orders...`;
    }
    if (needOrderBookApproval) {
      return t`Enable Orders`;
    }

    if (!isMarketOrder) return t`Create ${orderOption.charAt(0) + orderOption.substring(1).toLowerCase()} Order`;

    if (isLong) {
      const indexTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
      if (indexTokenInfo && indexTokenInfo.minPrice) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          indexTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdfSupply,
          totalTokenWeights,
          false
        );
        const nextToAmountUsd = nextToAmount
          .mul(indexTokenInfo.minPrice)
          .div(expandDecimals(1, indexTokenInfo.decimals));
        if (fromTokenAddress === USDF_ADDRESS && nextToAmountUsd.lt(fromUsdMin.mul(98).div(100))) {
          return t`High USDF Slippage, Long Anyway`;
        }
      }
      return t`Long ${toToken.symbol}`;
    }

    return t`Short ${toToken.symbol}`;
  };

  const onSelectFromToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
    setIsWaitingForApproval(false);

    if (isShort && token.isStable) {
      setShortCollateralAddress(token.address);
    }
  };

  // const onSelectShortCollateralAddress = (token) => {
  //   setShortCollateralAddress(token.address);
  // };

  const onSelectToToken = (token) => {
    setToTokenAddress(swapOption, token.address);
  };

  const onFromValueChange = (e) => {
    setmultiplier(0);
    setAnchorOnFromAmount(true);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setFromValue(value);
  };

  const onToValueChange = (e) => {
    setmultiplier(0);
    setAnchorOnFromAmount(false);
    let value = e.target.value || "";
    value = value.replace(/[-eE]+/g, "");
    setToValue(value);
  };

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress];

    if (path[0] === USDF_ADDRESS) {
      if (isLong) {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        path.push(stableToken.address);
      } else {
        path.push(shortCollateralAddress);
      }
    }

    const minOut = 0;
    const indexToken = getToken(chainId, indexTokenAddress);
    const successMsg = t`
      Created limit order for ${indexToken.symbol} ${isLong ? "Long" : "Short"}: ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD!
    `;
    return Api.createIncreaseOrder(
      chainId,
      signer,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      minOut,
      toUsdMax,
      collateralTokenAddress,
      isLong,
      triggerPriceUsd,
      {
        pendingTxns,
        setPendingTxns,
        sentMsg: t`Limit order submitted!`,
        successMsg,
        failMsg: t`Limit order creation failed.`,
      }
    )
      .then(() => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const increasePosition = async () => {
    setIsSubmitting(true);
    const tokenAddress0 = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
    const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
    let path = [indexTokenAddress]; // assume long
    if (toTokenAddress !== fromTokenAddress) {
      path = [tokenAddress0, indexTokenAddress];
    }

    if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
      path = [nativeTokenAddress];
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
      path = [nativeTokenAddress];
    }

    if (isShort) {
      path = [shortCollateralAddress];
      if (tokenAddress0 !== shortCollateralAddress) {
        path = [tokenAddress0, shortCollateralAddress];
      }
    }

    const refPrice = isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0);

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDF_ADDRESS && isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdfSupply,
        totalTokenWeights,
        false
      );
      if (nextToAmount.eq(0)) {
        helperToast.error(t`Insufficient liquidity`);
        return;
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath);
      }
    }

    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      0, // _minOut
      toUsdMax, // _sizeDelta
      isLong, // _isLong
      priceLimit, // _acceptablePrice
      positionMinExecutionFee, // _executionFee
      referralCode, // _referralCode
      // AddressZero, // _callbackTarget
    ];

    let method = "createIncreasePosition";
    let value = positionMinExecutionFee;
    if (fromTokenAddress === AddressZero) {
      method = "createIncreasePositionETH";
      value = boundedFromAmount.add(positionMinExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        toUsdMax, // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        positionMinExecutionFee, // _executionFee
        referralCode, // _referralCode
        // AddressZero, // _callbackTarget
      ];
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    const contractAddress = getContract(chainId, "PositionRouter");
    const contract = new ethers.Contract(contractAddress, PositionRouter.abi, signer);
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexToken.symbol;
    const longOrShortText = isLong ? t`Long` : t`Short`;
    const successMsg = t`Requested increase of ${tokenSymbol} ${longOrShortText} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD.`;

    callContract(chainId, contract, method, params, {
      value,
      setPendingTxns,
      sentMsg: `${longOrShortText} submitted.`,
      failMsg: `${longOrShortText} failed.`,
      successMsg,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        setIsConfirming(false);

        const key = getPositionKey(account, path[path.length - 1], indexTokenAddress, isLong);
        let nextSize = toUsdMax;
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax);
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === STOP) {
      setOrderOption(MARKET);
    }
    setAnchorOnFromAmount(true);
    setFromValue("");
    setToValue("");
    setTriggerPriceValue("");
    setTriggerRatioValue("");

    if (opt === SHORT && infoTokens) {
      const fromToken = getToken(chainId, tokenSelection[opt].from);
      if (fromToken && fromToken.isStable) {
        setShortCollateralAddress(fromToken.address);
      } else {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        setShortCollateralAddress(stableToken.address);
      }
    }
  };

  const onConfirmationClick = () => {
    if (!active) {
      openConnectModal();
      return;
    }

    if (needOrderBookApproval) {
      approveOrderBook();
      return;
    }

    setIsPendingConfirmation(true);

    if (orderOption === LIMIT) {
      createIncreaseOrder();
      return;
    }

    increasePosition();
  };

  function approveFromToken() {
    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
  }

  const onClickPrimary = () => {
    if (isStopOrder) {
      setOrderOption(MARKET);
      return;
    }

    if (!active) {
      openConnectModal();
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent.`,
        failMsg: t`Enable leverage failed.`,
      });
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    const [, modal, errorCode] = getError();

    if (modal) {
      setModalError(errorCode);
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const isStopOrder = orderOption === STOP;
  const showFromAndToSection = !isStopOrder;
  const showTriggerPriceSection = !isMarketOrder && !isStopOrder;

  let fees;
  let feesUsd;
  let feeBps;
  let swapFees;
  let positionFee;
  if (toUsdMax) {
    positionFee = bigNumberify(0);
    feesUsd = positionFee;

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdfSupply,
      totalTokenWeights,
      false
    );
    if (feeBasisPoints) {
      swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
      feesUsd = feesUsd.add(swapFees);
    }
    feeBps = feeBasisPoints;
  }

  const leverageMarks = {
    2: "2x",
    5: "5x",
    10: "10x",
    // 15: "15x",
    20: "20x",
    // 25: "25x",
    30: "30x",
    // 35: "35x",
    40: "40x",
    // 45: "45x",
    50: "50x",
  };

  if (!fromToken || !toToken) {
    return null;
  }

  // let hasZeroBorrowFee = false;
  // let borrowFeeText;
  // if (isLong && toTokenInfo && toTokenInfo.rolloverRate) {
  //   borrowFeeText = formatAmount(toTokenInfo.rolloverRate, 4, 4) + "% / 1h";
  //   if (toTokenInfo.rolloverRate.eq(0)) {
  //     hasZeroBorrowFee = true
  //   }
  // }
  // if (isShort && shortCollateralToken && shortCollateralToken.rolloverRate) {
  //   borrowFeeText = formatAmount(shortCollateralToken.rolloverRate, 4, 4) + "% / 1h";
  //   if (shortCollateralToken.rolloverRate.eq(0)) {
  //     hasZeroBorrowFee = true
  //   }
  // }

  function setFromValueToMaximumAvailable(multiplier) {
    if (!fromToken || !fromBalance) {
      return;
    }

    multiplier && setmultiplier(multiplier);

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    const fromAvailableAmount =
      !maxAvailableAmount || multiplier === 1 ? maxAvailableAmount : maxAvailableAmount.div(2);
    setFromValue(formatAmountFree(fromAvailableAmount, fromToken.decimals, fromToken.decimals) * multiplier);
    setAnchorOnFromAmount(true);
  }

  // function shouldShowMaxButton() {
  //   if (!fromToken || !fromBalance) {
  //     return false;
  //   }
  //   const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
  //   return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  // }

  const ERROR_TOOLTIP_MSG = {
    [ErrorCode.PoolExceeded]: t`FLP doesn't accept this amount of ${fromTokenInfo.symbol}.`,
  };

  function renderPrimaryButton() {
    const [errorMessage, errorType, errorCode] = getError();
    const primaryTextMessage = getPrimaryText();
    if (errorType === ErrorDisplayType.Tooltip && errorMessage === primaryTextMessage && ERROR_TOOLTIP_MSG[errorCode]) {
      return (
        <Tooltip
          isHandlerDisabled
          handle={
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {primaryTextMessage}
            </button>
          }
          position="center-bottom"
          className="Tooltip-flex"
          renderContent={() => ERROR_TOOLTIP_MSG[errorCode]}
        />
      );
    }
    return (
      <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
        {primaryTextMessage}
      </button>
    );
  }

  const SWAP_LABELS = {
    [LONG]: t`Long`,
    [SHORT]: t`Short`,
    // [SWAP]: t`Swap`,
  };

  return (
    <div className="Exchange-swap-box">
      <div>
        <Tab
          options={SWAP_OPTIONS}
          optionLabels={SWAP_LABELS}
          option={swapOption}
          onChange={onSwapOptionChange}
          className="Exchange-swap-option-tabs"
        />
        {flagOrdersEnabled && (
          <Tab
            options={orderOptions}
            optionLabels={orderOptionLabels}
            className="Exchange-swap-order-type-tabs"
            type="inline"
            option={orderOption}
            onChange={onOrderOptionChange}
          />
        )}
      </div>

      <div className="Exchange-swap-box-inner App-box-highlight">
        {showTriggerPriceSection && (
          <div
            className="muted align-right clickable MarkPrice"
            onClick={() => {
              setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2));
            }}
          >
            <Trans>Mark: {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}</Trans>
          </div>
        )}
        {showTriggerPriceSection && (
          <div className="Exchange-swap-section price">
            <div className="Exchange-swap-section-top price">
              <div className="muted">
                <Trans>Limit Price</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPriceValue.toString().slice(0, 10)}
                  onChange={onTriggerPriceChange}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
        )}

        {!showTriggerPriceSection && (
          <div className={cx("Exchange-swap-section price", { "mark-price": !showTriggerPriceSection })}>
            <div className="Exchange-swap-section-top price">
              <div className="muted">
                <Trans>Mark price</Trans>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => {
                  setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2));
                }}
              >
                {/* <Trans>Mark: {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}</Trans> */}
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  // min="0"
                  // placeholder="0.0"
                  className="Exchange-swap-input"
                  value={formatAmountFree(entryMarkPrice, USD_DECIMALS, 2)}
                  disabled={true}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
            {/* <div className="Exchange-swap-section-bottom Mark">
              <div className="Exchange-swap-input-container">
                {/* <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPriceValue}
                  onChange={onTriggerPriceChange}
                />
                <Trans>${formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}</Trans>
              </div>
             <div className="PositionEditor-token-symbol">USD</div>
            </div> */}
          </div>
        )}

        {showFromAndToSection && (
          <React.Fragment>
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {fromUsdMin && (
                    <div className="Exchange-swap-usd">
                      <Trans>Collateral</Trans>
                    </div>
                  )}
                  {!fromUsdMin && t`Collateral`}
                </div>
                {fromBalance && (
                  <div className="align-right clickable">
                    {/* {shouldShowMaxButton() && ( */}
                    <div
                      className={cx("Exchange-swap-max", {
                        half: multiplier === 0.5,
                      })}
                      onClick={() => setFromValueToMaximumAvailable(0.5)}
                    >
                      <Trans>50%</Trans>
                    </div>
                    <div
                      className={cx("Exchange-swap-max", {
                        max: multiplier === 1,
                      })}
                      onClick={() => setFromValueToMaximumAvailable(1)}
                    >
                      <Trans>100%</Trans>
                    </div>
                    {/* )} */}
                    <div className="balance-tag" onClick={() => setFromValueToMaximumAvailable(1)}>
                      <Trans>Bal: {`${formatAmount(fromBalance, fromToken.decimals, 4, true)}`}</Trans>
                    </div>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue.toString().slice(0, 10)}
                    onChange={onFromValueChange}
                  />
                  {/* {shouldShowMaxButton() && (
                    <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                      <Trans>MAX</Trans>
                    </div>
                  )} */}
                  <div className="usd-value">~${formatAmount(fromUsdMin, USD_DECIMALS, 2, true, "0.00")}</div>
                </div>
                <div>
                  <TokenSelector
                    label={t`Pay`}
                    chainId={chainId}
                    tokenAddress={fromTokenAddress}
                    onSelectToken={onSelectFromToken}
                    tokens={fromTokens}
                    infoTokens={infoTokens}
                    showMintingCap={false}
                    showTokenImgInDropdown={true}
                    showBalances={false}
                  />
                </div>
              </div>
            </div>
            {/* <div className="Exchange-swap-ball-container">
              <div className="Exchange-swap-ball" onClick={switchTokens}>
                <CgArrowsExchangeV size="25" />
              </div>
            </div> */}
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {toUsdMax && <div className="Exchange-swap-usd">{getToLabel()}</div>}
                  {!toUsdMax && getToLabel()}
                </div>
                {/* {toBalance && isSwap && (
                  <div className="muted align-right">
                    <Trans>Bal</Trans>: {formatAmount(toBalance, toToken.decimals, 4, true)}
                  </div>
                )} */}
                {/* {(isLong || isShort) && hasLeverageOption && (
                  <div className="muted align-right">
                    <Trans>Leverage</Trans>: {parseFloat(leverageOption).toFixed(2)}x
                  </div>
                )} */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={toValue.toString().slice(0, 10)}
                    onChange={onToValueChange}
                  />
                  <div className="usd-value">~${formatAmount(toUsdMax, USD_DECIMALS, 2, true, "0.00")}</div>
                </div>
                <div>
                  <TokenSelector
                    label={getTokenLabel()}
                    chainId={chainId}
                    tokenAddress={toTokenAddress}
                    onSelectToken={onSelectToToken}
                    tokens={toTokens}
                    infoTokens={infoTokens}
                    showTokenImgInDropdown={true}
                    showBalances={false}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {(isLong || isShort) && !isStopOrder && (
          <div className="Exchange-leverage-box">
            <div className="Exchange-leverage-slider-settings">
              <span className="muted">
                Leverage slider
                {isLeverageSliderEnabled && (isLong || isShort) && hasLeverageOption && (
                  <div className="muted ">{parseFloat(leverageOption).toFixed(2)}x</div>
                )}
              </span>
              <DragSwitch
                className="toggle-leverage"
                checked={isLeverageSliderEnabled}
                onChange={(e) => {
                  setIsLeverageSliderEnabled(e);
                }}
                handleColor="#E2E2E3"
                onColor="#9D9D9D"
                offColor="#363636"
              />
            </div>
            {isLeverageSliderEnabled && (
              <div
                className={cx("Exchange-leverage-slider", "App-slider", {
                  positive: isLong,
                  negative: isShort,
                })}
              >
                <Slider
                  min={1.1}
                  max={MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR}
                  step={0.1}
                  marks={leverageMarks}
                  // handle={leverageSliderHandle}
                  onChange={(value) => setLeverageOption(value)}
                  defaultValue={leverageOption}
                />
              </div>
            )}
            {!isLeverageSliderEnabled && (
              <div className="add-sub-leverage">
                <div className="left">
                  <div
                    className="sub"
                    onClick={() => {
                      leverageOption > 1.1 &&
                        setLeverageOption(() => (leverageOption - 5 < 1.1 ? 1.1 : leverageOption - 5));
                    }}
                  >
                    <GrSubtract />
                  </div>
                  <p> 5x</p>
                </div>

                {/* {hasExistingPosition && toAmount && toAmount.gt(0) && (
                  <div className="inline-block muted">
                    {formatAmount(existingPosition.leverage, 4, 2)}x
                    <BsArrowRight className="transition-arrow" />
                  </div>
                )}
                {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`} */}

                {/* <input type="text" placeholder="0.00x" onChange={e => {setLeverageOption(e.target.value)}} value={leverageOption > 0 ? (
                  leverageOption > 50 ? 50 : leverageOption) : (
                    toAmount && leverage && leverage.gt(0) ? `${formatAmount(leverage, 4, 2)}x` : (
                      formatAmount(leverage, 4, 2)
                    )
                  )}/> */}
                <div className="input">
                  <input
                    type="text"
                    placeholder="0.00"
                    onChange={(e) => {
                      setLeverageOption(e.target.value < 1.1 ? 1.1 : e.target.value >= 50 ? 50 : e.target.value);
                    }}
                    value={leverageOption > 0 ? (leverageOption > 50 ? 50 : parseFloat(leverageOption).toFixed(2)) : ""}
                  />
                </div>
                <div className="right">
                  <div
                    className="add"
                    onClick={() => {
                      leverageOption <= 50 &&
                        setLeverageOption(() =>
                          leverageOption === 1.1 ? 5 : leverageOption + 5 > 50 ? 50 : leverageOption + 5 + 0
                        );
                    }}
                  >
                    <GrAdd />
                  </div>
                  <p> 5x</p>
                </div>
              </div>
            )}
          </div>
        )}
        {/* {isStopOrder && (
          <div className="Exchange-swap-section Exchange-trigger-order-info">
            <Trans>
              Take-profit and stop-loss orders can be set after opening a position. <br />
              <br />
              There will be a "Close" button on each position row, clicking this will display the option to set trigger
              orders. <br />
              <br />
              For screenshots and more information, please see the{" "}
              <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#stop-loss-take-profit-orders">docs</ExternalLink>
              .
            </Trans>
          </div>
        )} */}
        {<div className="Exchange-swap-button-container">{renderPrimaryButton()}</div>}
        {(isLong || isShort) && !isStopOrder && (
          <div className="App-card ">
            <div className="Exchange-swap-market-box-title">
              {isLong ? t`Long` : t`Short`}&nbsp;{toToken.symbol}
            </div>
            <div className="Exchange-Info-Card">
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Entry Price</Trans>
                </div>
                <div className="align-right">
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <>
                      <div className="inline-block muted">
                        ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                      </div>
                      <BsArrowRight className="transition-arrow" />
                    </>
                  )}
                  {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                  {!nextAveragePrice && `-`}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Exit Price</Trans>
                </div>
                <div className="align-right">
                  <Tooltip
                    handle={`$${formatAmount(exitMarkPrice, USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <div>
                          <Trans>
                            If you have an existing position, the position will be closed at{" "}
                            {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD.
                            <br />
                            <br />
                            This exit price will change with the price of the asset.
                            <br />
                            <br />
                            {/* <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#opening-a-position">
                            More Info
                          </ExternalLink> */}
                          </Trans>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Liq. Price</Trans>
                </div>
                <div className="align-right">
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <>
                      <div className="inline-block muted">
                        ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                      </div>
                      <BsArrowRight className="transition-arrow" />
                    </>
                  )}
                  {toAmount &&
                    displayLiquidationPrice &&
                    `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                  {!toAmount && displayLiquidationPrice && `-`}
                  {!displayLiquidationPrice && `-`}
                </div>
              </div>
              <ExchangeInfoRow label={t`Fees`}>
                <div>
                  {!feesUsd && "-"}
                  {feesUsd && (
                    <Tooltip
                      handle={`$${formatAmount(feesUsd, USD_DECIMALS, 2, true)}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            {swapFees && (
                              <div>
                                <Trans>{collateralToken.symbol} is required for collateral.</Trans> <br />
                                <br />
                                <StatsTooltipRow
                                  label={t`Swap ${fromToken.symbol} to ${collateralToken.symbol} Fee`}
                                  value={formatAmount(swapFees, USD_DECIMALS, 2, true)}
                                />
                                <br />
                              </div>
                            )}
                            <div>
                              <StatsTooltipRow
                                label={t`Position Fee (0.1% of position size)`}
                                value={formatAmount(positionFee, USD_DECIMALS, 2, true)}
                              />
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>
              </ExchangeInfoRow>
            </div>
            {/* <div className="Exchange-swap-button-container">{renderPrimaryButton()}</div> */}
          </div>
        )}
      </div>
      {/*{(isLong || isShort) && (
        <div className="Exchange-swap-market-box App-box App-box-border App-card Exchange-Info-Card">
           <div className="Exchange-swap-market-box-title">
            {isLong ? t`Long` : t`Short`}&nbsp;{toToken.symbol}
          </div>
          {/* <div className="App-card-divider" />
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Trans>Entry Price</Trans>
            </div>
            <div className="align-right">
              <Tooltip
                handle={`$${formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <div>
                      <Trans>
                        The position will be opened at {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD with a
                        max slippage of {parseFloat(savedSlippageAmount / 100.0).toFixed(2)}%.
                        <br />
                        <br />
                        The slippage amount can be configured under Settings, found by clicking on your address at the
                        top right of the page after connecting your wallet.
                        <br />
                        <br />
                        <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#opening-a-position">
                          More Info
                        </ExternalLink>
                      </Trans>
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Trans>Rollover Fee</Trans>
            </div>
            <div className="align-right">
              <Tooltip
                handle={borrowFeeText}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <div>
                      {hasZeroBorrowFee && (
                        <div>
                          {isLong && t`There are more shorts than longs, rollover fees for longing is currently zero`}
                          {isShort && t`There are more longs than shorts, rollover fees for shorting is currently zero`}
                        </div>
                      )}
                      {!hasZeroBorrowFee && (
                        <div>
                          <Trans>
                            The rollover fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                          </Trans>
                          <br />
                          <br />
                          {isShort && t`You can change the "Collateral In" token above to find lower fees`}
                        </div>
                      )}
                      <br />
                      <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#opening-a-position">
                        <Trans>More Info</Trans>
                      </ExternalLink>
                    </div>
                  );
                }}
              >
                {!hasZeroBorrowFee && null}
              </Tooltip>
            </div>
          </div>
          {renderAvailableLongLiquidity()}
          {isShort && toTokenInfo.hasMaxAvailableShort && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Available Liquidity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`$${formatAmount(toTokenInfo.maxAvailableShort, USD_DECIMALS, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={t`Max ${toTokenInfo.symbol} short capacity`}
                          value={formatAmount(toTokenInfo.maxGlobalShortSize, USD_DECIMALS, 0, true)}
                        />
                        <StatsTooltipRow
                          label={t`Current ${toTokenInfo.symbol} shorts`}
                          value={formatAmount(toTokenInfo.globalShortSize, USD_DECIMALS, 0, true)}
                        />
                      </>
                    );
                  }}
                ></Tooltip>
              </div>
            </div>
          )}
        </div>
      )}*/}
      {/* <UsefulLinks className="Useful-links-swapbox" /> */}
      <NoLiquidityErrorModal
        chainId={chainId}
        fromToken={fromToken}
        toToken={toToken}
        shortCollateralToken={shortCollateralToken}
        isLong={isLong}
        isShort={isShort}
        modalError={modalError}
        setModalError={setModalError}
      />
      {renderOrdersToa()}
      {isConfirming && (
        <ConfirmationBox
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isSwap={false}
          isLong={isLong}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          isShort={isShort}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          hasExistingPosition={hasExistingPosition}
          shortCollateralAddress={shortCollateralAddress}
          shortCollateralToken={shortCollateralToken}
          leverage={leverage}
          existingPosition={existingPosition}
          existingLiquidationPrice={existingLiquidationPrice}
          displayLiquidationPrice={displayLiquidationPrice}
          nextAveragePrice={nextAveragePrice}
          triggerPriceUsd={triggerPriceUsd}
          triggerRatio={triggerRatio}
          fees={fees}
          feesUsd={feesUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          collateralTokenAddress={collateralTokenAddress}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          positionMinExecutionFee={positionMinExecutionFee}
          positionMinExecutionFeeUSD={positionMinExecutionFeeUSD}
          positionMinExecutionFeeErrorMessage={positionMinExecutionFeeErrorMessage}
        />
      )}
    </div>
  );
}
