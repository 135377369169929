import React from "react";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";

import "./RewardActions.css";

import { Trans } from "@lingui/macro";
import { useChainId } from "lib/chains";
import RewardHistory from "components/RewardHistory/RewardHistory";

export default function RewardActions(props) {
  const { account } = useParams();
  const { chainId } = useChainId();

  let checkSummedAccount = "";
  if (ethers.utils.isAddress(account)) {
    checkSummedAccount = ethers.utils.getAddress(account);
  }

  return (
    <div className="Actions">
      {checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <Trans>Account</Trans>: {checkSummedAccount}
        </div>
      )}
      <div className="Actions-section">
        <div className="Actions-title">
          <Trans>Reward Distribution & Claim History</Trans>
        </div>
        <RewardHistory
          account={checkSummedAccount}
          chainId={chainId}
          shouldShowPaginationButtons={true}
          isDistributor={props.isDistributor}
        />
      </div>
    </div>
  );
}
