import { useMemo, useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { FiTwitter } from "react-icons/fi";
import { useCopyToClipboard } from "react-use";
// import { IoWarningOutline } from "react-icons/io5";
import { BiCopy } from "react-icons/bi";
import Card from "../Common/Card";
import Modal from "../Modal/Modal";
import { shortenAddress } from "lib/legacy";
import EmptyMessage from "./EmptyMessage";
import InfoCard from "./InfoCard";
import {
  getReferralCodeTradeUrl,
  getTierIdDisplay,
  getTwitterShareUrl,
  getUSDValue,
  isRecentReferralCodeNotExpired,
  liquidityTierRebateInfo,
  tierRebateInfo,
} from "./referralsHelper";
import { AffiliateCodeForm } from "./AddAffiliateCode";
// import TooltipWithPortal from "../Tooltip/TooltipWithPortal";
import { getExplorerUrl } from "config/chains";
import { helperToast } from "lib/helperToast";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Tooltip from "../Tooltip/Tooltip";
import Button from "components/Button/Button";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { DISTRIBUTION_TYPE_REBATES } from "domain/referrals";

function AffiliatesStats({
  referralsData,
  handleCreateReferralCode,
  chainId,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}) {
  const [isAddReferralCodeModalOpen, setIsAddReferralCodeModalOpen] = useState(false);
  const addNewModalRef = useRef(null);

  const [, copyToClipboard] = useCopyToClipboard();
  const open = () => setIsAddReferralCodeModalOpen(true);
  const close = () => setIsAddReferralCodeModalOpen(false);

  const { cumulativeStats, referrerTotalStats, allRebateDistributions, referrerTierInfo, liquidityReferrerTierInfo } =
    referralsData;
  const allReferralCodes = referrerTotalStats.map((c) => c.referralCode.trim());
  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv);
        }
        return acc;
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, recentlyAddedCodes]
  );

  const tierId = referrerTierInfo?.tierId;
  const liquidityTierId = liquidityReferrerTierInfo?.tierId;
  let referrerRebates = bigNumberify(0);
  if (cumulativeStats && cumulativeStats.totalRebateUsd && cumulativeStats.discountUsd) {
    referrerRebates = cumulativeStats.totalRebateUsd.sub(cumulativeStats.discountUsd);
  }

  return (
    <div>
      <div className="header-container">
        <div className="headerTitle">
          <h2 className="title">Generate a New Referral Code</h2>
          <p className="sub-title">Create multiple referral codes to earn maximum rebates!</p>
        </div>
        <div className="referral-form-container">
          <h2 className="title">
            <Trans>Generate new Referral Code</Trans>
          </h2>
          <div className="card-action">
            <Button onClick={open} className="App-cta Exchange-swap-button">
              <span className="ml-small">
                <Trans>Create </Trans>
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div className="App-card-divider section-link"></div>
      <div className="section-link-container">
        <a href="#total-stats">Stats</a>
        {allRebateDistributions?.length > 0 ? <a href="#rebates">Rebates</a> : <a href="#no-rebates">Rebates</a>}
        <a href="#how">How it works</a>
      </div>
      <div className="referral-body-container">
        <h2 className="Page-title" id="#total-stats">
          Total Stats
        </h2>
        <div className="referral-stats">
          <InfoCard
            label={t`Total Users Referred`}
            tooltipText={t`Amount of traders and FLP holders you referred.`}
            data={cumulativeStats?.registeredReferralsCount || "0"}
          />
          <InfoCard
            label={t`Total Referral Volume`}
            tooltipText={
              <>
                <Trans>Volume traded by your referred traders and FLP holders.</Trans>
                <br />
                <br />
                <StatsTooltipRow
                  label={t`Trade Volume`}
                  value={getUSDValue(cumulativeStats?.volume)}
                  showDollar={false}
                />
                <StatsTooltipRow
                  label={t`BuyFLP Volume`}
                  value={getUSDValue(cumulativeStats?.liquidityVolume)}
                  showDollar={false}
                />
              </>
            }
            data={getUSDValue(cumulativeStats?.volume?.add(cumulativeStats?.liquidityVolume))}
          />
          <InfoCard
            label={t`Total Rebates`}
            tooltipText={
              <>
                <Trans>Rebates earned by this account as an affiliate.</Trans>
                <br />
                <br />
                <StatsTooltipRow label={t`From Trades`} value={getUSDValue(referrerRebates, 4)} showDollar={false} />
                <StatsTooltipRow
                  label={t`From BuyFlp`}
                  value={getUSDValue(cumulativeStats?.liquidityTotalRebateUsd, 4)}
                  showDollar={false}
                />
              </>
            }
            data={getUSDValue(cumulativeStats?.liquidityTotalRebateUsd?.add(referrerRebates), 4)}
          />
        </div>
        <div className="card-list">
          <Modal
            className="Connect-wallet-modal"
            isVisible={isAddReferralCodeModalOpen}
            setIsVisible={close}
            label={t`Create Referral Code`}
            onAfterOpen={() => addNewModalRef.current?.focus()}
          >
            <div className="edit-referral-modal">
              <AffiliateCodeForm
                handleCreateReferralCode={handleCreateReferralCode}
                recentlyAddedCodes={recentlyAddedCodes}
                setRecentlyAddedCodes={setRecentlyAddedCodes}
                callAfterSuccess={close}
              />
            </div>
          </Modal>
          <div className="table-wrapper small">
            {/* <table className="referral-table">
              <tbody> */}
            {finalAffiliatesTotalStats.map((stat, index) => {
              // const ownerOnOtherChain = stat?.ownerOnOtherChain;
              let referrerRebate = bigNumberify(0);
              if (stat && stat.totalRebateUsd && stat.discountUsd) {
                referrerRebate = stat.totalRebateUsd.sub(stat.discountUsd);
              }
              return (
                <div className="App-card" key={`${index}`}>
                  <div className="App-card-title">
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">
                          <div className="table-referral-code">
                            <span className="referral-text ">{stat.referralCode}</span>
                            <div
                              onClick={() => {
                                copyToClipboard(getReferralCodeTradeUrl(stat.referralCode));
                                helperToast.success("Referral link copied to your clipboard");
                              }}
                              className="referral-code-icon"
                            >
                              <BiCopy />
                            </div>
                            {/* {ownerOnOtherChain && !ownerOnOtherChain?.isTaken && (
                              <div className="info">
                                <TooltipWithPortal
                                  position="left-bottom"
                                  handle={<IoWarningOutline color="#ffba0e" size={16} />}
                                  renderContent={() => (
                                    <div>
                                      <Trans>
                                        This code is not yet registered on{" "}
                                        {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}, you will not receive rebates
                                        there.
                                        <br />
                                        <br />
                                        Switch your network to create this code on{" "}
                                        {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}.
                                      </Trans>
                                    </div>
                                  )}
                                />
                              </div>
                            )}
                            {ownerOnOtherChain &&
                              ownerOnOtherChain?.isTaken &&
                              !ownerOnOtherChain?.isTakenByCurrentUser && (
                                <div className="info">
                                  <TooltipWithPortal
                                    position="right-bottom"
                                    handle={<BiErrorCircle color="#e82e56" size={16} />}
                                    renderContent={() => (
                                      <div>
                                        <Trans>
                                          This code has been taken by someone else on{" "}
                                          {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}, you will not receive
                                          rebates from traders using this code on{" "}
                                          {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}.
                                        </Trans>
                                      </div>
                                    )}
                                  />
                                </div>
                              )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    {tierId && liquidityTierId && (
                      <>
                        <div className="App-card-row">
                          <div className="label">
                            <Trans>Trade Tier</Trans>
                          </div>
                          <Tooltip
                            handle={`tier ${getTierIdDisplay(tierId)}`}
                            position="right-top"
                            renderContent={() => `${tierRebateInfo[tierId]}% rebate`}
                          />
                        </div>
                        <div className="App-card-row">
                          <div className="label">
                            <Trans>BuyFLP Tier</Trans>
                          </div>
                          <Tooltip
                            handle={`tier ${getTierIdDisplay(liquidityTierId)}`}
                            position="right-top"
                            renderContent={() => `${liquidityReferrerTierInfo[liquidityTierId]}% rebate`}
                          />
                        </div>
                      </>
                    )}
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Trade Volume</Trans>
                      </div>
                      {getUSDValue(stat.volume)}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>BuyFLP Volume</Trans>
                      </div>
                      {getUSDValue(stat.liquidityVolume)}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Referred</Trans>
                      </div>
                      {stat.registeredReferralsCount}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Trade Rebates</Trans>
                      </div>
                      {getUSDValue(referrerRebate, 4)}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>BuyFLP Rebates</Trans>
                      </div>
                      {getUSDValue(stat.liquidityTotalRebateUsd, 4)}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Invite</Trans>
                      </div>
                      <a
                        href={getTwitterShareUrl(stat.referralCode)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="referral-code-icon"
                      >
                        <FiTwitter />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </tbody>
            </table> */}
          </div>
        </div>
        <div className="referral-list-container large">
          <div className="header">
            <div className="table-head">
              <Trans>Referral Code</Trans>
            </div>
            <div className="table-head">
              <Trans>Type</Trans>
            </div>
            {tierId && liquidityTierId && (
              <div className="table-head">
                <Trans>Tier</Trans>
              </div>
            )}
            <div className="table-head">
              <Trans>Total Volume</Trans>
            </div>
            <div className="table-head">
              <Trans>Users Referred</Trans>
            </div>
            <div className="table-head">
              <Trans>Total Rebates</Trans>
            </div>
            <div className="table-head">
              <Trans>Invite</Trans>
            </div>
          </div>
          <div className="body">
            {finalAffiliatesTotalStats.map((stat, index) => {
              // const ownerOnOtherChain = stat?.ownerOnOtherChain;
              let referrerRebate = bigNumberify(0);
              if (stat && stat.totalRebateUsd && stat.discountUsd) {
                referrerRebate = stat.totalRebateUsd.sub(stat.discountUsd);
              }
              return (
                <div className="row" key={`${index}`}>
                  <div data-label="Referral Code" className="table-head">
                    <div className="table-referral-code">
                      <span className="referral-text ">{stat.referralCode}</span>
                      <div
                        onClick={() => {
                          copyToClipboard(getReferralCodeTradeUrl(stat.referralCode));
                          helperToast.success("Referral link copied to your clipboard");
                        }}
                        className="referral-code-icon"
                      >
                        <BiCopy />
                      </div>
                      {/* {ownerOnOtherChain && !ownerOnOtherChain?.isTaken && (
                        <div className="info">
                          <TooltipWithPortal
                            position="left-bottom"
                            handle={<IoWarningOutline color="#ffba0e" size={16} />}
                            renderContent={() => (
                              <div>
                                <Trans>
                                  This code is not yet registered on {chainId === GOERLI ? "OptimismGoerli" : "Goerli"},
                                  you will not receive rebates there.
                                  <br />
                                  <br />
                                  Switch your network to create this code on{" "}
                                  {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}.
                                </Trans>
                              </div>
                            )}
                          />
                        </div>
                      )}
                      {ownerOnOtherChain && ownerOnOtherChain?.isTaken && !ownerOnOtherChain?.isTakenByCurrentUser && (
                        <div className="info">
                          <TooltipWithPortal
                            position="right-bottom"
                            handle={<BiErrorCircle color="#e82e56" size={16} />}
                            renderContent={() => (
                              <div>
                                <Trans>
                                  This code has been taken by someone else on{" "}
                                  {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}, you will not receive rebates from
                                  traders using this code on {chainId === GOERLI ? "OptimismGoerli" : "Goerli"}.
                                </Trans>
                              </div>
                            )}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div data-label="Total Rebates tier" className="table-head">
                    <p>
                      <Trans>Trade</Trans>
                    </p>
                    <p>
                      <Trans>BuyFLP</Trans>
                    </p>
                  </div>
                  {tierId && liquidityTierId && (
                    <div data-label="Total Rebates tier" className="table-head">
                      <p>
                        <Tooltip
                          handle={`Tier ${getTierIdDisplay(tierId)}`}
                          position="right-top"
                          renderContent={() => `${tierRebateInfo[tierId]}% rebate`}
                        />
                      </p>
                      <p>
                        <Tooltip
                          handle={`Tier ${getTierIdDisplay(liquidityTierId)}`}
                          position="right-top"
                          renderContent={() => `${liquidityTierRebateInfo[liquidityTierId]}% rebate`}
                        />
                      </p>
                    </div>
                  )}
                  <div data-label="Total Volume" className="table-head">
                    <p>{getUSDValue(stat.volume)}</p>
                    <p>{getUSDValue(stat.liquidityVolume)}</p>
                  </div>
                  <div data-label="Users Referred" className="table-head">
                    {stat.registeredReferralsCount}
                  </div>
                  <div data-label="Total Rebates" className="table-head">
                    <p>{getUSDValue(referrerRebate, 4)}</p>
                    <p>{getUSDValue(stat.liquidityTotalRebateUsd, 4)}</p>
                  </div>
                  <div data-label="Total Rebates" className="table-head">
                    <a
                      href={getTwitterShareUrl(stat.referralCode)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="referral-code-icon"
                    >
                      <FiTwitter />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {allRebateDistributions?.length > 0 ? (
          <div className="reward-history" id="rebates">
            <Card title={t`Rewards Distribution History`} tooltipText={t`Rewards are airdropped weekly.`}>
              <div className="table-wrapper">
                <table className="referral-table">
                  <thead>
                    <tr>
                      <th className="table-head" scope="col">
                        <Trans>Date</Trans>
                      </th>
                      <th className="table-head" scope="col">
                        <Trans>Type</Trans>
                      </th>
                      <th className="table-head" scope="col">
                        <Trans>Amount</Trans>
                      </th>
                      <th className="table-head" scope="col">
                        <Trans>Transaction</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRebateDistributions.map((rebate, index) => {
                      let tokenInfo;
                      try {
                        tokenInfo = getToken(chainId, rebate.token);
                      } catch {
                        tokenInfo = getNativeToken(chainId);
                      }
                      const explorerURL = getExplorerUrl(chainId);
                      return (
                        <tr key={index}>
                          <td className="table-head" data-label="Date">
                            {formatDate(rebate.timestamp)}
                          </td>
                          <td className="table-head" data-label="Type">
                            {rebate.typeId === DISTRIBUTION_TYPE_REBATES ? t`Trades` : t`BuyFLP`}
                          </td>
                          <td className="table-head" data-label="Amount">
                            {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                          </td>
                          <td className="table-head" data-label="Transaction">
                            <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                              {shortenAddress(rebate.transactionHash, 13)}
                            </ExternalLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        ) : (
          <div className="reward-history" id="no-rebates">
            <h4 className="Page-title">Rebates Distribution</h4>
            <EmptyMessage message={t`No rebates distribution history yet. Rebates are airdropped weekly`} />
          </div>
        )}
        <h4 className="Page-title" id="how">
          How it works
        </h4>
        <h6 className="Page-subtitle">Trade Referrals</h6>
        <div className="referral-info-container">
          <div className="tiers-container">
            <div className="tier">
              <h4>Tier 1</h4>
              <p>for Traders : 5% discount</p>
              <p>to Referrer : 5% rebates</p>
            </div>
            <div className="tier">
              <h4>Tier 2</h4>
              <p>for Traders : 10% discount</p>
              <p>to Referrer : 10% rebates</p>
            </div>
            <div className="tier">
              <h4>Tier 3</h4>
              <p>for Traders : 10% discount</p>
              <p>
                to Referrer : &nbsp;
                <span>
                  <Tooltip
                    handle={<p>15% rebates</p>}
                    position="center-bottom"
                    renderContent={() => "15% rebates in ETH + 5% rebates in esFXDX"}
                  />
                </span>
              </p>
            </div>
          </div>
          <div className="tier-info">
            <p>Anyone can create a Tier 1 code. To upgrade your code to Tier 2 or Tier 3:</p>
            <p>
              Tier 2: At least 15 active users using your referral codes per week and a combined weekly volume above $5
              million
            </p>
            <p>
              Tier 3: At least 30 active users using your referral codes per week and a combined weekly volume above $25
              million
            </p>
          </div>
        </div>
        <h6 className="Page-subtitle">BuyFLP Referrals</h6>
        <div className="referral-info-container">
          <div className="tiers-container">
            <div className="tier">
              <h4>Tier 1</h4>
              <p>to Referrer : 10% rebates</p>
            </div>
            <div className="tier">
              <h4>Tier 2</h4>
              <p>to Referrer : 20% rebates</p>
            </div>
            <div className="tier">
              <h4>Tier 3</h4>
              <p>
                to Referrer : 25% rebates&nbsp;
                {/* <span>
                  <Tooltip
                    handle={<p>25% rebates</p>}
                    position="center-bottom"
                    renderContent={() => "15% rebates in ETH + 5% rebates in esFXDX"}
                  />
                </span> */}
              </p>
            </div>
          </div>
          <div className="tier-info">
            <p>Anyone can create a Tier 1 code. To upgrade your code to Tier 2 or Tier 3:</p>
            <p>
              Tier 2: At least 15 active users using your referral codes per week and a combined weekly BuyFLP volume
              above $5 million
            </p>
            <p>
              Tier 3: At least 30 active users using your referral codes per week and a combined weekly BuyFLP volume
              above $25 million
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AffiliatesStats;
