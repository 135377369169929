import { getServerBaseUrl } from "config/backend";
import useSWR from "swr";

export function useRewardActions(chainId, account, forSingleAccount, afterId) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/reward_actions?account=${account}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/reward_actions`;

  if (url && afterId && afterId.length > 0) {
    const urlItem = new URL(url);
    urlItem.searchParams.append("afterId", afterId);
    url = urlItem.toString();
  }

  const { data: actions, mutate: updateActions } = useSWR(url && url, {
    dedupingInterval: 10000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  if (actions) {
    actions.sort((item0, item1) => {
      if (item1.timestamp > item0.timestamp) {
        return 1;
      }
      if (item1.timestamp < item0.timestamp) {
        return -1;
      }

      if (item1.blockNumber > item0.blockNumber) {
        return 1;
      }

      if (item1.blockNumber < item0.blockNumber) {
        return -1;
      }

      return 0;
    });
  }

  return { actions, updateActions };
}
