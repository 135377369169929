// ReferralImage.js
import React, { forwardRef } from 'react';

const ReferralImage = forwardRef(({ referralCode }, ref) => {
  // The content is hidden by default; styles will be applied during download
  return (
    <div ref={ref} style={{ display: 'none' }}>
      <h1>Kickstart 2024 with FXDX</h1>
      <ol>
        <li>Use my referral code and get upto 25% rebates</li>
        <li>Trying out trading on FXDX, up to 50x leverage on $BTC, $ETH</li>
      </ol>
      <div className="referral-code">
        <strong>{referralCode}</strong>
      </div>
      {/* Add other elements like images and brand logo */}
      {/* <div className="brand-logo">
        YourBrandLogo
      </div> */}
    </div>
  );
});

export default ReferralImage;
