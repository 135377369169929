import { FALLBACK_PROVIDERS, getRpcUrl } from "config/chains";
import _ from "lodash";
import { ethers } from "ethers";
import { Signer } from "ethers";

export function getProvider(signer: Signer | undefined, chainId: number) {
  let provider;

  if (signer && signer.provider) {
    return signer;
  }

  provider = getRpcUrl(chainId);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}

export function getFallbackProvider(chainId: number) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return;
  }

  const provider = _.sample(FALLBACK_PROVIDERS[chainId]);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}
