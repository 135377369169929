export enum ErrorCode {
    PoolExceeded = "POOL_EXCEEDED",
    Buffer = "BUFFER",
    MaxUSDG = "MAX_USDG",
    InvalidLiqPrice = "INVALID_LIQ_PRICE",
  }
  
  export enum ErrorDisplayType {
    Modal = "MODAL",
    Tooltip = "TOOLTIP",
  }
  