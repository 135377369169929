import React from "react";
import "./LaunchCardMain.css";
import LaunchCardMainButton from "./LaunchCardMainButton";
const LaunchCardMain = ({ props }) => {
  return (
    <div className="launchcard-mbl-container">
      <p className="launchcard-mbl-title">
        {`Phase ${props.Stage} - `}
        {props.title}
      </p>
      <p className="launchcard-mbl-desc">{props.desc1}</p>
      <LaunchCardMainButton props={props} />
    </div>
  );
};

export default LaunchCardMain;
