import cx from "classnames";

import { NavLink } from "react-router-dom";

import { getContract } from "config/contracts";
import * as Api from "domain/legacy";
import { useAllOrders, useAllOrdersStats, usePositionsForOrders } from "domain/legacy";
import {
  DECREASE,
  getNextToAmount,
  getExchangeRate,
  // getExchangeRateDisplay,
  getOrderKey,
  INCREASE,
  shortenAddress,
  // shouldInvertTriggerRatio,
  SWAP,
  USD_DECIMALS,
} from "lib/legacy";

import "./OrdersOverview.css";
import { t, Trans } from "@lingui/macro";
import { getTokenInfo } from "domain/tokens/utils";
import { useInfoTokens } from "domain/tokens";
import { formatAmount, formatPrice } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import useWallet from "lib/wallets/useWallet";

export default function OrdersOverview() {
  const { isConnected: active, address: account, signer, chainId } = useWallet();

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdfAddress = getContract(chainId, "USDF");
  const vaultAddress = getContract(chainId, "Vault");

  const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);

  const { data: usdfSupply } = useSWR([`OrdersOverview:usdfSupply:${active}`, chainId, usdfAddress, "totalSupply"], {
    fetcher: contractFetcher(signer, Token),
  });

  const { data: totalTokenWeights } = useSWR(
    [`OrdersOverview:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(signer, Vault),
    }
  );

  const orders = useAllOrders(chainId, signer);
  const { stats } = useAllOrdersStats(chainId);

  const positionsForOrders = usePositionsForOrders(
    chainId,
    signer,
    orders.filter((order) => order.type === DECREASE)
  );

  let openTotal;
  let executedTotal;
  let cancelledTotal;

  if (stats) {
    openTotal = stats.openDecrease + stats.openIncrease + stats.openSwap;
    executedTotal = stats.executedDecrease + stats.executedIncrease + stats.executedSwap;
    cancelledTotal = stats.cancelledDecrease + stats.cancelledIncrease + stats.cancelledSwap;
  }

  const NEAR_TRESHOLD = 98;

  const executeOrder = (evt, order) => {
    evt.preventDefault();

    const params = [chainId, signer, order.account, order.index, account];
    let method;
    if (order.type === "Swap") {
      method = "executeSwapOrder";
    } else if (order.type === "Increase") {
      method = "executeIncreaseOrder";
    } else {
      method = "executeDecreaseOrder";
    }
    return Api[method](...params);
  };

  return (
    <div className="Orders-overview">
      {stats && (
        <p className="Orders-overview-stats">
          <span>
            <p>Total active: {openTotal}</p>
            <p>executed: {executedTotal}</p>
            <p> cancelled: {cancelledTotal}</p>
          </span>

          <span>
            <p>Increase active: {stats.openIncrease}</p>
            <p>executed: {stats.executedIncrease}</p>
            <p>cancelled: {stats.cancelledIncrease}</p>
          </span>

          <span>
            <p>Decrease active: {stats.openDecrease}</p>
            <p>executed: {stats.executedDecrease}</p>
            <p>cancelled: {stats.cancelledDecrease}</p>
          </span>

          <span>
            <p>Swap active: {stats.openSwap}</p>
            <p>executed: {stats.executedSwap}</p>
            <p>cancelled: {stats.cancelledSwap}</p>
          </span>
        </p>
      )}
      <p className="color-tags">
        <span className="positive">
          <Trans>Price conditions are met</Trans>
        </span>
        <br />
        <span className="orange">
          <Trans>Close to execution price</Trans>
        </span>
        <br />
        <span className="negative">
          <Trans>Can't execute because of an error</Trans>
        </span>
      </p>
      <table className="Orders-overview-table">
        <thead>
          <tr>
            <th>
              <Trans>Type</Trans>
            </th>
            <th>
              <Trans>Order</Trans>
            </th>
            <th>
              <Trans>Size</Trans>
            </th>
            <th>
              <Trans>Price</Trans>
            </th>
            <th>
              <Trans>Mark</Trans> Price
            </th>
            <th>
              <Trans>Diff</Trans>
            </th>
            <th>
              <Trans>Account</Trans>
            </th>
            <th>
              <Trans>Created</Trans> At
            </th>
            <th>
              <Trans>Index</Trans>
            </th>
            <th>
              <Trans>Error</Trans>
            </th>
            <th>
              <Trans>Error</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const { type } = order;
            const key = getOrderKey(order);
            if (type === SWAP) {
              const fromToken = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
              const toTokenAddress = order.path[order.path.length - 1];
              const toToken = getTokenInfo(infoTokens, toTokenAddress, order.shoudUnwrap, nativeTokenAddress);

              let markExchangeRate;
              // let prefix;
              let shouldExecute;
              let nearExecute;
              let diffPercent;
              let invalidToken = false;
              let error;
              if (fromToken && toToken) {
                // const invert = shouldInvertTriggerRatio(fromToken, toToken);
                markExchangeRate = getExchangeRate(fromToken, toToken);
                // prefix =
                //   (order.triggerAboveThreshold && !invert) || (!order.triggerAboveThreshold && invert) ? "> " : "< ";
                const { amount: amountOut } = getNextToAmount(
                  chainId,
                  order.amountIn,
                  order.path[0],
                  toTokenAddress,
                  infoTokens,
                  undefined,
                  undefined,
                  usdfSupply,
                  totalTokenWeights,
                  true
                );

                shouldExecute = amountOut && amountOut.gte(order.minOut);
                nearExecute = amountOut && amountOut.gt(order.minOut.mul(NEAR_TRESHOLD).div(100));

                if (shouldExecute && order.triggerAboveThreshold && markExchangeRate) {
                  shouldExecute = markExchangeRate.gt(order.triggerRatio);
                }

                if (amountOut && amountOut.gt(0)) {
                  const diff = order.minOut.gt(amountOut) ? order.minOut.sub(amountOut) : amountOut.sub(order.minOut);
                  diffPercent = diff.mul(1000).div(amountOut);
                }
              } else {
                invalidToken = true;
                error = t`Invalid token fromToken: "${order.path0}" toToken: "${toTokenAddress}"`;
              }

              return (
                <tr key={key}>
                  <td>
                    <Trans>Swap</Trans>
                  </td>
                  <td>
                    {!invalidToken && (
                      <>
                        {/* {formatAmount(order.amountIn, fromToken.decimals, 4, true)}  */}
                        {fromToken.symbol}
                        &nbsp;to&nbsp;
                        {/* {formatAmount(order.minOut, toToken.decimals, 4, true)}  */}
                        {toToken.symbol}
                      </>
                    )}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {formatAmount(order.amountIn, fromToken.decimals, 4, true)} &nbsp;
                    {fromToken.symbol}
                    {/* {!invalidToken && prefix}
                    {getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)} */}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {/* {getExchangeRateDisplay(markExchangeRate, fromToken, toToken)} */}
                    {formatAmount(order.minOut, toToken.decimals, 4, true)} &nbsp;
                    {toToken.symbol}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {/* {getExchangeRateDisplay(markExchangeRate, fromToken, toToken)} */}$
                    {formatPrice(
                      order.triggerAboveThreshold
                        ? fromToken.isStable
                          ? toToken.contractMinPrice
                          : fromToken.contractMinPrice
                        : fromToken.isStable
                        ? toToken.contractMaxPrice
                        : fromToken.contractMaxPrice,
                      true
                    )}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {formatAmount(diffPercent, 2, 2)}%
                  </td>
                  <td>
                    <NavLink to={`/trades/${order.account}`}>{shortenAddress(order.account, 12)}</NavLink>
                  </td>
                  <td>{formatDateTime(order.createdTimestamp)}</td>
                  <td>{order.index}</td>
                  <td className="negative">{error}</td>
                  <td>
                    <button className="Orders-overview-action" onClick={(evt) => executeOrder(evt, order)}>
                      Execute
                    </button>
                  </td>
                </tr>
              );
            } else {
              const indexToken = getTokenInfo(infoTokens, order.indexToken, true, nativeTokenAddress);
              const collateralToken = getTokenInfo(infoTokens, order.collateralToken, true, nativeTokenAddress);
              const purchaseToken = getTokenInfo(infoTokens, order.purchaseToken);

              let markPrice;
              let error;
              if (indexToken && collateralToken && (order.type === DECREASE || purchaseToken)) {
                markPrice = order.triggerAboveThreshold ? indexToken.contractMinPrice : indexToken.contractMaxPrice;
              } else {
                error = t`Invalid token indexToken: "${order.indexToken}" collateralToken: "${order.collateralToken}"`;
                if (order.type === "increase") {
                  error += ` purchaseToken: ${order.purchaseToken}`;
                }
              }

              let shouldExecute;
              let nearExecute;
              let diffPercent;
              if (markPrice) {
                shouldExecute = order.triggerAboveThreshold
                  ? markPrice.gt(order.triggerPrice)
                  : markPrice.lt(order.triggerPrice);

                nearExecute = order.triggerAboveThreshold
                  ? markPrice.gt(order.triggerPrice.mul(NEAR_TRESHOLD).div(100))
                  : markPrice.lt(order.triggerPrice.mul(100).div(NEAR_TRESHOLD));

                const diff = markPrice.gt(order.triggerPrice)
                  ? markPrice.sub(order.triggerPrice)
                  : order.triggerPrice.sub(markPrice);
                diffPercent = diff.mul(10000).div(markPrice);
              }

              if (!error && type === DECREASE) {
                if (positionsForOrders && key in positionsForOrders) {
                  const position = positionsForOrders[key];
                  if (!position) {
                    error = t`No position`;
                  } else if (order.sizeDelta.gt(position[0])) {
                    error = t`Order size exceeds position`;
                  } else if (order.sizeDelta.eq(0)) {
                    error = t`Order size is 0`;
                  }
                }
              }

              return (
                <tr key={key}>
                  <td>{order.type}</td>
                  <td>
                    {order.isLong ? t`Long` : t`Short`} {indexToken && indexToken.symbol}
                  </td>
                  <td>
                    {type === INCREASE ? "+" : "-"}${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {order.triggerAboveThreshold ? "> " : "< "}
                    {formatPrice(order.triggerPrice, true)}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    ${formatPrice(markPrice, true)}
                  </td>
                  <td className={cx({ positive: shouldExecute, near: !shouldExecute && nearExecute })}>
                    {formatAmount(diffPercent, 2, 2)}%
                  </td>
                  <td>
                    <NavLink to={`/trades/${order.account}`}>{shortenAddress(order.account, 12)}</NavLink>
                  </td>
                  <td>{formatDateTime(order.createdTimestamp)}</td>
                  <td>{order.index}</td>
                  <td className="negative">{error}</td>
                  <td>
                    <button className="Orders-overview-action" onClick={(evt) => executeOrder(evt, order)}>
                      <Trans>Execute</Trans>
                    </button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
