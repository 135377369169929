import { ethers } from "ethers";
import useSWR from "swr";
import { gql } from "@apollo/client";

import { BASE, OPTIMISM, OPTIMISM_GOERLI } from "config/chains";
import { FLP_DECIMALS } from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmountFree } from "lib/numbers";
import { optimismGoerliSagaGraphClient, optimismSagaGraphClient, baseSagaGraphClient } from "lib/subgraph";

export const BEFORE_EVENT = "BEFORE_EVENT";
export const IN_EVENT = "IN_EVENT";
export const IN_HOLDING_PERIOD = "IN_HOLDING_PERIOD";
export const IN_REWARD_PREIOD = "IN_REWARD_PERIOD";
export const AFTER_REWARD_PERIOD = "AFTER_REWARD_PERIOD";

export const SAGA_REWARD_RATE_PRECISION = 100;

export const TIERS = [
  {
    name: "Tier 1",
    upperLimit: expandDecimals(250000, FLP_DECIMALS),
    rewardRate: 2000,
  },
  {
    name: "Tier 2",
    upperLimit: expandDecimals(750000, FLP_DECIMALS),
    rewardRate: 1750,
  },
  {
    name: "Tier 3",
    upperLimit: expandDecimals(1500000, FLP_DECIMALS),
    rewardRate: 1500,
  },
  {
    name: "Tier 4",
    upperLimit: expandDecimals(3000000, FLP_DECIMALS),
    rewardRate: 1250,
  },
  {
    name: "Tier 5",
    upperLimit: expandDecimals(5000000, FLP_DECIMALS),
    rewardRate: 1000,
  },
  {
    name: "Tier 6",
    upperLimit: expandDecimals(10000000, FLP_DECIMALS),
    rewardRate: 500,
  },
];

const EVENT_START_TIMESTAMP = {
  [OPTIMISM_GOERLI]: 1697112000,
  [OPTIMISM]: 1697112000,
  [BASE]: 1697112000,
};

const EVENT_END_TIMESTAMP = {
  [OPTIMISM_GOERLI]: 1699790400,
  [OPTIMISM]: 1699790400,
  [BASE]: 1699790400,
};

const HOLD_PERIOD_END_TIMESTAMP = {
  [OPTIMISM_GOERLI]: 1702382400,
  [OPTIMISM]: 1702382400,
  [BASE]: 1702382400,
};

const REWARD_PERIOD_END_TIMESTAMP = {
  [OPTIMISM_GOERLI]: 1731412800,
  [OPTIMISM]: 1731412800,
  [BASE]: 1731412800,
};

function getGraphClient(chainId) {
  if (chainId === OPTIMISM_GOERLI) {
    return optimismGoerliSagaGraphClient;
  } else if (chainId === OPTIMISM) {
    return optimismSagaGraphClient;
  } else if (chainId === BASE) {
    return baseSagaGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

const totalStatQuery = gql`
  {
    totalStat(id: "total") {
      startTimestamp
      endTimestamp
      holdEndTime
      rewardPeriodEndTimestamp
      totalBuyAmount
      currentActiveTier
      totalDistributedRewards
      lastRewardDistributionTime
    }
  }
`;

export function useSagaTotalStat(chainId) {
  const { data } = useSWR([chainId, "saga-total-stat"], {
    fetcher: async () => {
      const res = await getGraphClient(chainId).query({
        query: totalStatQuery,
        fetchPolicy: "no-cache",
      });

      if (res.data && res.data.totalStat) {
        const totalStat = res.data.totalStat;

        return {
          ...totalStat,
          totalBuyAmount: bigNumberify(totalStat.totalBuyAmount),
          totalDistributedRewards: bigNumberify(totalStat.totalDistributedRewards),
        };
      } else if (res.data && !res.data.totalStat) {
        return {
          startTimestamp: EVENT_START_TIMESTAMP[chainId],
          endTimestamp: EVENT_END_TIMESTAMP[chainId],
          holdEndTime: HOLD_PERIOD_END_TIMESTAMP[chainId],
          rewardPeriodEndTimestamp: REWARD_PERIOD_END_TIMESTAMP[chainId],
          totalBuyAmount: bigNumberify(0),
          currentActiveTier: 0,
          totalDistrbutedRewards: bigNumberify(0),
          lastRewardDistributionTime: EVENT_END_TIMESTAMP[chainId],
        };
      }
    },
    refreshInterval: 5000,
  });

  return {
    data,
    loading: !data,
  };
}

const userStatQuery = gql`
  query getUserStat($account: String!) {
    userStat(id: $account) {
      tierCommitments
      totalCommitment
      initialTotalCommitment
      flpBalance
      isQualifiedForRewards
      distributedRewards
      lastRewardDistributionTime
      claimableRewards
      claimableLastUpdatedAt
    }
  }
`;

export function useSagaUserStat(chainId, account) {
  const { data } = useSWR(account ? [chainId, "saga-user-stat", account] : false, {
    fetcher: async () => {
      const res = await getGraphClient().query({
        query: userStatQuery,
        variables: {
          account: (account || "").toLowerCase(),
        },
        fetchPolicy: "no-cache",
      });

      if (res.data && res.data.userStat) {
        const userStat = res.data.userStat;

        return {
          tierCommitments: userStat.tierCommitments.map((item) => bigNumberify(item)),
          totalCommitment: bigNumberify(userStat.totalCommitment),
          initialTotalCommitment: bigNumberify(userStat.initialTotalCommitment),
          flpBalance: bigNumberify(userStat.flpBalance),
          isQualifiedForRewards: userStat.isQualifiedForRewards,
          distributedRewards: bigNumberify(userStat.distributedRewards),
          lastRewardDistributionTime: userStat.lastRewardDistributionTime,
          claimableRewards: bigNumberify(userStat.claimableRewards),
          claimableLastUpdatedAt: userStat.claimableLastUpdatedAt,
        };
      } else if (res.data && !res.data.userStat) {
        return {
          tierCommitments: [
            bigNumberify(0),
            bigNumberify(0),
            bigNumberify(0),
            bigNumberify(0),
            bigNumberify(0),
            bigNumberify(0),
          ],
          totalCommitment: bigNumberify(0),
          initialTotalCommitment: bigNumberify(0),
          flpBalance: bigNumberify(0),
          isQualifiedForRewards: true,
          distributedRewards: bigNumberify(0),
          lastRewardDistributionTime: EVENT_END_TIMESTAMP[chainId],
          claimableRewards: bigNumberify(0),
          claimableLastUpdatedAt: EVENT_END_TIMESTAMP[chainId],
        };
      }
    },
    refreshInterval: 5000,
  });

  return {
    data,
    loading: !data,
  };
}

export const calculateTimeLeft = (timestamp) => {
  const currentTimestamp = Date.now() / 1000;

  if (timestamp < currentTimestamp) {
    return {};
  }

  let seconds = Math.floor(timestamp - currentTimestamp);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours % 24;
  minutes = minutes % 60;
  seconds = seconds % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const TIMER_TEXT_MAP = {
  [BEFORE_EVENT]: "Event starts in",
  [IN_EVENT]: "Event ends in",
  [IN_HOLDING_PERIOD]: "Hold period ends in",
  [IN_REWARD_PREIOD]: "Reward period ends in",
  [AFTER_REWARD_PERIOD]: "The whole event ended.",
};

export const getTimerText = (section) => {
  return TIMER_TEXT_MAP[section];
};

export const getTierLowerLimit = (tierIndex) => {
  if (tierIndex < 1) {
    return bigNumberify(0);
  }
  if (tierIndex > 5) {
    return TIERS[5].upperLimit;
  }

  return TIERS[tierIndex - 1].upperLimit;
};

export const getTierUpperLimit = (tierIndex) => {
  return TIERS[tierIndex].upperLimit;
}

export const getTierRewardRate = (tierIndex) => {
  return TIERS[tierIndex].rewardRate;
}

export function convertToSymbols(labelValue) {
  const floatValue = parseFloat(ethers.utils.formatUnits(labelValue, FLP_DECIMALS));

  if (floatValue >= 1.0e9) {
    return formatAmountFree(Math.round(floatValue / 1.0e7), 2) + "B";
  } else if (floatValue >= 1.0e6) {
    return formatAmountFree(Math.round(floatValue / 1.0e4), 2) + "M";
  } else if (floatValue >= 1.0e3) {
    return formatAmountFree(Math.round(floatValue / 10), 2) + "K";
  } else {
    return floatValue;
  }
}
