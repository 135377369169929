import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";

import { USD_DECIMALS, deserialize, getExchangeRateDisplay, FLP_DECIMALS } from "lib/legacy";
import { useTrades } from "domain/legacy";
import { getContract } from "config/contracts";

import "./TradeHistory.css";
import { getExplorerUrl } from "config/chains";
import { bigNumberify, formatAmount, formatPrice } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import { t, Trans } from "@lingui/macro";
import { FiExternalLink } from "react-icons/fi";

const { AddressZero } = ethers.constants;

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith("Create")) {
    actionDisplay = t`Create`;
  } else if (action.startsWith("Cancel")) {
    actionDisplay = t`Cancel`;
  } else {
    actionDisplay = t`Update`;
  }

  return t`${actionDisplay} Order`;
}

export default function OrderHistory(props) {
  const { account, infoTokens, getTokenInfo, chainId, nativeTokenAddress } = props;
  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [msgLength, setMsgLength] = useState(5);

  const getAfterId = () => {
    return pageIds[pageIndex];
  };

  const { trades, updateTrades } = useTrades(chainId, account, props.forSingleAccount, getAfterId(), props.type);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateTrades]);

  const onShowMore = () => {
    if (!trades || trades.length === 0) {
      return;
    }

    const lastTrade = trades[trades.length - 1];
    pageIds[pageIndex + 1] = lastTrade.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const getMsg = useCallback(
    (trade) => {
      const tradeData = trade.data;
      const params = JSON.parse(tradeData.params);
      const longOrShortText = params?.isLong ? t`Long` : t`Short`;
      const defaultMsg = [];
      const gt = () => {
        return ">";
      };
      const lt = () => {
        return "<";
      };

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(tradeData.action)) {
        const order = deserialize(params);
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(order.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";

        return [
          `${getOrderActionTitle(tradeData.action)}`,
          ` ${minOutDisplay} ${toToken?.symbol || ""}`,
          `${getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}`,
          `${fromToken?.symbol || ""} ->  ${toToken?.symbol || ""}`,
          `Swap ${amountInDisplay} ${fromToken?.symbol || ""}`,
        ];
      }

      if (tradeData.action === "CreateIncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        return [
          `Request`,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()} ${formatPrice(params.acceptablePrice, true)}`,
          `${indexToken.symbol} ${longOrShortText}`,
          `increase`,
        ];
      }

      if (tradeData.action === "CreateDecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        return [
          `Request`,
          `-${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? gt() : lt()} ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
          `decrease`,
        ];
      }

      if (tradeData.action === "CancelIncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [`Execution failed`, "-", "-", `${indexToken.symbol} ${longOrShortText}`, "deposit"];
        }

        return [
          `Order execution failed `,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()} " " ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
          `increase`,
        ];
      }

      if (tradeData.action === "CancelDecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return [`Order execution failed`, "-", "-", `${indexToken.symbol} ${longOrShortText}`, `withdrawal`];
        }

        return [
          `Order execution failed `,
          `+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${params.isLong ? lt() : gt()}  ${formatPrice(params.acceptablePrice, true)} USD`,
          `${indexToken.symbol} ${longOrShortText}`,
          `decrease`,
        ];
      }

      if (
        [
          "CreateIncreaseOrder",
          "CancelIncreaseOrder",
          "UpdateIncreaseOrder",
          "CreateDecreaseOrder",
          "CancelDecreaseOrder",
          "UpdateDecreaseOrder",
        ].includes(tradeData.action)
      ) {
        const order = deserialize(params);
        const indexToken = getTokenInfo(infoTokens, order.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = tradeData.action.includes("Increase");
        const priceDisplay = `${order.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          2,
          true
        )}`;
        const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";

        return [
          `${getOrderActionTitle(tradeData.action)}`,
          `${increase ? "+" : "-"} ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)} USD`,
          `${priceDisplay}`,
          `${symbol} ${order.isLong ? t`Long` : t`Short`}`,
          `${increase ? "increase" : "decrease"}`,
        ];
      }

      if (tradeData.action === "CreateAddLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Request order `,
          `${formatAmount(params.amountIn, token.decimals, 4, true)}`,
          `> ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`,
          `${token.symbol}`,
          `buy FLP`,
        ];
      }

      if (tradeData.action === "CancelAddLiquidity") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Order execution failed `,
          `${formatAmount(params.amountIn, USD_DECIMALS, 2, true)}`,
          `> ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
          `buy FLP `,
        ];
      }

      if (tradeData.action === "CreateRemoveLiquidity") {
        const token = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Request order `,
          `${formatAmount(params.flpAmount, FLP_DECIMALS, 4, true)} FLP`,
          `< ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
          `sell FLP`,
        ];
      }

      if (tradeData.action === "CancelRemoveLiquidity") {
        const token = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }

        return [
          `Order execution failed `,
          `${formatAmount(params.flpAmount, FLP_DECIMALS, 2, true)} FLP`,
          `< ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)}`,
          `${token.symbol}`,
          `sell FLP`,
        ];
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainId]
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }

    return trades
      .map((trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade) => trade.msg);
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      <table className="token-table all-trades Exchange-list Orders App-box large">
        <thead className="Exchange-list-header">
          <tr>
            <th>
              <Trans>TYPE</Trans>
            </th>
            <th>
              <Trans>ACTION</Trans>
            </th>
            <th>
              <Trans>INSTRUMENT</Trans>
            </th>
            <th>
              <Trans>DELTA</Trans>
            </th>
            <th>
              <Trans>PRICE</Trans>
            </th>
            <th className="timestamp">
              <Trans>TIMESTAMP</Trans>
            </th>
            <th>
              <Trans>TRANSACTION</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {tradesWithMessages.length > 0 &&
            tradesWithMessages.map((trade, index) => {
              const tradeData = trade.data;
              const txUrl = getExplorerUrl(chainId) + "tx/" + tradeData.txhash;

              let msg = getMsg(trade);

              if (index >= msgLength || msg.length === 0) {
                return null;
              }

              return (
                <tr className="Exchange-list-item" key={`${index}`}>
                  <td>{msg[0]}</td>
                  <td>{msg[4]}</td>
                  <td>{msg[3]}</td>
                  <td>{msg[1]}</td>
                  <td>{msg[2]}</td>
                  <td>{formatDateTime(tradeData.timestamp)}</td>
                  <td>
                    <a href={txUrl} className="balance-explorer" target="_blank" rel="noopener noreferrer">
                      view on explorer &nbsp; <FiExternalLink className="external-link" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {tradesWithMessages.length === 0 && (
        <div className="TradeHistory-row App-box">
          <Trans>No actions yet</Trans>
        </div>
      )}

      {tradesWithMessages.length > 5 && (
        <div className="show-more-container">
          <button
            className="App-button-option App-card-option show-more"
            onClick={() => {
              if (trades && trades.length <= msgLength + 5) {
                onShowMore();
              }
              setMsgLength(msgLength + 5);
            }}
          >
            <Trans>Show more</Trans>
          </button>
        </div>
      )}
    </div>
  );
}
