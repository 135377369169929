import React from "react";
import { Trans } from "@lingui/macro";
// import { LiFiWidget } from "@lifi/widget";

import Footer from "components/Footer/Footer";
import "./Bridge.css";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
// import { useChainId } from "lib/chains";
import { bridgeInfo } from "./BridgeInfo";
import BridgeCard from "components/BridgeCard/BridgeCard";
// import Tab from "components/Tab/Tab";
// import { useLocalStorageByChainId } from "lib/localStorage";
// import { IS_TESTNET } from "config/chains";

// const baseUrl = "https://app.thevoyager.io/swap";

// const configuration = {
//   isWidget: true,
//   widgetId: "69",
//   fromChain: "1",
//   toChain: "10",
//   fromToken: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
//   toToken: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
//   ctaColor: "#2d42fc",
//   textColor: "#1A1B1C",
//   backgroundColor: "#101124",
//   logoURI: "https://test.fxdx.exchange/og.png",
// };

// const WIDGET_CONFIG_DEFAULT = {
//   integrator: "Fxdx Exchange",
//   containerStyle: {
//     border: "1px solid rgb(83, 83, 83)",
//     borderRadius: "16px",
//   },
//   variant: "expandable",
//   fromChain: 1,
//   // set destination chain to Optimism
//   toChain: 10,
//   // set source token to USDC (Polygon)
//   fromToken: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
//   // set source token to USDC (Optimism)
//   toToken: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
//   appearance: "dark",
//   disableAppearance: true,
// };

// const ROUTER = "Router";
// const LI_FI = "LiFi";
// const LIST_SECTIONS = [ROUTER, LI_FI];
// const LIST_SECTIONS_LABELS = {
//   [ROUTER]: "Router",
//   [LI_FI]: "Li.Fi",
// };

export default function Bridge() {
  // const { chainId } = useChainId();
  // let [listSection, setListSection] = useLocalStorageByChainId(chainId, "Bridge-list-section", LIST_SECTIONS[0]);

  // const [widgetConfig, setWidgetConfig] = useState(WIDGET_CONFIG_DEFAULT);

  // if (!LIST_SECTIONS.includes(listSection)) {
  //   listSection = LIST_SECTIONS[0];
  // }
  // const listSection = ROUTER;

  return (
    <SEO title={getPageTitle("Bridge")}>
      <div className="Bridge page-layout">
        <div className="Bridge-container default-container">
          <div className="section-title-block">
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Bridge</Trans>
              </div>
            </div>
          </div>
          <div className="card-container">
            {bridgeInfo.map((item, index) => (
              <BridgeCard bridge={item} key={index} />
            ))}
          </div>
          {/* {listSection === LI_FI && <LiFiWidget config={widgetConfig} />} */}
        </div>

        <Footer />
      </div>
    </SEO>
  );
}
