import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { TRADES_PAGE_SIZE, FXDX_DECIMALS, shortenAddress } from "lib/legacy";
import "./RewardHistory.css";
import { getConstant, getExplorerUrl } from "config/chains";
import { bigNumberify, formatAmount } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import { Trans } from "@lingui/macro";
import { useRewardActions } from "domain/useRewardActions";
import { getTokenBySymbol } from "config/tokens";
import cx from "classnames";
import { FiExternalLink } from "react-icons/fi";

export default function RewardHistory(props) {
  const { account, chainId, shouldShowPaginationButtons, isDistributor } = props;
  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);

  const feeRewardTokenSymbol = getConstant(chainId, "feeRewardTokenSymbol");
  const feeRewardToken = getTokenBySymbol(chainId, feeRewardTokenSymbol);

  const getAfterId = () => {
    return pageIds[pageIndex];
  };

  const { actions, updateActions } = useRewardActions(
    chainId,
    account,
    props.forSingleAccount,
    getAfterId(),
    props.type
  );

  useEffect(() => {
    const interval = setInterval(() => {
      updateActions(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateActions]);

  const loadNextPage = () => {
    if (!actions || actions.length === 0) {
      return;
    }

    const lastAction = actions[actions.length - 1];
    pageIds[pageIndex + 1] = lastAction.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const loadPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const getMsg = useCallback(
    (action) => {
      const defaultMsg = [];
      const tokenArr = [];

      const fxdxAmount = bigNumberify(action.fxdx);
      const esFxdxAmount = bigNumberify(action.esFxdx);
      const bnFxdxAmount = bigNumberify(action.bnFxdx);
      const feeAmount = bigNumberify(action.fee);

      if (fxdxAmount && fxdxAmount.gt(0)) {
        tokenArr.push(`${formatAmount(fxdxAmount, FXDX_DECIMALS, 4, true)} FXDX`);
      }
      if (esFxdxAmount && esFxdxAmount.gt(0)) {
        tokenArr.push(`${formatAmount(esFxdxAmount, FXDX_DECIMALS, 4, true)} esFXDX`);
      }
      if (bnFxdxAmount && bnFxdxAmount.gt(0)) {
        tokenArr.push(`${formatAmount(bnFxdxAmount, FXDX_DECIMALS, 4, true)} Multiplier Points`);
      }
      if (feeAmount && feeAmount.gt(0)) {
        tokenArr.push(`${formatAmount(feeAmount, feeRewardToken.decimals, 4, true)} ${feeRewardTokenSymbol}`);
      }

      if (tokenArr.length === 0) {
        return defaultMsg;
      }

      if (action.type === "distribute") {
        return [`Distributed`, tokenArr[0]];
      }

      if (action.type === "claim") {
        return [`Claimed`, tokenArr[0]];
      }
    },
    [feeRewardToken, feeRewardTokenSymbol]
  );

  const actionsWithMessages = useMemo(() => {
    if (!actions) {
      return [];
    }

    return actions
      .map((action) => ({
        msg: getMsg(action),
        ...action,
      }))
      .filter((action) => action.msg);
  }, [actions, getMsg]);

  return (
    <div className="TradeHistory">
      <table className={cx(" token-table all-trades Exchange-list Orders App-box large", {})}>
        <thead className="Exchange-list-header">
          <tr>
            <th>
              <Trans>ACTION</Trans>
            </th>
            <th>
              <Trans>AMOUNT</Trans>
            </th>
            {(!account || account.length === 0) && (
              <th>
                <Trans>ACCOUNT</Trans>
              </th>
            )}
            <th>
              <Trans>TIMESTAMP</Trans>
            </th>
            <th>
              <Trans>TRANSACTION</Trans>
            </th>
          </tr>
        </thead>

        <tbody>
          {actionsWithMessages.length > 0 &&
            actionsWithMessages.map((action, index) => {
              const txUrl = getExplorerUrl(chainId) + "tx/" + action.txhash;

              let msg = getMsg(action);

              if (isDistributor || msg.length === 0) {
                return null;
              }

              return (
                <tr className="Exchange-list-item">
                  <td>{msg[0]}</td>
                  <td>{msg[1]}</td>
                  {(!account || account.length === 0) && (
                    <td>
                      <span>
                        {" "}
                        {action.type !== "distribute" && (
                          <Link to={`/reward_actions/${action.account}`}>{shortenAddress(action.account, 18)}</Link>
                        )}
                      </span>
                    </td>
                  )}
                  <td>{formatDateTime(action.timestamp)}</td>
                  <td>
                    <a href={txUrl} className="balance-explorer" target="_blank" rel="noopener noreferrer">
                      view on explorer &nbsp; <FiExternalLink className="external-link" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {actionsWithMessages.length === 0 && (
        <div className="RewardHistory-row App-box">
          {isDistributor ? <Trans>Protocol : FXDX</Trans> : <Trans>No actions yet</Trans>}
        </div>
      )}
      {isDistributor && (
        <div className="RewardHistory-row App-box">
          <Trans>Protocol : FXDX</Trans>
        </div>
      )}

      {shouldShowPaginationButtons && (
        <div>
          {pageIndex > 0 && (
            <button className="App-button-option App-card-option" onClick={loadPrevPage}>
              <Trans>Prev</Trans>
            </button>
          )}
          {actions && actions.length >= TRADES_PAGE_SIZE && (
            <button className="App-button-option App-card-option" onClick={loadNextPage}>
              <Trans>Next</Trans>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
