import { useEffect, useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import cx from "classnames";
import { getCodeError, getReferralCodeTakenStatus, getSampleReferrarStat } from "./referralsHelper";
// import { ARBITRUM } from "config/chains";
import { helperToast } from "lib/helperToast";
import { useDebounce } from "lib/useDebounce";
import { ethers } from "ethers";
// import { GOERLI, IS_TESTNET } from "config/chains";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useWallet from "lib/wallets/useWallet";

function AddAffiliateCode({ handleCreateReferralCode, active, setRecentlyAddedCodes, recentlyAddedCodes }) {
  const { openConnectModal } = useConnectModal();

  return (
    <div className="referral-card section-center mt-medium">
      <div className="headerTitle">
        <h2 className="title">Generate Referral Code</h2>
        <p className="sub-title">Create a referral code to share to start earning rebates!</p>
      </div>

      <div className="referral-form-container">
        <h2 className="title">
          <Trans>Generate Referral Code</Trans>
        </h2>
        <p className="sub-title">
          <Trans>
            Looks like you don't have a referral code to share. <br /> Create one now and start earning rebates! <br />
            Allowed characters are numbers and letters. Ex: fxdx123, 123434f, 99823, fxdx
          </Trans>
        </p>
        <div className="card-action">
          {active ? (
            <AffiliateCodeForm
              handleCreateReferralCode={handleCreateReferralCode}
              recentlyAddedCodes={recentlyAddedCodes}
              setRecentlyAddedCodes={setRecentlyAddedCodes}
            />
          ) : (
            <button className="App-cta Exchange-swap-button" type="submit" onClick={openConnectModal}>
              <Trans>Connect Wallet</Trans>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export function AffiliateCodeForm({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
  callAfterSuccess,
}) {
  const [referralCode, setReferralCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef("");
  const [referralCodeCheckStatus, setReferralCodeCheckStatus] = useState("ok");
  const debouncedReferralCode = useDebounce(referralCode, 300);
  const { address: account, chainId } = useWallet();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    let cancelled = false;
    const checkCodeTakenStatus = async () => {
      if (error) {
        setReferralCodeCheckStatus("ok");
        return;
      }
      const { status: takenStatus } = await getReferralCodeTakenStatus(account, debouncedReferralCode, chainId);
      // ignore the result if the referral code to check has changed
      if (cancelled) {
        return;
      }
      if (takenStatus === "none") {
        setReferralCodeCheckStatus("ok");
      } else {
        setReferralCodeCheckStatus("taken");
      }
    };
    setReferralCodeCheckStatus("checking");
    checkCodeTakenStatus();
    return () => {
      cancelled = true;
    };
  }, [account, debouncedReferralCode, error, chainId]);

  function getButtonError() {
    if (!debouncedReferralCode) {
      return t`Enter a code`;
    }
    if (referralCodeCheckStatus === "taken") {
      return t`Code already taken`;
    }
    if (referralCodeCheckStatus === "checking") {
      return t`Checking code...`;
    }

    return false;
  }

  const buttonError = getButtonError();

  function getPrimaryText() {
    if (buttonError) {
      return buttonError;
    }

    if (isProcessing) {
      return t`Creating...`;
    }

    return t`Create`;
  }
  function isPrimaryEnabled() {
    if (buttonError) {
      return false;
    }
    if (error || isProcessing) {
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    const { status: takenStatus /*, info: takenInfo*/ } = await getReferralCodeTakenStatus(
      account,
      referralCode,
      chainId
    );
    if (["all", "current", "other"].includes(takenStatus)) {
      setIsProcessing(false);
    }

    if (takenStatus === "none" || takenStatus === "other") {
      // const ownerOnOtherNetwork = IS_TESTNET
      //   ? takenInfo[chainId === GOERLI ? "ownerOptimism" : "ownerEthereum"]
      //   : ethers.constants.AddressZero;
      const ownerOnOtherNetwork = ethers.constants.AddressZero;
      try {
        const tx = await handleCreateReferralCode(referralCode);
        if (callAfterSuccess) {
          callAfterSuccess();
        }
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          recentlyAddedCodes.push(getSampleReferrarStat(referralCode, ownerOnOtherNetwork, account));

          helperToast.success(t`Referral code created!`);
          setRecentlyAddedCodes(recentlyAddedCodes);
          setReferralCode("");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        ref={inputRef}
        value={referralCode}
        disabled={isProcessing}
        className={cx("text-input", { "mb-sm": !error })}
        placeholder={t`Enter a code`}
        onChange={({ target }) => {
          const { value } = target;
          setReferralCode(value);
          setError(getCodeError(value));
        }}
      />
      {error && <p className="error">{error}</p>}
      <button className="App-cta Exchange-swap-button width-auto" type="submit" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </button>
    </form>
  );
}

export default AddAffiliateCode;
