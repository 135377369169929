import "./Referral.css";
import Referalsvg from "./Referralimg/referralnew.svg";
import Treasurebox from "./Referralimg/treasurebox.png";
import twitterlogo from "./Referralimg/xtwitterfxdx.svg";
import VectorRedi from "./Referralimg/vectorredi.svg";
import Instagramlogo from "./Referralimg/instgram.svg";
import Tiktoklogo from "./Referralimg/tiktok.svg";
import Telegram from "./Referralimg/telegram.svg";
import whastapp from "./Referralimg/whatsapp.svg";
import Dicord from "./Referralimg/discord.svg";
import Facebook from "./Referralimg/facebook.svg";
import circleall from "./Referralimg/circleall.png";
import Footer from "components/Footer/Footer";
import scroll from "./Referralimg/scroll.svg";
import mobileinvite from "./Referralimg/mobileinvite.png";
import mobilesvg from "./Referralimg/mobilebox.svg";
import { Link } from "react-router-dom";
import downloadReferralImage from "./htmltocanvas";
import ReferralImage from "./Refimgcode";
import referalurl from "./Referralimg/referalurl.svg";
import React, { useRef, useState } from "react";

const Referral = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userReferralCode, setUserReferralCode] = useState("add your referal code in button");
  const [inputReferralCode, setInputReferralCode] = useState("");

  const handleInputChange = (event) => {
    setInputReferralCode(event.target.value);
    if (isSubmitted) {
      setIsSubmitted(false);
    }
  };
  const handleUpdateReferralCode = () => {
    setUserReferralCode(inputReferralCode);
    setIsSubmitted(true);
  };

  const hiddenReferralImageRef = useRef(null);

  const handleDownloadClick = () => {
    downloadReferralImage(hiddenReferralImageRef.current, userReferralCode);
  };

  const handleCopyLinkClick = () => {
    const referralLink = `https://app.fxdx.exchange/trade/?ref=${userReferralCode}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        console.log("Referral link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  //for twitter
  const handleTwitterShareClick = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?text=";
    const twitterShareUrl = `${twitterBaseUrl}${encodedMessage}`;
    window.open(twitterShareUrl, "_blank");
  };

  //for whats app
  const referralLink = `https://app.fxdx.exchange/trade/?ref=${userReferralCode}`;
  const imageUrl = "https://imgur.com/WqtaZhv";
  const customMessage = `Step into the arena of the season's biggest “UNKNOWN EVENT!”
                       
Join me in the most thrilling Trading Competition of the year 2024!

👉 Participate here - ${referralLink}

Let the DeFi begin!
  ${imageUrl}`;
  const encodedMessage = encodeURIComponent(customMessage);

  const handleWhatsAppShareClick = () => {
    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  //for telegram
  const handleTelegramShareClick = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodedMessage}`;
    window.open(telegramUrl, "_blank");
  };

  const handleFacebookShareClick = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      referralLink
    )}&quote=${encodedMessage}`;
    window.open(facebookUrl, "_blank");
  };

  //for reddit
  const handleRedditShareClick = () => {
    const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(referralLink)}&title=${encodedMessage}`;
    window.open(redditUrl, "_blank");
  };

  const handleViewReferralRulesClick = () => {
    window.open("https://fxdxdex.medium.com/fxdx-uknown-event-04944b804345", "_blank");
  };

  return (
    <div>
      <div className="referalall_mobile">
        <div className="referral-hero_mobile">
          <img src={Treasurebox} alt="referal_box_for" />
        </div>
        <div className="textinbox_mobile">
          <h3>Referral Trading Volume</h3>
          <p>
            Get started with <span>1000</span> points for every <br />
            successful referral
          </p>
          <div className="scrollandrules_mobile">
            <div className="scrollimage_mobile">
              <img src={scroll} alt="scroll_click" />
            </div>
            <div className="textreferalrules_mobile" onClick={handleViewReferralRulesClick}>
              <h5>View referral rules</h5>
            </div>
          </div>
          <div className="mobilesvg_mob">
            <img src={mobilesvg} alt="referal_box_mobile" />
          </div>
        </div>
      </div>
      <div className="referalall">
        <div className="referral-hero">
          <img src={Referalsvg} alt="referal_box_img" />
          <div className="textinbox">
            <h3>Referral Trading Volume</h3>
            <p>
              Get started with <span>1000</span> points for every <br />
              successful referral
            </p>
            <div style={{ cursor: "pointer" }} className="scrollandrules" onClick={handleViewReferralRulesClick}>
              <div className="scrollimage">
                <img src={scroll} alt="scrollimg" />
              </div>
              <div className="textreferalrules" >
                <h5 >View referral rules</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="treasureimg">
          <img src={Treasurebox} alt="trasureboxbig" />
        </div>
      </div>
      <div className="invitedataall">
        <div className="inviteandearn">
          <h3>Invite & Earn More</h3>
          <p>Your referral becomes easier just by referring your friends and colleagues on social media</p>
        </div>
      </div>
      <div className="invitetablebox">
        <div className="invitereferaltable">
          <div className="tableinvitedata">
            <div className="tabledataheader">
              <div className="tabledata-invite">Tasks</div>
              {/* <div className="tabledata-invite_bonus">Bonus Points</div> */}
              <div className="tabledata-invite_actions">Actions</div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={twitterlogo} alt="share on twitter" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via Twitter</div>

                  <div className="invite_text_all">Twitter</div>
                </div>
                {/* <div className="bonus_table">
                  {" "}
                  <h4>+100</h4>{" "}
                </div> */}

                <div className="imgvectorarrow">
                  {/* <Link to={`/referrals`} target="_blank"> */}
                  <img src={VectorRedi} alt="Share on Twitter" onClick={handleTwitterShareClick} />
                  {/* </Link>  */}
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={Telegram} alt="shareon telegram" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via Telegram</div>
                  <div className="invite_text_all">Telegram</div>
                </div>
                {/* <div className="bonus_table_telegram">
                  {" "}
                  <h4>+50</h4>{" "}
                </div> */}
                <div className="imgvectorarrow">
                  {" "}
                  <img src={VectorRedi} alt="Share on Telegram" onClick={handleTelegramShareClick} />
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={whastapp} alt="whastapp_logo" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via WhatsApp</div>
                  <div className="invite_text_all">WhatsApp</div>
                </div>
                {/* <div className="bonus_table_whatsapp">
                  {" "}
                  <h4>+30</h4>{" "}
                </div> */}
                <div className="imgvectorarrow">
                  {" "}
                  <img src={VectorRedi} alt="ShareonWhatsApp" onClick={handleWhatsAppShareClick} />
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={Facebook} alt="share on facebook" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via Facebook</div>
                  <div className="invite_text_all">Facebook</div>
                </div>
                {/* <div className="bonus_table_facebook">
                  {" "}
                  <h4>+30</h4>{" "}
                </div> */}
                <div className="imgvectorarrow">
                  {" "}
                  <img src={VectorRedi} alt="Share on Facebook" onClick={handleFacebookShareClick} />
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={Instagramlogo} alt="instalogo" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via Reddit</div>
                  <div className="invite_text_all">Reddit</div>
                </div>
                {/* <div className="bonus_table_insta">
                  {" "}
                  <h4>+20</h4>{" "}
                </div> */}
                <div className="imgvectorarrow">
                  {" "}
                  <img src={VectorRedi} alt="Share on Reddit" onClick={handleRedditShareClick} />
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={Dicord} alt="discordlogo" />{" "}
                  </div>
                  <div className="twitter_invite">Share Via Link</div>
                  <div className="invite_text_all">Link</div>
                </div>
                {/* <div className="bonus_table_discord">
                  {" "}
                  <h4>+30</h4>{" "}
                </div> */}
                <div
                  className="imgvectorarrow"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  {isHovering && <div className="custom-tooltip">Copy Referral Link</div>}
                  <img
                    src={VectorRedi}
                    alt="Copy Referral Link"
                    onClick={handleCopyLinkClick}
                    className="copy-referral-image"
                  />
                </div>
              </div>
            </div>
            <div className="tabledatabox">
              <div className="datainisdetable_aligh">
                <div className="imageandinvitetxt">
                  <div className="imageinbox_logo">
                    {" "}
                    <img src={Tiktoklogo} alt="reddit" />{" "}
                  </div>
                  <div className="twitter_invite">Share The Referral Img</div>
                  <div className="invite_text_all">Img</div>
                </div>
                {/* <div className="bonus_table_img">
                  {" "}
                  <h4>+20</h4>{" "}
                </div> */}
                <div className="imgvectorarrow">
                  <ReferralImage ref={hiddenReferralImageRef} referralCode={userReferralCode} />

                  {/* Visible content on the frontend */}
                  <img src={VectorRedi} onClick={handleDownloadClick} alt="Download Referral" />
                </div>
              </div>
            </div>
            <div className="border_line_for_referral"></div>
            <div className="for_referal_button_referalcodegen">
              <div className="data_disclaimer">
                <div className="disclaimer_link">
                  <Link className="link_data_referal" target="_blank" to={`/referrals`}>
                    Click here to Create / Check Your Referral Code <img src={referalurl} alt="refural_action" />
                  </Link>
                </div>
                <div className="disclaimer">
                  * Connect Your Wallet And Share
                  <br />
                  * You can share the image on Instagram or any social platform
                  <br />
                </div>
              </div>
              <div className="referal_button_gen">
                <input
                  type="text"
                  value={inputReferralCode}
                  onChange={handleInputChange}
                  placeholder="Enter Your Referral Code"
                />
                <button className="submit-button-referal" onClick={handleUpdateReferralCode}>
                  {isSubmitted ? "Try Action Links Now" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="referafriend">
        <h3>How To Refer Your Friends</h3>
      </div>
      <div className="theeclipsecircle">
        <div className="circle">
          <span>
            <div className="sendinvtiation">
              <img src={circleall} alt="circlebox" />
            </div>
            <div className="circle_mobile_invite">
              <img src={mobileinvite} alt="circlebox_mobile" />
            </div>
            <div className="textfor_mobile">
              <div className="sendinvut">
                <h3>Send Invitation</h3>
                <p>
                  Share your referral link to your
                  <br />
                  friends and let them know how
                  <br />
                  FXDX is!
                </p>
              </div>
              <div className="singupandconnect">
                <h3>Signup/Connect wallet</h3>
                <p>
                  Let them to enter this exchange
                  <br /> with your referral link.
                </p>
              </div>
              <div className="earnreward">
                <h3>Earn Rewards</h3>
                <p>
                  Once the process completed. You
                  <br />
                  both will get rewards. Rewards will <br />
                  be based on your tier.
                </p>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="containtdata_aliealigh">
        <div className="contantdatareffre">
          <div className="sendinvut">
            <h3>Send Invitation</h3>
            <p>
              Share your referral link to <br /> your friends and let them
              <br />
              know how FXDX is!
            </p>
          </div>
          <div className="singupandconnect">
            <h3>Signup/Connect wallet</h3>
            <p>
              Let them to enter this
              <br />
              exchange with your <br />
              referral link.
            </p>
          </div>
          <div className="earnreward">
            <h3>Earn Rewards</h3>
            <p>
              Once the process completed. You
              <br />
              both will get rewards. Rewards will <br />
              be based on your tier.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
// }
export default Referral;
