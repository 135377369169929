import { Trans } from "@lingui/macro";
import { BigNumber } from "ethers";
import { USD_DECIMALS } from "lib/legacy";
import "./StatsTooltip.css";
import { formatAmount } from "lib/numbers";
import { IS_TESTNET } from "config/chains";

type Props = {
  title: string;
  total?: BigNumber;
  // ethereumValue?: BigNumber;
  baseValue?: BigNumber;
  optimismValue?: BigNumber;
  avaxValue?: BigNumber;
  arbitrumValue?: BigNumber;
  moonbeamValue?: BigNumber;
  showDollar?: boolean;
  decimalsForConversion: number;
  symbol: string;
};

export default function StatsTooltip({
  title,
  total,
  // ethereumValue,
  baseValue,
  optimismValue,
  // moonbeamValue,
  // avaxValue,
  // arbitrumValue,
  showDollar = true,
  decimalsForConversion = USD_DECIMALS,
  symbol,
}: Props) {
  return (
    <>
      {IS_TESTNET ? (
        <>
          {/* <p className="Tooltip-row">
            <span className="label">
              <Trans>{title} on Ethereum:</Trans>
            </span>
            <span className="amount">
              {showDollar && "$"}
              {formatAmount(ethereumValue, decimalsForConversion, 0, true)}
              {!showDollar && symbol && " " + symbol}
            </span>
          </p> */}
          <p className="Tooltip-row">
            <span className="label">
              <Trans>{title} on Optimism:</Trans>
            </span>
            <span className="amount">
              {showDollar && "$"}
              {formatAmount(optimismValue, decimalsForConversion, 0, true)}
              {!showDollar && symbol && " " + symbol}
            </span>
          </p>
        </>
      ) : (
        <>
          <p className="Tooltip-row">
            <span className="label">
              <Trans>{title} on Base:</Trans>
            </span>
            <span className="amount">
              {showDollar && "$"}
              {formatAmount(baseValue, decimalsForConversion, 0, true)}
              {!showDollar && symbol && " " + symbol}
            </span>
          </p>
          <p className="Tooltip-row">
            <span className="label">
              <Trans>{title} on Optimism:</Trans>
            </span>
            <span className="amount">
              {showDollar && "$"}
              {formatAmount(optimismValue, decimalsForConversion, 0, true)}
              {!showDollar && symbol && " " + symbol}
            </span>
          </p>
        </>
      )}
      {/* <p className="Tooltip-row">
          <span className="label">
            <Trans>{title} on MoonBeam:</Trans>
          </span>
          <span className="amount">
            {showDollar && "$"}
            {formatAmount(moonbeamValue, decimalsForConversion, 0, true)}
            {!showDollar && symbol && " " + symbol}
          </span>
        </p> */}
      {/* <p className="Tooltip-row">
        <span className="label">
          <Trans>{title} on Arbitrum:</Trans>
        </span>
        <span className="amount">
          {showDollar && "$"}
          {formatAmount(arbitrumValue, decimalsForConversion, 0, true)}
          {!showDollar && symbol && " " + symbol}
        </span>
      </p>
      <p className="Tooltip-row">
        <span className="label">
          <Trans>{title} on Avalanche:</Trans>
        </span>
        <span className="amount">
          {showDollar && "$"}
          {formatAmount(avaxValue, decimalsForConversion, 0, true)}
          {!showDollar && symbol && " " + symbol}
        </span>
      </p> */}
      <div className="Tooltip-divider" />
      <p className="Tooltip-row">
        <span className="label">
          <Trans>Total:</Trans>
        </span>
        <span className="amount">
          {showDollar && "$"}
          {formatAmount(total, decimalsForConversion, 0, true)}
          {!showDollar && symbol && " " + symbol}
        </span>
      </p>
    </>
  );
}
