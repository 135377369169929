import { ethers } from "ethers";
import { gql } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
// import { Token as UniToken } from "@uniswap/sdk-core";
// import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";

import OrderBook from "abis/OrderBook.json";
import PositionManager from "abis/PositionManager.json";
import Vault from "abis/Vault.json";
import Router from "abis/Router.json";
// import UniPool from "abis/UniPool.json";
// import UniswapV2 from "abis/UniswapV2.json";
import Token from "abis/Token.json";
import PositionRouter from "abis/PositionRouter.json";
import SwapRouter from "abis/SwapRouter.json";
import LiquidityRouter from "abis/LiquidityRouter.json";

import { getContract } from "config/contracts";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  getConstant,
  getHighExecutionFee,
  GOERLI,
  IS_TESTNET,
  MAINNET,
  OPTIMISM_GOERLI,
  MOONBEAM,
  OPTIMISM,
  BASE,
} from "config/chains";
import { DECREASE, getOrderKey, INCREASE, SWAP, USD_DECIMALS } from "lib/legacy";

import { groupBy } from "lodash";
import { UI_VERSION } from "config/ui";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { getFxdxGraphClient } from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { replaceNativeTokenAddress } from "./tokens";
import { getUsd } from "./tokens/utils";
import { getProvider } from "lib/rpc";
import { bigNumberify, expandDecimals } from "lib/numbers";
// import { getTokenBySymbol } from "config/tokens";
import { t } from "@lingui/macro";
import {stakeFxdxVaultData} from "../pages/StakeFxdx/data/stakeFxdxVault.data.js";
import {fxdxData} from "../pages/StakeFxdx/data/fxdx.data.js";

export * from "./prices";

const { AddressZero } = ethers.constants;

export function useAllOrdersStats(chainId) {
  const url = `${getServerBaseUrl(chainId)}/all_orders_stats`;

  const { data: stats, mutate: updateStats } = useSWR(url && url, {
    dedupingInterval: 5000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  return { stats, updateStats };
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getFxdxGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getFxdxGraphClient(chainId);
      if (!graphClient) {
        return;
      }

      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, signer) {
  // const count = 1000;
  // const query = gql(`{
  //   aggregatedTradeOpens(
  //     first: ${count}
  //   ) {
  //     account
  //     initialPosition{
  //       indexToken
  //       collateralToken
  //       isLong
  //       sizeDelta
  //     }
  //     increaseList {
  //       sizeDelta
  //     }
  //     decreaseList {
  //       sizeDelta
  //     }
  //   }
  // }`);

  // const [res, setRes] = useState();

  // useEffect(() => {
  //   nissohGraphClient.query({ query }).then(setRes).catch(console.warn);
  // }, [setRes, query]);

  // const key = res ? `allPositions${count}__` : false;
  // const { data: positions = [] } = useSWR(key, async () => {
  //   const provider = getProvider(signer, chainId);
  //   const vaultAddress = getContract(chainId, "Vault");
  //   const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
  //   const ret = await Promise.all(
  //     res.data.aggregatedTradeOpens.map(async (dataItem) => {
  //       try {
  //         const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
  //         const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
  //         const position = {
  //           size: bigNumberify(positionData[0]),
  //           collateral: bigNumberify(positionData[1]),
  //           entryRolloverRate: bigNumberify(positionData[3]),
  //           account: dataItem.account,
  //         };
  //         position.rolloverFee = await contract.getRolloverFee(collateralToken, position.size, position.entryRolloverRate);
  //         position.marginFee = position.size.div(1000);
  //         position.fee = position.rolloverFee.add(position.marginFee);

  //         const THRESHOLD = 5000;
  //         const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
  //         position.danger = collateralDiffPercent.gt(THRESHOLD);

  //         return position;
  //       } catch (ex) {
  //         console.error(ex);
  //       }
  //     })
  //   );

  //   return ret.filter(Boolean);
  // });

  // return positions;
  return [];
}

export function useAllOrders(chainId, signer) {
  const url = `${getServerBaseUrl(chainId)}/all_orders`;

  const { data: allOrders } = useSWR(url && url, {
    dedupingInterval: 5000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const key = allOrders ? allOrders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;

  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(signer, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      allOrders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {
          console.error(ex);
        }
      })
    );
  });

  return orders.filter(
    (order) =>
      order && ((order.triggerRatio && order.triggerRatio.gt(0)) || (order.triggerPrice && order.triggerPrice.gt(0)))
  );
}

export function usePositionsForOrders(chainId, signer, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(signer, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          console.error(ex);
        }
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTrades(chainId, account, forSingleAccount, afterId, type) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/actions?account=${account}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/actions`;

  if (url && ((afterId && afterId.length > 0) || (type && type.length > 0))) {
    const urlItem = new URL(url);
    if (afterId && afterId.length > 0) {
      urlItem.searchParams.append("afterId", afterId);
    }
    if (type && type.length > 0) {
      urlItem.searchParams.append("type", type);
    }
    url = urlItem.toString();
  }

  const { data: trades, mutate: updateTrades } = useSWR(url && url, {
    dedupingInterval: 10000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  if (trades) {
    trades.sort((item0, item1) => {
      const data0 = item0.data;
      const data1 = item1.data;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useTokenActions(chainId, token, actionType) {
  const url = `${getServerBaseUrl(chainId)}/token_actions?token=${token}&actionType=${actionType}`;

  const { data: actions, mutate: updateActions } = useSWR(url && url, {
    dedupingInterval: 3000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  return { actions, updateActions };
}

export function useToken24hVolume(chainId, token, type) {
  const url = token ? `${getServerBaseUrl(chainId)}/token_24h_volume?token=${token}&type=${type}` : undefined;

  const { data: token24hVolume, mutate: updateToken24hVolume } = useSWR(url && url, {
    dedupingInterval: 3000,
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  return { token24hVolume, updateToken24hVolume };
}

export function useMinExecutionFee(signer, active, chainId, infoTokens) {
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const swapRouterAddress = getContract(chainId, "SwapRouter");
  const liquidityRouterAddress = getContract(chainId, "LiquidityRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const { data: positionMinExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
    fetcher: contractFetcher(signer, PositionRouter),
  });

  const { data: swapMinExecutionFee } = useSWR([active, chainId, swapRouterAddress, "minExecutionFee"], {
    fetcher: contractFetcher(signer, SwapRouter),
  });

  const { data: liquidityMinExecutionFee } = useSWR([active, chainId, liquidityRouterAddress, "minExecutionFee"], {
    fetcher: contractFetcher(signer, LiquidityRouter),
  });

  const { data: gasPrice } = useSWR(["gasPrice", chainId], {
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(signer, chainId);
        if (!provider) {
          resolve(undefined);
          return;
        }

        try {
          const gasPrice = await provider.getGasPrice();
          resolve(gasPrice);
        } catch (e) {
          console.error(e);
        }
      });
    },
  });

  let multiplier;

  // multiplier for Ethereum is just the average gas usage
  if (chainId === MAINNET || chainId === GOERLI) {
    multiplier = 700000;
  }

  if (chainId === ARBITRUM || chainId === ARBITRUM_TESTNET) {
    multiplier = 2150000;
  }

  if (chainId === OPTIMISM_GOERLI) {
    multiplier = 215000;
  }

  if (chainId === BASE) {
    multiplier = 2150000;
  }

  if (chainId === OPTIMISM) {
    multiplier = 2150000;
  }

  // multiplier for Avalanche is just the average gas usage
  if (chainId === AVALANCHE) {
    multiplier = 700000;
  }

  if (chainId === MOONBEAM) {
    multiplier = 700000;
  }

  let finalPositionExecutionFee = positionMinExecutionFee;
  let finalSwapExecutionFee = swapMinExecutionFee;
  let finalLiquidityExecutionFee = liquidityMinExecutionFee;

  if (gasPrice) {
    const estimatedExecutionFee = gasPrice.mul(multiplier);

    if (positionMinExecutionFee && estimatedExecutionFee.gt(positionMinExecutionFee)) {
      finalPositionExecutionFee = estimatedExecutionFee;
    }

    if (swapMinExecutionFee && estimatedExecutionFee.gt(swapMinExecutionFee)) {
      finalSwapExecutionFee = estimatedExecutionFee;
    }

    if (liquidityMinExecutionFee && estimatedExecutionFee.gt(liquidityMinExecutionFee)) {
      finalLiquidityExecutionFee = estimatedExecutionFee;
    }
  }

  const finalPositionExecutionFeeUSD = getUsd(finalPositionExecutionFee, nativeTokenAddress, false, infoTokens);
  const isPositionFeeFeeHigh = finalPositionExecutionFeeUSD?.gt(
    expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS)
  );

  const finalSwapExecutionFeeUSD = getUsd(finalSwapExecutionFee, nativeTokenAddress, false, infoTokens);
  const isSwapFeeFeeHigh = finalSwapExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS));

  const finalLiquidityExecutionFeeUSD = getUsd(finalLiquidityExecutionFee, nativeTokenAddress, false, infoTokens);
  const isLiquidityFeeFeeHigh = finalLiquidityExecutionFeeUSD?.gt(
    expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS)
  );

  const errorMessage = `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

  return {
    positionMinExecutionFee: finalPositionExecutionFee,
    positionMinExecutionFeeUSD: finalPositionExecutionFeeUSD,
    positionMinExecutionFeeErrorMessage: isPositionFeeFeeHigh && errorMessage,
    swapMinExecutionFee: finalSwapExecutionFee,
    swapMinExecutionFeeUSD: finalSwapExecutionFeeUSD,
    swapMinExecutionFeeErrorMessage: isSwapFeeFeeHigh && errorMessage,
    liquidityMinExecutionFee: finalLiquidityExecutionFee,
    liquidityMinExecutionFeeUSD: finalLiquidityExecutionFeeUSD,
    liquidityMinExecutionFeeErrorMessage: isLiquidityFeeFeeHigh && errorMessage,
  };
}

// export function useStakedFxdxSupply(signer, active) {
//   const fxdxAddressArb = getContract(ARBITRUM, "FXDX");
//   const stakedFxdxTrackerAddressArb = getContract(ARBITRUM, "StakedFxdxTracker");

//   const { data: arbData, mutate: arbMutate } = useSWR(
//     [`StakeV2:stakedFxdxSupply:${active}`, ARBITRUM, fxdxAddressArb, "balanceOf", stakedFxdxTrackerAddressArb],
//     {
//       fetcher: contractFetcher(signer, Token),
//     }
//   );

//   const fxdxAddressAvax = getContract(AVALANCHE, "FXDX");
//   const stakedFxdxTrackerAddressAvax = getContract(AVALANCHE, "StakedFxdxTracker");

//   const { data: avaxData, mutate: avaxMutate } = useSWR(
//     [`StakeV2:stakedFxdxSupply:${active}`, AVALANCHE, fxdxAddressAvax, "balanceOf", stakedFxdxTrackerAddressAvax],
//     {
//       fetcher: contractFetcher(undefined, Token),
//     }
//   );

//   let data;
//   if (arbData && avaxData) {
//     data = arbData.add(avaxData);
//   }

//   const mutate = () => {
//     arbMutate();
//     avaxMutate();
//   };

//   return { data, mutate };
// }

export function useHasOutdatedUi() {
  const url = getServerUrl(IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM, "/ui_version");
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  let hasOutdatedUi = false;

  if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
    hasOutdatedUi = true;
  }

  return { data: hasOutdatedUi, mutate };
}

export function useFxdxPrice(chainId, libraries, active) {
  // const arbitrumsigner = libraries && libraries.arbitrum ? libraries.arbitrum : undefined;
  // const { data: fxdxPriceFromArbitrum, mutate: mutateFromArbitrum } = useFxdxPriceFromArbitrum(arbitrumsigner, active);
  // const { data: fxdxPriceFromAvalanche, mutate: mutateFromAvalanche } = useFxdxPriceFromAvalanche();

  // const fxdxPrice = chainId === ARBITRUM ? fxdxPriceFromArbitrum : fxdxPriceFromAvalanche;
  // const mutate = useCallback(() => {
  //   mutateFromAvalanche();
  //   mutateFromArbitrum();
  // }, [mutateFromAvalanche, mutateFromArbitrum]);

  // return {
  //   fxdxPrice,
  //   fxdxPriceFromArbitrum,
  //   fxdxPriceFromAvalanche,
  //   mutate,
  // };

  const url = "https://token.fxdx.exchange/tokendata";
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  let fxdxPriceMEXC = data ? parseInt( parseFloat(data.fxdxPrice) * 10 ** 10 ) : "0";

  return {
    fxdxPrice: expandDecimals(fxdxPriceMEXC, 20),
    // fxdxPriceFromEthereum: expandDecimals(1, 29),
    fxdxPriceFromBase: expandDecimals(fxdxPriceMEXC, 20),
    fxdxPriceFromOptimism: expandDecimals(1, 29),
    // fxdxPriceFromMoonBeam: expandDecimals(1, 29),
  };
}

// use only the supply endpoint on arbitrum, it includes the supply on avalanche
export function useTotalFxdxSupply() {
  // const fxdxSupplyUrl = getServerUrl(IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM, "/fxdx_supply");

  // const { data: fxdxSupply, mutate: updateFxdxSupply } = useSWR([fxdxSupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });

  const url = "https://token.fxdx.exchange/tokendata";
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  const fxdxSupply = data ? parseInt( parseFloat(data.fxdxCirculatingSupply) * 10 ** 5) : "0";

  return {
    total: fxdxSupply ? expandDecimals(fxdxSupply, 13) : 0,
    // mutate: updateFxdxSupply,
  };
}

export function useTotalStakedFxdx() {
  const url = "https://token.fxdx.exchange/tokendata";
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  const stakedFXDXUSD = data ? parseFloat(data.fxdxStakedBalanceUSD): "0";
  const stakedFXDX = data ? parseFloat(data.fxdxStakedBalance): "0";
  
  return {
    stakedFXDX,
    stakedFXDXUSD
  };
}
 

// export async function getTotalStakedFXDX(signer, chainId) {

//   const stakedFxdxVaultAddress = getContract(chainId, "StakedFxdxVault");
//   const stakeFxdxVault = new ethers.Contract(stakedFxdxVaultAddress, stakeFxdxVaultData.abi, signer);
//   const totalStakedFxdxReserves = await stakeFxdxVault.totalReserves();
  
//   const fxdxAddress = getContract(chainId, "FXDXV2");
//   const token = new ethers.Contract(fxdxAddress, fxdxData.abi, signer);
//   const tokenDecimals = await token.decimals();

//   return (totalStakedFxdxReserves / expandDecimals(10, tokenDecimals)) * data.fxdxPrice;
// }

//stake fxdx
export function useTotalFxdxStaked() {
  let totalStakedFxdx = useRef(bigNumberify(0));

  // const etherChainId = GOERLI;
  // const stakedFxdxTrackerAddressEthereum = getContract(etherChainId, "StakedFxdxTracker");
  // const { data: stakedFxdxSupplyEthereum, mutate: updateStakedFxdxSupplyEthereum } = useSWR(
  //   IS_TESTNET && [
  //     `StakeV2:stakedFxdxSupply:${etherChainId}`,
  //     etherChainId,
  //     getContract(etherChainId, "FXDX"),
  //     "balanceOf",
  //     stakedFxdxTrackerAddressEthereum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  const baseChainId = BASE;
  const stakedFxdxTrackerAddressBase = getContract(baseChainId, "StakedFxdxTracker");
  const { data: stakedFxdxSupplyBase, mutate: updateStakedFxdxSupplyBase } = useSWR(
    IS_TESTNET && [
      `StakeV2:stakedFxdxSupply:${baseChainId}`,
      baseChainId,
      getContract(baseChainId, "FXDX"),
      "balanceOf",
      stakedFxdxTrackerAddressBase,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const optimismChainId = IS_TESTNET ? OPTIMISM_GOERLI : OPTIMISM;
  const stakedFxdxTrackerAddressOptimism = getContract(optimismChainId, "StakedFxdxTracker");
  const { data: stakedFxdxSupplyOptimism, mutate: updateStakedFxdxSupplyOptimism } = useSWR(
    IS_TESTNET && [
      `StakeV2:stakedFxdxSupply:${optimismChainId}`,
      optimismChainId,
      getContract(optimismChainId, "FXDX"),
      "balanceOf",
      stakedFxdxTrackerAddressOptimism,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  // const stakedFxdxTrackerAddressMoonBeam = getContract(MOONBEAM, "StakedFxdxTracker");
  // const { data: stakedFxdxSupplyMoonBeam, mutate: updateStakedFxdxSupplyMoonBeam } = useSWR(
  //   !IS_TESTNET && [
  //     `StakeV2:stakedFxdxSupply:${MOONBEAM}`,
  //     MOONBEAM,
  //     getContract(MOONBEAM, "FXDX"),
  //     "balanceOf",
  //     stakedFxdxTrackerAddressMoonBeam,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  const mutate = useCallback(() => {
    if (!IS_TESTNET) {
      updateStakedFxdxSupplyBase();
    }
    // updateStakedFxdxSupplyEthereum();
    updateStakedFxdxSupplyOptimism();
    // updateStakedFxdxSupplyMoonBeam();
  }, [
    // updateStakedFxdxSupplyEthereum,
    updateStakedFxdxSupplyBase,
    updateStakedFxdxSupplyOptimism,
    // updateStakedFxdxSupplyMoonBeam,
  ]);

  if (stakedFxdxSupplyOptimism) {
    let total = bigNumberify(stakedFxdxSupplyOptimism);
    if (!IS_TESTNET) {
      total = total.add(stakedFxdxSupplyBase);
    }
    totalStakedFxdx.current = total;
  }

  // if (IS_TESTNET && /* stakedFxdxSupplyEthereum &&*/ stakedFxdxSupplyOptimism) {
  //   // let total = bigNumberify(stakedFxdxSupplyEthereum).add(stakedFxdxSupplyOptimism);
  //   let total = bigNumberify(stakedFxdxSupplyOptimism);
  //   totalStakedFxdx.current = total;
  // } else if (!IS_TESTNET && stakedFxdxSupplyMoonBeam) {
  //   let total = bigNumberify(stakedFxdxSupplyMoonBeam);
  //   totalStakedFxdx.current = total;
  // }

  return {
    base: IS_TESTNET ? undefined : stakedFxdxSupplyBase,
    // ethereum: stakedFxdxSupplyEthereum,
    optimism: stakedFxdxSupplyOptimism,
    // moonbeam: stakedFxdxSupplyMoonBeam,
    total: totalStakedFxdx.current,
    mutate,
  };

  // const stakedFxdxTrackerAddressArbitrum = getContract(ARBITRUM, "StakedFxdxTracker");
  // const { data: stakedFxdxSupplyArbitrum, mutate: updateStakedFxdxSupplyArbitrum } = useSWR(
  //   [
  //     `StakeV2:stakedFxdxSupply:${ARBITRUM}`,
  //     ARBITRUM,
  //     getContract(ARBITRUM, "FXDX"),
  //     "balanceOf",
  //     stakedFxdxTrackerAddressArbitrum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  // const stakedFxdxTrackerAddressAvax = getContract(AVALANCHE, "StakedFxdxTracker");
  // const { data: stakedFxdxSupplyAvax, mutate: updateStakedFxdxSupplyAvax } = useSWR(
  //   [
  //     `StakeV2:stakedFxdxSupply:${AVALANCHE}`,
  //     AVALANCHE,
  //     getContract(AVALANCHE, "FXDX"),
  //     "balanceOf",
  //     stakedFxdxTrackerAddressAvax,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  // const mutate = useCallback(() => {
  //   updateStakedFxdxSupplyArbitrum();
  //   updateStakedFxdxSupplyAvax();
  // }, [
  //   updateStakedFxdxSupplyArbitrum,
  //   updateStakedFxdxSupplyAvax
  // ]);

  // if (stakedFxdxSupplyArbitrum && stakedFxdxSupplyAvax) {
  //   let total = bigNumberify(stakedFxdxSupplyArbitrum).add(stakedFxdxSupplyAvax);
  //   totalStakedFxdx.current = total;
  // }

  // return {
  //   avax: stakedFxdxSupplyAvax,
  //   arbitrum: stakedFxdxSupplyArbitrum,
  //   total: totalStakedFxdx.current,
  //   mutate,
  // };
}

export function useTotalFxdxInLiquidity() {
  // let poolAddressArbitrum = getContract(ARBITRUM, "UniswapFxdxEthPool");
  // let poolAddressAvax = getContract(AVALANCHE, "TraderJoeFxdxAvaxPool");
  // let totalFXDX = useRef(bigNumberify(0));

  // const { data: fxdxInLiquidityOnArbitrum, mutate: mutateFXDXInLiquidityOnArbitrum } = useSWR(
  //   [`StakeV2:fxdxInLiquidity:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "FXDX"), "balanceOf", poolAddressArbitrum],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: fxdxInLiquidityOnAvax, mutate: mutateFXDXInLiquidityOnAvax } = useSWR(
  //   [`StakeV2:fxdxInLiquidity:${AVALANCHE}`, AVALANCHE, getContract(AVALANCHE, "FXDX"), "balanceOf", poolAddressAvax],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const mutate = useCallback(() => {
  //   mutateFXDXInLiquidityOnArbitrum();
  //   mutateFXDXInLiquidityOnAvax();
  // }, [mutateFXDXInLiquidityOnArbitrum, mutateFXDXInLiquidityOnAvax]);

  // if (fxdxInLiquidityOnAvax && fxdxInLiquidityOnArbitrum) {
  //   let total = bigNumberify(fxdxInLiquidityOnArbitrum).add(fxdxInLiquidityOnAvax);
  //   totalFXDX.current = total;
  // }
  // return {
  //   avax: fxdxInLiquidityOnAvax,
  //   arbitrum: fxdxInLiquidityOnArbitrum,
  //   total: totalFXDX.current,
  //   mutate,
  // };
  return {
    base: bigNumberify(0),
    // ethereum: bigNumberify(0),
    optimism: bigNumberify(0),
    // moonbeam: bigNumberify(0),
    total: bigNumberify(0),
  };
}

// function useFxdxPriceFromAvalanche() {
//   const poolAddress = getContract(AVALANCHE, "TraderJoeFxdxAvaxPool");

//   const { data, mutate: updateReserves } = useSWR(["TraderJoeFxdxAvaxReserves", AVALANCHE, poolAddress, "getReserves"], {
//     fetcher: contractFetcher(undefined, UniswapV2),
//   });
//   const { _reserve0: fxdxReserve, _reserve1: avaxReserve } = data || {};

//   const vaultAddress = getContract(AVALANCHE, "Vault");
//   const avaxAddress = getTokenBySymbol(AVALANCHE, "WAVAX").address;
//   const { data: avaxPrice, mutate: updateAvaxPrice } = useSWR(
//     [`StakeV2:avaxPrice`, AVALANCHE, vaultAddress, "getMinPrice", avaxAddress],
//     {
//       fetcher: contractFetcher(undefined, Vault),
//     }
//   );

//   const PRECISION = bigNumberify(10).pow(18);
//   let fxdxPrice;
//   if (avaxReserve && fxdxReserve && avaxPrice) {
//     fxdxPrice = avaxReserve.mul(PRECISION).div(fxdxReserve).mul(avaxPrice).div(PRECISION);
//   }

//   const mutate = useCallback(() => {
//     updateReserves(undefined, true);
//     updateAvaxPrice(undefined, true);
//   }, [updateReserves, updateAvaxPrice]);

//   return { data: fxdxPrice, mutate };
// }

// function useFxdxPriceFromArbitrum(signer, active) {
//   const poolAddress = getContract(ARBITRUM, "UniswapFxdxEthPool");
//   const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR(
//     [`StakeV2:uniPoolSlot0:${active}`, ARBITRUM, poolAddress, "slot0"],
//     {
//       fetcher: contractFetcher(signer, UniPool),
//     }
//   );

//   const vaultAddress = getContract(ARBITRUM, "Vault");
//   const ethAddress = getTokenBySymbol(ARBITRUM, "WETH").address;
//   const { data: ethPrice, mutate: updateEthPrice } = useSWR(
//     [`StakeV2:ethPrice:${active}`, ARBITRUM, vaultAddress, "getMinPrice", ethAddress],
//     {
//       fetcher: contractFetcher(signer, Vault),
//     }
//   );

//   const fxdxPrice = useMemo(() => {
//     if (uniPoolSlot0 && ethPrice) {
//       const tokenA = new UniToken(ARBITRUM, ethAddress, 18, "SYMBOL", "NAME");

//       const fxdxAddress = getContract(ARBITRUM, "FXDX");
//       const tokenB = new UniToken(ARBITRUM, fxdxAddress, 18, "SYMBOL", "NAME");

//       const pool = new Pool(
//         tokenA, // tokenA
//         tokenB, // tokenB
//         10000, // fee
//         uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
//         1, // liquidity
//         uniPoolSlot0.tick, // tickCurrent
//         []
//       );

//       const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
//       const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
//       return poolTokenPriceAmount.mul(ethPrice).div(expandDecimals(1, 18));
//     }
//   }, [ethPrice, uniPoolSlot0, ethAddress]);

//   const mutate = useCallback(() => {
//     updateUniPoolSlot0(undefined, true);
//     updateEthPrice(undefined, true);
//   }, [updateEthPrice, updateUniPoolSlot0]);

//   return { data: fxdxPrice, mutate };
// }

export async function approvePlugin(chainId, pluginAddress, { signer, pendingTxns, setPendingTxns, sentMsg, failMsg }) {
  const routerAddress = getContract(chainId, "Router");
  const contract = new ethers.Contract(routerAddress, Router.abi, signer);
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  });
}

export async function createSwapOrder(
  chainId,
  signer,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  chainId,
  signer,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  signer,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, signer, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, signer, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, signer, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export function handleCancelOrder(chainId, signer, order, opts) {
  let func;
  if (order.type === SWAP) {
    func = cancelSwapOrder;
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder;
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder;
  }

  return func(chainId, signer, order.index, {
    successMsg: t`Order cancelled.`,
    failMsg: t`Cancel failed.`,
    sentMsg: t`Cancel submitted.`,
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
  });
}

export async function cancelMultipleOrders(chainId, signer, allIndexes = [], opts) {
  const ordersWithTypes = groupBy(allIndexes, (v) => v.split("-")[0]);
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return;
    return ordersWithTypes[key].map((d) => d.split("-")[1]);
  }
  // params order => swap, increase, decrease
  const params = ["Swap", "Increase", "Decrease"].map((key) => getIndexes(key) || []);
  const method = "cancelMultiple";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);
  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  signer,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  signer,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(chainId, signer, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, signer);

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, signer, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, signer);
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, signer, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, signer, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, signer, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, signer, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, signer, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, signer, "executeDecreaseOrder", account, index, feeReceiver, opts);
}