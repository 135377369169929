import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { ethers } from "ethers";

import { getContract } from "config/contracts";

import Footer from "components/Footer/Footer";

import Reader from "abis/Reader.json";
import FaucetManager from "abis/FaucetManager.json";

import { Trans, t } from "@lingui/macro";

import "./Faucet.css";
import { callContract, contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import TokenSelector from "components/Exchange/TokenSelector";
import { getConstant } from "config/chains";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getToken, getTokenBySymbol, getWhitelistedTokens } from "config/tokens";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { usePrevious } from "lib/usePrevious";
import { formatAmountFree } from "lib/numbers";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const getFaucetData = (rawData, tokenAddresses, nativeTokenAddress) => {
  if (!rawData) {
    return;
  }

  const data = {};
  for (let i = 0; i < tokenAddresses.length; i++) {
    data[tokenAddresses[i]] = {
      faucetAmount: rawData[i * 2],
      claimedAt: rawData[i * 2 + 1],
    };

    if (tokenAddresses[i] === nativeTokenAddress) {
      data[ethers.constants.AddressZero] = {
        faucetAmount: rawData[i * 2],
        claimedAt: rawData[i * 2 + 1],
      };
    }
  }

  return data;
};

export default function Faucet(props) {
  const { setPendingTxns } = props;
  const { isConnected: active, address: account, signer, chainId } = useWallet();
  const { openConnectModal } = useConnectModal();

  const defaultIndexTokenSymbol = getConstant(chainId, "defaultIndexTokenSymbol");

  const [tokenAddress, setTokenAddress] = useLocalStorageByChainId(
    chainId,
    "Exchange-faucet-token",
    getTokenBySymbol(chainId, defaultIndexTokenSymbol).address
  );

  const tokens = getWhitelistedTokens(chainId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWaitingForClaim, setIsWaitingForClaim] = useState(false);

  const readerAddress = getContract(chainId, "Reader");
  const faucetManagerAddress = getContract(chainId, "FaucetManager");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const queryTokenAddresses = tokens.filter((token) => !token.isNative).map((token) => token.address);
  const { data: faucetRawData } = useSWR(
    active ? [active, chainId, faucetManagerAddress, "getStates", account] : undefined,
    {
      fetcher: contractFetcher(signer, FaucetManager, [queryTokenAddresses]),
    }
  );

  const faucetData = getFaucetData(faucetRawData, queryTokenAddresses, nativeTokenAddress);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `FaucetManager:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(signer, Reader, [tokenAddresses]),
    }
  );

  const { infoTokens } = useInfoTokens(signer, chainId, active, tokenBalances, undefined);

  const canClaim =
    account &&
    faucetData &&
    faucetData[tokenAddress] &&
    faucetData[tokenAddress].faucetAmount.gt(0) &&
    faucetData[tokenAddress].claimedAt.eq(0);

  const prevCanClaim = usePrevious(canClaim);

  const selectedToken = getToken(chainId, tokenAddress);

  useEffect(() => {
    if (!canClaim && prevCanClaim && isWaitingForClaim) {
      setIsWaitingForClaim(false);
    }
  }, [canClaim, prevCanClaim, isWaitingForClaim]);

  const getError = () => {
    if (!account) {
      return t`Connect Wallet`;
    }

    if (!faucetData || !faucetData[tokenAddress]) {
      return t`Loading Faucet Data ...`;
    }

    if (faucetData[tokenAddress].faucetAmount.eq(0)) {
      return t`Faucet Not Allowed for ${selectedToken.symbol}`;
    }

    if (faucetData[tokenAddress].claimedAt.gt(0)) {
      return t`Already Claimed ${selectedToken.symbol}`;
    }
  };

  const isPrimaryEnabled = () => {
    if (!account) {
      return true;
    }

    const error = getError();
    if (error) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isWaitingForClaim) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isSubmitting) {
      return t`Submitting Claim...`;
    }
    if (isWaitingForClaim) {
      return t`Claiming`;
    }

    return t`Claim ${selectedToken.symbol}`;
  };

  const onClickPrimary = () => {
    if (!account) {
      openConnectModal();
      return;
    }

    setIsSubmitting(true);
    const contract = new ethers.Contract(faucetManagerAddress, FaucetManager.abi, signer);

    const claimTokenAddress = ethers.constants.AddressZero === tokenAddress ? nativeTokenAddress : tokenAddress;
    const shouldUnwrap = ethers.constants.AddressZero === tokenAddress;

    callContract(chainId, contract, "claimToken", [claimTokenAddress, shouldUnwrap], {
      sentMsg: t`Claim submitted!`,
      failMsg: t`Claim failed.`,
      successMsg: t`Claimed ${formatAmountFree(faucetData[tokenAddress].faucetAmount, selectedToken.decimals, 4)} ${
        selectedToken.symbol
      }`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsWaitingForClaim(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onSelectToken = (token) => {
    setTokenAddress(token.address);
  };

  return (
    <div className="Faucet Page page-layout">
      <div className="Page-title-section">
        <div className="Page-title">
          <Trans>Testnet Faucet</Trans>
        </div>
        <div className="Page-description">
          <Trans>
            Prior to using Fxdx Exchange, you will need to obtain testnet funds (BTC, ETH, WETH, FETH, USDC, or USDT).
            You can receive the amount corresponding to 100 USD per token (ETH and WETH will share the amount).
          </Trans>
        </div>
      </div>
      <div className="Page-content">
        <div className="input-form">
          <div className="token-select-label">
            <Trans>Select a Token</Trans>
          </div>
          <TokenSelector
            label={t`Select Token`}
            chainId={chainId}
            tokenAddress={tokenAddress}
            onSelectToken={onSelectToken}
            tokens={tokens}
            infoTokens={infoTokens}
            showMintingCap={false}
            showTokenImgInDropdown={true}
            showBalances={false}
          />
          <div className="input-row">
            <button
              className="App-cta Exchange-swap-button"
              disabled={!isPrimaryEnabled()}
              onClick={() => onClickPrimary()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
